import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import LoadingSpinner from "../../../../LoadingSpinner";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";



function WorkItemBulkUpdation() {
    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const { addToast } = useToasts();
    const nav = processingDashboardParam.nav;
    const userRoles = processingDashboardParam.userRoles;
    const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
    let currentAcitvity = "";
    if (typeof processingDashboardParam.row.activityName !== "undefined") {
      currentAcitvity = processingDashboardParam.row.activityName;
    } else {
      currentAcitvity = processingDashboardParam.currentAcitvity;
    }
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
      navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
      navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }
    const activityID = processingDashboardParam.nav[0].workflowStateID;


    const [ bulkUpdateFileDetails , setBulkUpdateFileDetails] = useState([]);


    const [companyDepartment, setcompanyDepartment] = useState("");
    const [SelectedClientLocation, setSelectedClientLocation] = useState("");
    const [locationClient, setlocationClient] = useState("");
    const [companyDeparmentID, setcompanyDeparmentID] = useState("");
    const [locationClientID, setlocationClientID] = useState("");
    const [processSubProcessID, setprocessSubProcessID] = useState("");
    const [processSubProcess, setprocessSubProcess] = useState("");
    const [selectedSubprocess, setSelectedSubprocess] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [departments, setDepartments] = useState([]);
    const [clientLocations, setClientLocations] = useState([]);
    const [processSubprocess, setprocessSubprocess] = useState([]);


    const [workitemFieldID, setWorkitemFieldID] = useState([]);
    const [workitemField, setWorkitemField] = useState("");
    const WorkitemIdRef = useRef(null);
    const [isLoading, set_IsLoading] = useState(false);
    const [SaveDisabled, setSaveDisabled] = useState(true);
    const [UploadDisabled, setUploadDisabled] = useState(true);


    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const {REACT_APP_GetBulkUpdateFileConfigDetails } = process.env;


    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
      };

    const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        setWorkitemFieldID([]);
        setSaveDisabled(true);
        setUploadDisabled(true);
    
        if (e.target.value == "Select") {
          setprocessSubprocess([]);
          setWorkitemFieldID([]);
          setSaveDisabled(true);
          setUploadDisabled(true);
        }
        setSelectedLocation(e.target.value);
        setSelectedClientLocation(e.target.value.split("/")[1]);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: e.target.value.split("/")[1],
        };
    
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      };
    
      const FetchLocationClientsByGuid = (e) => { 
        setcompanyDeparmentID("");
        setcompanyDepartment("");
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        setWorkitemFieldID([]);
          setSaveDisabled(true);
          setUploadDisabled(true);
        
        if (e.target.value == "Select") {
          setprocessSubprocess([]);
          setClientLocations([]);
          setWorkitemFieldID([]);
          setSaveDisabled(true);
          setUploadDisabled(true);
        }
        if (e.target.value !== "Select") {
          setSelectedDepartment(e.target.value);
          let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: e.target.value.split("/")[1],
          };
    
          let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status == 200) {
                setClientLocations(result.data);
              } else if (result.status === 401) {
                //unauthorized
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              //if (result.status === 401) {
              console.log("ERROR: ", error);
              //   navigate("/");
              // }
            });
        }
      };
    
      const FetchDepartmentbyGUID = () => {
            let body = {
              memberShipGuid: sessionStorage.getItem("GUID"),
            };
    
            let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
            let req = new Request(`${loginUrl}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
              },
              body: JSON.stringify(body),
            });
            fetch(req)
              .then((res) => res.json())
              .then((result) => {
                if (result.status == 200) {
                  setDepartments(result.data);
                } else if (result.status === 401) {
                  //unauthorized
                  addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  sessionStorage.clear();
                  sessionStorage.clear();
                  navigate("/");
                }
              })
              .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
              });
      };
    
      const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        setWorkitemFieldID([]);
        setSaveDisabled(true);
        setUploadDisabled(true);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          departmentId: departmentId,
        };
    
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setClientLocations(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      };
    
      const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        setWorkitemFieldID([]);
        setSaveDisabled(true);
        setUploadDisabled(true);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: clientId,
        };
    
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
              //GetActivitiesForSubprocess();
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
    
      const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
      ) => {
        setcompanyDeparmentID(companyDeparmentID);
        setSelectedDepartment(companyDeparmentID);
        //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        setSelectedLocation(locationClientID);
        //sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
        FetchSubprocessName(processSubProcessID, locationClientID);         
      };
      const subprocesOnchange = (SubProcessID) => {
        debugger;
        if( SubProcessID != undefined){
         if(SubProcessID.target.value != "Select" ){
            setBulkUpdateFileDetails([]);
        GetBulkUpdatefileName(SubProcessID.target.value);

        }
        else if (SubProcessID.target.value == "Select"){
            setBulkUpdateFileDetails([]);
        }
        }
      }

      const FetchSubprocessName = (subprocessId, clientId) => {
        //debugger;
        setWorkitemFieldID([]);
        setSaveDisabled(true);
        setUploadDisabled(true);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: subprocessId,
          clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };


      useEffect(() => {
        debugger;
        FetchDefaultValueForSelectedSubprocess(
          sessionStorage.getItem("selectedDepartment"),
          sessionStorage.getItem("selectedLocation"),
          sessionStorage.getItem("ProcessId") 
        );
        setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
        FetchDepartmentbyGUID();
        GetBulkUpdatefileName(sessionStorage.getItem("ProcessId"));
        subprocesOnchange();
        
      }, []);


      const GetBulkUpdatefileName = (subProcessID) => {
        debugger;
      let body = {
      subProcessID: subProcessID,
      };
      let loginUrl = getAPIURL(REACT_APP_GetBulkUpdateFileConfigDetails);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
             debugger;
            setBulkUpdateFileDetails(result.data)
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    };

    return (
        <>
          <Header onpreferenceShow={null} pageName="Workitem Bulk Updation" />
          <ApplicationUserContext.Provider
            value={processingDashboardParam.userRoles}
          >
            <DashboardContext.Provider value={processingDashboardParam.nav}>
              <NavDataContext.Provider value={navData}>
                <Nav selectedMenu="admin" />
              </NavDataContext.Provider>
            </DashboardContext.Provider>
          </ApplicationUserContext.Provider>
          
          {isLoading ? (
            <LoadingSpinner />
          ) : (
          <>
          <div className="centerSection">
            <div className="pageBody">
              <div className="dashBoxs mb-3">
                <div className="formWrap">
                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label"> Company Department *</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                        >
                          <option>Select</option>
                          {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString == companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label">Location Client *</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                        >
                          <option>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                                "/" +
                                location.clientIDString
                              }
                              selected={location.clientIDString == locationClientID}
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
      <div className="mb-3">
        <label className="form-label">Process SubProcess *</label>
        <select
          className="form-control form-control-sm"
          onLoadedDataCapture={(e) => subprocesOnchange(e)}
      onLoad={(e) => subprocesOnchange(e)}
      onChange={(e) => subprocesOnchange(e)}
        >
          <option>Select</option>
          {processSubprocess.map((subprocess) => (
            <option
              value={subprocess.subProcessIDString}
              selected={subprocess.subProcessIDString === processSubProcessID}
            >
              {subprocess.processSubProcess}
            </option>
          ))}
        </select>
        </div>
          </div>
        <div className="pageBody">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="dateFrom">Date From</label>
                        <br></br>
                            <input
                            type="date"
                            id="dateFrom"
                            name="dateFrom"
                            defaultValue={new Date(Date.now() - 6 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]}
                            max={new Date(Date.now() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]}
                            ></input>
                                </div>
                                </div>

                <div className="col-4">
                    <div className="mb-3">
                    <label htmlFor="dateFrom">Date To</label>
                        <br></br>
                            <input
                            type="date"
                            id="dateTo"
                            name="dateTo"
                            defaultValue={new Date(Date.now()+ 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]}
                            max={new Date(Date.now() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]}
                            ></input>
                        </div>
                        </div>

                        <div className="col-4">
                    <div className="mb-3">
                    <label className="form-label">Bulk Update File Name</label>
                    <select
                      className="form-control form-control-sm"
                      
                      
                    >
                      <option>Select</option>
                      debugger;
                      {bulkUpdateFileDetails.map((item) => ( 
                        <option
                        key={item.subProcessBulkUpdateFileDetailID}
                        value={item.subProcessBulkUpdateFileDetailID}
                        //selected={workitemFields.workitemFieldsIDString === workitemFieldID}
                        >
                        {item.bulkUpdateFileName}
                        </option>
                      ))}
                    </select>
                    </div>
                    </div>
                    <div className="pageBody">
            <div className="formWrap">
              <div className="row align-items-end">
                    <div className="col-4">
                        <div className="mb-3">
                            {/* <label className="form-label">Choose File</label> */}
                            <div className="input-group">
                                <input type="file" className="form-control" />
                                <button className="btn btn-primary" type="button">Upload</button>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                  </div>
                
              </div>   
            </div>
            </div>
            </div>
                
              </div>   
            </div>
            </div>
                
          <Footer />
        </>
        
      )};
      </> 
      );
}

export default WorkItemBulkUpdation;