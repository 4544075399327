import React, { useState, useEffect , useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
 
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import {
    Checkbox,
    ListItemText,
    Input,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    Container,
    ListItem,
    TextField,
} from "@material-ui/core";
 
 
function ClientDailyInfo() {
    const { addToast } = useToasts();
 
    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
    const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
    const { React_APP_GetClientDailySummary } = process.env;
    const { React_APP_SaveClientDailySummary } = process.env;
    const { React_APP_GetClientDailySummaryForSelectedDate } = process.env;
    const { React_APP_DeleteRecord } = process.env;
 
    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const userRoles = processingDashboardParam.userRoles;
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }
 
    const [companyDeparmentID, setcompanyDeparmentID] = useState("");
    const [companyDepartment, setcompanyDepartment] = useState("");
    const [locationClientID, setlocationClientID] = useState("");
    const [locationClient, setlocationClient] = useState("");
    const [processSubProcessID, setprocessSubProcessID] = useState("");
    const [processSubProcess, setprocessSubProcess] = useState("");
    const [clientLocations, setClientLocations] = useState([]);
    const [processSubprocess, setprocessSubprocess] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [departments, setDepartments] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [SelectedClientLocation, setSelectedClientLocation] = useState("");

    const [ExcelColumns,setExcelColumns] = useState([]);
    const [numberOfComplaints, setNumberOfComplaints] = useState("");
    const [numberOfErrors, setNumberOfErrors] = useState("");
    const [feedbackScore, setFeedbackScore] = useState("");
    const [dateText, setDateText] = useState("");
    
    const dateTimeFromRef = useRef("");
    const dateTimeToRef = useRef("");

    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };
    const handleApply = (event, picker) => {    
        dateTimeToRef.current=picker.endDate.toISOString();
        dateTimeFromRef.current=picker.startDate.toISOString();
    };
    
    const columns = [
        {
            title: "Applicable Date",
            field: "applicableDate"            
        },
        {
            title: "Number of Complaints",
            field: "customerComplaints"            
        },
        {
            title: "Errors",
            field: "clientErrors"            
        },
        {
            title: "Feedback Score",
            field: "customerFeedbackScore"            
        },
        {
            title: "Updated Date",
            field: "date"            
        }
    ];
    const GetClientDailySummary = () => {
        let body = {
             subProcessID : sessionStorage.getItem("ProcessId"),
             fromDate: dateTimeFromRef.current,
             toDate: dateTimeToRef.current
           }; 
           debugger;
               let GetClientDailySummaryURL = getAPIURL(React_APP_GetClientDailySummary);
               let req1 = new Request(`${GetClientDailySummaryURL}`, {
                 method: "POST",
                 headers: {
                   "Content-Type": "application/json",
                   UserName: sessionStorage.getItem("UserName"),
                   Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                 },
                 body: JSON.stringify(body),
               });
               fetch(req1)
                 .then((res) => res.json())
                 .then((result) => {
                   if (result.status == 200) {
                     setExcelColumns(result.data);
                   }
                 })
                 .catch((error) => {
                   console.log("ERROR: ", error);
                 });
             };
            //  const GetClientDailySummaryForSelectedDate = () => {
            //     let body = {
            //          subProcessID : sessionStorage.getItem("ProcessId"),
            //          fromDate: dateTimeFromRef.current                
            //        }; 
            //        debugger;
            //            let GetClientDailySummaryForSelectedDateURL = getAPIURL(React_APP_GetClientDailySummaryForSelectedDate);
            //            let req1 = new Request(`${GetClientDailySummaryForSelectedDateURL}`, {
            //              method: "POST",
            //              headers: {
            //                "Content-Type": "application/json",
            //                UserName: sessionStorage.getItem("UserName"),
            //                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            //              },
            //              body: JSON.stringify(body),
            //            });
            //            fetch(req1)
            //              .then((res) => res.json())
            //              .then((result) => {
            //                if (result.status == 200) {
            //                //   setisLoadingFigma(false);
            //                 //  updateColumns(Object.keys(result.data[0]));
            //                  setExcelColumns(result.data);
            //                }
            //              })
            //              .catch((error) => {
            //                console.log("ERROR: ", error);
            //              });
            //          };
                     const handleSave = () =>{
                        if(dateText==""){
                            addToast("Please select a date", {
                                appearance: "error",
                                autoDismiss: true,
                              });
                        }
                        else{
                        if (numberOfComplaints == "" &&numberOfErrors==""&&feedbackScore==""){
                            addToast("Please enter data in either of the 3 text boxes.", {
                                appearance: "error",
                                autoDismiss: true,
                              });
                        }
                        else
                        SaveClientDailySummary();
                     }}
             const SaveClientDailySummary = () => {
                let body ={                  
                  subProcessID : sessionStorage.getItem("ProcessId"),
                  ApplicableDate : dateText,//==""?new Date().toISOString():dateText,
                  CustomerComplaints: numberOfComplaints ==""?"0":numberOfComplaints,
                  ClientErrors: numberOfErrors==""?"0":numberOfErrors,
                  CustomerFeedbackScore: feedbackScore==""?"0":feedbackScore
                }; 
                let SaveClientDailySummaryUrl = getAPIURL(React_APP_SaveClientDailySummary);
                let req = new Request(`${SaveClientDailySummaryUrl}`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                  },
                  body: JSON.stringify(body),
                });
                fetch(req)
                  .then((res) => res.json())
                  .then((result) => {
                    if (result.status == 200) {
                        addToast("Record Saved Successfully", {
                            appearance: "success",
                            autoDismiss: true,
                          });
                          GetClientDailySummary();
                    } else if (result.status === 401) {
                      //unauthorized
                      addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                      });
                      sessionStorage.clear();
                      sessionStorage.clear();
                      navigate("/");
                    }
                  })
                  .catch((error) => {
                    console.log("ERROR: ", error);
                  }
                );
              };
    useEffect(() => {
        // debugger;
        const current = new Date();
        const startdate = `${current.getMonth() + 1}/${
          current.getDate() - 1
        }/${current.getFullYear()}`;
        const enddate = `${
          current.getMonth() + 1
        }/${current.getDate()}/${current.getFullYear()}`;
        dateTimeToRef.current=new Date(enddate).toISOString();
        dateTimeFromRef.current=new Date(startdate).toISOString();
        FetchDefaultValueForSelectedSubprocess(
            sessionStorage.getItem("selectedDepartment"),
            sessionStorage.getItem("selectedLocation"),
            sessionStorage.getItem("ProcessId")
        );
        setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
        FetchDepartmentbyGUID();    
        GetClientDailySummary();
    }, []);
    const handleReset = () => {
        const current = new Date();
        const startdate = `${current.getMonth() + 1}/${
          current.getDate() - 1
        }/${current.getFullYear()}`;
        const enddate = `${
          current.getMonth() + 1
        }/${current.getDate()}/${current.getFullYear()}`;
        dateTimeToRef.current=new Date(enddate).toISOString();
        dateTimeFromRef.current=new Date(startdate).toISOString();
        GetClientDailySummary();
      };
    const FetchLocationClientsByGuid = (e) => {
        setcompanyDeparmentID("");
        setcompanyDepartment("");
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
            setClientLocations([]);
        }
        if (e.target.value !== "Select") {
            // sessionStorage.setItem(
            //   "selectedDepartment",
            //   e.target.value.split("/")[1]
            // );
            // sessionStorage.setItem(
            //   "selectedDepartmentName",
            //   e.target.value.split("/")[0]
            // );
            setSelectedDepartment(e.target.value);
            let body = {
                guid: sessionStorage.getItem("GUID"),
                departmentId: e.target.value.split("/")[1],
            };
 
            let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status == 200) {
                        setClientLocations(result.data);
                    } else if (result.status === 401) {
                        //unauthorized
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    //if (result.status === 401) {
                    console.log("ERROR: ", error);
                    //   navigate("/");
                    // }
                });
        }
    };
 
    const FetchDepartmentbyGUID = () => {
        let body = {
            memberShipGuid: sessionStorage.getItem("GUID"),
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setDepartments(result.data);
                    // if (result.data.length > 1) {
                    //   setUserButtonShow(true);
                    // }
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };
 
    const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: departmentId,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };
 
    const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: clientId,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                    //GetActivitiesForSubprocess();
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
 
    const FetchSubprocessName = (subprocessId, clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: subprocessId,
            clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    // sessionStorage.setItem(
                    //   "SelctedSubprocessName",
                    //   result.data[0].processSubProcess
                    // );
                    //SubProcessSubmitForTimer();
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
 
    const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
    ) => {
        setcompanyDeparmentID(companyDeparmentID);
        setSelectedDepartment(companyDeparmentID);
        //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        setSelectedLocation(locationClientID);
        //sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
        FetchSubprocessName(processSubProcessID, locationClientID);
    };
 
    const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
        }
        setSelectedLocation(e.target.value);
        setSelectedClientLocation(e.target.value.split("/")[1]);
        // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
        // sessionStorage.setItem(
        //   "selectedLocationName",
        //   e.target.value.split("/")[0]
        // );
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: e.target.value.split("/")[1],
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                    setprocessSubprocess([]);
                } else {
                    setprocessSubprocess([]);
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };
    const handleComplaintNo = (e) => {
        if (/^\d+$/.test(e.target.value)) {
            const intValue = parseInt(e.target.value, 10);
            if (intValue >= 0 && intValue <= 100) {
                setNumberOfComplaints(e.target.value);
            }
        }
          else
        setNumberOfComplaints('');
      }
      const handleErrorNo = (e) => {
        if (/^\d+$/.test(e.target.value)) {
            const intValue = parseInt(e.target.value, 10);
            if (intValue >= 0 && intValue <= 100) {
                setNumberOfErrors(e.target.value);
            }
        }
          else
        setNumberOfErrors('');
      }
      const handleFeedbackScore = (e) => { 
        if (/^\d*(\.\d{0,2})?$/.test(e.target.value)) {
            const newValue = parseFloat(e.target.value);
            if (0.00 <= parseFloat(newValue) && parseFloat(newValue) <= 10.00) {
                setFeedbackScore(e.target.value);
            }
        }      
        // const newValue = e.target.value;
        // if (/^\d*(\.\d{0,2})?$/.test(newValue) && 0.00 <= parseFloat(newValue) && parseFloat(newValue) <= 10.00) {
        //     setFeedbackScore(newValue);
        // } 
        else {
                setFeedbackScore('');
                addToast(
                    "Please enter a number between 0 and 10 upto two decimal places",
                    {
                      appearance: "error",
                      autoDismiss: true,
                    }
                  );
              }                     
      }
      const getDateText = (e) =>{
        setDateText(e.target.value);
      }
      const DeleteRecord = (row) => {if (
                      window.confirm(
                        "Are you sure you want to delete the Record?"
                      )
                    ) {
                      let body = {
                        ClientDailyInfoID:+row.clientDailyInfoID
                      };
                      let DeleteRecordUrl = getAPIURL(React_APP_DeleteRecord);
                      let req = new Request(`${DeleteRecordUrl}`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          UserName: sessionStorage.getItem("UserName"),
                          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                        },
                        body: JSON.stringify(body),
                      });
                      fetch(req)
                        .then((res) => res.json())
                        .then((result) => {
                          if (result.status === 200) {
                            addToast("Record deleted successfully", {
                              appearance: "success",
                              autoDismiss: true,
                            });
                            GetClientDailySummary();
                          } else if (result.status === 401) {
                            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                              appearance: "error",
                              autoDismiss: true,
                            });
                            sessionStorage.clear();
                            sessionStorage.clear();
                            navigate("/");
                          }
                        })
                        .catch((error) => {
                          console.log("ERROR: ", error);
                          //navigate("/");
                        });
                    }
                  };
    
 
    return (
        <>
            <Header onpreferenceShow={null} pageName="Client Daily Info" />
            <ApplicationUserContext.Provider
                value={processingDashboardParam.userRoles}
            >
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="activities" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
 
            <div className="centerSection">
                <div className="pageBody">
 
                    <div className="dashBoxs mb-3">
                        <div className="formWrap">
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label"> Company Department</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={FetchLocationClientsByGuid}
                                        >
                                            <option>Select</option>
                                            {departments.map((department) => (
                                                <option
                                                    value={
                                                        department.companyDepartment +
                                                        "/" +
                                                        department.departmentIDString
                                                    }
                                                    selected={
                                                        department.departmentIDString == companyDeparmentID
                                                    }
                                                >
                                                    {department.companyDepartment}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Location Client</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={FetchProcessSubProcessByGuid}
                                        >
                                            <option>Select</option>
                                            {clientLocations.map((location) => (
                                                <option
                                                    value={
                                                        location.locationClient +
                                                        "/" +
                                                        location.clientIDString
                                                    }
                                                    selected={location.clientIDString == locationClientID}
                                                >
                                                    {location.locationClient}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Process SubProcess</label>
                                        <select
                                            className="form-control form-control-sm"                                            
                                            // onChange={FetchSubProcessInstructionListOnsubProcessChange}
                                        >
                                            <option>Select</option>
                                            {processSubprocess.map((subprocess) => (
                                                <option
                                                    value={subprocess.subProcessIDString}
                                                    selected={
                                                        subprocess.subProcessIDString == processSubProcessID
                                                    }
                                                >
                                                    {subprocess.processSubProcess}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashBoxs mb-3">
                    <div className="formWrap">
                    <div className="row align-items-end">

                    <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Date* </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      onChange={getDateText}
                      
                    />
                  </div>
                </div>
                <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Number of Complaints</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          maxLength={50}                                                    
                          value={numberOfComplaints}
                        //   ref={TemplateNameRef}
                          onChange={handleComplaintNo}
                          title="Enter a numeric value between 0 and 100."
                          
                        />
                      </div>
                </div>
                <div className="col-3">
                      <div className="mb-3">                        
                        <label className="form-label">Number of Errors</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          
                          maxLength={255}
                          title="Enter a numeric value between 0 and 100."
                          value={numberOfErrors}
                        //   ref={SubjectRef}
                          onChange={handleErrorNo}
                        />
                      </div>
                </div>                
                <div className="col-3">
                      <div className="mb-3">                        
                        <label className="form-label">Feedback Score</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          
                          maxLength={255}
                          title="Enter a decimal value between 0.00 and 10.00."
                          value={feedbackScore}
                        //   ref={SubjectRef}
                          onChange={handleFeedbackScore}
                        />
                      </div>
                </div>

                </div>
                </div>
                <button
                    type="submit"
                    className="btn btn-sm btn-primary ms-3"
                    // ref={ButtonTextRef}
                    onClick={() => {
                        handleSave();
                    }}
                  >
                    Save Daily Information
                  </button>
                </div>
                
                    <div className="dashBoxs mb-3">
                        <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                            <div className="row align-items-center">
                                {/* <div className="col-8">
                                    <h6>Search Based on Date</h6>
                                </div> */}
                                <div className="col-2">
                                    <div
                                        className="row align-items-start"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        
                                        <label className="form-label">From Date - To Date</label>
                    <DateRangePicker
                      initialSettings={{
                        autoApply: true,
                        autoUpdateInput: false,
                        opens: "right",
                        showDropdowns:true,
                        timePicker: false,
                        locale: { format: "M/DD/YY hh:mm A" }
                      }}
                      onApply={handleApply}
                    >
                      <input
                        id="txtDateSelector"
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Select Date"
                        value={new Date(dateTimeFromRef.current).toLocaleDateString() + "-" + new Date(dateTimeToRef.current).toLocaleDateString()}
                        />
                    </DateRangePicker>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div
                                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-2"
                                            onClick={() => {
                                                GetClientDailySummary();
                                            }}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div
                                        className="d-grid d-sm-flex justify-content-md-start align-items-center gap-2"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-2"
                                            onClick={() => {
                                                handleReset();
                                            }}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
 
                            </div>
                        </div>
 
                        <div className="tableWrap">
                            <div className="tableWrap-body">
                                <table className="table table-borderless MatTable" style={{ zIndex: "1", position: "relative" }}>
                                    <MaterialTable
                                        title="Client Daily Info"
                                        actions={[
                                           
                                            {
                                              icon: () => <DeleteIcon />,
                                              tooltip: "Delete Row",
                                              onClick: (event, rowData) =>
                                                DeleteRecord(rowData),
                                              position: "row",
                                            }
                                          ]}
                                        options={{
                                            showTitle: false,
                                            exportButton: false,
                                            sorting: true,
                                            filtering: false,
                                            search: false,
                                            paging: false,
                                            cellStyle: {
                                                // width: 100,
                                                // minWidth: 100,
                                            },
                                            headerStyle: {},
                                            rowStyle: {
                                                // fontFamily: '"Yantramanav", sans-serif',
                                            }
                                        }}
                                        components={{
                                            Container: props => <Paper {...props} elevation={0} />,
                                            Toolbar: props => (
                                                <div
                                                    style={
                                                        {
                                                            // backgroundColor: "rgb(206 206 206)",
                                                            // fontSize: "0.75em",
                                                        }
                                                    }
                                                >
                                                    <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                                                </div>
                                            )
                                        }}
                                        columns={columns}
                                        icons={tableIcons}
                                        data={ExcelColumns}
                                    />
 
                                </table>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
 
            <Footer />
        </>
    );
};
 
export default ClientDailyInfo;