import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";

import { ToastProvider, useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer
} from "recharts";

function SubprocessUserClassificationMapping() {
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCHPRIORITYFORSUBPROCESSIDACTIVITYID } = process.env;
  const { REACT_APP_ADDDELETEUSERPRIORITY } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [
    PriortyForSubProcessDetails,
    setPriortyForSubProcessDetails
  ] = useState([]);
  const [PriorityJson, setPriorityJson] = useState([]);
  const [Isdepartments, setIsDepartments] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] = useState(
    []
  );
  const [IsFile, setIsFile] = useState(false);

  const columns = [
    {
      title: "userID",
      field: "userID"
    },
    {
      title: "name",
      field: "name"
    },
    {
      title: "supervisorName",
      field: "supervisorName"
    },
    {
      title: "priority1",
      field: "priority1"
    },
    {
      title: "priority2",
      field: "priority2"
    },
    {
      title: "priority3",
      field: "priority3"
    },
    {
      title: "priority4",
      field: "priority4"
    },
    {
      title: "priority5",
      field: "priority5"
    }
  ];

  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const GetPriortyForSubProcess = () => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
      activityID: sessionStorage.getItem("PriortyActivityID")
    };
    let activityUrl = getAPIURL(
      REACT_APP_FETCHPRIORITYFORSUBPROCESSIDACTIVITYID
    );
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setPriortyForSubProcessDetails(result.data);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const FetchPriortyForSubProcessIDActivityID = e => {
    //setSelectedSubprocess(e.target.value.split("/")[0]);
    sessionStorage.setItem("PriortyActivityID", e.target.value);
    e.target.value != "Select"
      ? setIsActivitiesForSubprocess(true)
      : setIsActivitiesForSubprocess(false);
    GetPriortyForSubProcess();
  };

  const FetchInvalidProcessSubprocessByGuid = e => {
    if (e.target.value == "Select") {
      setShowModalButton(false);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      setShowModalButton(true);
      setIsprocessSubprocess(true);
      setSelectedSubprocess(e.target.value.split("/")[0]);
      sessionStorage.setItem("ProcessId", e.target.value.split("/")[0]);
      sessionStorage.setItem(
        "SelctedSubprocessName",
        e.target.value.split("/")[1]
      );
      GetActivitiesForSubprocess();
    }
  };

  const FetchProcessSubProcessByGuid = e => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setPriortyForSubProcessDetails([]);
      setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setIsClientLocations(false);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        sessionStorage.setItem(
          "selectedLocation",
          e.target.value.split("/")[e.target.value.split("/").length - 1]
        );
        sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1]
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken")
          },
          body: JSON.stringify(body)
        });
        fetch(req)
          .then(res => res.json())
          .then(result => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch(error => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      }
    }
  };

  const FetchLocationClientsByGuid = e => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      //setIsDepartments(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      setIsDepartments(true);
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1]
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch(error => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID")
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const FetchLocationClientsByGuidOnPageLoad = departmentId => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const GetActivitiesForSubprocess = () => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId")
    };
    let activityUrl = getAPIURL(REACT_APP_GETACTIVITIESFORSUBPROCESS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setActivitiesForSubprocess(result.data._listworkitemgridData);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = clientId => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          GetActivitiesForSubprocess();
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const readUploadFile = e => {
    e.target.files.length > 0 ? setIsFile(true) : setIsFile(false);
    e.preventDefault();
    if (e.target.files) {
      //setIsFile(true);
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        console.log(json);
        setPriorityJson(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const uploadFileOnServerForUpload = () => {
    let body = {
      userPriorities: PriorityJson,
      updatedby: sessionStorage.getItem("PersonID"),
      subProcessID: sessionStorage.getItem("ProcessId"),
      activityID: sessionStorage.getItem("PriortyActivityID")
    };

    let loginUrl = getAPIURL(REACT_APP_ADDDELETEUSERPRIORITY);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          addToast("File Successfully Uploaded", {
            appearance: "success",
            autoDismiss: true
          });
          //alert(result.data);
          //   var newArray = result.data[0].join(' - ');
          //   alert(newArray);
          GetPriortyForSubProcess();
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
  }, []);

  const downloadExcel = () => {
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const fileName = "PriortyForSubProcessDetailsHead";
    const ws = XLSX.utils.json_to_sheet(PriortyForSubProcessDetails);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="User Classification Mapping Config"
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="bussinessrules" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"> Company Department</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map(department => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Location Client</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map(location => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Process SubProcess</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchInvalidProcessSubprocessByGuid}
                    >
                      <option>Select</option>
                      {processSubprocess.map(subprocess => (
                        <option
                          value={
                            subprocess.subProcessIDString +
                            "/" +
                            subprocess.processSubProcess
                          }
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Activity</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchPriortyForSubProcessIDActivityID}
                    >
                      <option>Select</option>
                      {ActivitiesForSubprocess.map(subprocess => (
                        <option value={subprocess.ActivityID}>
                          {subprocess.ActivityName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Upload Document</label>
                    <input
                      className="form-control  form-control-sm"
                      type="file"
                      onChange={readUploadFile}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3 d-grid d-sm-flex justify-content-md-center align-items-center gap-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary "
                      onClick={() => {
                        uploadFileOnServerForUpload();
                      }}
                      disabled={
                        !Isdepartments ||
                        !IsclientLocations ||
                        !IsprocessSubprocess ||
                        !IsActivitiesForSubprocess ||
                        !IsFile
                      }
                      //disabled={!IsUploadOrAppend}
                    >
                      {/* {IsUploading ? "Uploading..." : "Upload"} */}
                      Upload
                    </button>
                  </div>
                </div>
              </div>

              {/* <hr className="mb-4" /> */}
            </div>
          </div>
          <div className="dashBoxs">
            <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
              <div className="row align-items-center">
                <div className="col-4">
                  <h6>Priority Details</h6>
                  {/* //<h5>Processing Dashboard</h5> */}
                </div>
                <div className="col-8">
                  <div
                    className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                    style={{ marginTop: "-10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                      <b></b>Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
            >
              <MaterialTable
                title="Priority Details"
                options={{
                  showTitle: false,
                  exportButton: false,
                  sorting: true,
                  filtering: false,
                  search: false,
                  paging: false,
                  cellStyle: {
                    // width: 100,
                    // minWidth: 100,
                  },
                  headerStyle: {},
                  rowStyle: {
                    // fontFamily: '"Yantramanav", sans-serif',
                  }
                }}
                components={{
                  Container: props => <Paper {...props} elevation={0} />,
                  Toolbar: props => (
                    <div
                      style={
                        {
                          // backgroundColor: "rgb(206 206 206)",
                          // fontSize: "0.75em",
                        }
                      }
                    >
                      <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                    </div>
                  )
                }}
                columns={columns}
                icons={tableIcons}
                data={PriortyForSubProcessDetails}
              />
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SubprocessUserClassificationMapping;
