import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import Modal from "react-bootstrap/Modal";
import { Paper } from "@material-ui/core";
import { FcSearch } from "react-icons/fc";
import { FcImageFile } from "react-icons/fc";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@material-ui/core";
import GlobalStyles from "@mui/material/GlobalStyles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#008000",
    },
    secondary: {
      main: "#FB4E0B",
    },
  },
});
const displayOrder = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];
const Columnwidth = [
  50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425,
  450, 475, 500,
];

let columnsPreview = [];
const DashboardConfig = ({ onDataSend, reference }) => {
  const columns = [
    {
      title: "Select",
      field: "isSelected",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            //
            checked={rowData.isSelected}
            onClick={(e) => handleselected(e, rowData)}
          />
        </>
      ),
    },
    {
      title: "Sort Column",
      field: "isSortColumn",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            //
            checked={rowData.isSortColumn}
            onClick={(e) => handlesort(e, rowData)}
          />
        </>
      ),
    },
    {
      title: "Work Item Field",
      field: "labelText",
    },
    {
      title: "Display Order",
      field: "DisplayOrder",
      render: (rowData) => (
        <>
          <select
            id="displayOrder"
            className="form-control form-control-sm"
            onChange={(e) => handleDisplayOrder(e, rowData)}
          >
            <option>Select</option>
            {displayOrder.map((Order) => (
              <option value={Order} selected={+Order == rowData.displayOrder}>
                {Order}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "Column Width",
      field: "Columnwidth",
      render: (rowData) => (
        <>
          <select
            id="displayOrder"
            className="form-control form-control-sm"
            //onChange={(e) => selectedEmployee_ID(e, rowData)}
            onChange={(e) => handleColumnwidth(e, rowData)}
          >
            <option>Select</option>
            {Columnwidth.map((width) => (
              <option value={width} selected={+width == rowData.columnwidth}>
                {width}
              </option>
            ))}
          </select>
        </>
      ),
    },
  ];
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETDASHBOARDCONFIGURATION } = process.env;
  const { REACT_APP_SAVEDASHBOARDCONFIGURATION } = process.env;
  const { REACT_APP_GETCOPYSUBPROCESSACTIVITYFORDASHBOARD } = process.env;
  const { REACT_APP_CheckValidDashBoardConfig } = process.env;
  const { REACT_APP_CheckValidScreenConfig } = process.env;
  const [dashboardConfiguration, setdashboardConfiguration] = useState([]);
  const [DashboardWorkitemFieldConfigs, setDashboardWorkitemFieldConfigs] =
    useState([]);
  const [preferenceModal, setShow] = useState(false);
  const [disableCopyActivity, setdisableCopyActivity] = useState(true);
  const [previewColumns, setpreviewColumns] = useState([]);
  const [filterStyle, setfilterStyle] = useState(
    "btn btn-sm ms-2 btn-primary active"
  );
  const [isexpand, setisexpand] = useState(false);
  const [allowStatusChangeFromDashboard, setallowStatusChangeFromDashboard] =
    useState(false);
  const [allowAssignmentFromDashboard, setallowAssignmentFromDashboard] =
    useState(false);
  const [
    showLastActivityDetailOnDashboard,
    setshowLastActivityDetailOnDashboard,
  ] = useState(false);
  const [
    showNextActivityDetailOnDashboard,
    setshowNextActivityDetailOnDashboard,
  ] = useState(false);
  const [
    showDashboardIndicatorForSLADueDate,
    setshowDashboardIndicatorForSLADueDate,
  ] = useState(false);
  const [
    excludeWeekEndAndHolidayForActivity,
    setexcludeWeekEndAndHolidayForActivity,
  ] = useState(false);
  const [IsUploadAge, setIsUploadAge] = useState(false);
  const [isActivityAge, setisActivityAge] = useState(false);
  const [showDashboardGrid, setshowDashboardGrid] = useState(false);
  const [allowEmailNotification, setallowEmailNotification] = useState(false);
  const [AllowAdhocAdditionForUpload, setAllowAdhocAdditionForUpload] =
    useState(false);
  const [
    allowAdhocAdditionOfItemsForUpload,
    setallowAdhocAdditionOfItemsForUpload,
  ] = useState(false);
  const [allowAdhocAdditionOfItems, setallowAdhocAdditionOfItems] =
    useState(false);
  const [none, setnone] = useState(false);
  const [defaultFilterCount, setdefaultFilterCount] = useState(false);
  const [
    excludeWeekEndAndHolidayForUploadDate,
    setexcludeWeekEndAndHolidayForUploadDate,
  ] = useState(false);
  const [useDays, setuseDays] = useState(false);
  const [IsDefaultFilter, setIsDefaultFilter] = useState(false);
  const [CopydashboardActivity, setCopydashboardActivity] = useState([]);
  const [CopyActivity, setCopyActivity] = useState("");
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const handleDashBoardPreview = () => {
    setShow(true);
  };
  const preferenceClose = () => {
    setShow(false);
  };
  const handleallowStatusChangeFromDashboard = () => {
    setallowStatusChangeFromDashboard(!allowStatusChangeFromDashboard);
  };
  const handleallowAssignmentFromDashboard = () => {
    setallowAssignmentFromDashboard(!allowAssignmentFromDashboard);
  };
  const handleshowLastActivityDetailOnDashboard = () => {
    setshowLastActivityDetailOnDashboard(!showLastActivityDetailOnDashboard);
  };
  const handleshowNextActivityDetailOnDashboard = () => {
    setshowNextActivityDetailOnDashboard(!showNextActivityDetailOnDashboard);
  };
  const handleshowDashboardIndicatorForSLADueDate = () => {
    setshowDashboardIndicatorForSLADueDate(
      !showDashboardIndicatorForSLADueDate
    );
  };
  const handleisActivityAge = () => {
    setisActivityAge(!isActivityAge);
  };
  const handleexcludeWeekEndAndHolidayForUploadDate = () => {
    setexcludeWeekEndAndHolidayForUploadDate(
      !excludeWeekEndAndHolidayForUploadDate
    );
  };
  const handleIsUploadAge = () => {
    setIsUploadAge(!IsUploadAge);
  };
  const handleshowDashboardGrid = () => {
    setshowDashboardGrid(!showDashboardGrid);
  };
  const handleallowEmailNotification = () => {
    setallowEmailNotification(!allowEmailNotification);
  };
  const handleallowAdhocAdditionOfItems = (e) => {
    if (e.target.checked) {
      setnone(false);
      setallowAdhocAdditionOfItems(true);
      setallowAdhocAdditionOfItemsForUpload(false);
    }
  };
  const handleallowAdhocAdditionOfItemsForUpload = (e) => {
    if (e.target.checked) {
      setnone(false);
      setallowAdhocAdditionOfItems(false);
      setallowAdhocAdditionOfItemsForUpload(true);
    }
  };
  const handleNone = (e) => {
    if (e.target.checked) {
      setnone(true);
      setallowAdhocAdditionOfItems(false);
      setallowAdhocAdditionOfItemsForUpload(false);
    }
  };
  const handleuseDays = () => {
    setuseDays(!useDays);
    setIsDefaultFilter(!IsDefaultFilter);
  };
  const handledefaultFilterCount = (e) => {
    setdefaultFilterCount(e.target.value);
  };
  const handleColumnwidth = (e, rowData) => {
    let newDashboardWorkitemFieldConfigs = DashboardWorkitemFieldConfigs.map(
      (item) => {
        if (item.workItemFieldID === rowData.workItemFieldID) {
          return { ...item, columnwidth: +e.target.value };
        }
        return item;
      }
    );
    setDashboardWorkitemFieldConfigs(newDashboardWorkitemFieldConfigs);
  };
  const handleDisplayOrder = (e, rowData) => {
    let newDashboardWorkitemFieldConfigs = DashboardWorkitemFieldConfigs.map(
      (item) => {
        if (item.workItemFieldID === rowData.workItemFieldID) {
          return { ...item, displayOrder: +e.target.value };
        }
        return item;
      }
    );
    setDashboardWorkitemFieldConfigs(newDashboardWorkitemFieldConfigs);
  };
  const handleDashboardValidation = () => {
    let body = {
      SubProcessID: reference.SubprocessID,
    };
    let dashboardValidationUrl = getAPIURL(REACT_APP_CheckValidDashBoardConfig);
    let req = new Request(`${dashboardValidationUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          if (result.data) {
            onDataSend(true);
          } else {
            onDataSend(false);
          }
        } else if (result.status === 401) {
          onDataSend(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          onDataSend(false);
        }
      })
      .catch((error) => {
        onDataSend(false);
        console.log("ERROR: ", error);
      });
  };
  const handleSave = () => {
    debugger;
    let body = {
      ActivityId: +reference.ActivityforConfig,
      SubprocessID: reference.SubprocessID,
      ActivityName: reference.ActivityNameforConfig,
      AllowStatusChangeFromDashboard: allowStatusChangeFromDashboard,
      AllowAssignmentFromDashboard: allowAssignmentFromDashboard,
      ShowDashboardIndicatorForSLADueDate: showDashboardIndicatorForSLADueDate,
      ShowLastActivityDetailOnDashboard: showLastActivityDetailOnDashboard,
      ShowNextActivityDetailOnDashboard: showNextActivityDetailOnDashboard,
      AllowAdhocAdditionOfItems: allowAdhocAdditionOfItems,
      AllowAdhocAdditionOfItemsForUpload: allowAdhocAdditionOfItemsForUpload,
      AllowEmailNotification: allowEmailNotification,
      IsDefaultFilter: IsDefaultFilter,
      DefaultFilterCount: defaultFilterCount,
      IsActivityAge: isActivityAge,
      IsUploadAge: IsUploadAge,
      ShowDashboardGrid: showDashboardGrid,
      ExcludeWeekEndAndHolidayForActivity: excludeWeekEndAndHolidayForActivity,
      ExcludeWeekEndAndHolidayForUploadDate:
        excludeWeekEndAndHolidayForUploadDate,
      IsCopyActivitySelected: CopyActivity != "" ? true : false,
      DashboardWorkitemFieldConfigs: DashboardWorkitemFieldConfigs,
    };
    let workflowConfigUrl = getAPIURL(REACT_APP_SAVEDASHBOARDCONFIGURATION);
    let req = new Request(`${workflowConfigUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setCopyActivity("");
          addToast("Dashboard configuration is saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setCopyActivity("");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const handlesort = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = DashboardWorkitemFieldConfigs.map(
      (item) => {
        if (item.workItemFieldID === rowData.workItemFieldID) {
          return { ...item, isSortColumn: e.target.checked };
        }
        return item;
      }
    );
    let newDashboardWorkitemFieldConfigsFinal =
      newDashboardWorkitemFieldConfigs.map((item) => {
        if (item.workItemFieldID !== rowData.workItemFieldID) {
          return { ...item, isSortColumn: false };
        }
        return item;
      });
    setDashboardWorkitemFieldConfigs(newDashboardWorkitemFieldConfigsFinal);
  };
  const handleselected = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = DashboardWorkitemFieldConfigs.map(
      (item) => {
        if (item.workItemFieldID === rowData.workItemFieldID) {
          return { ...item, isSelected: e.target.checked };
        }
        return item;
      }
    );
    setDashboardWorkitemFieldConfigs(newDashboardWorkitemFieldConfigs);
  };
  const GetCopySubProcessActivityForDashboard = () => {
    //setpreviewColumns([]);
    let body = {
      SubProcessID: reference.SubprocessID,
    };
    let workflowConfigUrl = getAPIURL(
      REACT_APP_GETCOPYSUBPROCESSACTIVITYFORDASHBOARD
    );
    let req = new Request(`${workflowConfigUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setCopydashboardActivity(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetCopyDashboardActivity = (e) => {
    setCopyActivity(e.target.value);

    // GetCopyDashboardConfiguration(e.target.value);
  };
  const GetCopyDashboardConfiguration = () => {
    debugger;
    let body = {
      SubProcessID: reference.SubprocessID,
      SubProcessWorkActivityConfigID: CopyActivity,
    };
    let workflowConfigUrl = getAPIURL(REACT_APP_GETDASHBOARDCONFIGURATION);
    let req = new Request(`${workflowConfigUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setdisableCopyActivity(true);
          setDashboardWorkitemFieldConfigs(
            result.data.dashboardWorkitemFieldConfigs
          );
          setallowStatusChangeFromDashboard(
            result.data.dashboardConfiguration[0].allowStatusChangeFromDashboard
          );
          setallowAssignmentFromDashboard(
            result.data.dashboardConfiguration[0].allowAssignmentFromDashboard
          );
          setshowLastActivityDetailOnDashboard(
            result.data.dashboardConfiguration[0]
              .showLastActivityDetailOnDashboard
          );
          setshowNextActivityDetailOnDashboard(
            result.data.dashboardConfiguration[0]
              .showNextActivityDetailOnDashboard
          );
          setshowDashboardIndicatorForSLADueDate(
            result.data.dashboardConfiguration[0]
              .showDashboardIndicatorForSLADueDate
          );
          setexcludeWeekEndAndHolidayForActivity(
            result.data.dashboardConfiguration[0]
              .excludeWeekEndAndHolidayForActivity
          );
          setIsUploadAge(result.data.dashboardConfiguration[0].isUploadAge);
          setisActivityAge(result.data.dashboardConfiguration[0].isActivityAge);
          setshowDashboardGrid(
            result.data.dashboardConfiguration[0].showDashboardGrid
          );
          setallowEmailNotification(
            result.data.dashboardConfiguration[0].allowEmailNotification
          );
          setAllowAdhocAdditionForUpload(
            result.data.dashboardConfiguration[0].AllowAdhocAdditionForUpload
          );
          setallowAdhocAdditionOfItemsForUpload(
            result.data.dashboardConfiguration[0]
              .allowAdhocAdditionOfItemsForUpload
          );
          setallowAdhocAdditionOfItems(
            result.data.dashboardConfiguration[0].allowAdhocAdditionOfItems
          );
          setdefaultFilterCount(
            result.data.dashboardConfiguration[0].defaultFilterCount
          );
          if (result.data.dashboardConfiguration[0].defaultFilterCount > 0) {
            setIsDefaultFilter(true);
          } else {
            setIsDefaultFilter(false);
          }
          setexcludeWeekEndAndHolidayForUploadDate(
            result.data.dashboardConfiguration[0]
              .excludeWeekEndAndHolidayForUploadDate
          );
          debugger;
          setdashboardConfiguration(result.data.dashboardConfiguration[0]);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetDashboardConfiguration = () => {
    debugger;
    let body = {};
    //setpreviewColumns([]);
    if (CopyActivity != "") {
      body = {
        SubProcessID: CopyActivity,
        SubProcessWorkActivityConfigID: reference.ActivityforConfig,
      };
    } else {
      body = {
        SubProcessID: reference.SubprocessID,
        SubProcessWorkActivityConfigID: reference.ActivityforConfig,
      };
    }

    let workflowConfigUrl = getAPIURL(REACT_APP_GETDASHBOARDCONFIGURATION);
    let req = new Request(`${workflowConfigUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setdisableCopyActivity(false);
          setDashboardWorkitemFieldConfigs(
            result.data.dashboardWorkitemFieldConfigs
          );
          setallowStatusChangeFromDashboard(
            result.data.dashboardConfiguration[0].allowStatusChangeFromDashboard
          );
          setallowAssignmentFromDashboard(
            result.data.dashboardConfiguration[0].allowAssignmentFromDashboard
          );
          setshowLastActivityDetailOnDashboard(
            result.data.dashboardConfiguration[0]
              .showLastActivityDetailOnDashboard
          );
          setshowNextActivityDetailOnDashboard(
            result.data.dashboardConfiguration[0]
              .showNextActivityDetailOnDashboard
          );
          setshowDashboardIndicatorForSLADueDate(
            result.data.dashboardConfiguration[0]
              .showDashboardIndicatorForSLADueDate
          );
          setexcludeWeekEndAndHolidayForActivity(
            result.data.dashboardConfiguration[0]
              .excludeWeekEndAndHolidayForActivity
          );
          setIsUploadAge(result.data.dashboardConfiguration[0].isUploadAge);
          setisActivityAge(result.data.dashboardConfiguration[0].isActivityAge);
          setshowDashboardGrid(
            result.data.dashboardConfiguration[0].showDashboardGrid
          );
          setallowEmailNotification(
            result.data.dashboardConfiguration[0].allowEmailNotification
          );
          setAllowAdhocAdditionForUpload(
            result.data.dashboardConfiguration[0].AllowAdhocAdditionForUpload
          );
          setallowAdhocAdditionOfItemsForUpload(
            result.data.dashboardConfiguration[0]
              .allowAdhocAdditionOfItemsForUpload
          );
          setallowAdhocAdditionOfItems(
            result.data.dashboardConfiguration[0].allowAdhocAdditionOfItems
          );
          setdefaultFilterCount(
            result.data.dashboardConfiguration[0].defaultFilterCount
          );
          if (result.data.dashboardConfiguration[0].defaultFilterCount > 0) {
            setIsDefaultFilter(true);
          } else {
            setIsDefaultFilter(false);
          }
          setexcludeWeekEndAndHolidayForUploadDate(
            result.data.dashboardConfiguration[0]
              .excludeWeekEndAndHolidayForUploadDate
          );
          debugger;
          setdashboardConfiguration(result.data.dashboardConfiguration[0]);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const handleAccordionChange = (e, isExpanded) => {
    debugger;
    setisexpand(isExpanded);
    if (isExpanded) {
      debugger;
      DashboardWorkitemFieldConfigs.map((e) => {
        if (e.isSelected) {
          columnsPreview.push({
            title: e.labelText,
            field: e.labelText,
          });
        }
      });
      let res =
        columnsPreview.length === previewColumns.length &&
        columnsPreview.every((value, index) => value === previewColumns[index]);
      if (!res) {
        setpreviewColumns(columnsPreview);
      }
    }
  };
  useEffect(() => {
    setisexpand(false);
    //setpreviewColumns([]);
    if (reference.ActivityforConfig != 0) {
      GetDashboardConfiguration();
      GetCopySubProcessActivityForDashboard();
    }
  }, [reference.ActivityforConfig]);
  return (
    <>
      <div className="dashBoxs mb-3" style={{ marginTop: "1.0rem" }}>
        <div className="formWrap">
          <div className="fieldSet mb-4">
            <div className="row align-items-end">
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={allowStatusChangeFromDashboard}
                      onChange={handleallowStatusChangeFromDashboard}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow Status change from Dashboard
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={allowAssignmentFromDashboard}
                      onChange={handleallowAssignmentFromDashboard}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow Assignment from Dashboard
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={showLastActivityDetailOnDashboard}
                      onChange={handleshowLastActivityDetailOnDashboard}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Show Last Activity Details On Dashboard
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={showNextActivityDetailOnDashboard}
                      onChange={handleshowNextActivityDetailOnDashboard}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Show Next Activity Details in Dashboard
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={showDashboardIndicatorForSLADueDate}
                      onChange={handleshowDashboardIndicatorForSLADueDate}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Show RAG Indicator
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={true}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow Bulk Update
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isActivityAge}
                      onChange={handleisActivityAge}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Show Workitem age in current activity
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={excludeWeekEndAndHolidayForUploadDate}
                      onChange={handleexcludeWeekEndAndHolidayForUploadDate}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Exclude weekends and holidays
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={IsUploadAge}
                      onChange={handleIsUploadAge}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Show Workitem age (based on Scan Date)
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={showDashboardGrid}
                      onChange={handleshowDashboardGrid}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Hide Dashboard Table
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={true}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow Order Column Preference
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="inlineRadioOptions"
                      checked={allowEmailNotification}
                      onChange={handleallowEmailNotification}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow adhoc Email notifications from Dashboard
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      checked={allowAdhocAdditionOfItems}
                      onChange={handleallowAdhocAdditionOfItems}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow Adhoc addition of Batch/Workitem For Activity
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      checked={allowAdhocAdditionOfItemsForUpload}
                      onChange={handleallowAdhocAdditionOfItemsForUpload}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Allow Adhoc addition of Workitem For Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-3" style={{ height: "47px" }}>
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      checked={none}
                      onChange={handleNone}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      None
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-1">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={IsDefaultFilter}
                      onChange={handleuseDays}
                    />
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Use
                      <input
                        className="form-control form-control-xs"
                        type="text"
                        value={defaultFilterCount}
                        style={{
                          width: "50px",
                          float: "right",
                          //backgroundColor: "#D4D0C8",
                        }}
                        onChange={handledefaultFilterCount}
                        disabled={!IsDefaultFilter}
                      />
                    </label>
                    <label
                      className="form-check-label"
                      for="inlineCheckbox1"
                      style={{ fontSize: "13px" }}
                    >
                      Days Default Filter on the Dashboard
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="mb-2">
                  <label
                    className="form-check-label"
                    style={{ fontSize: "13px" }}
                  >
                    Copy Dashboard Configuration From
                  </label>
                  <select
                    className="form-control form-control-sm"
                    onChange={GetCopyDashboardActivity}
                    disabled={disableCopyActivity}
                  >
                    <option>Select</option>
                    {CopydashboardActivity.map((activity) => (
                      <option value={activity.subProcessWorkActivityConfigID}>
                        {activity.activityName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-3" style={{ height: "42px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary me-3"
                  style={{ fontSize: "small" }}
                  onClick={GetCopyDashboardConfiguration}
                >
                  Copy Dashboard Configuration
                </button>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className="tableWrap">
              <div className="tableWrap-body">
                <div className="dashBoxs">
                  <div
                    className="dashBoxs-header "
                    style={{ maxHeight: "45px" }}
                  >
                    <div className="row align-items-center">
                      <div className="col-8">
                        <h6>
                          Add/Edit Sub-Process Work Activity Dashboard Config
                        </h6>
                      </div>
                    </div>
                  </div>
                  <table
                    className="table table-borderless MatTable"
                    //style={{ zIndex: "1", position: "relative" }}
                  >
                    <MaterialTable
                      title="Add/Edit Sub-Process Work Activity Dashboard Config"
                      options={{
                        tableLayout: "auto",
                        overflowY: true,
                        exportButton: false,
                        sorting: true,
                        filtering: true,
                        search: false,
                        paging: true,
                        showTitle: false,
                        //pageSize: 10,
                        cellStyle: {
                          //   width: 100,
                          //   minWidth: 100,
                        },
                        headerStyle: {
                          // width: 100,
                          // minWidth: 100,
                          // fontFamily: '"Yantramanav", sans-serif',
                          // fontWeight: "inherit",
                          // fontSize: "16px",
                          // height: 40,
                          // textAlign: "left",
                        },
                        rowStyle: {
                          // fontFamily: '"Yantramanav", sans-serif',
                        },
                      }}
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: (props) => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em",
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{ fontSize: "5px" }}
                            />
                          </div>
                        ),
                      }}
                      columns={columns}
                      icons={tableIcons}
                      data={DashboardWorkitemFieldConfigs}
                    />
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className="tableWrap">
              <Accordion expanded={isexpand} onChange={handleAccordionChange}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{
                    border: "1px solid #ddd",
                    backgroundColor: "#eeeeee !important",
                    color: "#000",
                    height: "40px !important",
                    padding: "5px !important",
                    minHeight: "0px !important",
                    width: "100% !important",
                    border: "1px solid #ddd !important",
                    borderRadius: "8px",
                  }}
                >
                  <Typography>
                    <h6>Dashboard Preview</h6>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="dashBoxs">
                      <div
                        className="dashBoxs-header "
                        style={{ maxHeight: "45px" }}
                      >
                        <div className="row align-items-center">
                          <div className="col-4">
                            <h6>
                              {reference.ActivityNameforConfig.toUpperCase() +
                                " " +
                                "DASHBOARD"}
                            </h6>
                          </div>
                          <div className="col-8">
                            <div
                              className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                              style={{ marginTop: "-10px" }}
                            >
                              <>
                                <div className="d-inline">
                                  <div className="input-group input-group-sm">
                                    <label
                                      className="input-group-text"
                                      for="inputGroupSelect01"
                                    >
                                      Show
                                    </label>
                                    <select
                                      className="form-select font-Standard-11"
                                      id="inputGroupSelect01"
                                    >
                                      <>
                                        <option selected value="0">
                                          My Work Items
                                        </option>
                                        <option value="1">
                                          Other's Work Items
                                        </option>
                                        <option value="2">
                                          All Work Items
                                        </option>
                                      </>
                                    </select>
                                  </div>
                                </div>
                              </>
                              <button
                                type="button"
                                className="btn btn-sm btn-primary ms-2"
                              >
                                <b></b>Export
                              </button>
                              <h3>
                                <button
                                  type="button"
                                  className={filterStyle + ""}
                                >
                                  <b className="icon-sliders"></b>
                                </button>
                                <span>
                                  <a
                                    className="resetData"
                                    data-for="attachmentTip"
                                    data-tip
                                  >
                                    Clear Filter
                                  </a>
                                </span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tableWrap">
                        <div className="tableWrap-body">
                          <table
                            className="table table-borderless MatTable"
                            style={{ zIndex: "1", position: "relative" }}
                          >
                            <ThemeProvider theme={theme}>
                              <MaterialTable
                                title={
                                  reference.ActivityNameforConfig.toUpperCase() +
                                  " " +
                                  "DASHBOARD"
                                }
                                actions={[
                                  {
                                    icon: () => <FcSearch />,
                                    tooltip: "View workitem's detail",
                                    position: "row",
                                  },
                                  {
                                    icon: () => <FcImageFile />,
                                    tooltip: "View document",
                                    position: "row",
                                  },
                                  {
                                    tooltip: "Actions",
                                    icon: () => (
                                      <PendingActionsIcon className="btn btn-sm btn-outline-primary" />
                                    ),
                                  },
                                  {
                                    tooltip: "Export All Selected Rows",
                                    icon: () => <CloudDownloadIcon />,
                                  },
                                ]}
                                options={{
                                  headerStyle: {
                                    position: "sticky",
                                    // top: "-5px",
                                  },
                                  maxBodyHeight: "420px",
                                  pageSize: 100,
                                  exportAllData: true,
                                  showEmptyDataSourceMessage: true,
                                  emptyRowsWhenPaging: false,
                                  pageSizeOptions: [100, 200, 500, 1000],
                                  debounceInterval: 700,
                                  exportButton: false,
                                  // exportCsv: () => {
                                  //   getDashboardDataForExport();
                                  // },
                                  sorting: true,
                                  filtering: true,
                                  search: false,
                                  selection: true,
                                  tableLayout: "auto",
                                  overflowY: false,
                                  showTitle: false,
                                  toolbarButtonAlignment: "left",
                                  headerStyle: {
                                    //  height: 30,
                                  },
                                  rowStyle: {},
                                  tableLayout: "auto",
                                  headerStyle: {
                                    // fontFamily: '"Yantramanav", sans-serif',
                                    fontWeight: "inherit",
                                    // fontSize: "14px",
                                    // textAlign: "left !Important",
                                  },
                                }}
                                components={{
                                  Container: (props) => (
                                    <Paper {...props} elevation={0} />
                                  ),
                                  Toolbar: (props) => (
                                    <div
                                      style={{
                                        backgroundColor: "rgb(206 206 206)",
                                        fontSize: "0.75em",
                                      }}
                                    >
                                      <MTableToolbar
                                        {...props}
                                        style={{ fontSize: "5px" }}
                                      />
                                    </div>
                                  ),
                                }}
                                columns={previewColumns}
                                icons={tableIcons}
                                //data={null}
                              />
                            </ThemeProvider>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <button
            ref={reference.DashBoardConfigRef}
            style={{ display: "none" }}
            onClick={handleSave}
          ></button>
          {/* <button
            ref={reference.ValidateDashBoardConifgRef}
            style={{ display: "none" }}
            onClick={handleDashboardValidation}
          ></button> */}
        </div>
        {/* <GlobalStyles
          styles={{
            ".MuiButtonBase-root.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26) !important",
              cursor: "default !important",
              PointerEvent: "none !important",
            },
            ".MuiButton-root": {
              color: "#1976d2 !important",
            },
          }}
        /> */}
      </div>
    </>
  );
};

export default DashboardConfig;
