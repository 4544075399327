import logo from '../../../../assets/images/logo/exl_21_logo_org.svg';
import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";

function Reset() {
    const { addToast } = useToasts();
    const location = useLocation();
    const dd = location.state;
    const [option, setOption] = useState(dd == null ? 'changepassword' : location.state.option);
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [resendotp, setResendOTP] = useState(false);
    const [usernameSubmitted, setUsernameSubmitted] = useState(false);
    const [savedisabled, setSaveDisabled] = useState(true);
    const [usernamesubmitdisabled, setusernamesubmitDisabled] = useState(false);
    const [otpSubmitted, setOtpSubmitted] = useState(false);
    const [validationcheck, setvalidationcheck] = useState(false);
    const { REACT_APP_PWD } = process.env;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_USER } = process.env;
    const { REACT_APP_OtpCreate } = process.env;
    const { REACT_APP_OtpGet } = process.env;
    const { REACT_APP_PWDChange } = process.env;
    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };
    const OptionChange = (selectedOption) => {
        setOption(selectedOption);
    };

    const UsernameSubmit = (e) => {
        e.preventDefault()
        setusernamesubmitDisabled(true);
        // Logic to send OTP to the user's email or phone number
        console.log(`Sent OTP to ${username}`);
        let body = {
            username: username,
            methodName: '',
            oldpassword: '',
            newpassword: '',
        };
        let userUrl = getAPIURL(REACT_APP_FETCH_USER);
        let req = new Request(`${userUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result != '') {

                    setUsernameSubmitted(true);
                    let bodyotp = {
                        username: username,
                        Otpno: ''
                    };
                    let userUrl1 = getAPIURL(REACT_APP_OtpCreate);
                    let req1 = new Request(`${userUrl1}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(bodyotp),
                    });
                    fetch(req1)
                        .then((req1) => req1.json())
                        .then((result) => {
                            if (result != '') {
                                setResendOTP(true);
                                addToast('OTP send to your EmailId', {
                                    appearance: "info",
                                    autoDismiss: true,
                                });

                            }
                            else {
                                setResendOTP(true);
                                addToast('Please Resend the OTP', {
                                    appearance: "error",
                                    autoDismiss: true,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log("ERROR: ", error);
                            //navigate("/");
                        });


                }
                else {
                    addToast('Forgot Password is available for client user only', {
                        appearance: "info",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });

    };
    const CheckconfirmPassword = (e) => {

        console.log(newPassword);
        console.log(confirmPassword);
        if (newPassword !== confirmPassword) {
            addToast("Your New password and confirm password do not match.", {
                appearance: "error",
                autoDismiss: true,
            });
            setSaveDisabled(true);
        }
        else {
            if (validationcheck == true) {
                setSaveDisabled(false);
            }

        }
    };
    const OtpSubmit = (e) => {
        e.preventDefault()
        let bodyotp = {
            username: username,
            Otpno: otp
        };
        let userUrl = getAPIURL(REACT_APP_OtpGet);
        let req = new Request(`${userUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bodyotp),
        });
        fetch(req)
            .then((req) => req.json())
            .then((result) => {
                if (result !== '') {
                    setOtpSubmitted(true);
                    addToast("Correct otp submitted successfully!", {
                        appearance: "info",
                        autoDismiss: true,
                    });
                }
                else {
                    setResendOTP(true);
                    addToast("Please enter correct otp", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });

        // Logic to verify the OTP
        console.log(`Verified OTP: ${otp}`);

    };

    const ResendOtpsubmit = (e) => {
        let bodyotp = {
            username: username,
            Otpno: ''
        };
        let userUrl1 = getAPIURL(REACT_APP_OtpCreate);
        let req1 = new Request(`${userUrl1}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bodyotp),
        });
        fetch(req1)
            .then((req1) => req1.json())
            .then((result) => {
                if (result != '') {
                    setResendOTP(true);
                    addToast('OTP send to your EmailId', {
                        appearance: "info",
                        autoDismiss: true,
                    });

                }
                else {
                    setResendOTP(true);
                    addToast('Please Resend the OTP', {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });

    }

    const saveForgotPassword = (e) => {
        e.preventDefault()

        let body = {
            username: username,
            methodName: '',
            oldpassword: oldPassword,
            newpassword: newPassword,
            Otpno: otp
        };
        let loginUrl = getAPIURL(REACT_APP_PWD);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result == 'Password changed successfully!') {
                    addToast(result, {
                        appearance: "info",
                        autoDismiss: true,
                    });
                    setTimeout(() => {
                        sessionStorage.clear();
                        sessionStorage.clear();
                        window.location.href = "/";
                    }, 5000);
                }
                else {
                    addToast(result, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
                // if (result.status == 500) {
                //     addToast(result.message, {
                //         appearance: "error",
                //         autoDismiss: true,
                //     });
                // } else if (result.status === 401) {
                //     addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                //         appearance: "error",
                //         autoDismiss: true,
                //     });
                // } else {
                //     if (result.status == 200) {
                //         addToast(result.message, {
                //             appearance: "info",
                //             autoDismiss: true,
                //         });
                //     }
                // }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });

    };
    const saveChangePassword = (e) => {
        e.preventDefault()

        let body = {
            username: sessionStorage.getItem("UserName"),
            methodName: '',
            oldpassword: oldPassword,
            newpassword: newPassword,
            Otpno: ''
        };
        let loginUrl = getAPIURL(REACT_APP_PWDChange);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result == 'Password changed successfully!') {
                    addToast(result, {
                        appearance: "info",
                        autoDismiss: true,
                    });
                    setTimeout(() => {
                        sessionStorage.clear();
                        sessionStorage.clear();
                        window.location.href = "/";
                    }, 5000);
                }
                else {
                    addToast(result, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
                // if (result.status == 500) {
                //     addToast(result.message, {
                //         appearance: "error",
                //         autoDismiss: true,
                //     });
                // } else if (result.status === 401) {
                //     addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                //         appearance: "error",
                //         autoDismiss: true,
                //     });
                // } else {
                //     if (result.status == 200) {
                //         addToast(result.message, {
                //             appearance: "info",
                //             autoDismiss: true,
                //         });
                //     }
                // }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });

    };
    const checkNewPasswordValidation = (e) => {
        const regex = /^.*(?=.{8,})(?=.*\d)(?=.*[^a-z^a-z])(?=.*[a-z])/;
        if (regex.test(e.target.value) == false) {
            addToast(" Please enter atleast 8 charatcer with number, symbol and  letter.", {
                appearance: "error",
                autoDismiss: true,
            });
            setvalidationcheck(false);
            setSaveDisabled(true);
        }
        else {
            setvalidationcheck(true);
        }
    }
    return (
        <>
            {option === 'changepassword' ? <> <div className="authCenter">
                <div className="authWrap">
                    <div className="authOnly">
                        <div className="authForm-main">
                            <div className="brandName"><img src={logo} alt="#" /></div>
                            <h2>Change Password</h2>
                            <form>
                                <ul>
                                    <li>
                                        <label>old password</label>
                                        <input className="form-control" type="password" placeholder="oldpassword" onChange={(e) => setOldPassword(e.target.value)} />
                                    </li>
                                    <li>
                                        <label>new password</label>
                                        <input className="form-control" type="password" placeholder="new password" onChange={(e) => setNewPassword(e.target.value)} onBlur={(e) => checkNewPasswordValidation(e)} />
                                    </li>
                                    <li>
                                        <label>confirm password</label>
                                        <input className="form-control" type="password" onBlur={(e) => CheckconfirmPassword(e)} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="confirm password" />
                                    </li>
                                    <li className="pt-3">
                                        <button className="btn btn-primary" disabled={savedisabled} type="submit" onClick={saveChangePassword} >Save</button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : <> <div className="authCenter">
                <div className="authWrap">
                    <div className="authOnly">
                        <div className="authForm-main">
                            <div className="brandName"><img src={logo} alt="#" /></div>
                            <h2>Forgot Password</h2>
                            <form>
                                <ul>
                                    <li>
                                        <label>user name</label>
                                        <input className="form-control"
                                            type="text"
                                            placeholder="Enter username"
                                            value={username}
                                            disabled={usernameSubmitted}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </li>
                                    {!usernameSubmitted && (
                                        <li className="pt-3">
                                            <button className="btn btn-primary" disabled={usernamesubmitdisabled} onClick={(e) => UsernameSubmit(e)}>Submit Username</button>
                                        </li>)}

                                    {usernameSubmitted && !otpSubmitted && (
                                        <>
                                            <li>
                                                <label>otp</label>
                                                <input className="form-control"
                                                    type="text"
                                                    placeholder="Enter OTP"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                />
                                                {resendotp && (
                                                    <a style={{ float: 'inline-end', color: '#fb4e0b' }} onClick={(e) => ResendOtpsubmit(e.target.value)}>Resend OTP</a>
                                                )}
                                            </li>


                                            <li className="pt-3">
                                                <button className="btn btn-primary" type="submit" onClick={OtpSubmit}>Submit OTP</button>
                                            </li>
                                        </>
                                    )}



                                    {otpSubmitted && (
                                        <>
                                            <li>
                                                <label>new password</label>
                                                <input className="form-control"
                                                    type="password"
                                                    placeholder="New Password"
                                                    value={newPassword}

                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    onBlur={(e) => checkNewPasswordValidation(e)}
                                                />

                                            </li>
                                            <li>
                                                <label>confirm password</label>
                                                <input className="form-control"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    value={confirmPassword}
                                                    onBlur={(e) => CheckconfirmPassword(e)} onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                            </li>
                                            <li className="pt-3">
                                                <button className="btn btn-primary" disabled={savedisabled} type="submit" onClick={(e) => saveForgotPassword(e)}>Save</button>
                                            </li>

                                        </>
                                    )}



                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            </>}


        </>
    )
}

export default Reset;