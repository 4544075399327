import React, { useState, useEffect, useContext } from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography} from '@mui/material'
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";

import DashboardContext from "../../../../contexts/DashboardContext";

import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
//import "../../../../assets/css/subprocesssummary.scss";


 

function SubProcessSummary() {
    const { addToast } = useToasts();
  const { REACT_APP_API_URL } = process.env;
  const {REACT_APP_SubProcessSummary} = process.env;
  const [ subProcessSummary, setsubProcessSummary ] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;

  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  


  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const GetSummaryForSubProcess = () => {
    debugger;
    let body = {
        subProcessID: sessionStorage.getItem("ProcessId"),
    };
    let activityUrl = getAPIURL(REACT_APP_SubProcessSummary);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
            setsubProcessSummary(
            result.data
          );
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    GetSummaryForSubProcess();
  } , []);

  
  

  return (
    <>
    <Header onpreferenceShow={null} pageName="Sub-Process Summary" />
    <ApplicationUserContext.Provider value={processingDashboardParam.userRoles}>
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection" 
      >
        

    <div className="container">
        <h2 className="title">
        Daily Transaction Statistics
        </h2>
        <table className="table table-bordered MatTable"
                  >
            <tbody>
                <tr>
                    <th colSpan={2} >
                        Start of the Day
                    </th>
                </tr>
                <tr>
                <td >With EXL:</td>
                <td ><b>{subProcessSummary.length > 0? subProcessSummary[0].openingwithOPI : ''}</b></td>
                </tr>
                <tr>
                    <td >
                        With Client:
                    </td>
                    <td ><b>
                    {subProcessSummary.length > 0? subProcessSummary[0].openingwithClient : ''}
                    </b>
                    </td>
                </tr>


                <tr>
                    <th colSpan={2}>During the Day</th>
                </tr>
                <tr>
                    <td>Received by EXL:</td>
                    <td ><b>{subProcessSummary.length > 0? subProcessSummary[0].received : ''}</b></td>
                </tr>
                <tr>
                    <td >Completed by EXL:</td>
                    <td ><b>{subProcessSummary.length > 0? subProcessSummary[0].completed : ''}</b></td>
                </tr>


                <tr>
                    <th colSpan={2}>Current Status</th>
                </tr>
                <tr>
                    <td >With EXL:</td>
                    <td ><b>{subProcessSummary.length > 0? subProcessSummary[0].currentStatuswithOPI : ''}</b></td>
                </tr>
                <tr>
                    <td >With Client:</td>
                    <td><b>{subProcessSummary.length > 0? subProcessSummary[0].currentStatuswithClient : ''}</b></td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
    < Footer />
    </>
  )
}

export default SubProcessSummary;