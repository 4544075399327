import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import FormContext from "../../../../contexts/FormContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import UserClassificationListGridDetailsPage from "./UserClassificationListGridDetailsPage";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import { alpha } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import EditIcon from "@mui/icons-material/Edit";
import { Paper } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingSpinnerFigma from "src/LoadingSpinnerFigma";

function WorkitemFieldsRules() {
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
  const { REACT_APP_GETWORKITEMFIELDSLIST } = process.env;
  const { REACT_APP_GETWORKITEMFIELDDROPDOWNLIST } = process.env;
  const { REACT_APP_GETCONDITIONALOPERATOR } = process.env;
  const { REACT_APP_SAVEWORKITEMFIELDSRULES } = process.env;
  const { REACT_APP_GETWORKITEMFIELDSRULES } = process.env;
  const { REACT_APP_DELETEWORKITEMFIELDSRULES } = process.env;
  const { REACT_APP_GETWORKITEMFIELDSRULESBYRULESID } = process.env;
  const location = useLocation();
  const processingDashboardParam = location.state;
  let navigate = useNavigate();
  const { addToast } = useToasts();
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");

  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");

  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [departments, setDepartments] = useState([]);
  const [Isdepartments, setIsDepartments] = useState(false);

  const [clientLocations, setClientLocations] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState(false);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] =
    useState(false);
  const [ActivityId, setActivityId] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [JsonValues, setJsonValues] = useState([]);
  const [workitemFields, setWorkitemFields] = useState([]);
  const [workitemFieldsTarget, setWorkitemFieldsTarget] = useState([]);
  const [SourceWorkitemFieldDropdownlist, setSourceWorkitemFieldDropdownlist] =
    useState([]);
  const [TargetWorkitemFieldDropdownlist, setTargetWorkitemFieldDropdownlist] =
    useState([]);
  const [conditionalOperator, setConditionalOperator] = useState([]);
  const [workitemFieldRuleName, setWorkitemFieldRuleName] = useState("");
  const [sourceMapToField, setSourceMapToField] = useState("");
  const [sourceFieldID, setSourceFieldID] = useState("");
  const [sourceControlType, setSourceControlType] = useState("");
  const [sourceFieldName, setSourceFieldName] = useState("");
  const [conditionalOperatorselect, setconditionalOperatorSelect] =
    useState("");
  const [conditionalValue, setconditionalValue] = useState("");
  const [targetMapToField, setTargetMapToField] = useState("");
  const [targetFieldID, setTargetFieldID] = useState("");
  const [targetControlType, setTargetControlType] = useState("");
  const [targetFieldName, setTargetFieldName] = useState("");
  const [targetFieldValue, setTargetFieldValue] = useState("");
  const [targetFieldReadonly, setTargetFieldReadonly] = useState("False");
  const [targetFieldMandatory, settargetFieldMandatory] = useState("False");
  const [TargetFieldColor, setTargetFieldColor] = useState("#FFFFFF");
  const [
    GetSaveWorkitemFieldsGridDetails,
    setGetSaveWorkitemFieldsGridDetails,
  ] = useState([]);
  const [workitemFieldRulesID, setworkitemFieldRulesID] = useState("0");
  const [buttonSaveName, setbuttonSaveName] = useState("Save");
  const [isloading, setisloading] = useState(false);

  const [isActiveRulesName, setIsActiveRulesName] = useState(false);
  const [isActiveSourceField, setIsActiveSourceField] = useState(false);
  const [isActiveConditionalOperator, setIsActiveConditionalOperator] =
    useState(false);
  const [isActiveConditionalValue, setisActiveConditionalValue] =
    useState(false);
  const [isActiveTargetField, setisActiveTargetField] = useState(false);
  const [isActiveTargetValue, setisActiveTargetValue] = useState(false);

  const [maxMessage, setMaxMessage] = useState("");
  const [ruleTitle, setRuleTitle] = useState("Add Rule Details");
  const [isCustomField, setIsCustomField] = useState("1");
  const colorPickerRef = React.useRef(null);
  const columns = [
    {
      title: "workitemFieldRulesID",
      field: "workitemFieldRulesID",
      hidden: true,
    },
    {
      title: "Rules Name",
      field: "workitemFieldRuleName",
    },
    {
      title: "Source Field Name",
      field: "sourceFieldName",
    },
    {
      title: "Conditional Operator ",
      field: "sourceconditionalOperator",
    },
    {
      title: "Conditional Value",
      field: "sourceconditionalValue",
    },
    {
      title: "Target Field Name",
      field: "targetFieldName",
    },
    {
      title: "Target Value",
      field: "targetValue",
    },
    {
      title: "Color",
      field: "targetFieldColor",
      render: (rowData) => (
        <input type="color" value={rowData.targetFieldColor} disabled />
      ),
    },
    {
      title: "Readonly",
      field: "targetFieldReadonly",
    },
    {
      title: "Mandatory",
      field: "targetFieldMandatory",
    },
    {
      title: "Is Custom Field",
      field: "isCustomField",
      render: (rowData) => (
        <input value={rowData.isCustomField==1?"Yes":"No"} disabled />
      ),
    },
  ];

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const FetctActivityID = (e) => {
    //debugger
    setActivityId(e.target.value);
    e.target.value != "0"
      ? setIsActivitiesForSubprocess(true)
      : setIsActivitiesForSubprocess(false);
    Reset();
    setMaxMessage("");
    setRuleTitle("Add Rule Details");
    FetchGetSaveWorkitemFieldDetails(processSubProcessID, e.target.value);
    if (e.target.value != "0") {
      GetWorkitemFields(
        sessionStorage.getItem("ProcessId"),
        e.target.value,
        ""
      );
      GetWorkitemFieldsTarget(
        sessionStorage.getItem("ProcessId"),
        e.target.value,
        ""
      );
    }
    else{
      setWorkitemFields([]);
      setWorkitemFieldsTarget([]);
    }
  };
  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          setIsDepartments(true);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const GetActivitiesForSubprocess = (subprocessID) => {
    //debugger
    let body = {
      subProcessID: subprocessID,
    };
    let activityUrl = getAPIURL(REACT_APP_GETACTIVITIESFORSUBPROCESS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setActivitiesForSubprocess(result.data._listworkitemgridData);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchProcessSubProcessByGuid = (e) => {
    //debugger
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setActivitiesForSubprocess([]);
      //setPriortyForSubProcessDetails([]);
      //setPriortyForSubProcessDetails([]);
      //setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setIsClientLocations(false);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        // sessionStorage.setItem(
        //   "selectedLocation",
        //   e.target.value.split("/")[e.target.value.split("/").length - 1]
        // );
        // sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      }
    }
  };
  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      //setShowModalButton(false);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      //setIsDepartments(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      //   sessionStorage.setItem(
      //     "selectedDepartment",
      //     e.target.value.split("/")[1]
      //   );
      //   sessionStorage.setItem(
      //     "selectedDepartmentName",
      //     e.target.value.split("/")[0]
      //   );
      setSelectedDepartment(e.target.value);
      setIsDepartments(true);
      //setIsClientLocations(true);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
            setIsClientLocations(true);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          setIsprocessSubprocess(true);          
          GetActivitiesForSubprocess(sessionStorage.getItem("ProcessId"));
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
          setIsClientLocations(true);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };
  const FetchInvalidProcessSubprocessByGuid = async (e) => {
    //debugger
    if (e.target.value == "Select") {      
      setActivitiesForSubprocess([]);
      
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {      
      setIsprocessSubprocess(true);
      setSelectedSubprocess(e.target.value.split("/")[0]);
      GetActivitiesForSubprocess(e.target.value.split("/")[0]);
    }
  };
  const handelChange = (event, oldElements) => {
    const newElements = { ...oldElements };
    newElements.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
      (element) => {
        if (element.maptofield == event.target.id) {
          element.fieldValues = event.target.value;
        }
      }
    );
    setJsonValues(newElements);
  };
  const GetWorkitemFields = (subProcessID, ActivityId, customfield) => {
    if (customfield === "") {
      customfield = isCustomField;
    }
    let body = {
      //guid: sessionStorage.getItem("GUID"),
      subProcessId: subProcessID,
      activityID: ActivityId,
      isCustomField: customfield,
    };

    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDSLIST);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setWorkitemFields(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetWorkitemFieldsTarget = (subProcessID, ActivityId, customfield) => {
    customfield = "1";
    let body = {
      //guid: sessionStorage.getItem("GUID"),
      subProcessId: subProcessID,
      activityID: ActivityId,
      isCustomField: customfield,
    };

    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDSLIST);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setWorkitemFieldsTarget(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetWorkitemFieldDropdownlist = (workItemIdField, value) => {
    let body = {
      workitemFieldID: workItemIdField,
      subProcessId: sessionStorage.getItem("ProcessId"),
      activityID: ActivityId,
      isCustomField: isCustomField,
    };

    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDDROPDOWNLIST);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          if (value === "SourceField")
            setSourceWorkitemFieldDropdownlist(result.data);
          if (value === "TargetField")
            setTargetWorkitemFieldDropdownlist(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetWorkitemFieldDropdownlistTarget = (workItemIdField, value) => {
    let body = {
      workitemFieldID: workItemIdField,
      subProcessId: sessionStorage.getItem("ProcessId"),
      activityID: ActivityId,
      isCustomField: "1",
    };

    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDDROPDOWNLIST);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setTargetWorkitemFieldDropdownlist(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetConditionalOperator = (workItemIdField) => {
    let body = {
      subProcessID: "",
      workitemFieldID: workItemIdField,
      activityID: "",
    };

    let loginUrl = getAPIURL(REACT_APP_GETCONDITIONALOPERATOR);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setConditionalOperator(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchOnchangeRuleName = (e) => {
    setWorkitemFieldRuleName(e.target.value);
    setIsActiveRulesName(true);
    if (e.target.value === "") {
      setIsActiveRulesName(false);
    }
  };
  const FetchOnchangeSourceField = (e) => {
    if (e.target.value != "Select") {
      let workitemfieldId = e.target.value.split("/")[1];
      let workitemfieldName = e.target.value.split("/")[0];
      let controlType = e.target.value.split("/")[3];
      setSourceControlType(controlType);
      setSourceMapToField(e.target.value.split("/")[2]);
      setSourceFieldID(workitemfieldId);
      setSourceFieldName(workitemfieldName);
      GetConditionalOperator(workitemfieldId);
      if (controlType === "System.Web.UI.WebControls.DropDownList") {
        GetWorkitemFieldDropdownlist(workitemfieldId, "SourceField");
      }
      setIsActiveSourceField(true);
    } else {
      setIsActiveSourceField(false);
    }
  };
  const FetchOnchangeTargetField = (e) => {
    if (e.target.value != "Select") {
      let workitemfieldId = e.target.value.split("/")[1];
      let targetcontrolType = e.target.value.split("/")[3];
      setTargetMapToField(e.target.value.split("/")[2]);
      setTargetFieldID(workitemfieldId);
      setTargetFieldName(e.target.value.split("/")[0]);
      setTargetControlType(targetcontrolType);
      if (targetcontrolType === "System.Web.UI.WebControls.DropDownList") {
        GetWorkitemFieldDropdownlistTarget(workitemfieldId, "TargetField");
      }
      setisActiveTargetField(true);
    } else {
      setisActiveTargetField(false);
    }
  };
  const FetchOnchangeConditionalValue = (e) => {
    if (e.target.value != "Select") {
      setconditionalOperatorSelect(e.target.value);
      setIsActiveConditionalOperator(true);
    } else {
      setIsActiveConditionalOperator(false);
    }
  };

  const FetchOnchangeSourceCV = (e) => {
    if (e.target.value != "Select") {
      setconditionalValue(e.target.value);
      setisActiveConditionalValue(true);
    } else {
      setisActiveConditionalValue(false);
    }
  };
  const FetchOnchangeTV = (e) => {
    if (e.target.value != "Select") {
      setTargetFieldValue(e.target.value);
      setisActiveTargetValue(true);
    } else {
      setisActiveTargetValue(false);
    }
  };

  const FetchOnchangeTargetReadonly = (e) => {
    setTargetFieldReadonly(e.target.value);
  };

  const FetchOnchangeTargetMandatory = (e) => {
    settargetFieldMandatory(e.target.value);
  };
  const FetchOnchangeTC = (e) => {
    //debugger
    if (e.target.value === "undefined") {
      setTargetFieldColor("#FFFFFF");
    } else {
      setTargetFieldColor(e.target.value);
    }
  };
  const SaveWorkitemFieldsRules = () => {
    //debugger
    setbuttonSaveName("Saving....");
    if (TargetFieldColor == undefined || TargetFieldColor == null) {
      setTargetFieldColor("");
    }
    let body = {
      subProcessID: processSubProcessID,
      activityId: ActivityId,
      workitemFieldRuleName: workitemFieldRuleName,
      sourceMapToField: sourceMapToField,
      sourceFieldID: sourceFieldID,
      sourceFieldName: sourceFieldName,
      sourceControlType: sourceControlType,
      sourceConditionalOperator: conditionalOperatorselect,
      sourceConditionalValue: conditionalValue,
      targetMapToField: targetMapToField,
      targetFieldID: targetFieldID,
      targetFieldName: targetFieldName,
      targetControlType: targetControlType,
      targetValue: targetFieldValue,
      targetFieldReadonly: targetFieldReadonly.toString(),
      targetFieldMandatory: targetFieldMandatory.toString(),
      targetFieldColor: TargetFieldColor,
      workitemFieldRulesID: workitemFieldRulesID.toString(),
      updatedBy: sessionStorage.getItem("PersonID"),
      isCustomField: isCustomField,
    };
    console.log(body);
    //debugger
    let loginUrl = getAPIURL(REACT_APP_SAVEWORKITEMFIELDSRULES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          //debugger
          FetchGetSaveWorkitemFieldDetails(processSubProcessID, ActivityId);
          setbuttonSaveName("Save");
          if (result.data[0].errorYN === "N") {
            addToast(result.data[0].message, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(result.data[0].message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          //reseting value
          Reset();
          //setIsActivitiesForSubprocess(true);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };  
  const Reset = (reset) => {
    if(reset==0)
    {
      setActivityId("0");
      setGetSaveWorkitemFieldsGridDetails([]);
      setWorkitemFields([]);
      setWorkitemFieldsTarget([]);
    }
    setworkitemFieldRulesID("0");
    setWorkitemFieldRuleName("");
    setSourceFieldName("");
    setconditionalOperatorSelect("");
    setconditionalValue("");
    setTargetFieldName("");
    setTargetFieldValue("");
    setTargetFieldReadonly("False");
    settargetFieldMandatory("False");
    setTargetFieldColor("#FFFFFF");
    setconditionalOperatorSelect("");
    setIsActiveRulesName(false);
    setIsActiveSourceField(false);
    setIsActiveConditionalOperator(false);
    setisActiveConditionalValue(false);
    setisActiveTargetField(false);
    setisActiveTargetValue(false);
    setTargetControlType("");
    setSourceControlType("");
    setIsCustomField("1");
    setRuleTitle("Add Rule Details");
  };
  const FetchGetSaveWorkitemFieldDetails = (subProcessID, activityId) => {
    let body = {
      subProcessID: subProcessID,
      activityID: activityId,
    };
    setActivityId(activityId);
    let getWorkitemFieldUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDSRULES);
    let req = new Request(`${getWorkitemFieldUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setGetSaveWorkitemFieldsGridDetails(result.data);
          setMaxMessage(result.data[0].maxMessage);
        } else {
          setGetSaveWorkitemFieldsGridDetails([]);
        }
      })
      .catch((error) => {
        setGetSaveWorkitemFieldsGridDetails([]);
        console.log("ERROR: ", error);
      });
  };
  const FetchSaveWorkitemFieldDetailsByRuleID = (workitemFieldRulesID) => {
    //debugger
    setisloading(true);
    let body = {
      workitemFieldRulesID: "" + workitemFieldRulesID,
    };
    let getWorkitemFieldUrl = getAPIURL(
      REACT_APP_GETWORKITEMFIELDSRULESBYRULESID
    );
    let req = new Request(`${getWorkitemFieldUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          //debugger
          let sourceControlType = result.data[0].sourceControlType;
          let targetControlType = result.data[0].targetControlType;
          setworkitemFieldRulesID(result.data[0].workitemFieldRulesID);
          setWorkitemFieldRuleName(result.data[0].workitemFieldRuleName);
          setSourceFieldID(result.data[0].sourceFieldID);
          setSourceMapToField(result.data[0].sourceMapToField);
          setSourceFieldName(result.data[0].sourceFieldName);
          setSourceControlType(result.data[0].sourceControlType);

          GetConditionalOperator(result.data[0].sourceFieldID);
          setconditionalOperatorSelect(
            result.data[0].sourceConditionalOperator
          );

          if (sourceControlType === "System.Web.UI.WebControls.DropDownList") {
            GetWorkitemFieldDropdownlist(
              result.data[0].sourceFieldID,
              "SourceField"
            );
          }
          setconditionalValue(result.data[0].sourceConditionalValue);

          setTargetFieldID(result.data[0].targetFieldID);
          setTargetFieldName(result.data[0].targetFieldName);
          setTargetMapToField(result.data[0].targetMapToField);
          setTargetControlType(result.data[0].targetControlType);

          if (targetControlType === "System.Web.UI.WebControls.DropDownList") {
            GetWorkitemFieldDropdownlistTarget(
              result.data[0].targetFieldID,
              "TargetField"
            );
          }
          setTargetFieldValue(result.data[0].targetValue);

          setTargetFieldReadonly(result.data[0].targetFieldReadonly);
          settargetFieldMandatory(result.data[0].targetFieldMandatory);
          setTargetFieldColor(result.data[0].targetFieldColor);
          setIsCustomField(result.data[0].isCustomField);
          GetWorkitemFields(
            sessionStorage.getItem("ProcessId"),
            ActivityId,
            result.data[0].isCustomField
          );
          setIsActiveRulesName(true);
          setIsActiveSourceField(true);
          setIsActiveConditionalOperator(true);
          setisActiveConditionalValue(true);
          setisActiveTargetField(true);
          setisActiveTargetValue(true);
          setRuleTitle("Edit Rules Details");
        }
        setisloading(false);
      })
      .catch((error) => {
        //setGetWorkitemFieldsByRuleID([]);
        console.log("ERROR: ", error);
        setisloading(false);
      });
  };
  const ImageModeOnChange = (e) => {
    let IsCustomField = "";
    if (e.target.checked === false) {
      setIsCustomField("0");
      IsCustomField = "0";
    } else {
      setIsCustomField("1");
      IsCustomField = "1";
    }
    GetWorkitemFields(
      sessionStorage.getItem("ProcessId"),
      ActivityId,
      IsCustomField
    );
    GetWorkitemFieldsTarget(
      sessionStorage.getItem("ProcessId"),
      ActivityId,
      IsCustomField
    );
  };
  const DeleteWorkitemFieldRule = (workitemFieldRulesID) => {
    //debugger
    let body = {
      subProcessID: "",
      workitemFieldID: "",
      activityID: "",
      workitemFieldRulesID: "" + workitemFieldRulesID,
    };
    let getWorkitemFieldUrl = getAPIURL(REACT_APP_DELETEWORKITEMFIELDSRULES);
    let req = new Request(`${getWorkitemFieldUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          FetchGetSaveWorkitemFieldDetails(processSubProcessID, ActivityId);
          addToast("Rules Deleted Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
  }, []);
  return (
    <>
      <Header onpreferenceShow={null} pageName="Workitem Fields Rules" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="bussinessrules" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <FormContext.Provider value={{ onchange: handelChange }}>
        <div className="centerSection">
          <div className="pageBody">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label"> Company Department</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchLocationClientsByGuid}
                        disabled
                      >
                        <option>Select</option>
                        {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString ==
                              companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Location Client</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchProcessSubProcessByGuid}
                        disabled
                      >
                        <option>Select</option>
                        {clientLocations.map((location) => (
                          <option
                            value={
                              location.locationClient +
                              "/" +
                              location.clientIDString
                            }
                            selected={
                              location.clientIDString == locationClientID
                            }
                          >
                            {location.locationClient}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Process SubProcess</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchInvalidProcessSubprocessByGuid}
                        disabled
                      >
                        <option>Select</option>
                        {processSubprocess.map((subprocess) => (
                          <option
                            value={
                              subprocess.subProcessIDString +
                              "/" +
                              subprocess.processSubProcess
                            }
                            selected={
                              subprocess.subProcessIDString ==
                              processSubProcessID
                            }
                          >
                            {subprocess.processSubProcess}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Activity</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetctActivityID}
                        value={ActivityId}
                      >
                        <option value="0">Select</option>
                        {ActivitiesForSubprocess.map((subprocess) => (
                          <option value={subprocess.ActivityID}>
                            {subprocess.ActivityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col-2">
                <h6 class="mb-3">{ruleTitle} </h6>
              </div>
              <div className="col-6">
                <h6 class="mb-3">{maxMessage}</h6>
              </div>
            </div>

            <div className="dashBoxs mb-3">
              {isloading ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="formWrap ">
                  <div className="row align-items-end">
                    <div className="col-2">
                      <div className="mb-3">
                        <label className="form-label">Rules Name</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          onChange={FetchOnchangeRuleName}
                          value={workitemFieldRuleName}
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="mb-3">
                        <label className="form-label form-check form-switch mb-1 pb-1">
                          {" "}
                          Is Custom Field
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={ImageModeOnChange}
                            checked={isCustomField == "1" ? true : false}
                          />
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchOnchangeSourceField}
                        >
                          <option>Select</option>
                          {workitemFields.map((workitemField) => (
                            <option
                              value={
                                workitemField.labelText +
                                "/" +
                                workitemField.workitemFieldID +
                                "/" +
                                workitemField.mapToField +
                                "/" +
                                workitemField.uiControlType
                              }
                              selected={
                                workitemField.labelText == sourceFieldName
                              }
                            >
                              {workitemField.labelText}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="mb-3">
                        <label className="form-label">
                          Conditional Operator
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchOnchangeConditionalValue}
                        >
                          <option>Select</option>
                          {conditionalOperator.map((operator) => (
                            <option
                              value={operator.contionalOperator}
                              selected={
                                operator.contionalOperator ==
                                conditionalOperatorselect
                              }
                            >
                              {operator.contionalOperator}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Conditional Value</label>
                        {sourceControlType.includes(
                          "Infragistics.Web.UI.EditorControls"
                        ) ? (
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            onChange={FetchOnchangeSourceCV}
                            value={conditionalValue}
                          />
                        ) : (
                          <>
                            {sourceControlType.includes("DropDownList") ? (
                              <select
                                className="form-control form-control-sm"
                                onChange={FetchOnchangeSourceCV}
                              >
                                <option>Select</option>
                                {SourceWorkitemFieldDropdownlist.map(
                                  (dropdownvalue) => (
                                    <option
                                      value={
                                        dropdownvalue.workitemFieldsDropdownValues
                                      }
                                      selected={
                                        dropdownvalue.workitemFieldsDropdownValues ==
                                        conditionalValue
                                      }
                                    >
                                      {
                                        dropdownvalue.workitemFieldsDropdownValues
                                      }
                                    </option>
                                  )
                                )}
                              </select>
                            ) : (
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                onChange={FetchOnchangeSourceCV}
                                value={conditionalValue}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Target Field</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchOnchangeTargetField}
                        >
                          <option>Select</option>
                          {workitemFieldsTarget.map((workitemField) => (
                            <option
                              value={
                                workitemField.labelText +
                                "/" +
                                workitemField.workitemFieldID +
                                "/" +
                                workitemField.mapToField +
                                "/" +
                                workitemField.uiControlType
                              }
                              selected={
                                workitemField.labelText == targetFieldName
                              }
                            >
                              {workitemField.labelText}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-2">
                      <div className="mb-3">
                        <label className="form-label">Target Value</label>
                        {targetControlType.includes(
                          "Infragistics.Web.UI.EditorControls"
                        ) ? (
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            onChange={FetchOnchangeTV}
                            value={targetFieldValue}
                          />
                        ) : (
                          <>
                            {targetControlType.includes("DropDownList") ? (
                              <select
                                className="form-control form-control-sm"
                                onChange={FetchOnchangeTV}
                              >
                                <option>Select</option>
                                {TargetWorkitemFieldDropdownlist.map(
                                  (dropdownvalue) => (
                                    <option
                                      value={
                                        dropdownvalue.workitemFieldsDropdownValues
                                      }
                                      selected={
                                        dropdownvalue.workitemFieldsDropdownValues ==
                                        targetFieldValue
                                      }
                                    >
                                      {
                                        dropdownvalue.workitemFieldsDropdownValues
                                      }
                                    </option>
                                  )
                                )}
                              </select>
                            ) : (
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                onChange={FetchOnchangeTV}
                                value={targetFieldValue}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="mb-2">
                        <label className="form-label"> Readonly</label>
                        <input
                          type="radio"
                          value="True"
                          name="readonly"
                          checked={targetFieldReadonly === "True"}
                          onChange={FetchOnchangeTargetReadonly}
                        />{" "}
                        Yes{" "}
                        <input
                          type="radio"
                          value="False"
                          name="readonly"
                          checked={targetFieldReadonly === "False"}
                          onChange={FetchOnchangeTargetReadonly}
                        />{" "}
                        No
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="mb-2">
                        <label className="form-label"> Mandatory</label>
                        <input
                          type="radio"
                          value="True"
                          name="mandatory"
                          checked={targetFieldMandatory === "True"}
                          onChange={FetchOnchangeTargetMandatory}
                        />{" "}
                        Yes{" "}
                        <input
                          type="radio"
                          value="False"
                          name="mandatory"
                          checked={targetFieldMandatory === "False"}
                          onChange={FetchOnchangeTargetMandatory}
                        />{" "}
                        No
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-2">
                        <label className="form-label"> Target Color</label>
                        <div title="Click on color box to change." className="form-control form-control-sm ColorPickerDiv" >
                          <div className="ColorPickerDiv-first" >
                            <i>{TargetFieldColor}</i>
                          </div>
                          <div
                            className="ColorPickerDiv-second"
                            
                          >
                            <input
                              type="color"
                              class=""
                              onChange={FetchOnchangeTC}
                              id="favcolor"
                              name="favcolor"
                              value={TargetFieldColor}
                            />                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <button
                          class="btn btn-primary ms-3"
                          disabled={
                            !isActiveConditionalOperator ||
                            !isActiveRulesName ||
                            !isActiveSourceField ||
                            !isActiveTargetField ||
                            !isActiveConditionalValue
                          }
                          onClick={() => {
                            SaveWorkitemFieldsRules();
                          }}
                        >
                          {buttonSaveName}
                        </button>
                        <button
                          class="btn btn-primary ms-3"
                          onClick={() => {
                            Reset(0);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-6">
                    <h6>Workitem Fields Rules Details</h6>
                  </div>
                </div>
              </div>
              <table
                className="table table-borderless MatTable"
                style={{ zIndex: "1", position: "relative" }}
              >
                <MaterialTable
                  title="WorkitemFields Rules Details"
                  editable={{
                    onRowDelete: (selectedRow) =>
                      new Promise((resolve, reject) => {
                        DeleteWorkitemFieldRule(
                          selectedRow.workitemFieldRulesID
                        );
                        resolve();
                      }),
                  }}
                  actions={[
                    {
                      icon: () => <EditIcon />,
                      tooltip: "Edit Row",
                      onClick: (event, rowData) =>
                        FetchSaveWorkitemFieldDetailsByRuleID(
                          rowData.workitemFieldRulesID
                        ),
                    },
                  ]}
                  options={{
                    showTitle: false,
                    sorting: true,
                    filtering: true,
                    search: false,
                    paging: false,
                    cellStyle: {
                      // width: 100,
                      // minWidth: 100,
                    },
                    headerStyle: {},
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                  }}
                  components={{
                    OverlayLoading: (props) => <LoadingSpinnerFigma />,
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={GetSaveWorkitemFieldsGridDetails}
                />
              </table>
            </div>
          </div>
        </div>
      </FormContext.Provider>
      <Footer />
    </>
  );
}
export default WorkitemFieldsRules;
