import React, { useState, useContext, useEffect } from "react";
// import Nav from "../../../../components/nav/Nav";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../../src/authConfig";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Token from "@mui/icons-material/Token";
import LoadingSpinner from "../../../../LoadingSpinner";
import {
    setupRefreshTokenTimer,
} from "src/components/common/functions";

//toast.configure()
function SSOLogin() {
  const { instance } = useMsal();
  const { REACT_APP_SSOLOGIN } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_UPDATEMEMBERSHIPLOGININFO } = process.env;
  const { REACT_APP_CLIENTURL } = process.env;
  const { addToast } = useToasts();
  const [isLoading, set_IsLoading] = useState(false);

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  let navigate = useNavigate();

  const LoginHandle = (useremail, token) => {
    const myArray = useremail.split("@");
    let username = myArray[0];
  
    set_IsLoading(true);
    sessionStorage.setItem("ApplicationURL", window.location.origin);
   
    let UserToken = "";
    let indexp = 0;
   
    var Buffer = require("buffer/").Buffer;

    // let buff = new Buffer(UserToken);
    // let encryptedUserToken = buff.toString("base64");
    //let encryptedpassword = Buffer.from(password, "base64");
    let body = {
      AzureADUserName: useremail,
      // UserToken: encryptedUserToken,
      applicationname: sessionStorage.getItem("ApplicationURL"),
       ForceLogin: false,
    };
    ProcessLogin(body,token,username);
  };
  const ProcessLogin = (body,token,username) => { 
    let loginUrl = getAPIURL(REACT_APP_SSOLOGIN);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        set_IsLoading(false);
        debugger;
        if (result.status == 500) {
          const confirmBox = window.confirm(
            "You are already Loggedin in other place! If you want to login here click OK"
          );

         
          if (confirmBox === true) {
            let body1 = {
              AzureADUserName: body.AzureADUserName,
              // UserToken: encryptedUserToken,
              applicationname: body.applicationname,
               ForceLogin: true,
            };
          ProcessLogin(body1,token,username);
          }
        } else if (result.status == 200) {
          if(result.data[0].restrictCWSActive=="Y" && sessionStorage.getItem("platform")=='null')
          {
            const confirmBox = window.confirm(
              result.data[0].descriptions
            );
            if (confirmBox === true) {
              // window.open(result.data[0].redirectURLLink);
              window.location.href = result.data[0].redirectURLLink;
            }
          }
          else
          {
            addToast(result.message, {
              appearance: "info",
              autoDismiss: true,
            });

          sessionStorage.setItem("authorizationToken", result.data[0].token);
          sessionStorage.setItem("RefreshToken", result.data[0].refreshToken);
          if (result.data[0].isMultipleSubProcessAllowed) {
            sessionStorage.setItem("is_userPreference", true);
          } else {
            sessionStorage.setItem("is_userPreference", false);
          }
          const rolesName = result.data[0].rolesName;

            // Navigation Blacklisting according to current development- Aman
            sessionStorage.setItem("rolesList", JSON.stringify(rolesName));

            //Added by Gajendra  for local timezone name
            var timezonename = new Date()
              .toLocaleTimeString("en-us", { timeZoneName: "long" })
              .split(" ")[2];
            sessionStorage.setItem("LocalTimezoneName", timezonename);
            for (let i = 0; i < rolesName.length; i++) {
              if (rolesName[i].roleName === "Admin1")
                sessionStorage.setItem("is_admin", true);
              else if (rolesName[i].roleName === "One And Done")
                sessionStorage.setItem("is_OneandDone", true);
              
              else if (rolesName[i].roleName === "Super Admin" || rolesName[i].roleName === "SuperAdmin")
                sessionStorage.setItem("is_superadmin", true);
              else if (rolesName[i].roleName === "Upload")
                sessionStorage.setItem("is_upload", true);
              else if (
                rolesName[i].roleName === "Activities" ||
                rolesName[i].roleName === "CWSActivities"
              )
                sessionStorage.setItem("is_activities", true);
              else if (rolesName[i].roleName === "Client")
                sessionStorage.setItem("is_client", true);
              else if (rolesName[i].roleName === "Exceptions")
                sessionStorage.setItem("is_exceptions", true);
              else if (rolesName[i].roleName === "Complete")
                sessionStorage.setItem("is_complete", true);
              else if (rolesName[i].roleName === "Vendor")
                sessionStorage.setItem("is_vendor", true);
           
              else if (rolesName[i].roleName === "Report" ||
                rolesName[i].roleName === "Reports")
                sessionStorage.setItem("is_report", true);
            
              else if (rolesName[i].roleName === "Configuration")
                sessionStorage.setItem("is_Configuration", true);
              else if (rolesName[i].roleName === "User Classification Mapping")
                sessionStorage.setItem("is_UserClassificationMapping", true);  
              else if (rolesName[i].roleName === "User Classification List")
                sessionStorage.setItem("is_UserClassificationList", true); 
              else if (rolesName[i].roleName === "Workitem Fields Rules")
                sessionStorage.setItem("is_WorkitemFieldsRules", true);    
             
              else if (rolesName[i].roleName === "Manage WorkItems")
                sessionStorage.setItem("is_manageWorkItems", true);
              else if (rolesName[i].roleName === "CWSSearch" || rolesName[i].roleName === "CWSRestrictedSearch")
                sessionStorage.setItem("is_search", true);
              else if (rolesName[i].roleName === "Upload and Assignment")
                sessionStorage.setItem("is_UploadandAssignment", true);
              else if (rolesName[i].roleName === "Queue Management")
                sessionStorage.setItem("is_QueueManagement", true);
              else if (rolesName[i].roleName === "Bucket Management")
                sessionStorage.setItem("is_BucketManagement", true);
              else if (rolesName[i].roleName === "Real Time Dashboard")
                sessionStorage.setItem("is_RealTimeDashboard", true);
              else if (rolesName[i].roleName === "SubprocessView")
                sessionStorage.setItem("is_SubprocessView", true);
            }
            // sessionStorage.setItem("ApplicationURL", window.location.origin);
            sessionStorage.setItem("is_Authenticated", true);
            sessionStorage.setItem("authorizationToken", result.data[0].token);

          //  console.log("before refresh token timer");

            let timerid = setupRefreshTokenTimer();
            clearTimeout(timerid);
            sessionStorage.setItem("UserName", username);
            sessionStorage.setItem("UserFullName", result.data[0].fullName);
            sessionStorage.setItem("UserEmail", result.data[0].email);
            sessionStorage.setItem("GUID", result.data[0].guid);
            sessionStorage.setItem("PersonID", result.data[0].personID);
            sessionStorage.setItem("PersonType", result.data[0].personType);
            sessionStorage.setItem(
              "HRISEmployeeID",
              result.data[0].hrisEmployeeID
            );
            sessionStorage.setItem(
              "EmployeeVersionID",
              result.data[0].employeeVersionID
            );
            UpdateMemberShipLogIn_SSO();
            navigate("/Home");
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  }

  const UpdateMemberShipLogIn_SSO = (e) =>{
    var applicationUrl = sessionStorage.getItem("ApplicationURL");
    var applicationName = "";
    if(!applicationUrl.includes(REACT_APP_CLIENTURL))
    {
      applicationName = "ClientFeedback"
    }
    else applicationName = "ProductionDashboard";
    var userName = "";
    if(sessionStorage.getItem("PersonType").toString() === "Client")
    {
      userName = sessionStorage.getItem("UserName");
    }
    else userName = sessionStorage.getItem("HRISEmployeeID");
    
    let body = {
      userName: userName,
      applicationname: applicationName,
    };
    let loginUrl = getAPIURL(REACT_APP_UPDATEMEMBERSHIPLOGININFO);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          console.log("Updating Membership Info");
          
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          //setReason(false);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          console.log("Updating Membership Info failed");
          //navigate("/ErrorPage");
        }
      });

  }

  async function handleLoginPopup() {
    // debugger;
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    await instance
      .acquireTokenPopup({
        ...loginRequest,
        redirectUri: "/SSOLogin",
      })
      .then((res) => {
        // debugger;
        //this will give you the token
        const activeAccount = instance.getActiveAccount();
        //console.log(res.accessToken);
        LoginHandle(activeAccount.username, res.accessToken);
      })
      .catch((error) => console.log(error));
    // debugger;
  }

  useEffect(() => {
    handleLoginPopup();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        // <h4>Redirecting to CWS...Please wait</h4>
        <h4></h4>
      )}
    </>
  );
}

export default SSOLogin;
