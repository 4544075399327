import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { Paper } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

function SubProcessRandomReviewConfig() {
    const { addToast } = useToasts();

    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
    const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;

    const { REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORAUTOASSIGNMENT } = process.env;
    const { REACT_APP_DeleteRandomReviewerWorkItemField } = process.env;
    const { REACT_APP_MoveOrderRandomReviewWorkItemField } = process.env;
    const { REACT_APP_AddRandomReviewWorkItemFieldList } = process.env;
    const { REACT_APP_GetRandomReviewWorkItemFieldDetails } = process.env;

    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const userRoles = processingDashboardParam.userRoles;
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }

    const testRef = useRef(null);

    const [companyDeparmentID, setcompanyDeparmentID] = useState("");
    const [companyDepartment, setcompanyDepartment] = useState("");
    const [locationClientID, setlocationClientID] = useState("");
    const [locationClient, setlocationClient] = useState("");
    const [processSubProcessID, setprocessSubProcessID] = useState("");
    const [processSubProcess, setprocessSubProcess] = useState("");
    const [clientLocations, setClientLocations] = useState([]);
    const [processSubprocess, setprocessSubprocess] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [departments, setDepartments] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [SelectedClientLocation, setSelectedClientLocation] = useState("");

    const [getWorkItemFields, setgetWorkItemFields] = useState([]);
    const [dataType, setDataType] = useState("");
    const [fieldLabelText, setFieldLabelText] = useState("");
    const [workitemfieldid, setworkitemfieldid] = useState("");

    const [randomReviewWorkitemList, setRandomReviewWorkitemList] = useState([]);

    const [fieldValue, setFieldValue] = useState("");
    const [reviewPercentage, setReviewPercentage] = useState("");
    const [defaultPercentage, setDefaultPercentage] = useState("0");
    const [prevDefaultPercentage, setPrevDefaultPercentage] = useState("0");

    const [buttonText, setButtonText] = useState("Add");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };

    const FetchDepartmentbyGUID = () => {
        let body = {
            memberShipGuid: sessionStorage.getItem("GUID"),
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setDepartments(result.data);
                    // if (result.data.length > 1) {
                    //   setUserButtonShow(true);
                    // }
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                //console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };

    const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: departmentId,
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                //console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };

    const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: clientId,
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                    GetWorkItemFieldsbySubProcessIDForAutoAssignment();
                    GetRandomReviewWorkItemFieldList();
                }
            })
            .catch((error) => {
                //console.log("ERROR: ", error);
            });
    };

    const FetchSubprocessName = (subprocessId, clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: subprocessId,
            clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    // sessionStorage.setItem(
                    //   "SelctedSubprocessName",
                    //   result.data[0].processSubProcess
                    // );
                    //SubProcessSubmitForTimer();
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //console.log("ERROR: ", error);
            });
    };

    const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
    ) => {
        setcompanyDeparmentID(companyDeparmentID);
        setSelectedDepartment(companyDeparmentID);
        //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        setSelectedLocation(locationClientID);
        //sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
        FetchSubprocessName(processSubProcessID, locationClientID);
    };

    const FetchLocationClientsByGuid = (e) => {
        setcompanyDeparmentID("");
        setcompanyDepartment("");
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        clearDetails();
        if (e.target.value == "Select") {
            setSelectedDepartment(e.target.value);
            setSelectedLocation(e.target.value);
            setClientLocations([]);
            setprocessSubProcessID("");

            setgetWorkItemFields([]);
            setDataType("");
            setFieldLabelText("");
            setworkitemfieldid("");
            setRandomReviewWorkitemList([]);
            setDefaultPercentage("0");
            setPrevDefaultPercentage("0");
        }
        setClientLocations([]);
        setSelectedClientLocation("");
        setprocessSubprocess([]);
        setSelectedDepartment(e.target.value);
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: e.target.value.split("/")[1],
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                //console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });

    };

    const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        clearDetails();
        if (e.target.value == "Select") {
            setSelectedLocation(e.target.value);
            setprocessSubprocess([]);
            setgetWorkItemFields([]);
            setDataType("");
            setFieldLabelText("");
            setworkitemfieldid("");
            setRandomReviewWorkitemList([]);
            setDefaultPercentage("0");
            setPrevDefaultPercentage("0");
        }
        setprocessSubprocess([]);
        setSelectedLocation(e.target.value);
        setSelectedClientLocation(e.target.value.split("/")[1]);
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: e.target.value.split("/")[1],
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                    setprocessSubprocess([]);
                } else {
                    setprocessSubprocess([]);
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                //console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };

    useEffect(() => {
        FetchDefaultValueForSelectedSubprocess(
            sessionStorage.getItem("selectedDepartment"),
            sessionStorage.getItem("selectedLocation"),
            sessionStorage.getItem("ProcessId")
        );
        setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
        FetchDepartmentbyGUID();
    }, []);

    const handleProcessSubprocessChange = (e) => {
        //debugger;
        clearDetails();
        if (e.target.value == "Select") {
            setgetWorkItemFields([]);
            setDataType("");
            setFieldLabelText("");
            setworkitemfieldid("");
            setRandomReviewWorkitemList([]);
            setDefaultPercentage("0");
            setPrevDefaultPercentage("0");
        }
        else {
            GetWorkItemFieldsbySubProcessIDForAutoAssignment();
            GetRandomReviewWorkItemFieldList();
        }
    }

    const GetWorkItemFieldsbySubProcessIDForAutoAssignment = () => {
        let body = {
            SubProcessID: testRef.current.value,
        };
        let loginUrl = getAPIURL(
            REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORAUTOASSIGNMENT
        );
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                UserName: sessionStorage.getItem("UserName"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setgetWorkItemFields(result.data);
                    console.log(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //console.log("ERROR: ", error);
                //navigate("/");
            });
    };

    const handleFieldDropdown = (e) => {
        if (e.target.value != "") {
            console.log(e.target.value);
            var fieldVal = e.target.value.split('/');
            console.log(fieldVal[0], fieldVal[1], fieldVal[2]);
            setDataType(fieldVal[0]);
            setFieldLabelText(fieldVal[1]);
            setworkitemfieldid(fieldVal[2]);
            if (fieldVal[0] == "System.DateTime") {
                addToast("Add value in the format MM/DD/YYYY", {
                    appearance: "success",
                    autoDismiss: true,
                });
            }
        }
        else {
            console.log(e.target.value);
            setDataType("");
            setFieldLabelText("");
            setworkitemfieldid("");
        }
    }

    const columns = [
        {
            title: "WorkItem Field",
            field: "labelText",
        },
        {
            title: "Operator",
            field: "operator",
        },
        {
            title: "Value",
            field: "value",
        },
        {
            title: "Review Percentage",
            field: "reviewPercentage",
        },
        {
            title: "Created By",
            field: "name",
        }
    ]

    const GetRandomReviewWorkItemFieldList = async () => {
        let body = {
            subProcessID: testRef.current.value
        };

        let loginUrl = getAPIURL(REACT_APP_GetRandomReviewWorkItemFieldDetails);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    if (result.data.randomReviewWorkitemFieldList.length > 0) {
                        setRandomReviewWorkitemList(result.data?.randomReviewWorkitemFieldList);
                    }
                    else {
                        setRandomReviewWorkitemList([]);
                    }

                    if (result.data.randomReviewDefaultPercent.length > 0) {
                        setDefaultPercentage(result.data?.randomReviewDefaultPercent[0]?.defaultPercentage?.toString());
                        setPrevDefaultPercentage(result.data?.randomReviewDefaultPercent[0]?.defaultPercentage?.toString());
                    }
                    else {
                        setDefaultPercentage("0");
                        setPrevDefaultPercentage("0");
                    }

                    console.log(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                //console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };

    const HandleMoveOrder = (row, order) => {
        let body = {
            randomReviewID: row.subProcessRandomReviewerID.toString(),
            subProcessID: row.subProcessIDtring,
            moveOrder: order,
            orderNo: row.assignmentOrder.toString(),
        };
        let loginUrl = getAPIURL(REACT_APP_MoveOrderRandomReviewWorkItemField);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization:
                    "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    GetRandomReviewWorkItemFieldList();
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //console.log("ERROR: ", error);
                //navigate("/");
            });
    };

    const DeleteRandomReviewWorkItemField = (row) => {
        if (
            window.confirm(
                "Are you sure you want to delete the row?"
            )
        ) {
            let body = {
                subProcessID: row.subProcessIDtring,
                subProcessRandomReviewerID: row.subProcessRandomReviewerID.toString()
            };
            let loginUrl = getAPIURL(REACT_APP_DeleteRandomReviewerWorkItemField);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization:
                        "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });

            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status === 200) {
                        addToast("Row deleted successfully", {
                            appearance: "success",
                            autoDismiss: true,
                        });
                        GetRandomReviewWorkItemFieldList();
                    } else if (result.status === 401) {
                        //setIsresolve(true);
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    //console.log("ERROR: ", error);
                    //navigate("/");
                });
        }
    };

    const handleValueChangeTextbox = (e) => {
        setFieldValue(e.target.value);
    }

    const handleReviewPercentageTextbox = (e) => {
        setReviewPercentage(e.target.value);
    }

    const handleDefaultPercentageTextbox = (e) => {
        setDefaultPercentage(e.target.value);
    }

    const handleAddRandomReview = () => {
        debugger;
        if (selectedDepartment != "Select") {
            if (selectedLocation != "Select") {
                if (testRef.current.value != "Select") {
                    if (defaultPercentage != "") {
                        const regex = /^\d+$/;
                        if (regex.test(defaultPercentage)) {
                            const defaultPercent = parseInt(defaultPercentage, 10);
                            if (defaultPercent >= 0 && defaultPercent <= 100) {
                                handleAddRandomReviewFurtherValidations();
                            }
                            else {
                                addToast("Invalid value! Please add a default percentage between 0 to 100", {
                                    appearance: "error",
                                    autoDismiss: true,
                                });
                            }
                        }
                        else {
                            addToast("Invalid value! Please enter a valid number for default percentage", {
                                appearance: "error",
                                autoDismiss: true,
                            });
                        }
                    }
                    else {
                        addToast("Please add a default percentage between 0 to 100", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                }
                else {
                    addToast("Please select a process-subprocess", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            }
            else {
                addToast("Please select a location-client", {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
        else {
            addToast("Please select a company-department", {
                appearance: "error",
                autoDismiss: true,
            });
        }
    }

    const handleAddRandomReviewFurtherValidations = () => {
        debugger;
        if (workitemfieldid != "") {
            if (reviewPercentage != "" && fieldValue != "") {
                const regex = /^\d+$/;
                if (regex.test(reviewPercentage)) {

                    const reviewPercent = parseInt(reviewPercentage, 10);
                    if (reviewPercent >= 0 && reviewPercent <= 100) {
                        if (dataType == "System.DateTime") {
                            //const inputDate = new Date(fieldValue);
                            const parts = fieldValue.split('/');
                            const month = parseInt(parts[0], 10);
                            const day = parseInt(parts[1], 10);
                            const year = parseInt(parts[2], 10);

                            const inputDate = new Date(year, month - 1, day);
                            if (month < 1 || month > 12 ||
                                day < 1 || day > new Date(year, month, 0).getDate() ||
                                inputDate.getFullYear() !== year ||
                                isNaN(inputDate.getTime())) {

                                addToast("Invalid date format! Please enter MM/DD/YYYY", {
                                    appearance: "error",
                                    autoDismiss: true,
                                });
                            }
                            else {
                                addToRandomReviewWorkitemField();
                            }
                        }
                        else {
                            const alphanumericRegex = /[a-zA-Z0-9]/;
                            const onlySpecialCharsRegex = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
                            if (!alphanumericRegex.test(fieldValue) || onlySpecialCharsRegex.test(fieldValue)) {
                                addToast("Invalid text format of value! Please enter a valid value", {
                                    appearance: "error",
                                    autoDismiss: true,
                                });
                            } else {
                                addToRandomReviewWorkitemField();
                            }
                        }
                    }
                    else {
                        addToast("Invalid value! Please add a review percentage between 0 to 100", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                }
                else {
                    addToast("Invalid value! Please enter a valid number for review percentage", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }

            }
            else {
                addToast("Value and review percentage is required.", {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        }
        else {
            addToRandomReviewWorkitemField();
        }
    }

    const addToRandomReviewWorkitemField = () => {
        debugger;
        setButtonText("Adding...");
        setIsButtonDisabled(true);
        let body;
        if (workitemfieldid != "") {
            body = {
                subProcessID: testRef.current.value,
                workItemFieldID: workitemfieldid,
                randomReviewOperator: "=",
                value: fieldValue,
                reviewPercentage: reviewPercentage,
                defaultPercentage: defaultPercentage,
                updatedBy: sessionStorage.getItem("PersonID"),
                subProcessRandomReviewerID: "0"
            };
        }
        else {
            body = {
                subProcessID: testRef.current.value,
                workItemFieldID: "0",
                randomReviewOperator: "=",
                value: "",
                reviewPercentage: "0",
                defaultPercentage: defaultPercentage,
                updatedBy: sessionStorage.getItem("PersonID"),
                subProcessRandomReviewerID: "0"
            };
        }
        let loginUrl = getAPIURL(REACT_APP_AddRandomReviewWorkItemFieldList);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization:
                    "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });

        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    addToast("Data updated successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    GetRandomReviewWorkItemFieldList();
                    clearDetails();
                    setButtonText("Add");
                    setIsButtonDisabled(false);
                } else if (result.status === 401) {
                    //setIsresolve(true);
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //console.log("ERROR: ", error);
                //navigate("/");
            });
    }

    const clearFields = () => {
        setDataType("");
        setFieldLabelText("");
        setworkitemfieldid("");
        setFieldValue("");
        setReviewPercentage("");
        setDefaultPercentage(prevDefaultPercentage);
    }

    const clearDetails = () => {
        setDataType("");
        setFieldLabelText("");
        setworkitemfieldid("");
        setFieldValue("");
        setReviewPercentage("");
    }

    return (
        <>
            <Header onpreferenceShow={null} pageName="Sub Process Random Review Config" />
            <ApplicationUserContext.Provider value={processingDashboardParam.userRoles}>
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="admin" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>

            <div className="centerSection">
                <div className="pageBody">

                    <div className="dashBoxs mb-3">
                        <div className="formWrap">
                            <div className="form-label label-sub-header">Note: Create default percentage without selecting workitem field.</div>
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Company-Department *</label>
                                        <select
                                            className="form-control form-control-sm"
                                            title="Select a Company-Department"
                                            onChange={FetchLocationClientsByGuid}
                                        >
                                            <option>Select</option>
                                            {departments.map((department) => (
                                                <option
                                                    value={
                                                        department.companyDepartment +
                                                        "/" +
                                                        department.departmentIDString
                                                    }
                                                    selected={
                                                        department.departmentIDString == companyDeparmentID
                                                    }
                                                >
                                                    {department.companyDepartment}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Location-Client *</label>
                                        <select
                                            className="form-control form-control-sm"
                                            title="Select a Location-Client"
                                            onChange={FetchProcessSubProcessByGuid}
                                        >
                                            <option>Select</option>
                                            {clientLocations.map((location) => (
                                                <option
                                                    value={
                                                        location.locationClient +
                                                        "/" +
                                                        location.clientIDString
                                                    }
                                                    selected={location.clientIDString == locationClientID}
                                                >
                                                    {location.locationClient}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Process-SubProcess *</label>
                                        <select
                                            className="form-control form-control-sm"
                                            title="Select a Process-Subprocess"
                                            onChange={
                                                (e) => {
                                                    handleProcessSubprocessChange(e);
                                                }}
                                            ref={testRef}
                                        >
                                            <option>Select</option>
                                            {processSubprocess.map((subprocess) => (
                                                <option
                                                    value={subprocess.subProcessIDString}
                                                    selected={
                                                        subprocess.subProcessIDString == processSubProcessID
                                                    }
                                                >
                                                    {subprocess.processSubProcess}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label"> Fields{" "} </label>
                                        <select
                                            className="form-control form-control-sm"
                                            title="Select a workitem field"
                                            value={`${dataType}/${fieldLabelText}/${workitemfieldid}`}
                                            onChange={(e) => handleFieldDropdown(e)}
                                            defaultValue="Select"
                                        >
                                            <option value="">Select</option>
                                            {getWorkItemFields.map((status) => (
                                                <option
                                                    value={
                                                        status.dataType +
                                                        "/" +
                                                        status.labeltext +
                                                        "/" +
                                                        status.workitemfieldid
                                                    }
                                                >
                                                    {status.labeltext}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label"> Operator </label>
                                        <select
                                            className="form-control form-control-sm"
                                            title="Select an Operator"
                                            value="="
                                            defaultValue="="
                                        >
                                            <option value="=">=</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Value *</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            title="Enter value, using this workitems will be assigned"
                                            value={fieldValue}
                                            onChange={(e) => handleValueChangeTextbox(e)}
                                            disabled={!workitemfieldid}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Review Percentage *</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            title="Enter Review Percentage, using this the workitems will be moved to review"
                                            value={reviewPercentage}
                                            onChange={(e) => handleReviewPercentageTextbox(e)}
                                            disabled={!workitemfieldid}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Default Percentage *</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            title="Enter Default Percentage, using this the workitems will be moved to review"
                                            value={defaultPercentage}
                                            onChange={(e) => handleDefaultPercentageTextbox(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 text-start mt-4">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary ms-2"
                                    title="Click here to Add a rule"
                                    onClick={() => { handleAddRandomReview(); }}
                                    disabled={isButtonDisabled}
                                >
                                    <b className=""></b>{buttonText}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary ms-2"
                                    title="Click here to clear values"
                                    onClick={() => { clearFields(); }}
                                >
                                    <b className=""></b>Clear
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="dashBoxs">
                        <div className="tableWrap">
                            <div className="tableWrap-body">
                                <table className="table table-borderless MatTable">
                                    {/* <ThemeProvider theme={theme}> */}
                                    <MaterialTable
                                        localization={{
                                            pagination: {
                                                //labelDisplayedRows: '{from}-{to} of {count}'
                                            },
                                            toolbar: {
                                                // nRowsSelected: '{0} row(s) selected'
                                                // exportCSVName: "Export as Excel",
                                                // exportPDFName: "Export as pdf",
                                            },
                                            header: {
                                                actions: "",
                                            },
                                        }}
                                        actions={[
                                            {
                                                icon: () => <DeleteIcon />,
                                                tooltip: "Click here to delete the rule",
                                                onClick: (event, rowData) => DeleteRandomReviewWorkItemField(rowData),
                                                position: "row",
                                            },
                                            {
                                                icon: () => <ArrowUpward />,
                                                tooltip: "MoveUp",
                                                onClick: (event, rowData) => HandleMoveOrder(rowData, "MoveUp"),
                                                position: "row",
                                            },
                                            {
                                                icon: () => <ArrowDownward />,
                                                tooltip: "MoveDown",
                                                onClick: (event, rowData) => HandleMoveOrder(rowData, "MoveDown"),
                                                position: "row",
                                            }
                                        ]}
                                        options={{
                                            headerStyle: {
                                                position: "sticky",
                                                top: "-5px",
                                            },
                                            maxBodyHeight: "420px",
                                            //pagination:true,
                                            //paginationType:"normal",
                                            //paginationPosition: "bottom",
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 20, 50, 100],
                                            exportButton: false,
                                            sorting: true,
                                            filtering: true,
                                            search: false,
                                            selection: false,
                                            //paging: false,

                                            overflowY: false,
                                            showTitle: false,
                                            toolbarButtonAlignment: "left",
                                            headerStyle: {
                                                // height: 30,
                                            },
                                            rowStyle: {
                                                fontFamily: '"Yantramanav", sans-serif',
                                                //                            fontSize: "13px",
                                                // whiteSpace: "nowrap",
                                            },
                                            headerStyle: {
                                                // fontFamily: '"Yantramanav", sans-serif',
                                                // fontWeight: "inherit",
                                                // fontSize: "14px",
                                                // textAlign: "left !Important",
                                            },
                                        }}

                                        columns={columns}
                                        icons={tableIcons}
                                        data={randomReviewWorkitemList}
                                        components={{
                                            Container: (props) => (
                                                <Paper {...props} elevation={0} />
                                            ),
                                            Toolbar: (props) => (
                                                <div className="materialTableComponent"
                                                >
                                                    <MTableToolbar
                                                        {...props}
                                                        className="mToolbarFontsize"
                                                    />
                                                </div>
                                            ),
                                        }}
                                    />
                                    {/* </ThemeProvider> */}
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    );

};

export default SubProcessRandomReviewConfig;