import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";

import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import tableIcons from "../../../../MaterialTableIcons";
//import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
//import LoadingSpinner from "../../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
//import Iframe from "react-iframe";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";

//debugger;
const EmailDomain = () => {
  //const location = useLocation();
  //const processingDashboardParam = location.state;
  const StatusNameRef = useRef("");
  const StatusNameRefpop = useRef("");
  const WorkItemStatusIDRef = useRef(0);
  const WorkItemStatusIDRefpop = useRef(0);
  const displayOrderRef = useRef("");
  const displayOrderRefpop = useRef("");
  const RemarksRef = useRef("");
  const RemarksRefpop = useRef("");
  const isDeletedRef = useRef("");
  const isDeletedRefpop = useRef("");
  const EmailDomainIDRef = useRef(0);
  const DomainNameRef = useRef("");
  const EditFlagRef = useRef("");
  const isEditableRef = useRef("");
  const EmailDomainIDRefpop = useRef(0);
  const DomainNameRefpop = useRef("");
  const EditFlagRefpop = useRef("");
  const isEditableRefpop = useRef("");
  const ButtonTextRef = useRef("");

  const { addToast } = useToasts();

  const { REACT_APP_API_URL } = process.env;

  const { REACT_APP_SAVETIMEONTICK } = process.env;
  // const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_GETEMAILDOMAIN } = process.env;
  const { REACT_APP_UPDATEEMAILDOMAIN } = process.env;
  const { REACT_APP_DELETEEMAILDOMAIN } = process.env;
  const { REACT_APP_ADDEMAILDOMAIN } = process.env;

  //const { REACT_APP_GETSTATUSBYID } = process.env;
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  //const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [searchWorkitemData, setsearchWorkitemData] = useState([]);
  const [Datacount, setDatacount] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const { validate, setvalidate } = useState("");

  const [displayOrderArry, setdisplayOrderArry] = useState([]);

  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [buttonText, setbuttonText] = useState("Save");
  const [openPopUp, setopenPopUp] = useState(false);
  const [openAddPopUp, setopenAddPopUp] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [data, setdata] = useState({});

  const Save = () => {
    debugger;
    seterrorMessage("");
    let index = DomainNameRef.current.value.indexOf(".");
    let err = {};
    let isSubmit = true;
    if (
      DomainNameRef.current.value.length == 0 &&
      DomainNameRef.current.value == ""
    ) {
      err.name = "Domain Name is Required.";
      isSubmit = false;
    }
    if (index <= -1) {
      err.name = "Invalid Email Domain Name.";
      isSubmit = false;
    }
    seterrorMessage({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;
      /* if (buttonText == "Save") */
      /* { */
      // setopenPopUp(true);
      loginUrl = getAPIURL(REACT_APP_ADDEMAILDOMAIN);
      body = {
        DomainName: DomainNameRef.current.value,
        IsDeleted: isDeletedRef.current.checked,
        IsEditable: isEditableRef.current.checked,
        EmailDomainID: 0,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenPopUp(false);
            debugger;
            addToast("Email Domain saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            //resetForm();
            GetEmailDomain();
            seterrorMessage("");
            //setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.name = "Record Already Exists";
            seterrorMessage({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };
  const popupClose = () => {
    setopenPopUp(false);
    seterrorMessage("");
  };
  const AddpopupClose = () => {
    setopenAddPopUp(false);
    seterrorMessage("");
  };
  const resetForm = () => {
    DomainNameRef.current.value = "";
    isDeletedRef.current.checked = false;
    isEditableRef.current.checked = false;
    seterrorMessage("");
  };

  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const updateColumns = keys => {
    console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach(item => {
      if (item != "updatedBy") col.push({ title: item, field: item });
    });
  };
  //debugger;
  const pop = () => {
    setopenPopUp(true);
    seterrorMessage("");
  };

  const Addpop = item => {
    debugger;
    if (item.isEditable) {
      setopenAddPopUp(true);
      seterrorMessage("");
      setdata(item);
    } else {
      addToast("Non Editable Field", {
        appearance: "warning",
        autoDismiss: true
      });
    }
  };
  useEffect(() => {
    debugger;
    if (Object.keys(data).length > 0) {
      DomainNameRefpop.current.value = data.domainName;
      isDeletedRefpop.current.checked = data.isDeleted;
      isEditableRefpop.current.checked = data.isEditable;

      EmailDomainIDRefpop.current = data.emailDomainID;
    }
  }, [openAddPopUp]);

  const resetUpdate = () => {
    DomainNameRefpop.current.value = "";
    isEditableRefpop.current.checked = false;
    isDeletedRefpop.current.checked = false;
    seterrorMessage("");
  };

  const AddEmailDomain = () => {
    debugger;
    seterrorMessage("");

    let index = DomainNameRef.current.value.indexOf(".");

    let isSubmit = true;
    let err = {};
    if (
      DomainNameRef.current.value.length == 0 &&
      DomainNameRef.current.value == ""
    ) {
      err.name = "Domain Name is Required.";
      isSubmit = false;
    }
    if (index <= -1) {
      err.name = "Invalid Email Domain Name.";
      isSubmit = false;
    }

    seterrorMessage({ ...err });
    if (isSubmit) {
      let loginUrl;
      let body;
      /* if (buttonText == "Save") */
      /* { */

      loginUrl = getAPIURL(REACT_APP_ADDEMAILDOMAIN);
      body = {
        DomainName: DomainNameRef.current.value,
        IsDeleted: isDeletedRef.current.checked,
        IsEditable: isEditableRef.current.checked,
        EmailDomainID: 0,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenPopUp(true);
            debugger;
            addToast("Email Domain saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            resetForm();
            seterrorMessage("");
            GetEmailDomain();
            //setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.name = "Record Already Exists";
            seterrorMessage({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };

  const UpdateEmailDomain = () => {
    debugger;
    let index = DomainNameRefpop.current.value.indexOf(".");
    let isSubmit = true;
    let err = {};
    if (
      DomainNameRefpop.current.value.length == 0 &&
      DomainNameRefpop.current.value == ""
    ) {
      err.name = "Domain Name is Required.";
      isSubmit = false;
    }
    if (index <= -1) {
      err.name = "Invalid Email Domain Name.";
      isSubmit = false;
    }

    seterrorMessage({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;

      loginUrl = getAPIURL(REACT_APP_UPDATEEMAILDOMAIN);
      body = {
        DomainName: DomainNameRefpop.current.value,
        IsDeleted: isDeletedRefpop.current.checked,
        IsEditable: isEditableRefpop.current.checked,
        EditFlag: EditFlagRefpop.current.checked,
        EmailDomainID: EmailDomainIDRefpop.current,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            debugger;
            setopenAddPopUp(false);
            addToast("Email Domain Updated successfully", {
              appearance: "success",
              autoDismiss: true
            });
            // resetForm();

            GetEmailDomain();

            //setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.name = "Record Already Exists";
            seterrorMessage({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
    //setopenAddPopUp(false);
  };

  const columns = [
    {
      title: "Domain Name",
      field: "domainName"
    },

    {
      title: "Is Deleted",
      field: "isDeleted",

      render: isDeleted => (
        <>
          <input
            type="checkbox"
            id="chkIsDeleted"
            disabled
            checked={isDeleted["isDeleted"]}
          />
        </>
      )
    },
    {
      title: "Is Editable",
      field: "isEditable",

      render: isEditable => (
        <>
          <input
            type="checkbox"
            id="chkIsDeleted"
            disabled
            checked={isEditable["isEditable"]}
          />
        </>
      )
    }
  ];

  const DeleteEmailDomain = row => {
    debugger;
    if (row.isEditable) {
      if (row.isDeleted) {
        addToast("Email Domain - " + row.StatusName + " is already deleted.", {
          appearance: "success",
          autoDismiss: true
        });
        return false;
      }

      if (
        window.confirm(
          "Are you sure you want to delete the Email Domain - " +
            row.domainName +
            " ?"
        )
      ) {
        debugger;
        let body = {
          EmailDomainID: row.emailDomainID,
          UpdatedBy: sessionStorage.PersonID
        };
        let loginUrl = getAPIURL(REACT_APP_DELETEEMAILDOMAIN);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken")
          },
          body: JSON.stringify(body)
        });
        fetch(req)
          .then(res => res.json())
          .then(result => {
            if (result.status === 200) {
              debugger;
              addToast("Email Domain deleted successfully", {
                appearance: "success",
                autoDismiss: true
              });
              GetEmailDomain();
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch(error => {
            console.log("ERROR: ", error);
            //navigate("/");
          });
      }
    } else {
      addToast("Non Editable Field", {
        appearance: "warning",
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    // debugger;
    for (let index = 1; index <= 30; index++) {
      setdisplayOrderArry(oldArray => [...oldArray, index]);
    }
    GetEmailDomain();

    //editWorkItemStatus(item);
    setsearchDivVisible(true);
    setisLoadingFigma(false);
    //ButtonTextRef.current.value="Save";
    //setbuttonText("Save");
  }, []);

  const GetEmailDomain = () => {
    debugger;
    let EmailDomainURL = getAPIURL(REACT_APP_GETEMAILDOMAIN);
    let req1 = new Request(`${EmailDomainURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      }
      // body: JSON.stringify(body),
    });
    fetch(req1)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setisLoadingFigma(false);
          updateColumns(Object.keys(result.data[0]));
          setsearchWorkitemData(result.data);
          setDatacount(result.data.length);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName="Eamil Domain" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important"
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important"
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important"
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important"
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important"
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important"
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important"
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important"
          }
        }}
      />

      <div className="col-12">
        <div className="pageBody pd-top-0">
          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header pb-3 pt-2">
                <div class="row  align-items-center">
                  <div class="col">
                    <h6 /* className="pageName" */>MANAGE EMAIL DOMAINS</h6>
                  </div>
                  {/* <div class="col"></div> */}
                  <div class="col text-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-3"
                      //style = "float: right;"
                      //color = "#f194ff"
                      onClick={() => {
                        pop();
                      }}
                    >
                      + Add New Email Domain
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="dashBoxs-header">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                      <div className="d-inline">
                        <div className="input-group input-group-sm"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mt-2"></div>
                </div>
              </div> */}
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "blue",
                      marginLeft: "1rem"
                    }}
                  >
                    {Datacount} out of {Datacount} records displayed
                  </span>
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        title="List of Email Domains"
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'

                            exportCSVName: "Export as Excel",
                            exportPDFName: "Export as pdf"
                          },
                          header: {
                            actions: ""
                          }
                        }}
                        actions={[
                          {
                            icon: () => <EditIcon />,
                            tooltip: "Update Already Completed",
                            onClick: (event, rowData) =>
                              //editWorkItemStatus(rowData),
                              Addpop(rowData),
                            position: "row"
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete Already Completed",
                            onClick: (event, rowData) =>
                              DeleteEmailDomain(rowData),
                            position: "row"
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px"
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100, 200],
                          paging: false,
                          exportButton: true,
                          sorting: true,
                          filtering: false,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px"
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important"
                          }
                        }}
                        columns={columns}
                        icons={tableIcons}
                        data={searchWorkitemData}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px"
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <Footer />

      {/* Add Modal */}

      <Modal
        show={openPopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Email Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageBody pd-top-0">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="fieldSet">
                  {/*  <h4 className="mb-4">Add/Edit WorkItem Status</h4> */}

                  <div className="row align-items-end">
                    <div>
                      <span
                        style={{
                          color: "red"
                        }}
                      >
                        {errorMessage.name}
                      </span>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Domain Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            {""}*
                          </span>
                          <h12 style={{ fontWeight: "normal" }}>
                            (Example: exlservice.com)
                          </h12>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          type="text"
                          //value={errorCategoryName}
                          ref={DomainNameRef}
                          title={"Enter Email Domain Name"}
                          maxLength={50}
                          //onChange={getWorkitemId}
                        />
                        {/* <span >{errorMessage.name}</span> */}
                      </div>
                    </div>

                    <div className="col-1" />

                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Is Deleted
                        </label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          ref={isDeletedRef}
                          title={
                            "Check to flag this entry as deleted.Deleted fields are only removed from the scope but not actually deleted"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          style={{ fontWeight: "bold" }}
                        >
                          Is Editable
                        </label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          ref={isEditableRef}
                          title="Check to enable editing of the entry in future"
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <hr className="mb-4" /> */}
                  {/* <hr className="mb-4" />
                <div className="fieldSet"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2"></div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={
                            "Click here to save Email Domain and return to Email Domain list page"
                          }
                          onClick={() => {
                            Save();
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={
                            "Click here to save more than one Email Domain"
                          }
                          onClick={() => {
                            AddEmailDomain();
                          }}
                        >
                          Save and Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {searchDivVisible ? (
              <div className="dashBoxs">
                {isLoadingFigma ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {},

                            header: {
                              actions: ""
                            }
                          }}
                          actions={[]}
                          title="Existing Entries "
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px"
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100, 200],
                            paging: false,
                            exportButton: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: true,
                            toolbarButtonAlignment: "right",
                            headerStyle: {
                              height: 30
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px"
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important"
                            }
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={searchWorkitemData}
                          components={{
                            Container: props => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: props => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em"
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px"
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* Add Modal End */}

      {/* Edit Modal */}

      <Modal
        show={openAddPopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={AddpopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Email Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageBody pd-top-0">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="fieldSet">
                  {/*  <h4 className="mb-4">Add/Edit WorkItem Status</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {errorMessage.name}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Domain Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            {""}*
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          type="text"
                          /* value={workStatus}  
                          onChange={onChangeStatus} */
                          ref={DomainNameRefpop}
                          title={"Enter Email Domain Name"}
                          maxLength={50}
                          //onChange={getWorkitemId}
                        />
                      </div>
                    </div>

                    <div className="col-1" />

                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-1">
                        <label className="form-label">Is Deleted</label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          role="switch" /* defaultChecked={isDeleted}  onChange={onChangeisDeleted} */
                          /* value={isDeleted} */ ref={isDeletedRefpop}
                          title={
                            "Check to flag this entry as deleted.Deleted fields are only removed from the scope but not actually deleted"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-1">
                        <label className="form-label">Is Editable</label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          role="switch" /* defaultChecked={isDeleted}  onChange={onChangeisDeleted} */
                          /* value={isDeleted} */ ref={isEditableRefpop}
                          title="Check to enable editing of the entry in future"
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <hr className="mb-4" /> */}
                  {/* <hr className="mb-4" />
                <div className="fieldSet"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2"></div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetUpdate}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={"Click here to update Email Domain"}
                          onClick={() => {
                            UpdateEmailDomain();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {searchDivVisible ? (
              <div className="dashBoxs">
                {isLoadingFigma ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {},

                            header: {
                              actions: ""
                            }
                          }}
                          actions={[]}
                          title="Existing Entries"
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px"
                            },
                            maxBodyHeight: "420px",
                            pagination: true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100, 200],
                            paging: false,
                            exportButton: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: true,
                            toolbarButtonAlignment: "right",
                            headerStyle: {
                              height: 30
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px"
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important"
                            }
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={searchWorkitemData}
                          components={{
                            Container: props => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: props => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em"
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px"
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal */}
    </>
  );
};

export default React.memo(EmailDomain);
