import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import CryptoJS from "crypto-js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
//import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-table-filter/lib/styles.css";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import GlobalStyles from "@mui/material/GlobalStyles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FcSearch } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import WorkActivityScreenDetailsPage from "../WorkActivityScreen/WorkActivityScreenDetailsPage";
import FormContext from "../../../../contexts/FormContext";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import { debuggerStatement } from "@babel/types";
import Alert from "@material-ui/lab/Alert";

const SubprocessConfig = (props) => {
  const { addToast } = useToasts();

  const { REACT_APP_API_URL } = process.env;

  const { REACT_APP_FETCHSUBPROCESSCONFIG } = process.env;

  const { REACT_APP_FETCHUBPROCESSCONFIGURATIONDROPDOWN } = process.env;
  const { REACT_APP_GETGLOBALACTIVITY } = process.env;
  //const { REACT_APP_GETWORKITEMTYPE } = process.env;
  const { REACT_APP_CREATESUBPROCESSCONFIG } = process.env;
  const { REACT_APP_UPDATESUBPROCESSCONFIG } = process.env;
  const { REACT_APP_FETCHONBASEDOCUMENTTYPEBYSCANNO } = process.env;
  const { REACT_APP_FETCHONBASESCANQUENE } = process.env;
  const { REACT_APP_FETCHONBASEDOCUMENTTYPEONSCANQUEUECHANGE } = process.env;
  const { REACT_APP_FETCHSCANQUEUEONCLICK } = process.env;

  const [globalActivityData, setglobalActivityData] = useState([]);

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  //Jinnat
  const [SLAStartdatefield, setSLAStartdatefield] = useState([]);
  const [workitemType, setworkitemType] = useState([]);
  const [MultiPlierField, setMultiPlierField] = useState([]);
  const [AutoIncrementField, setAutoIncrementField] = useState([]);
  const [DocumentId, setDocumentId] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [scanQueues, setscanQueues] = useState([]);
  const [DocumentTypeonscan, setDocumentTypeonscan] = useState([]);
  const [DocumentTypescan, setDocumentTypescan] = useState([]);
  const [isLoading, set_IsLoading] = useState(false);
  //useState
  const [Allocationunit, setAllocationunit] = useState("");
  const [NoOfDaysToHoldWithClient, setNoOfDaysToHoldWithClient] = useState("");
  const [DefectOpportunitiesPerWorkitem, setDefectOpportunitiesPerWorkitem] =
    useState("");
  const [CustomerFeedbackScoreTarget, setCustomerFeedbackScoreTarget] =
    useState("");
  const [MultiplierField, setMultiplierField] = useState("");
  const [ProductiveUtilizationTarget, setProductiveUtilizationTarget] =
    useState("");
  const [EfficiencyTarget, setEfficiencyTarget] = useState("");

  const [AvailabilityTarget, setAvailabilityTarget] = useState("");
  const [TAT, setTAT] = useState("");
  const [SLAStartDateField, setSLAStartDateField] = useState("");
  const [CutOffTimeforSLAStartDate, setCutOffTimeforSLAStartDate] =
    useState("");
  const [SLAOnTimeTarget, setSLAOnTimeTarget] = useState("");
  const [SLAAccuracyTarget, setSLAAccuracyTarget] = useState("");
  const [AutoIncrementFieldvalue, setAutoIncrementFieldvalue] = useState("");
  const [SubProcessOwner, setSubProcessOwner] = useState("");
  const [RAGIndicatorCriteria, setRAGIndicatorCriteria] = useState("");
  const [AgeGreater, setAgeGreater] = useState("");
  const [AgeLess, setAgeLess] = useState("");
  const [AgeEqual, setAgeEqual] = useState("");
  const [ScanQueue, setScanQueue] = useState("");
  const [WorkspaceID, setWorkspaceID] = useState("");
  const [DashboardID, setDashboardID] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [DocumentIdField, setDocumentIdField] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [onBaseConnect, setonBaseConnect] = useState("");
  const [Workitemduewithinnext, setWorkitemduewithinnext] = useState("");
  const [Workitemdueafter, setWorkitemdueafter] = useState("");
  const [ScanNo, setScanNo] = useState(0);
  const [ScanQue, setScanQue] = useState("");
  //usestate End

  //Handle Onchange
  const handleAllocationunit = (e) => {
    //debugger;
    seterrorMessage("");
    setAllocationunit(e.target.value);
  };

  const NoOfDaysToHoldWithClientHandle = (e) => {
    //debugger;
    setNoOfDaysToHoldWithClient(e.target.value);
  };
  const CustomerFeedbackScoreTargetHandle = (e) => {
    //debugger;
    setCustomerFeedbackScoreTarget(e.target.value);
  };
  const DefectOpportunitiesPerWorkitemHandle = (e) => {
    //debugger;
    setDefectOpportunitiesPerWorkitem(e.target.value);
  };
  const MultiplierFieldHandle = (e) => {
    //debugger;
    setMultiplierField(e.target.value);
  };
  const ProductiveUtilizationTargetHandle = (e) => {
    //debugger;
    setProductiveUtilizationTarget(e.target.value);
  };
  const EfficiencyTargetHandle = (e) => {
    //debugger;
    setEfficiencyTarget(e.target.value);
  };
  const AvailabilityTargetHandle = (e) => {
    //debugger;
    setAvailabilityTarget(e.target.value);
  };
  const TATHandle = (e) => {
    //debugger;
    seterrorMessage("");
    setTAT(e.target.value);
  };
  const CutOffTimeforSLAStartDateHandle = (e) => {
    debugger;
    // if (e.target.value.length == 0) {
    //   setCutOffTimeforSLAStartDate(e.target.value);
    // } else if (e.target.value.length == 1) {
    //   if (/^(0[0-9]|1[0-9]|2[0-9]):[0-5][0-9]$/.test(e.target.value)) {
    //     setCutOffTimeforSLAStartDate(e.target.value);
    //   }
    // } else if (e.target.value.length == 2) {
    if (/^([0-9])|(:)$/.test(e.target.value) || e.target.value.length == 0) {
      setCutOffTimeforSLAStartDate(e.target.value);
    }
    // } else if (e.target.value.length == 3) {
    //   if (/^(:)$/.test(e.target.value)) {
    //     setCutOffTimeforSLAStartDate(e.target.value);
    //   }
    // } else if (e.target.value.length == 4) {
    //   if (/^([0-9])$/.test(e.target.value)) {
    //     setCutOffTimeforSLAStartDate(e.target.value);
    //   }
    // } else if (e.target.value.length == 5) {
    //   if (/^([0-9])$/.test(e.target.value)) {
    //     setCutOffTimeforSLAStartDate(e.target.value);
    //   }
    // }
    // if (/^([0-9])|(:)|()$/.test(e.target.value)) {
    //setCutOffTimeforSLAStartDate(e.target.value);
    // }
    //debugger;
    //setCutOffTimeforSLAStartDate(e.target.value);
  };
  const SLAOnTimeTargetHandle = (e) => {
    //debugger;
    setSLAOnTimeTarget(e.target.value);
  };
  const SLAAccuracyTargetHandle = (e) => {
    //debugger;
    setSLAAccuracyTarget(e.target.value);
  };

  const SubProcessOwnerHandle = (e) => {
    debugger;
    if (
      !isNaN(e.target.value) ||
      e.target.value.length == 0 ||
      e.target.value.includes("~")
    ) {
      if (e.target.value.length == 0) {
        setSubProcessOwner(e.target.value);
      } else {
        setSubProcessOwner(parseInt(e.target.value).toString());
      }
    }

    //}
  };
  const AgeLessHandle = (e) => {
    //debugger;

    setAgeEqual(e.target.value);
    setAgeLess(e.target.value);
  };
  const AgeEqualHandle = (e) => {
    //debugger;
    setAgeEqual(e.target.value);
  };
  const AgeGreaterHandle = (e) => {
    //debugger;
    setAgeGreater(e.target.value);
  };
  const WorkspaceIDHandle = (e) => {
    seterrorMessage("");
    //debugger;
    setWorkspaceID(e.target.value);
  };
  const ReportIDHandle = (e) => {
    seterrorMessage("");
    //debugger;
  };
  const ReportTilteIDHandle = (e) => {
    seterrorMessage("");
  };
  const DashboardIDHandle = (e) => {
    //debugger;
    setDashboardID(e.target.value);
  };
  const RemarksHandle = (e) => {
    //debugger;
    setRemarks(e.target.value);
  };
  const DocumentIdFieldHandle = (e) => {
    //debugger;
    setDocumentIdField(e.target.value);
  };

  //DocumentIdField

  const ScanQueueHandle = (e) => {
    debugger;
    setselectedDocumentType([]);
    setDocumentTypeonscan([]);
    seterrorMessage("");
    let scanId = e.target.value;
    setScanNo(parseInt(e.target.value));
    setScanQue(e.target.selectedOptions[0].label);
    setScanQueue(e.target.value);

    //setrAG("0");
    debugger;
    if (scanId != "0") {
      let body = {
        subprocessID: props.reference.SubprocessID,
        scanId: scanId,
        UserId: OnBaseUserNameRef.current.value,
        pass: OnBasePRef.current.value
      };

      let loginUrl = getAPIURL(
        REACT_APP_FETCHONBASEDOCUMENTTYPEONSCANQUEUECHANGE
      );
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            //debugger;
            if (result.data.documentTypeValue != null) {
              setDocumentTypeonscan(result.data.documentTypeValue);
              addToast("Document Type fetch successfully", {
                appearance: "success",
                autoDismiss: true
              });
            }
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //navigate("/");
          } else {
            addToast("Unable to Fetch DocumentType", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };
  const WorkitemduewithinnextHandle = (e) => {
    //debugger;
    setWorkitemdueafter(e.target.value);
    setWorkitemduewithinnext(e.target.value);
  };
  const WorkitemdueafterHandle = (e) => {
    //debugger;
    setWorkitemdueafter(e.target.value);
  };
  //End Handle Onchange
  const reportIDRef = useRef("");
  const reportTitleIdRef = useRef("");

  const IsDelete2Ref = useRef(false);
  const enableonbaseRef = useRef(false);
  const DocumentIdFieldRef = useRef("");
  const OnBaseUserNameRef = useRef("");
  const OnBasePRef = useRef("");
  const CopyWorkitemTypeInActivityScreenRef = useRef(false);
  const UseShortcutkeysInActivityScreenRef = useRef(false);
  const HideSLArelatedfieldsfromSearchresultRef = useRef(false);
  const IncludeWeekendinSLACalculationRef = useRef(false);
  const IncludeHolidaysinSLACalculationRef = useRef(false);
  const ExcludeCompletedItemsinDuplicateCheckRef = useRef(false);
  const AppendaudittrialOnBaseDocumentRef = useRef(false);
  const CreateWorkitemAutomaticallyRef = useRef(false);
  const EnableDocumentUploadtoonBaseRef = useRef(false);
  //First

  const HandleSaveandUpdate = () => {
    debugger;
    if (buttonSave) {
      SaveSubprocessConfig();
    } else {
      UpdateSubprocessConfig();
    }
  };
  const GetDropdownValue = () => {
    //debugger;

    let body = {
      subprocessID: props.reference.SubprocessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHUBPROCESSCONFIGURATIONDROPDOWN);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          //debugger;
          setSLAStartdatefield(result.data.slaStartdatefield);
          setMultiPlierField(result.data.multiPlierField);
          setAutoIncrementField(result.data.autoIncrementField);
          setDocumentId(result.data.documentIdField);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",

            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          addToast("Fetching DropDown Value Failed", {
            appearance: "error",

            autoDismiss: true,
          });
        }
      })

      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  //Second

  const columnsreport = [
    {
      title: "PBIReportID",
      field: "pbiReportID",
    },
    {
      title: "PBIReportTitle",
      field: "pbiReportTitle",
    },
  ];
  const GetGlobalActivity = () => {
    //debugger;
    //setrAG("0");
    let WorkItemStatusURL = getAPIURL(REACT_APP_GETGLOBALACTIVITY);
    let req1 = new Request(`${WorkItemStatusURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      // body: JSON.stringify(body),
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // setisLoadingFigma(false);
          // updateColumns(Object.keys(result.data[0]));
          setglobalActivityData(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  //onbase
  const GetOnbaseDocumentType = () => {
    debugger;
    set_IsLoading(true);
    setonBaseConnect("");
    let body = {
      subprocessID: props.reference.SubprocessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHONBASEDOCUMENTTYPEBYSCANNO);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          debugger;
          //setDocumentTypeonscan(result.data.documentTypeValue);
          if (
            result.data.documentTypeValue != null ||
            result.data.documentTypeValue != undefined
          ) {
            // if (selectedDocumentType.length != 0) {
            //   const difference = result.data.documentTypeValue.filter(
            //     item =>
            //       !selectedDocumentType.some(
            //         selectedDocumentType => selectedDocumentType.id === item.id
            //       )
            //   );
            //   //setDocumentTypeonscan(difference);
            // } else {
            // if (
            //   DocumentTypeRef.current.value != "" ||
            //   DocumentTypeRef.current.value != 0
            // ) {
            //   //debugger;
            //   let finalSelectedArray = [];
            //   const filteredField = result.data.documentTypeValue.filter(
            //     item =>
            //       !DocumentTypeRef.current.value
            //         .split(",")
            //         .includes(item.id.toString())
            //   );
            //   console.log(
            //     "result.data.documentTypeValue" + DocumentTypeonscan
            //   );
            //   const selectedField = result.data.documentTypeValue.filter(
            //     item =>
            //       DocumentTypeRef.current.value
            //         .split(",")
            //         .includes(item.id.toString())
            //   );
            //   setDocumentTypeonscan(filteredField);
            //   finalSelectedArray = selectedDocumentType;

            //   selectedField.map(e => {
            //     finalSelectedArray = [...finalSelectedArray, e];
            //   });
            //   setselectedDocumentType(finalSelectedArray);
            // }
            setonBaseConnect("");
            setDocumentTypescan(result.data.documentTypeValue);
            setuse(true);
            //}
            debugger;
            //GetSubprocessConfig(result.data.documentTypeValue);
          }
          //GetSubprocessConfig();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else if (result.status === 500) {
          debugger;
          // addToast("Error: Unable to connect to the Onbase server.", {
          //   appearance: "error",
          //   autoDismiss: true
          // });
          // let err = [];
          setonBaseConnect("Error: Unable to connect to the Onbase server.");
          //setonBaseConnect({ ...err });

          //navigate("/");
        } else {
          addToast("OnBase DocumentType Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const GetOnbaseScanQuene = () => {
    //setrAG("0");
    //debugger;
    setonBaseConnect("");
    let body = {
      subprocessID: props.reference.SubprocessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHONBASESCANQUENE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          //debugger;
          setonBaseConnect("");
          if (
            result.data.documentTypeValue != null ||
            result.data.documentTypeValue != undefined
          ) {
            setscanQueues(result.data.documentTypeValue);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else if (result.status === 500) {
          debugger;
          // addToast("Error: Unable to connect to the Onbase server.", {
          //   appearance: "error",
          //   autoDismiss: true
          // });
          //let err = {};
          setonBaseConnect("Error: Unable to connect to the Onbase server.");
          //setonBaseConnect({ ...err });

          //navigate("/");
        } else {
          addToast("OnBase Scan Queue Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  //Onbase
  const [rAG, setrAG] = useState("0");
  const [buttonSave, setbuttonSave] = useState(true);
  const RAGIndicator = (e) => {
    //debugger;
    setrAG(e.target.value);
    setRAGIndicatorCriteria(e.target.value);
  };
  // const Age = e => {
  //   //debugger;
  //   AgeLessEqualRef.current = e.target.value;
  // };
  const GetSubprocessConfig = () => {
    //debugger;
    //let selectedTypes = DocumentTypeonscan;
    //sessionStorage.setItem("ShowNext", false);

    let body = {
      Guid: sessionStorage.getItem("GUID").toString(),
      subprocessID: props.reference.SubprocessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSCONFIG);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          set_IsLoading(false);
          debugger;
          seterrorMessage("");
          //result.data.subprocessDetails[0].onBasePassword)

          if (
            result.data.subprocessDetails.length != 0 ||
            result.data.powerBiReport.length != 0
          ) {
            set_IsLoading(false);
            setbuttonSave(false);
            setrAG(result.data.subprocessDetails[0].ragIndicatorCriteria);

            debugger;
            if (
              result.data.subprocessDetails[0].onbaseDocumentType != "" ||
              result.data.subprocessDetails[0].onbaseDocumentType != 0
            ) {
              debugger;
              let finalSelectedArray = [];

              // selectedId = parseInt(
              //   result.data.subprocessDetails[0].onbaseDocumentType.split(",")
              // );
              // DocumentTypeID.map(e => {
              //   //debugger;

              //   const Id = parseInt(e);

              //   selectedId = [...selectedId, Id];
              // });
              const filteredField = DocumentTypescan.filter(
                (item) =>
                  !result.data.subprocessDetails[0].onbaseDocumentType
                    .split(",")
                    .includes(item.id.toString())
              );
              // console.log("result.data.documentTypeValue" + DocumentTypeonscan);
              const selectedField = DocumentTypescan.filter((item) =>
                result.data.subprocessDetails[0].onbaseDocumentType
                  .split(",")
                  .includes(item.id.toString())
              );
              setDocumentTypeonscan(filteredField);
              //finalSelectedArray = selectedDocumentType;

              selectedField.map((e) => {
                finalSelectedArray = [...finalSelectedArray, e];
              });
              setselectedDocumentType(finalSelectedArray);
            }
            //debugger;
            SelectatleastoneDocumenttypeRef.current.value =
              result.data.subprocessDetails[0].onbaseDocumentType;
            // if (
            //   result.data.subprocessDetails[0].onBaseUserID != "" ||
            //   result.data.subprocessDetails[0].onBaseUserID != null
            // ) {
            //   const bytes = CryptoJS.AES.decrypt(
            //     result.data.subprocessDetails[0].onBaseUserID,
            //     "A4B3C2D15%6^W7X8Y9Z"
            //   );
            //   console.log(bytes.toString(CryptoJS.enc.Utf8));
            //   // result.data.subprocessDetails[0].onBaseUserID = bytes.toString(
            //   //   CryptoJS.enc.Utf8
            //   // );
            // }
            // const [a, seta] = useState("");
            // const [b, setb] = useState("");
            // const [c, set] = useState("");

            if (
              (result.data.subprocessDetails[0].slaCalForDash != null ||
                result.data.subprocessDetails[0].slaCalForDash != undefined) &&
              result.data.subprocessDetails[0].ragIndicatorCriteria == "1"
            ) {
              setWorkitemduewithinnext(
                result.data.subprocessDetails[0].slaCalForDash
              );
            }
            if (
              (result.data.subprocessDetails[0].ageCalForDash != null ||
                result.data.subprocessDetails[0].ageCalForDash != undefined) &&
              result.data.subprocessDetails[0].ragIndicatorCriteria == "2"
            ) {
              let dash = [];
              dash = result.data.subprocessDetails[0].ageCalForDash.split(",");
              setAgeGreater(
                result.data.subprocessDetails[0].ageCalForDash.split(",")[0]
              );
              setAgeLess(
                result.data.subprocessDetails[0].ageCalForDash.split(",")[1]
              );
              setAgeEqual(
                result.data.subprocessDetails[0].ageCalForDash.split(",")[1]
              );
            } else {
              setAgeGreater("");
              setAgeLess("");
              setAgeEqual("");
            }
            setenable(result.data.subprocessDetails[0].onBaseIntegration);
            setreportData(result.data.powerBiReport);
            setDashboardID(
              result.data.subprocessDetails[0].pbiSubProcessDashboardID
            );
            setRemarks(result.data.subprocessDetails[0].remarks);
            IsDelete2Ref.current.value =
              result.data.subprocessDetails[0].isDeleted;
            setWorkspaceID(result.data.subprocessDetails[0].pbiWorkspaceID);

            // AllocationunitRef.current.value =
            //   result.data.subprocessDetails[0].allocationUnit;
            setAllocationunit(result.data.subprocessDetails[0].allocationUnit);

            setNoOfDaysToHoldWithClient(
              result.data.subprocessDetails[0].noOfDaysToHoldWithClient
            );
            setDefectOpportunitiesPerWorkitem(
              result.data.subprocessDetails[0].defectOpportunitiesPerWorkItem
            );
            setCustomerFeedbackScoreTarget(
              result.data.subprocessDetails[0].customerFeedbackScoreTarget
            );
            setTAT(result.data.subprocessDetails[0].taTinDays);
            setAvailabilityTarget(
              result.data.subprocessDetails[0].availabilityTarget
            );
            setEfficiencyTarget(
              result.data.subprocessDetails[0].efficiencyTarget
            );
            setProductiveUtilizationTarget(
              result.data.subprocessDetails[0].productiveUtilizationTarget
            );
            setCutOffTimeforSLAStartDate(
              result.data.subprocessDetails[0].uploadDuringTheDayStartTime
            );
            setSLAOnTimeTarget(
              result.data.subprocessDetails[0].slaOnTimeTarget
            );
            setSLAAccuracyTarget(
              result.data.subprocessDetails[0].slaAccuracyTarget
            );
            setAutoIncrementFieldvalue(
              result.data.subprocessDetails[0].autoIncrementField
            );
            setMultiplierField(
              result.data.subprocessDetails[0].multiplierField
            );
            setSLAStartDateField(
              result.data.subprocessDetails[0].tatCalculationDateField
            );
            setSubProcessOwner(
              result.data.subprocessDetails[0].subprocessOwner
            );

            setRAGIndicatorCriteria(
              result.data.subprocessDetails[0].ragIndicatorCriteria
            );
            //debugger;
            setScanQue(result.data.subprocessDetails[0].scanQueue);
            setScanNo(result.data.subprocessDetails[0].onbasescanQueue);

            CopyWorkitemTypeInActivityScreenRef.current.checked =
              result.data.subprocessDetails[0].copyWorkItemType;
            UseShortcutkeysInActivityScreenRef.current.checked =
              result.data.subprocessDetails[0].useShortcutkeys;
            HideSLArelatedfieldsfromSearchresultRef.current.checked =
              result.data.subprocessDetails[0].hideSLARelatedFields;
            IncludeWeekendinSLACalculationRef.current.checked =
              result.data.subprocessDetails[0].includeWeekendInSLACalculation;
            IncludeHolidaysinSLACalculationRef.current.checked =
              result.data.subprocessDetails[0].includeHolidaysInSLACalculation;
            ExcludeCompletedItemsinDuplicateCheckRef.current.checked =
              result.data.subprocessDetails[0].excludeCompletedItemsInDuplicateCheck;
            //debugger;
            setDocumentIdField(
              result.data.subprocessDetails[0].onBaseDocumentIDField
            );
            console.log(
              "DocumentIdFieldRef" +
                result.data.subprocessDetails[0].onBaseDocumentIDField
            );
            enableonbaseRef.current.checked =
              result.data.subprocessDetails[0].onBaseIntegration;
            OnBaseUserNameRef.current.value =
              result.data.subprocessDetails[0].onBaseUserID;
            OnBasePRef.current.value =
              result.data.subprocessDetails[0].onBasePassword;
            ////debugger;
            setScanQueue(result.data.subprocessDetails[0].onbasescanQueue);
            // console.log(
            //   "ScanQueueRef.current.value =" + ScanQueueRef.current.value
            // );
            AppendaudittrialOnBaseDocumentRef.current.checked =
              result.data.subprocessDetails[0].appendStatusHistoryToOnbase;
            CreateWorkitemAutomaticallyRef.current.checked =
              result.data.subprocessDetails[0].autoWorkitemCreation;
            EnableDocumentUploadtoonBaseRef.current.checked =
              result.data.subprocessDetails[0].onbaseDocumentUpload;
            // GetOnbaseScanQuene();
            // GetOnbaseDocumentType();
          } else {
            setbuttonSave(true);
            set_IsLoading(false);
          }
          //setworkitemType(result.data);
        } else if (result.status === 401) {
          set_IsLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          //debugger;
          set_IsLoading(false);
          addToast("Fetching Subprocess Config Detail Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  //Start SaveSubprocessConfig

  const SaveSubprocessConfig = () => {
    // set_IsLoading(true);
    debugger;
    seterrorMessage("");
    let err = {};
    let isSubmit = true;
    if (Allocationunit == 0 && Allocationunit == "0") {
      // err.Allocationunit = "Allocation Unit is Required.";
      addToast("Allocation Unit is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (TAT.length == 0 || TAT == "" || TAT == "0") {
      //err.TAT = "TAT is Required.";
      addToast("TAT is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (SLAStartDateField == "0" || SLAStartDateField == 0) {
      //err.SLAStartDateField = "SLA Start Date Field is Required.";
      addToast("SLA Start Date Field is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (CutOffTimeforSLAStartDate == "" || CutOffTimeforSLAStartDate == "0") {
      // err.CutOffTimeforSLAStartDate =
      //   "Cut-Off Time for SLA Start Date is Required.";
      addToast("Cut-Off Time for SLA Start Date is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    // seterrorMessage({ ...err });

    //debugger;
    if (
      !/^(0[0-9]|1[0-9]|2[0-9]):[0-5][0-9]$/.test(CutOffTimeforSLAStartDate)
    ) {
      // err.CutOffTimeforSLAStartDate =
      //   "Cut-Off Time for SLA Start Date should be in 24 hrs format, Ex: 22:57";
      addToast(
        "Cut-Off Time for SLA Start Date should be in 24 hrs format, Ex: 22:57",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      isSubmit = false;
    }
    //seterrorMessage({ ...err });
    //debugger;
    if (onBaseConnect == "" && onBaseConnect.length == 0) {
      if (enableonbaseRef.current.checked) {
        if (DocumentIdField == "0" || DocumentIdField == 0) {
          // err.DocumentIdField = "Document-ID Field is Required.";
          addToast("Document-ID Field is Required.", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
      }
    }
    //seterrorMessage({ ...err });

    if (onBaseConnect == "" && onBaseConnect.length == 0) {
      if (enableonbaseRef.current.checked) {
        if (ScanQueue == "0" || ScanQueue == 0) {
          //err.ScanQueue = "ScanQueue is Required.";
          addToast("ScanQueue is Required.", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
      }
    }
    //seterrorMessage({ ...err });

    if (onBaseConnect == "" && onBaseConnect.length == 0) {
      if (enableonbaseRef.current.checked) {
        if (selectedDocumentType == "" || selectedDocumentType.length == 0) {
          //err.SelectatleastoneDocumenttype = "Select Atleast One Document Type";
          addToast("Select Atleast One Document Type", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
      }
    }
    //seterrorMessage({ ...err });

    if (RAGIndicatorCriteria == "2") {
      if (AgeGreater == "" || AgeGreater == null) {
        // err.AgeGreater = "Red workitem age days is required.";
        addToast("Red workitem age days is required.", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });

    if (RAGIndicatorCriteria == "2") {
      if (AgeLess == "" || AgeLess == null) {
        //err.AgeLess = "Amber workitem age days is required.";
        addToast("Amber workitem age days is required", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });
    //debugger;
    if (RAGIndicatorCriteria == "2") {
      if (parseInt(AgeLess) > parseInt(AgeGreater) || AgeLess == AgeGreater) {
        // err.Agecondition =
        //   "Workitem age days for Amber should be less than Red.";
        addToast("Workitem age days for Amber should be less than Red", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });

    if (RAGIndicatorCriteria == "1") {
      if (Workitemduewithinnext == "" || Workitemduewithinnext == null) {
        //err.Workitemduewithinnext = "Amber SLA days is required.";
        addToast("Amber SLA days is required", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });
    debugger;
    if (isSubmit) {
      //set_IsLoading(true);
      //debugger;
      let body = {
        SubProcessID: props.reference.SubprocessID,
        CustomerFeedbackScoreTarget: parseFloat(
          CustomerFeedbackScoreTarget == "" ? 0 : CustomerFeedbackScoreTarget
        ),
        ProductiveUtilizationTarget: parseInt(
          ProductiveUtilizationTarget == "" ? 0 : ProductiveUtilizationTarget
        ),
        EfficiencyTarget: parseInt(
          EfficiencyTarget == "" ? 0 : EfficiencyTarget
        ),
        AvailabilityTarget: parseInt(
          AvailabilityTarget == "" ? 0 : AvailabilityTarget
        ),
        AllocationUnit: Allocationunit,
        TATinDays: parseInt(TAT == "" ? 0 : TAT),
        SLAOnTimeTarget: parseInt(SLAOnTimeTarget == "" ? 0 : SLAOnTimeTarget),
        SLAAccuracyRate: parseInt(
          SLAAccuracyTarget == "" ? 0 : SLAAccuracyTarget
        ),
        RAGIndicatorCriteria:
          RAGIndicatorCriteria == "1"
            ? Workitemduewithinnext == "" || Workitemduewithinnext == "0"
              ? 0
              : parseInt(RAGIndicatorCriteria)
            : RAGIndicatorCriteria == "2"
            ? AgeGreater == "" ||
              AgeGreater == "0" ||
              AgeLess == "" ||
              AgeLess == "0"
              ? 0
              : parseInt(RAGIndicatorCriteria)
            : 0,
        ProcessAbsenteeismTarget: 0,
        AttritionTarget: 0,
        SLACalForDash:
          Workitemduewithinnext == "" || Workitemduewithinnext == "0"
            ? 0
            : parseInt(Workitemduewithinnext),
        AgeCalForDash:
          AgeGreater == "" ||
          AgeGreater == "0" ||
          AgeLess == "" ||
          AgeLess == "0"
            ? "0"
            : AgeGreater + "," + AgeLess,
        DefectOpportunitiesPerWorkItem: parseInt(
          DefectOpportunitiesPerWorkitem == ""
            ? 0
            : DefectOpportunitiesPerWorkitem
        ),
        TATCalculationDateField: parseInt(
          SLAStartDateField == "" ? 0 : SLAStartDateField
        ),
        IncludeWeekendInSLACalculation:
          IncludeWeekendinSLACalculationRef.current.checked,
        ReportField1: 0,
        ReportField2: 0,
        ExcludeCompletedItemsInDuplicateCheck:
          ExcludeCompletedItemsinDuplicateCheckRef.current.checked,
        IncludeHolidaysInSLACalculation:
          IncludeHolidaysinSLACalculationRef.current.checked,
        PermitSplittingOfWorkItems: false,
        WorkItemType: "",
        Remarks: Remarks,
        IsDeleted: IsDelete2Ref.current.checked,
        UploadDuringTheDayStartTime: "1 jan 2000 " + CutOffTimeforSLAStartDate,
        UpdatedBy: parseInt(sessionStorage.getItem("PersonID")),
        NoOfDaysToHoldWithClient: parseInt(
          NoOfDaysToHoldWithClient == "" ? 0 : NoOfDaysToHoldWithClient
        ),
        MultiplierField: parseInt(MultiplierField == "" ? 0 : MultiplierField),
        copyWorkitemType: CopyWorkitemTypeInActivityScreenRef.current.checked,
        UseShortcutkeys: UseShortcutkeysInActivityScreenRef.current.checked,
        BreakType: "",
        OnBaseIntegration: enableonbaseRef.current.checked,
        OnBaseDocumentIDField: parseInt(
          DocumentIdField == "" ? 0 : DocumentIdField
        ),
        OnBaseUserID: OnBaseUserNameRef.current.value,
        OnBaseP: OnBasePRef.current.value,
        StatusHistory: false,
        chkHideSLARelatedFields:
          HideSLArelatedfieldsfromSearchresultRef.current.checked,
        OnbaseDocumentUpload: EnableDocumentUploadtoonBaseRef.current.checked,
        AutoWorkitemCreation: CreateWorkitemAutomaticallyRef.current.checked,
        ScanQueNo: ScanNo,
        ScanQueue: ScanQue,
        DocumentTypeNo: selectedDocumentType,
        DocumentType: selectedDocumentType,
        autoIncrementField: parseInt(
          AutoIncrementFieldvalue == "" ? 0 : AutoIncrementFieldvalue
        ),
        autoIncrementInitialValue: 0,
        SubprocessOwner:
          SubProcessOwner == null
            ? ""
            : SubProcessOwner.includes("~")
            ? SubProcessOwner.split("~")[0]
            : SubProcessOwner,
        IsOCR: false,
        IsOCRPriority: false,
        OCRThreshold: 0,
        OCRPriorityThreshold: 0,
        PBIWorkspaceId: WorkspaceID,
        PBISubProcessDashboardId: DashboardID == null ? "" : DashboardID,
        DTPowerBI: reportData,
      };

      let loginUrl = getAPIURL(REACT_APP_CREATESUBPROCESSCONFIG);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            //set_IsLoading(false);
            setbuttonSave(true);
            seterrorMessage("");
            addToast("Subprocess Config saved successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            //debugger;
          } else if (result.status === 401) {
            seterrorMessage("");
            //set_IsLoading(false);
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //navigate("/");
          } else {
            seterrorMessage("");
            //set_IsLoading(false);
            addToast("SubProcess Config Failed to save", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };
  // const [AgeCalForDash, setAgeCalForDash] = useState("");
  // const [SLACalForDash, setSLACalForDash] = useState(0);
  const UpdateSubprocessConfig = () => {
    debugger;
    seterrorMessage("");

    let err = {};
    let isSubmit = true;
    if (Allocationunit == 0 && Allocationunit == "0") {
      //err.Allocationunit = "Allocation Unit is Required.";
      addToast("Allocation Unit is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (TAT.length == 0 || TAT == "" || TAT == "0") {
      //err.TAT = "TAT is Required.";
      addToast("TAT is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (SLAStartDateField == "0" || SLAStartDateField == 0) {
      //err.SLAStartDateField = "SLA Start Date Field is Required.";
      addToast("SLA Start Date Field is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (CutOffTimeforSLAStartDate == "" || CutOffTimeforSLAStartDate == "0") {
      // err.CutOffTimeforSLAStartDate =
      //   "Cut-Off Time for SLA Start Date is Required.";
      addToast("Cut-Off Time for SLA Start Date is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    debugger;
    if (
      !/^(0[0-9]|1[0-9]|2[0-9]):[0-5][0-9]$/.test(CutOffTimeforSLAStartDate)
    ) {
      // err.CutOffTimeforSLAStartDate =
      //   "Cut-Off Time for SLA Start Date should be in 24 hrs format, Ex: 22:57";
      addToast(
        "Cut-Off Time for SLA Start Date should be in 24 hrs format, Ex: 22:57",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      isSubmit = false;
    }
    //seterrorMessage({ ...err });
    debugger;
    if (onBaseConnect == "" && onBaseConnect.length == 0) {
      if (enableonbaseRef.current.checked) {
        if (ScanQueue == "0" || ScanQueue == 0) {
          //err.ScanQueue = "ScanQueue is Required.";
          addToast("ScanQueue is Required.", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
      }
    }
    //seterrorMessage({ ...err });

    if (onBaseConnect == "" && onBaseConnect.length == 0) {
      if (enableonbaseRef.current.checked) {
        if (selectedDocumentType == "" || selectedDocumentType.length == 0) {
          // err.SelectatleastoneDocumenttype = "Select Atleast One Document Type";
          addToast("Select Atleast One Document Type", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
      }
    }
    //seterrorMessage({ ...err });

    if (onBaseConnect == "" && onBaseConnect.length == 0) {
      if (enableonbaseRef.current.checked) {
        if (DocumentIdField == "0" || DocumentIdField == 0) {
          //err.DocumentIdField = "Document-ID Field is Required.";
          addToast("Document-ID Field is Required.", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
      }
    }
    //seterrorMessage({ ...err });

    if (RAGIndicatorCriteria == "2") {
      if (AgeGreater == "" || AgeGreater == null) {
        //err.AgeGreater = "Red workitem age days is required.";
        addToast("Red workitem age days is required.", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });j

    if (RAGIndicatorCriteria == "2") {
      if (AgeLess == "" || AgeLess == null) {
        //err.AgeLess = "Amber workitem age days is required.";
        addToast("Amber workitem age days is required", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });

    if (RAGIndicatorCriteria == "2") {
      if (parseInt(AgeLess) > parseInt(AgeGreater) || AgeLess == AgeGreater) {
        // err.Agecondition =
        //   "Workitem age days for Amber should be less than Red.";
        addToast("Workitem age days for Amber should be less than Red", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });

    if (RAGIndicatorCriteria == "1") {
      if (Workitemduewithinnext == "" || Workitemduewithinnext == null) {
        //err.Workitemduewithinnext = "Amber SLA days is required.";
        addToast("Amber SLA days is required", {
          appearance: "error",
          autoDismiss: true,
        });
        isSubmit = false;
      }
    }
    //seterrorMessage({ ...err });
    debugger;
    // if (enableonbaseRef.current.checked) {
    //   if (
    //     SelectatleastoneDocumenttypeRef.current.value.length == 0 ||
    //     SelectatleastoneDocumenttypeRef.current.value == ""
    //   ) {
    //     err.SelectatleastoneDocumenttype = "Select Atleast One Document Type";
    //     isSubmit = false;
    //   }
    // }
    //seterrorMessage({ ...err });

    if (isSubmit) {
      //set_IsLoading(true);
      let body = {
        SubProcessID: props.reference.SubprocessID,
        CustomerFeedbackScoreTarget: parseFloat(
          CustomerFeedbackScoreTarget == "" ? 0 : CustomerFeedbackScoreTarget
        ),
        ProductiveUtilizationTarget: parseInt(
          ProductiveUtilizationTarget == "" ? 0 : ProductiveUtilizationTarget
        ),
        EfficiencyTarget: parseInt(
          EfficiencyTarget == "" ? 0 : EfficiencyTarget
        ),
        AvailabilityTarget: parseInt(
          AvailabilityTarget == "" ? 0 : AvailabilityTarget
        ),
        AllocationUnit: Allocationunit,
        TATinDays: parseInt(TAT == "" ? 0 : TAT),
        SLAOnTimeTarget: parseInt(SLAOnTimeTarget == "" ? 0 : SLAOnTimeTarget),
        SLAAccuracyRate: parseInt(
          SLAAccuracyTarget == "" ? 0 : SLAAccuracyTarget
        ),
        //
        RAGIndicatorCriteria:
          RAGIndicatorCriteria == "1"
            ? Workitemduewithinnext == "" || Workitemduewithinnext == "0"
              ? 0
              : parseInt(RAGIndicatorCriteria)
            : RAGIndicatorCriteria == "2"
            ? AgeGreater == "" ||
              AgeGreater == "0" ||
              AgeLess == "" ||
              AgeLess == "0"
              ? 0
              : parseInt(RAGIndicatorCriteria)
            : 0,
        ProcessAbsenteeismTarget: 0,
        AttritionTarget: 0,
        SLACalForDash:
          RAGIndicatorCriteria == "1"
            ? Workitemduewithinnext == "" || Workitemduewithinnext == "0"
              ? 0
              : parseInt(Workitemduewithinnext)
            : 0,
        AgeCalForDash:
          RAGIndicatorCriteria == "2"
            ? AgeGreater == "" ||
              AgeGreater == "0" ||
              AgeLess == "" ||
              AgeLess == "0"
              ? "0"
              : AgeGreater + "," + AgeLess
            : "0",
        DefectOpportunitiesPerWorkItem: parseInt(
          DefectOpportunitiesPerWorkitem == ""
            ? 0
            : DefectOpportunitiesPerWorkitem
        ),
        TATCalculationDateField: parseInt(
          SLAStartDateField == "" ? 0 : SLAStartDateField
        ),
        IncludeWeekendInSLACalculation:
          IncludeWeekendinSLACalculationRef.current.checked,
        ReportField1: 0,
        ReportField2: 0,
        ExcludeCompletedItemsInDuplicateCheck:
          ExcludeCompletedItemsinDuplicateCheckRef.current.checked,
        IncludeHolidaysInSLACalculation:
          IncludeHolidaysinSLACalculationRef.current.checked,
        PermitSplittingOfWorkItems: false,
        WorkItemType: "",
        Remarks: Remarks,
        IsDeleted: IsDelete2Ref.current.checked,
        UploadDuringTheDayStartTime: "1 jan 2000 " + CutOffTimeforSLAStartDate,
        UpdatedBy: parseInt(sessionStorage.getItem("PersonID")),
        NoOfDaysToHoldWithClient: parseInt(
          NoOfDaysToHoldWithClient == "" ? 0 : NoOfDaysToHoldWithClient
        ),
        MultiplierField: parseInt(MultiplierField == "" ? 0 : MultiplierField),
        copyWorkitemType: CopyWorkitemTypeInActivityScreenRef.current.checked,
        UseShortcutkeys: UseShortcutkeysInActivityScreenRef.current.checked,
        BreakType: "",
        OnBaseIntegration: enableonbaseRef.current.checked,
        OnBaseDocumentIDField: parseInt(
          DocumentIdField == "" ? 0 : DocumentIdField
        ),
        OnBaseUserID: OnBaseUserNameRef.current.value,
        OnBaseP: OnBasePRef.current.value,
        StatusHistory: false,
        chkHideSLARelatedFields:
          HideSLArelatedfieldsfromSearchresultRef.current.checked,
        OnbaseDocumentUpload: EnableDocumentUploadtoonBaseRef.current.checked,
        AutoWorkitemCreation: CreateWorkitemAutomaticallyRef.current.checked,
        ScanQueueNo: ScanNo,
        ScanQueue: ScanQue,
        DocumentTypeNo: selectedDocumentType,
        DocumentType: selectedDocumentType,
        autoIncrementField: parseInt(
          AutoIncrementFieldvalue == "" ? 0 : AutoIncrementFieldvalue
        ),
        autoIncrementInitialValue: 0,
        SubprocessOwner:
          SubProcessOwner == null
            ? ""
            : SubProcessOwner.includes("~")
            ? SubProcessOwner.split("~")[0]
            : SubProcessOwner,
        IsOCR: false,
        IsOCRPriority: false,
        OCRThreshold: 0,
        OCRPriorityThreshold: 0,
        PBIWorkspaceId: WorkspaceID,
        PBISubProcessDashboardId: DashboardID == null ? "" : DashboardID,
        DTPowerBI: reportData,
      };
      //let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSCONFIG);
      let loginUrl = getAPIURL(REACT_APP_UPDATESUBPROCESSCONFIG);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // sessionStorage.setItem("ShowNext", "true");
            //GetSubprocessConfig();

            //debugger;
            //set_IsLoading(false);
            seterrorMessage("");
            addToast("SubProcess Config updated successfully", {
              appearance: "success",
              autoDismiss: true,
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //set_IsLoading(false);
            //navigate("/");
          } else {
            addToast("Subprocess Config Failed to update", {
              appearance: "error",
              autoDismiss: true,
            });
            //set_IsLoading(false);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  //End SaveSubprocessConfig

  const Add = () => {
    debugger;
    seterrorMessage("");
    let err = {};
    let isSubmit = true;
    if (WorkspaceID.length == 0 || WorkspaceID == "") {
      //err.WorkspaceID = "Workspace ID is Mandatory";
      addToast("Workspace ID is Mandatory", {
        appearance: "success",
        autoDismiss: true,
      });
      isSubmit = false;
    }

    //seterrorMessage({ ...err });
    debugger;
    if (isSubmit) {
      const a = {
        pbiReportID: reportIDRef.current.value,
        pbiReportTitle: reportTitleIdRef.current.value,
      };
      if (
        reportData.filter(
          (data) =>
            data.pbiReportID == a.pbiReportID &&
            data.pbiReportTitle == a.pbiReportTitle
        ).length > 0
      ) {
        // err.reportData = "Report Record already Exists";
        // seterrorMessage({ ...err });
        addToast("Report Record already Exists", {
          appearance: "warning",
          autoDismiss: true,
        });
      } else {
        setreportData((prevRecord) => [...prevRecord, a]);

        addToast("Report Added Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      reportIDRef.current.value = "";
      reportTitleIdRef.current.value = "";
    }
  };

  const Deletereportdata = (rowdata) => {
    ////debugger;
    if (
      window.confirm(
        "Are you sure you want to delete the WorkItem - " +
          rowdata.pbiReportID +
          " ?"
      )
    ) {
      const b = reportData.filter(
        (data) =>
          data.pbiReportID !== rowdata.pbiReportID &&
          data.pbiReportTitle !== rowdata.pbiReportTitle
      );
      setreportData(b);

      addToast("Report Deleted Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };
  const [enable, setenable] = useState(false);
  const handleenableOnbaseintegration = (e) => {
    //debugger;
    setenable(enableonbaseRef.current.checked);
  };
  const DocumentTypeRef = useRef("");
  const SelectatleastoneDocumenttypeRef = useRef("");
  const [selectedDocumentType, setselectedDocumentType] = useState([]);
  const [SelectedFields, setSelectedFields] = useState([]);
  const [SelectedPreferenceFields, setSelectedPreferenceFields] = useState([]);
  //Drag and Drop
  const addFieldValuestoSelectedColumn = () => {
    // After moving item to another dropdown, next available items get selected automatically. This code will deselect those items
    //debugger;
    for (
      let index = 0;
      index < DocumentTypeRef.current.options.length;
      index++
    ) {
      DocumentTypeRef.current.options[index].selected = false;
    }

    let selectedId = [];

    let finalSelectedArray = [];

    SelectedFields.map((e) => {
      //debugger;

      const Id = parseInt(e);

      selectedId = [...selectedId, Id];
    });

    const filteredField = DocumentTypeonscan.filter(
      (item) => !selectedId.includes(item.id)
    );
    const selectedField = DocumentTypeonscan.filter((item) =>
      selectedId.includes(item.id)
    );
    setDocumentTypeonscan(filteredField);
    finalSelectedArray = selectedDocumentType;

    selectedField.map((e) => {
      finalSelectedArray = [...finalSelectedArray, e];
    });
    setselectedDocumentType(finalSelectedArray);
    setSelectedFields([]);
  };

  const getCurrentField = (e) => {
    seterrorMessage("");
    //debugger;
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedFields(selectedOptions);
  };

  //
  const removeFieldValuestoSelectedColumn = () => {
    // After moving item to another dropdown, next available items get selected automatically. This code will deselect those items
    for (
      let index = 0;
      index < SelectatleastoneDocumenttypeRef.current.options.length;
      index++
    ) {
      SelectatleastoneDocumenttypeRef.current.options[index].selected = false;
    }
    let selectedId = [];

    let finalSelectedArray = [];
    SelectedPreferenceFields.map((e) => {
      const Id = parseInt(e);

      selectedId = [...selectedId, Id];
    });
    const filteredField = selectedDocumentType.filter(
      (item) => !selectedId.includes(item.id)
    );
    const selectedField = selectedDocumentType.filter((item) =>
      selectedId.includes(item.id)
    );
    setselectedDocumentType(filteredField);
    finalSelectedArray = DocumentTypeonscan;
    selectedField.map((e) => {
      finalSelectedArray = [...finalSelectedArray, e];
    });
    setDocumentTypeonscan(finalSelectedArray);
    setSelectedPreferenceFields([]);
  };

  const getCurrentFieldPrefrence = (e) => {
    seterrorMessage("");
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedPreferenceFields(selectedOptions);
  };

  const FetchScanQueue = () => {
    //debugger;
    let body = {
      subprocessID: props.reference.SubprocessID,
      pass: OnBasePRef.current.value,
      UserId: OnBaseUserNameRef.current.value,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHSCANQUEUEONCLICK);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          //debugger;
          addToast("Scan Queue fetch successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          if (
            result.data.documentTypeValue != null ||
            result.data.documentTypeValue != undefined
          ) {
            setscanQueues(result.data.documentTypeValue);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          addToast("Feching Scan Queue Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  //
  //Jinnat
  const [use, setuse] = useState(false);

  // useEffect(() => {
  //   GetSubprocessConfig();

  //   //debugger;
  // });

  useEffect(
    () => {
      GetOnbaseDocumentType();
      GetOnbaseScanQuene();
      GetDropdownValue();
      GetGlobalActivity();

      //debugger;
    },
    [
      //props.reference.SubprocessID
      //props.reference.subProcesscode
    ]
  );

  useEffect(() => {
    GetSubprocessConfig();

    //debugger;
  }, [use]);
  return (
    <>
      {/* One Scenario */}
      {isLoading ? (
        <div className="pt-4">
          {" "}
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          {/* <GlobalStyles
            styles={{
              ".MuiButtonBase-root.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                cursor: "default !important",
                PointerEvent: "none !important",
              },
              ".MuiButton-root": {
                color: "#1976d2 !important",               
              },
            }}
          /> */}
          <div className="dashBoxs mb-2" style={{ marginTop: "1.0rem" }}>
            <div className="formWrap">
              <div className="fieldSet mb-4">
                {" "}
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      {/* <div>
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px"
                          }}
                        >
                          {errorMessage.Allocationunit}
                        </span>
                      </div> */}
                      {/* <div>
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px"
                          }}
                        >
                          {errorMessage.Agecondition}
                        </span>
                      </div> */}
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Allocation Unit
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      </label>

                      <select
                        className="form-control form-control-sm"
                        required={true}
                        //ref={AllocationunitRef}
                        onChange={handleAllocationunit}
                        value={Allocationunit}
                        //defaultValue={selectedTask != "" ? selectedTask : ""}
                      >
                        <option value={"0"}>Select</option>
                        <option value={"Batch"}>Batch</option>
                        <option value={"Work Item"}>Work Item</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        {" "}
                        No. Of Days To Hold With Client
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={NoOfDaysToHoldWithClient}
                        onChange={NoOfDaysToHoldWithClientHandle}

                        //ref={NoOfDaysToHoldWithClientRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Defect Opportunities Per Workitem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={DefectOpportunitiesPerWorkitem}
                        onChange={DefectOpportunitiesPerWorkitemHandle}
                        //ref={DefectOpportunitiesPerWorkitemRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Customer Feedback Score Target
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="[0-9]+([,\.][0-9]+)?"
                        step="0.25"
                        //datatype={"double"}
                        value={CustomerFeedbackScoreTarget}
                        onChange={CustomerFeedbackScoreTargetHandle}
                        //ref={CustomerFeedbackScoreTargetRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Multiplier Field
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={MultiplierField}
                        onChange={MultiplierFieldHandle}
                        //ref={MultiplierFieldRef}
                        // onChange={e => {
                        //   setActivitiesSelected(e.target.value);
                        //   sessionStorage.setItem(
                        //     "ActivitiesSelectedForEditWorkItem",
                        //     e.target.value
                        //   );
                        // }}
                      >
                        <option value="0">Select</option>
                        {MultiPlierField.map((activity) => (
                          <option value={activity.workItemFieldID}>
                            {activity.labelText}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Productive Utilization Target
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={ProductiveUtilizationTarget}
                        onChange={ProductiveUtilizationTargetHandle}
                        //ref={ProductiveUtilizationTargetRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Efficiency Target
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={EfficiencyTarget}
                        onChange={EfficiencyTargetHandle}
                        //ref={EfficiencyTargetRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      {/* <div className="form-check form-check-inline ms-0 mx-0"> */}

                      {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                    /> */}
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Availability Target
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={AvailabilityTarget}
                        onChange={AvailabilityTargetHandle}
                        //ref={AvailabilityTargetRef}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      {/* <div className="form-check form-check-inline ms-0 mx-0"> */}
                      {/* <div>
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px"
                          }}
                        >
                          {errorMessage.TAT}
                        </span>
                      </div> */}
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        TAT (in Days)
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      </label>

                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={TAT}
                        onChange={TATHandle}
                        //ref={TATRef}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      {/* <div className="form-check form-check-inline ms-0 mx-0"> */}
                      {/* <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        onChange={handleunassignedcheckbox}
                      /> */}
                      {/* <div>
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px"
                          }}
                        >
                          {errorMessage.SLAStartDateField}
                        </span>
                      </div> */}
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        SLA Start Date Field
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      </label>

                      <select
                        className="form-control form-control-sm"
                        required={true}
                        value={SLAStartDateField}
                        // ref={SLAStartDateFieldRef}
                        onChange={(e) => {
                          seterrorMessage("");
                          setSLAStartDateField(e.target.value);
                        }}
                      >
                        <option value="0">Select</option>
                        {SLAStartdatefield.map((activity) => (
                          <option value={activity.workItemFieldID}>
                            {activity.labelText}
                          </option>
                        ))}
                      </select>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      {/* <div>
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px"
                          }}
                        >
                          {errorMessage.CutOffTimeforSLAStartDate}
                        </span>
                      </div> */}
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Cut-Off Time for SLA Start Date
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        pattern="/^[0-9]+:$/"
                        placeholder="HH:MM"
                        maxLength={"5"}
                        //inputMask="##:##"
                        // input
                        //="Password"
                        //name="password"
                        value={CutOffTimeforSLAStartDate}
                        onChange={CutOffTimeforSLAStartDateHandle}
                        required={true}

                        //step="1"
                        //ref={CutOffTimeforSLAStartDateRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      {/* <div className="form-check form-check-inline ms-0 mx-0"> */}

                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        SLA On Time Target
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={SLAOnTimeTarget}
                        onChange={SLAOnTimeTargetHandle}
                        //ref={SLAOnTimeTargetRef}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        SLA Accuracy Target
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        pattern="/^[0-9]*$/"
                        value={SLAAccuracyTarget}
                        onChange={SLAAccuracyTargetHandle}
                        //ref={SLAAccuracyTargetRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        Auto Increment Field
                      </label>
                      <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          setAutoIncrementFieldvalue(e.target.value);
                        }}
                        value={AutoIncrementFieldvalue}
                        //ref={AutoIncrementFieldRef}
                      >
                        <option value="0">Select</option>
                        {AutoIncrementField.map((activity) => (
                          <option value={activity.workItemFieldID}>
                            {activity.labelText}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        SubProcess Owner
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        pattern="/^[0-9]*$/"
                        value={SubProcessOwner}
                        onChange={SubProcessOwnerHandle}

                        //ref={SubProcessOwnerRef}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px" }}
                      >
                        RAG Indicator Criteria
                      </label>
                      <select
                        className="form-control form-control-sm"
                        onChange={RAGIndicator}
                        //ref={RAGIndicatorCriteriaRef}
                        value={RAGIndicatorCriteria}
                        //required={true}
                        //defaultValue={"0"} //{selectedTask != "" ? selectedTask : ""}
                      >
                        <option value="0">Select</option>
                        <option value="1">SLA Due Date</option>
                        <option value="2">Workitem Age</option>
                      </select>
                    </div>
                  </div>

                  {rAG == "2" ? (
                    <div className="row align-items-end">
                      <div className="col-2">
                        <div
                          className="mb-1 d-flex flex-row"
                          style={{ width: "200px" }}
                        >
                          {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px"
                        }}
                      >
                        {errorMessage.AgeGreater}
                      </span> */}
                          <label
                            className="form-label"
                            style={{ fontSize: "13px" }}
                          >
                            <span
                              style={{ backgroundColor: "#ff0000" }}
                              class="dot"
                            ></span>{" "}
                            {"Age >"}
                          </label>
                          <input
                            className="form-control form-control-sm"
                            style={{ width: "60px" }}
                            type="number"
                            pattern="/^[0-9]*$/"
                            onChange={AgeGreaterHandle}
                            value={AgeGreater}
                          />
                          <label
                            className="form-label"
                            style={{ marginLeft: ".5rem", fontSize: "13px" }}
                          >
                            days
                            <span
                              id=""
                              className="mandatory"
                              style={{ color: "#ff0000" }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="col-2">
                        <div
                          className="mb-1 d-flex flex-row"
                          style={{ width: "200px" }}
                        >
                          {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px"
                        }}
                      >
                        {errorMessage.AgeLess}
                      </span> */}
                          <label
                            className="form-label"
                            style={{ fontSize: "13px" }}
                          >
                            <span
                              style={{ backgroundColor: "#FFBB33" }}
                              class="dot"
                            ></span>{" "}
                            {"Age >"}
                          </label>
                          <input
                            className="form-control form-control-sm"
                            style={{ width: "60px" }}
                            type="number"
                            pattern="/^[0-9]*$/"
                            onChange={AgeLessHandle}
                            //ref={AgeLessRef}
                            value={AgeLess}
                          />
                          <label
                            className="form-label"
                            style={{ marginLeft: ".5rem", fontSize: "13px" }}
                          >
                            days
                            <span
                              id=""
                              className="mandatory"
                              style={{ color: "#ff0000" }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div
                          className="mb-1 d-flex flex-row"
                          style={{ width: "220px" }}
                        >
                          <label
                            className="form-label"
                            style={{ fontSize: "13px", fontSize: "13px" }}
                          >
                            <span
                              style={{ backgroundColor: "#3CB371" }}
                              class="dot"
                            ></span>{" "}
                            {"Age <="}
                          </label>
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            pattern="/^[0-9]*$/"
                            style={{ width: "60px" }}
                            //onChange={AgeEqualHandle}
                            //ref={AgeLessRef}
                            disabled={true}
                            value={AgeEqual}
                          />
                          <label
                            className="form-label"
                            style={{ marginLeft: ".5rem", fontSize: "13px" }}
                          >
                            days
                            <span
                              id=""
                              className="mandatory"
                              style={{ color: "#ff0000" }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : rAG == 1 ? (
                    <div className="row align-items-end">
                      <div className="col-2">
                        <div className="mb-2">
                          <label
                            className="form-label"
                            style={{ width: "100px", fontSize: "13px" }}
                          >
                            {" "}
                            <span
                              style={{ backgroundColor: "#ff0000" }}
                              class="dot"
                            ></span>{" "}
                            Overdue
                          </label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="mb-2 d-flex flex-row"
                          style={{ width: "450px", fontSize: "13px" }}
                        >
                          {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px"
                        }}
                      >
                        {errorMessage.Workitemduewithinnext}
                      </span> */}
                          <label
                            className="form-label"
                            style={{ fontSize: "13px" }}
                          >
                            <span
                              style={{ backgroundColor: "#FFBB33" }}
                              class="dot"
                            ></span>{" "}
                            Workitem due within next
                          </label>
                          <input
                            className="form-control form-control-sm"
                            style={{ width: "60px" }}
                            type="number"
                            pattern="/^[0-9]*$/"
                            value={Workitemduewithinnext}
                            onChange={WorkitemduewithinnextHandle}
                            //ref={WorkitemduewithinnextRef}
                          />
                          <label
                            className="form-label"
                            style={{ marginLeft: ".5rem", fontSize: "13px" }}
                          >
                            days
                            <span
                              id=""
                              className="mandatory"
                              style={{ color: "#ff0000" }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="mb-2 d-flex flex-row"
                          style={{ width: "390px" }}
                        >
                          {/* <span style={{ color: "green" }}>o </span> */}
                          <label
                            className="form-label"
                            style={{ fontSize: "13px" }}
                          >
                            <span
                              style={{ backgroundColor: "#3CB371" }}
                              class="dot"
                            ></span>{" "}
                            Workitem due after
                          </label>
                          <input
                            className="form-control form-control-sm"
                            style={{ width: "60px" }}
                            type="number"
                            pattern="/^[0-9]*$/"
                            value={Workitemduewithinnext}
                            disabled={true}
                            //onChange={WorkitemdueafterHandle}
                            //ref={WorkitemdueafterRef}
                          />
                          <label
                            className="form-label"
                            style={{ marginLeft: ".5rem", fontSize: "13px" }}
                          >
                            days
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          // value="option1"
                          // onChange={handleunassignedcheckbox}
                          ref={CopyWorkitemTypeInActivityScreenRef}
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox1"
                          style={{ fontSize: "13px" }}
                        >
                          Copy Workitem Type In Activity Screen
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          //
                          ref={UseShortcutkeysInActivityScreenRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox2"
                          style={{ fontSize: "13px" }}
                        >
                          Use Shortcut keys In Activity Screen
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox3"
                          ref={HideSLArelatedfieldsfromSearchresultRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox3"
                          style={{ fontSize: "13px" }}
                        >
                          Hide SLA related fields from Search result
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox4"
                          ref={IncludeWeekendinSLACalculationRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox4"
                          style={{ fontSize: "13px" }}
                        >
                          Include Weekend in SLA Calculation
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox5"
                          value="option1"
                          ref={IncludeHolidaysinSLACalculationRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox5"
                          style={{ fontSize: "13px" }}
                        >
                          Include Holidays in SLA Calculation
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox6"
                          ref={ExcludeCompletedItemsinDuplicateCheckRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox6"
                          style={{ fontSize: "13px" }}
                        >
                          Exclude Completed Items in Duplicate Check
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="formWrap">
                <div className="row align-items-end">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "13px",
                            // marginLeft: "1rem"
                          }}
                        >
                          OnBase Settings
                        </div>

                        {/* <div>
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1rem"
                  }}
                >
                  {onBaseConnect}
                </span>
              </div> */}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="dashBoxs mb-3">
                          <div className="formWrap">
                            <div className="fieldSet mb-4">
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                {onBaseConnect}
                              </span>
                              <div className="row align-items-end">
                                <div className="col-3">
                                  <div className="mb-3">
                                    {/* <div className="form-check form-check-inline ms-0 mx-0"> */}

                                    <div className="form-check form-check-inline ms-0 mx-0">
                                      <input
                                        //className="form-control form-control-sm"
                                        //type="text"
                                        //="Password"
                                        //name="password"
                                        //value={password}
                                        //onChange={ChangeHandle}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox7"
                                        // value="option1"
                                        onChange={handleenableOnbaseintegration}
                                        //disabled={!enable}
                                        ref={enableonbaseRef}
                                      />
                                      {/* </div> */}

                                      <label
                                        className="form-check-label"
                                        for="inlineCheckbox7"
                                        style={{ fontSize: "13px" }}
                                      >
                                        Enable OnBase Integration
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    {/* <div>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px"
                                  }}
                                >
                                  {errorMessage.DocumentIdField}
                                </span>
                              </div> */}
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Document-ID Field
                                      <span
                                        id=""
                                        className="mandatory"
                                        style={{ color: "#ff0000" }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className="form-control form-control-sm"
                                      //onChange={handletaskchange}
                                      required={true}
                                      disabled={!enable}
                                      value={DocumentIdField}
                                      onChange={DocumentIdFieldHandle}
                                      //ref={DocumentIdFieldRef}
                                      //defaultValue={selectedTask != "" ? selectedTask : ""}
                                    >
                                      <option value="0">Select</option>

                                      {DocumentId.map((activity) => (
                                        <option
                                          value={activity.workItemFieldID}
                                        >
                                          {activity.labelText}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      OnBase UserName
                                    </label>
                                    <input
                                      className="form-control form-control-sm"
                                      type="text"
                                      //="Password"
                                      //name="password"
                                      //value={password}
                                      //onChange={ChangeHandle}
                                      disabled={!enable}
                                      ref={OnBaseUserNameRef}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      OnBase Password
                                    </label>
                                    <input
                                      className="form-control form-control-sm"
                                      //type="text"
                                      type="password"
                                      disabled={!enable}
                                      ref={OnBasePRef}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    {/* <div className="form-check form-check-inline ms-0 mx-0"> */}
                                    <div className="form-check form-check-inline ms-0 mx-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox8"
                                        disabled={!enable}
                                        ref={AppendaudittrialOnBaseDocumentRef}
                                      />

                                      <label
                                        className="form-check-label"
                                        for="inlineCheckbox8"
                                        style={{ fontSize: "13px" }}
                                      >
                                        Append audit trial OnBase Document
                                      </label>

                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    {/* <div className="form-check form-check-inline ms-0 mx-0"> */}
                                    <div className="form-check form-check-inline ms-0 mx-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox20"
                                        disabled={!enable}
                                        ref={EnableDocumentUploadtoonBaseRef}
                                      />

                                      <label
                                        className="form-check-label"
                                        for="inlineCheckbox20"
                                        style={{ fontSize: "13px" }}
                                      >
                                        Enable Document Upload to Onbase
                                      </label>

                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <div className="form-check form-check-inline ms-0 mx-0">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox9"
                                        disabled={!enable}
                                        ref={CreateWorkitemAutomaticallyRef}
                                      />
                                      <label
                                        className="form-check-label"
                                        for="inlineCheckbox9"
                                        style={{ fontSize: "13px" }}
                                      >
                                        Create Workitem Automatically
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    {/* <div>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px"
                                  }}
                                >
                                  {errorMessage.ScanQueue}
                                </span>
                              </div> */}

                                    <label
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Scan Queue
                                      <span
                                        id=""
                                        className="mandatory"
                                        style={{ color: "#ff0000" }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className="form-control form-control-sm"
                                      onChange={ScanQueueHandle}
                                      required={true}
                                      value={ScanQueue}
                                      disabled={!enable}
                                    >
                                      <option value="0">Select</option>
                                      {scanQueues.map((activity) => (
                                        <option value={activity.id}>
                                          {activity.name}
                                        </option>
                                      ))}
                                    </select>
                                    {/* </div> */}
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <button
                                      type="submit"
                                      className="btn btn-sm btn-primary ms-3"
                                      //ref={ButtonTextRef}
                                      disabled={!enable}
                                      onClick={() => {
                                        FetchScanQueue();
                                      }}
                                    >
                                      Fetch Scan Queue
                                    </button>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <label
                                      htmlFor=""
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Document Type
                                    </label>

                                    <select
                                      multiple
                                      size="5"
                                      className="form-control form-control-xs"
                                      //title="Select a field to apply filter"
                                      ref={DocumentTypeRef}
                                      onChange={getCurrentField}
                                      disabled={!enable}
                                    >
                                      {DocumentTypeonscan.map((field) => (
                                        <option value={field.id}>
                                          {field.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-1 pt-3 ">
                                  <div className="mb-3 d-flex flex-column align-items-center flex-column mt-5">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                                      onClick={addFieldValuestoSelectedColumn}
                                      disabled={!enable}
                                    >
                                      {">"}
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                                      onClick={
                                        removeFieldValuestoSelectedColumn
                                      }
                                      disabled={!enable}
                                    >
                                      {"<"}
                                    </button>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <label
                                      htmlFor=""
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Select atleast one Document type
                                      <span
                                        id=""
                                        className="mandatory"
                                        style={{ color: "#ff0000" }}
                                      >
                                        *
                                      </span>
                                    </label>

                                    <select
                                      multiple
                                      size="5"
                                      className="form-control form-control-xs"
                                      title="Select a Document Type"
                                      onChange={getCurrentFieldPrefrence}
                                      ref={SelectatleastoneDocumenttypeRef}
                                      disabled={!enable}
                                      //value={selectedDocumentType}
                                    >
                                      {/* <option>Select</option> */}
                                      {selectedDocumentType.map((field) => (
                                        <option value={field.id}>
                                          {field.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="formWrap">
                <div className="row align-items-end">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "13px",
                            // marginLeft: "1rem"
                          }}
                        >
                          PowerBI Settings
                        </div>
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Typography>
                        <div className="dashBoxs mb-3">
                          <div className="formWrap">
                            <div className="fieldSet mb-4">
                              <div className="row align-items-end">
                                <div className="col-3">
                                  <div className="mb-3">
                                    {/* <div>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px"
                                  }}
                                >
                                  {errorMessage.WorkspaceID}
                                </span>
                              </div> */}
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Workspace ID
                                    </label>
                                    <input
                                      className="form-control form-control-sm"
                                      type="text"
                                      value={WorkspaceID}
                                      onChange={WorkspaceIDHandle}
                                      //ref={WorkspaceIDRef}
                                    />
                                    {/* </div> */}
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "13px" }}
                                    >
                                      Dashboard ID
                                    </label>
                                    <input
                                      className="form-control form-control-sm"
                                      type="text"
                                      //="Password"
                                      //name="password"
                                      value={DashboardID}
                                      onChange={DashboardIDHandle}
                                      //ref={DashboardIDRef}
                                    />
                                  </div>
                                </div>
                              </div>
                              <h10
                                style={{ fontWeight: "bold", fontSize: "13px" }}
                              >
                                Report Details
                              </h10>
                              <div>
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errorMessage.reportData}
                                </span>
                              </div>

                              <div className="fieldSet mb-4">
                                <div className="dashBoxs mb-3">
                                  <div className="formWrap">
                                    <div className="row align-items-end">
                                      <div className="col-3">
                                        <div className="mb-3">
                                          <label
                                            className="form-label"
                                            style={{ fontSize: "13px" }}
                                          >
                                            Report ID
                                          </label>

                                          <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            ref={reportIDRef}
                                            onChange={ReportIDHandle}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="mb-3">
                                          <label
                                            className="form-label"
                                            style={{ fontSize: "13px" }}
                                          >
                                            Report Tilte ID
                                          </label>
                                          <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            ref={reportTitleIdRef}
                                            onChange={ReportTilteIDHandle}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="mb-3">
                                          <button
                                            type="submit"
                                            className="btn btn-sm btn-primary ms-3"
                                            //ref={ButtonTextRef}
                                            onClick={() => {
                                              Add();
                                            }}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <table className="table table-borderless">
                                        {/* <ThemeProvider theme={theme}> */}
                                        <MaterialTable
                                          // title="List of WorkItem Statuses"
                                          localization={{
                                            pagination: {
                                              //labelDisplayedRows: '{from}-{to} of {count}'
                                            },
                                            toolbar: {
                                              // nRowsSelected: '{0} row(s) selected'
                                              // exportCSVName: "Export as Excel",
                                              // exportPDFName: "Export as pdf"
                                            },
                                            header: {
                                              actions: "",
                                            },
                                          }}
                                          actions={[
                                            // {
                                            //   icon: () => <EditIcon />,
                                            //   tooltip: "Update Already Completed",
                                            //   // onClick: (event, rowData) =>
                                            //   //   //editWorkItemStatus(rowData),
                                            //   //   Addpop(rowData),
                                            //   position: "row"
                                            // },
                                            {
                                              icon: () => <DeleteIcon />,
                                              tooltip:
                                                "Delete Already Completed",
                                              onClick: (event, rowData) =>
                                                Deletereportdata(rowData),
                                              position: "row",
                                            },
                                          ]}
                                          options={{
                                            headerStyle: {
                                              position: "sticky",
                                              top: "-5px",
                                            },
                                            maxBodyHeight: "420px",
                                            //pagination:true,
                                            //paginationType:"normal",
                                            //paginationPosition: "bottom",
                                            //pageSize: numberOfRecord,
                                            pageSizeOptions: [5, 10, 20, 50],
                                            //exportButton: true,
                                            sorting: true,
                                            filtering: true,
                                            search: false,
                                            selection: false,
                                            tableLayout: "auto",
                                            overflowY: false,
                                            showTitle: false,
                                            toolbarButtonAlignment: "right",
                                            headerStyle: {
                                              height: 30,
                                            },
                                            rowStyle: {
                                              fontFamily:
                                                '"Yantramanav", sans-serif',
                                              fontSize: "13px",
                                              // whiteSpace: "nowrap",
                                            },
                                            tableLayout: "auto",
                                            headerStyle: {
                                              fontFamily:
                                                '"Yantramanav", sans-serif',
                                              fontWeight: "inherit",
                                              fontSize: "14px",
                                              textAlign: "left !Important",
                                            },
                                          }}
                                          columns={columnsreport}
                                          icons={tableIcons}
                                          data={reportData}
                                          components={{
                                            Container: (props) => (
                                              <Paper {...props} elevation={0} />
                                            ),
                                            Toolbar: (props) => (
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    "rgb(206 206 206)",
                                                  fontSize: "0.75em",
                                                }}
                                              >
                                                <MTableToolbar
                                                  {...props}
                                                  style={{
                                                    fontSize: "5px",
                                                  }}
                                                />
                                              </div>
                                            ),
                                          }}
                                        />
                                        {/* </ThemeProvider> */}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="dashBoxs mb-2">
              <div className="formWrap">
                <div className="fieldSet mb-4">
                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Remarks</label>
                        <textarea
                          name="Text1"
                          cols="40"
                          rows="2"
                          maxLength="4000"
                          style={{ fontSize: "13px" }}
                          //ref={RemarksRef}
                          onChange={RemarksHandle}
                          value={Remarks}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="mb-3">
                        <div className="form-check form-check-inline ms-0 mx-0">
                          {/* <div className="form-check form-check-inline ms-0 mx-0"> */}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox13"
                            //onChange={handleunassignedcheckbox}
                            ref={IsDelete2Ref}
                          />

                          <label
                            className="form-check-label"
                            for="inlineCheckbox13"
                            style={{ fontSize: "13px" }}
                          >
                            Is Deleted
                          </label>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-3"> */}
          {/* {buttonSave ? (
              // <div className="mb-3">
              <button
                style={{ display: "none" }}
                ref={props.reference.SubprocessConfigRef}
                //ref={ButtonTextRef}
                onClick={() => {
                  SaveSubprocessConfig();
                }}
              ></button>
            ) : (
              // </div>
              // <div className="mb-3"> */}
          <button
            // type="submit"
            // className="btn btn-sm btn-primary ms-3"

            ref={props.reference.SubprocessConfigRef}
            style={{ display: "none" }}
            onClick={() => {
              HandleSaveandUpdate();
            }}
          >
            {/* Update */}
          </button>

          {/* //{" "} */}
          {/* </div> */}
          {/* )}
          </div> */}
        </div>
      )}
    </>
  );
};

export default SubprocessConfig;
