import React, { useEffect, useState, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormContext from "../../../../contexts/FormContext";

export default function WorkActivityScreenDetailsPage(state) {
  const [textFieldVaules, settextFieldVaules] = useState([]);
  let oldElements = state;
  const getTextValue = (e) => {
    settextFieldVaules(e.target.value);
  };
  const hyperlinkOnClick = (controlid) => {
    let url = document.getElementById(controlid).value;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleChange = useContext(FormContext);

  const handlekeypress = (e) =>{
    debugger
    if(e.key === "ArrowDown" || e.key === "ArrowUp"){
      e.preventDefault();
    }
  }

  const [DateFieldType, setDateFieldType] = useState("date");
  
  const OnDateclick = (e) =>{
    setDateFieldType("text");
  }
  
  const OnMouseOut = (e) =>{
    setDateFieldType("date");
  }

  return (
    <>
      <form className="form-inputs">
        {state.state.regGroup.map((data, index) => {
          let groupName = data.groupName;
          return (
            <div className="fieldSet mb-4" key={index}>
              <h4 className="mb-2">{data.groupName}</h4>
              <div className="row">
                {data.screenFields.map((values) => {
                  // debugger;
                  if (values.allowBlank == false) values.allowBlank = true;

                  let bgcolor = values.setColor;
                  if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("String")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <input
                            className="form-control  form-control-sm"
                            type="text"
                            //name={values.}
                            //pattern="\[A-za-z0–9_]\"
                            required={values.allowBlank}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Double")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <input
                            className="form-control  form-control-sm"
                            type="number"
                            pattern="[0-9]+([,\.][0-9]+)?"
                            //name={values.}
                            required={values.allowBlank}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={
                              values.value == ""
                                ? 0.0
                                : parseFloat(values.value).toFixed(2)
                            }
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            step="0.25"
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Float")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <input
                            className="form-control  form-control-sm"
                            type="number"
                            pattern="/^[-+]?[0-9]+\. [0-9]+$/"
                            //name={values.}
                            required={values.allowBlank}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={
                              values.value == ""
                                ? 0.0
                                : parseFloat(values.value).toFixed(2)
                            }
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            //value={textFieldVaules}
                            id={values.mapToField}
                            step="0.25"
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Int")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <input
                            className="form-control  form-control-sm"
                            type="number"
                            pattern="/^[0-9]*$/"
                            //name={values.}
                            required={values.allowBlank}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value == "" ? 0 : values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("HtmlTextArea")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <textarea
                            className="form-control  form-control-sm"
                            //name={values.}
                            required={values.allowBlank}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value}
                            readOnly={values.isReadOnly}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("HyperLink")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <input
                            className="form-control  form-control-sm"
                            type="text"
                            //name={values.}
                            required={values.allowBlank}
                            maxLength={values.maxLen}
                            defaultValue={values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                          <a
                            href="javascript:void(0)"
                            className="ahyperlink"
                            onClick={() => hyperlinkOnClick(values.mapToField)}
                          >
                            Click here to view.
                          </a>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("DateTime")
                  ) {
                    let finalDate = "";
                    if (values.value.trim() != "") {
                      const [month, date, year, time] = values.value
                        .replace(/\s{2,}/g, " ")
                        .trim()
                        .split(" ");
                      if (date == undefined || year == undefined) {
                        finalDate = values.value;
                      } else {
                        let monthNum =
                          new Date(Date.parse(month + " 1, 2012")).getMonth() +
                          1;

                        let monthName =
                          monthNum < 10
                            ? "0" + monthNum.toString()
                            : monthNum.toString();
                        let dateVal =
                          date < 10 ? "0" + date.toString() : date.toString();
                        finalDate = year.concat("-", monthName, "-", dateVal);
                      }
                    }
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}

                          <input
                            type={DateFieldType}
                            className="form-control form-control-sm"
                            name={values.name}
                            value={finalDate}
                            readOnly={values.isReadOnly}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //onClick={OnDateclick}
                            onDoubleClick={OnDateclick}
                            onMouseOut={OnMouseOut}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  } else if (
                    // values.uiControlType.includes("WebSchedule") &&
                    values.dataType.includes("DateTime")
                  ) {
                    let finalDate = "";
                    if (values.value.trim() != "") {
                      const [month, date, year, time] = values.value
                        .replace(/\s{2,}/g, " ")
                        .trim()
                        .split(" ");
                      if (date == undefined || year == undefined) {
                        finalDate = values.value;
                      } else {
                        let monthNum =
                          new Date(Date.parse(month + " 1, 2012")).getMonth() +
                          1;

                        let monthName =
                          monthNum < 10
                            ? "0" + monthNum.toString()
                            : monthNum.toString();
                        let dateVal =
                          date < 10 ? "0" + date.toString() : date.toString();
                        finalDate = year.concat("-", monthName, "-", dateVal);
                      }
                    }
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}

                          <input
                            type={DateFieldType}
                            className="form-control form-control-sm"
                            name={values.name}
                            value={finalDate}
                            readOnly={values.isReadOnly}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //onClick={OnDateclick}
                            onDoubleClick={OnDateclick}
                            onMouseOut={OnMouseOut}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("DropDownList")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div>
                          <label className="form-label">
                            {values.labelText}
                          </label>
                          {values.allowBlank ? (
                            <span id="" className="mandatory">
                              *
                            </span>
                          ) : null}
                          <select
                            className="form-control form-control-sm"
                            title=""
                            disabled={values.isReadOnly}
                            required={values.allowBlank}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //defaultValue={values.workItemDropdownValues[1]}
                            //value={values.workItemDropdownValues[0]}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          >
                            <option>{"Select"}</option>
                            {values.workItemDropdownValues.map((reason, i) => (
                              <option
                                selected={values.value === reason}
                                value={reason}
                                key={i}
                              >
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </form>
    </>
  );
}
