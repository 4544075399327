import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../../components/header/Header";
import Footer from "../../../../../components/footer/Footer";
import Nav from "../../../../../components/nav/Nav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import ApplicationUserContext from "../../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import tableIcons from "../../../../../MaterialTableIcons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FcViewDetails } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import { FcDeleteColumn } from "react-icons/fc";
import PendingActionsIcon from "@mui/icons-material/";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinner from "../../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../../LoadingSpinnerFigma";
import Iframe from "react-iframe";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";

import user01 from "../../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../../assets/images/icons/icon_empty.svg";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const ErrorCategory = () => {
  const errorCategoryNameRef = useRef(null);
  const errorCategoryIdRef = useRef(0);
  const displayOrderRef = useRef(null);
  const RemarksRef = useRef(null);
  const isDeletedRef = useRef(null);
  const ButtonTextRef = useRef("");

  const { addToast } = useToasts();
  const { REACT_APP_GETERRORCATEGORIES } = process.env;
  const { REACT_APP_UPDATEERRORCATEGORIES } = process.env;
  const { REACT_APP_DELETEERRORCATEGORIES } = process.env;
  const { REACT_APP_ADDERRORCATEGORIES } = process.env;
  const { REACT_APP_GETEMPLOYEEACTIVITYSUMMARY } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETWORKFLOWSTATES } = process.env;
  const { REACT_APP_GETWORKFLOWSTATUS } = process.env;
  const { REACT_APP_GETWORKFLOWITEMFIELDS } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [errorCategoryName, seterrorCategoryName] = useState("");
  const [workitemId, setworkitemId] = useState("");
  const [currentState, setcurrentState] = useState("");
  const [currentStateID, setcurrentStateID] = useState(0);
  const [currentStatus, setcurrentStatus] = useState("");
  const [subproseeworkflowConfigid, setsubproseeworkflowConfigid] =
    useState("");
  const [currentWith, setcurrentWith] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [imageUrlTemplate, setimageUrlTemplate] = useState("");
  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState("");
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState("");
  const [scanDateTimeFrom, setscanDateTimeFrom] = useState("");
  const [scanDateTimeTo, setscanDateTimeTo] = useState("");
  const [activityDateTimeFrom, setactivityDateTimeFrom] = useState("");
  const [activityDateTimeTo, setactivityDateTimeTo] = useState("");
  const [checkArchive, setcheckArchive] = useState(false);
  const [showStatusReasonComment, setshowStatusReasonComment] = useState(false);
  const [prefenceValue, setprefenceValue] = useState(3);
  const [advSearchField, setadvSearchField] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advCondition, setadvCondition] = useState("");
  const [advSearchText, setadvSearchText] = useState("");
  const [advSearchCondition, setadvSearchCondition] = useState("");
  const [searchWorkitemData, setsearchWorkitemData] = useState([]);
  //   const [columns, setcolumns] = useState([]);
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState(
    []
  );
  const [WorKFlowStates, setWorkFlowStates] = useState([]);
  const [array, setArray] = useState([]);
  const [displayOrderArry, setdisplayOrderArry] = useState([]);
  const [CurrentStateID_Client, setCurrentStateID_Client] = useState("");
  const [WorkFlowWorkItemFields, setWorkFlowWorkItemFields] = useState([]);
  const [WorkFlowStatuses, setWorkFlowStatuses] = useState([]);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [buttonText, setbuttonText] = useState("Save");

  const resetForm = () => {
    errorCategoryNameRef.current.value = "";
    displayOrderRef.current.value = "Select";
    RemarksRef.current.value = "";
    isDeletedRef.current.checked = false;
    //alert(errorCategoryNameRef.current.value);
  };
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const updateColumns = (keys) => {
    console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach((item) => {
      if (item != "updatedBy") col.push({ title: item, field: item });
    });
    //col.pop();
    //setcolumns(col);
  };

  const saveErrorCategoryData = () => {
    let loginUrl;
    let body;
    if (buttonText == "Save") {
      loginUrl = getAPIURL(REACT_APP_ADDERRORCATEGORIES);
      body = {
        ErrorCategoryName: errorCategoryNameRef.current.value,
        DisplayOrder: displayOrderRef.current.value,
        Remarks: RemarksRef.current.value,
        IsDeleted: isDeletedRef.current.checked,
        ErrorCategoryID: 0,
        UpdatedBy: sessionStorage.getItem("PersonID"),
      };
    } else {
      loginUrl = getAPIURL(REACT_APP_UPDATEERRORCATEGORIES);
      body = {
        ErrorCategoryName: errorCategoryNameRef.current.value,
        DisplayOrder: displayOrderRef.current.value,
        Remarks: RemarksRef.current.value,
        IsDeleted: isDeletedRef.current.checked,
        ErrorCategoryID: errorCategoryIdRef.current,
        UpdatedBy: sessionStorage.getItem("PersonID"),
      };
    }
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // debugger;
          addToast("Error category saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          GetErrorCategories();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const columns = [
    {
      title: "Error Category Name",
      field: "errorCategoryName",
    },
    {
      title: "Display Order",
      field: "displayOrder",
    },
    {
      title: "Remarks",
      field: "remarks",
    },
    {
      title: "IsDeleted",
      field: "isDeleted",
      render: (rowData) => (
        <>
          <input
            type="checkbox"
            id="chkIsDeleted"
            disabled
            checked={rowData["isDeleted"]}
          />
        </>
      ),
    },
  ];

  const editErrorCategory = (item) => {
    // debugger;
    errorCategoryNameRef.current.value = item.errorCategoryName;
    errorCategoryIdRef.current = item.errorCategoryID;
    displayOrderRef.current.value = item.displayOrder;
    RemarksRef.current.value = item.remarks;
    isDeletedRef.current.checked = item.isDeleted;
    ButtonTextRef.current.value = "Update";
    setbuttonText("Update");
  };

  const DeleteErrorCategory = (row) => {
    if (row.isDeleted) {
      addToast(
        "Error Category - " + row.errorCategoryName + " is already deleted.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      return false;
    }

    if (
      window.confirm(
        "Are you sure you want to delete the Error Category - " +
          row.errorCategoryName +
          " ?"
      )
    ) {
      let body = {
        ErrorCategoryID: row.errorCategoryID,
        UpdatedBy: sessionStorage.PersonID,
      };
      let loginUrl = getAPIURL(REACT_APP_DELETEERRORCATEGORIES);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // debugger;
            addToast("Error category deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            GetErrorCategories();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const getAdvanceSearchField = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWITEMFIELDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setWorkFlowWorkItemFields(result.data._listWorkItemFields);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  useEffect(() => {
    for (let index = 1; index <= 30; index++) {
      setdisplayOrderArry((oldArray) => [...oldArray, index]);
    }
    GetErrorCategories();
    setsearchDivVisible(true);
    setisLoadingFigma(false);
    ButtonTextRef.current.value = "Save";
    setbuttonText("Save");
  }, []);

  const GetErrorCategories = () => {
    let ErrorCategoryURL = getAPIURL(REACT_APP_GETERRORCATEGORIES);
    let req1 = new Request(`${ErrorCategoryURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      // body: JSON.stringify(body1),
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setisLoadingFigma(false);
          updateColumns(Object.keys(result.data[0]));
          setsearchWorkitemData(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const NavElements = useMemo(() => {
    return (
      <>
        <Header onpreferenceShow={null} pageName="Error Category" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav selectedMenu="admin" />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}

      <div className="centerSection">
        <div className="pageBody ">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <h4 className="mb-4">Add/Edit Error Category</h4>

              <div className="row align-items-end">
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Error Category Name*</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      //value={errorCategoryName}
                      ref={errorCategoryNameRef}
                      //onChange={getWorkitemId}
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="mb-3">
                    <label className="form-label">Display Order</label>
                    <select
                      className="form-control form-control-sm"
                      ref={displayOrderRef}
                    >
                      <option>Select</option>
                      {displayOrderArry.map((state) => (
                        <option value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-3" style={{ height: "81px" }}>
                  <div className="mb-2">
                    <label className="form-label">Remarks</label>
                    <textarea
                      name="Text1"
                      cols="40"
                      rows="2"
                      maxLength="4000"
                      ref={RemarksRef}
                    ></textarea>
                  </div>
                </div>

                <div className="col-1" />

                <div className="col-3" style={{ height: "81px" }}>
                  <div className="mb-1">
                    <label className="form-label">Is Deleted</label>
                    <input
                      type="checkbox"
                      id="chkIsDeletedForm"
                      ref={isDeletedRef}
                    />
                  </div>
                </div>
              </div>

              {/* <hr className="mb-4" /> */}
              <hr className="mb-4" />
              <div className="fieldSet">
                <div className="row">
                  <div className="col-6">
                    <div className="mb-2"></div>
                  </div>
                  <div className="col-6">
                    <div className="mb-2 text-end">
                      <button
                        type="button"
                        className="btn btn-sm btn-link"
                        onClick={resetForm}
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary ms-3"
                        ref={ButtonTextRef}
                        onClick={() => {
                          saveErrorCategoryData();
                        }}
                      >
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Error Categories</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <EditIcon />,
                            tooltip: "Edit Error Category",
                            onClick: (event, rowData) =>
                              editErrorCategory(rowData),
                            position: "row",
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) =>
                              DeleteErrorCategory(rowData),
                            position: "row",
                          },
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [10, 20, 50, 100],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,

                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            // height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            //                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          headerStyle: {
                            // fontFamily: '"Yantramanav", sans-serif',
                            // fontWeight: "inherit",
                            // fontSize: "14px",
                            // textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        columns={columns}
                        icons={tableIcons}
                        data={searchWorkitemData}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
        {/* <a onClick={dailyTransShow} className="btn btn-outline-primary mb-3">
          Show Sub Process Summary
        </a>
        <a onClick={activitySumShow} className="btn btn-outline-primary mb-3">
          Employee Activity Summary
        </a> */}
      </div>
      <Footer />
    </>
  );
};

export default React.memo(ErrorCategory);
