import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import LoadingSpinner from "../../../../LoadingSpinner";

import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import NavDataContext from "../../../../contexts/NavDataContext";



import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ToastProvider, useToasts } from "react-toast-notifications";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DownloadExcel,ArrayWithoutExtraColumn } from "../../Comman/ExcelFileDownload";
function SubprocessUserClassificationMapping() {
  const { addToast } = useToasts();
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_EMPLOYEETIMEUTILIZATIONDETAILS } = process.env;
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  const [EmployeeBreakDetails, setEmployeeBreakDetails] = useState([]);
  const [EmployeeActivityDetails, setEmployeeActivityDetails] = useState([]);
  const [EmployeeDetails, setEmployeeDetails] = useState([]);
  const [locationClientID, setlocationClientID] = useState("");
  const [DateTimeFrom, setDateTimeFrom] = useState("");
  const [DateTimeTo, setDateTimeTo] = useState("");
  const [loggedInPersonID, setloggedInPersonID] = useState("");
  const [selectedEmployeeID, setselectedEmployeeID] = useState("");
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [GridColLen, setGridColLen] = useState(0);
  const [columns, setcolumns] = useState([]);
  const [isLoading, set_IsLoading] = useState(false);
  let navData = [];
  let columnEmployeeActivity = [];
  let currentDate ='';
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
    root: {
      flexGrow: 1,
      marginTop: 30,
      display: "flex",
      oveflow: "auto",
      maxWidth: "100%",
    },
  });

  const columns_Time_Utilization = [
    {
      title: "Sub Process Name",
      field: "subProcessName",
      width: "20%",
      //size: 500, //small column
    },
    {
      title: "Process Name",
      field: "processName",
    },
    {
      title: "HRIS Employee ID",
      field: "hrisEmployeeID",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Activity Name",
      field: "activityName",
      width: "20%",
    },
    {
      title: "Break Type",
      field: "breakType",
    },
    {
      title: "Date",
      field: "date",
    },
    {
      title: "Break Start Time",
      field: "breakStartTime",
    },
    {
      title: "Total Time Spent",
      field: "totalTimeSpent",
    },
    {
      title: "Comment",
      field: "comment",
      width: "20%",
    },
  ];

  const setselectedEmployee_ID = (e) => {
    // alert(e.target.value);
    setselectedEmployeeID(e.target.value);
  };

  const handleApply = (event, picker) => {
    setDateTimeFrom(picker.startDate.format("MM/DD/YYYY"));
    setDateTimeTo(picker.endDate.format("MM/DD/YYYY"));
  };

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const GetPriortyForSubProcess = () => {
    setEmployeeBreakDetails([]);
    setEmployeeActivityDetails([]);
    setEmployeeDetails([]);
    set_IsLoading(true);
    let body = {
      loggedInPersonID: sessionStorage.getItem("PersonID"),
      dateFrom: DateTimeFrom, //"11/01/2022",
      dateTo: DateTimeTo, //"11/24/2022",
      selectedEmployeeID: selectedEmployeeID, //,"190951"
    };
    let activityUrl = getAPIURL(REACT_APP_EMPLOYEETIMEUTILIZATIONDETAILS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        // debugger;
        if (result.status == 200) {
          setEmployeeDetails(result.data._list_Employee_Details);
          if (result.data._list_Employee_Break_Details.length > 0) {
            setEmployeeBreakDetails(result.data._list_Employee_Break_Details);
          } else {
            setEmployeeBreakDetails([]);
          }
          if (result.data._list_Employee_Activiti_Details.length > 0) {
            // debugger;
            //setDashboardGridDataModels(result.data.dashboardGridDataModels);
            //   setGridColLen(result.data._list_Employee_Activiti_Details.length);

            updateColumns(
              Object.keys(result.data._list_Employee_Activiti_Details[0])
            );
            setEmployeeActivityDetails(
              result.data._list_Employee_Activiti_Details
            );
          } else {
            setEmployeeActivityDetails([]);
          }
          set_IsLoading(false);
        }
        set_IsLoading(false);
      })
      .catch((error) => {
        set_IsLoading(false);

        console.log("ERROR: ", error);
      });
  };
  const updateColumns = (keys) => {
    console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach((item) => {
      col.push({ title: item, field: item });
    });
    //col.pop();
    setcolumns(col);
  };

  useEffect(() => {
    // debugger;
    const current = new Date();
    const startdate = `${current.getMonth() + 1}/${
      current.getDate() - 1
    }/${current.getFullYear()}`;
    const enddate = `${
      current.getMonth() + 1
    }/${current.getDate()}/${current.getFullYear()}`;

    setDateTimeFrom(startdate);
    setDateTimeTo(enddate);
    setloggedInPersonID(sessionStorage.getItem("PersonID"));

    GetPriortyForSubProcess();
  }, []);

  const searchResultClose = () => {
    GetPriortyForSubProcess();
  };

  const resetForm = () => {
    const current = new Date();
    const startdate = `${current.getMonth() + 1}/${
      current.getDate() - 1
    }/${current.getFullYear()}`;
    const enddate = `${
      current.getMonth() + 1
    }/${current.getDate()}/${current.getFullYear()}`;

    setDateTimeFrom(startdate);
    setDateTimeTo(enddate);
    //setselectedEmployeeID("Select");
    // setloggedInPersonID(sessionStorage.getItem("PersonID"));

    GetPriortyForSubProcess();
  };

  const downloadExcel = (condition) => {  
    columnEmployeeActivity = [];  
    if (condition == "Employee Break") {
      EmployeeBreakDetails.forEach((data) => {
      columnEmployeeActivity.push({
        SubProcessName: data.subProcessName,
        ProcessName: data.processName,
        HRISEmployeeID: data.hrisEmployeeID,
        Name: data.name,
        ActivityName: data.activityName,
        BreakType: data.breakType,
        Date: data.date,
        BreakStartTime: data.breakStartTime,
        TotalTimeSpent: data.totalTimeSpent,
        Comment: data.comment,
      });
    });
      DownloadExcel(columnEmployeeActivity,"EmployeeBreakDetails");
    } else {
      const colToRemove = new Set(["tableData"]);
      const sheetData = ArrayWithoutExtraColumn(EmployeeActivityDetails,colToRemove);
      DownloadExcel(sheetData,"EmployeeActivityDetails");
    }
  };
  
  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Employee Activity Details"
        timeCaptureActivityName="Work Item Management"
      />
      <DashboardContext.Provider value={processingDashboardParam.nav}>
        <NavDataContext.Provider value={navData}>
          <Nav selectedMenu="activities" />
        </NavDataContext.Provider>
      </DashboardContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-1">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4" id="SearchArea">
                  <div className="">
                    <label className="form-label">Date From - To</label>
                    <DateRangePicker
                      initialSettings={{
                        autoApply: true,
                        autoUpdateInput: false,
                        opens: "right",
                        showDropdowns:true,
                        timePicker: false,
                        locale: { format: "M/DD/YY hh:mm A" }
                      }}
                      onApply={handleApply}
                    >
                      <input
                        id="txtDateSelector"
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Select Date"
                        value={DateTimeFrom + "-" + DateTimeTo}
                        />
                    </DateRangePicker>
                  </div>
                </div>
                <div className="col-4">
                  <div className="">
                    <label className="form-label">Employees</label>

                    <select
                      id="ddlEmployeeList"
                      className="form-control form-control-sm"
                      onChange={setselectedEmployee_ID}
                    >
                      <option>Select</option>
                      {EmployeeDetails.map((Emp) => (
                        <option
                          value={Emp.employeeID}
                          selected={
                            Emp.employeeID == sessionStorage.getItem("PersonID")
                          }
                        >
                          {Emp.employeeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4 text-end">
                  <div className="">
                    <button
                      className="btn btn-sm btn-primary ms-2"
                      onClick={resetForm}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-sm btn-primary ms-2"
                      onClick={searchResultClose}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-12">
                            <label className="form-label">Display of Employee Activity Details</label>
                            <small>1 out of 1 records displayed</small>
                        </div> */}
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {/* <h4>Display of Employee Break Details</h4> */}
              {/* <small className="noteText ms-3 mb-2">0 out of 0 records displayed</small> */}
              <div className="dashBoxs mb-4">
                <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h6>Employee Break Details</h6>
                      {/* //<h5>Processing Dashboard</h5> */}
                    </div>
                    <div className="col-8">
                      <div
                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                        style={{ marginTop: "-10px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            downloadExcel("Employee Break");
                          }}
                        >
                          <b></b>Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <table
                  className="table table-borderless MatTable"
                  style={{ zIndex: "1", position: "relative" }}
                >
                  {/* <ThemeProvider theme={theme}> */}
                  <MaterialTable
                    localization={{
                      toolbar: {
                        // nRowsSelected: '{0} row(s) selected'
                        exportCSVName: "Export as Excel",
                        exportPDFName: "Export as pdf",
                      },
                    }}
                    title="Display of Employee Break Details"
                    options={{
                      search: false,
                      overflowY: false,
                      showTitle: false,
                      cellStyle: {
                        // width: 100,
                        // minWidth: 100,
                      },
                      headerStyle: {
                        position: "sticky",
                        // top: "-5px",
                      },
                      rowStyle: {
                        fontFamily: '"Yantramanav", sans-serif',
                      },
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />,
                      Toolbar: (props) => (
                        <div
                          style={{
                            backgroundColor: "rgb(206 206 206)",
                            fontSize: "0.75em",
                            maxHeight: "50px",
                          }}
                        >
                          <MTableToolbar
                            {...props}
                            style={{
                              fontSize: "5px",
                            }}
                          />
                        </div>
                      ),
                    }}
                    columns={columns_Time_Utilization}
                    icons={tableIcons}
                    data={EmployeeBreakDetails}
                  />
                  {/* </ThemeProvider> */}
                </table>
              </div>
              <div className="dashBoxs">
                <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h6>Employee Activity Details</h6>
                      {/* //<h5>Processing Dashboard</h5> */}
                    </div>
                    <div className="col-8">
                      <div
                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                        style={{ marginTop: "-10px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            downloadExcel("Employee Activity");
                          }}
                        >
                          <b></b>Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table
                      className="table table-borderless MatTable"
                      style={{ zIndex: "1", position: "relative" }}
                    >
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '50-51 of 100',
                            labelRowsPerPage: "{50, 100, 250,500}",
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            exportCSVName: "Export as Excel",
                            exportPDFName: "Export as pdf",
                          },
                        }}
                        title="Display of Employee Activity Details"
                        options={{
                          search: false,
                          overflowY: false,
                          showTitle: false,
                          cellStyle: {
                            // width: 100,
                            // minWidth: 100,
                          },
                          headerStyle: {
                            position: "sticky",
                            // top: "-5px",
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                          },
                        }}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                                maxHeight: "50px",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{ fontSize: "5px" }}
                              />
                            </div>
                          ),
                        }}
                        columns={columns}
                        icons={tableIcons}
                        data={EmployeeActivityDetails}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default SubprocessUserClassificationMapping;
