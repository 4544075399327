import React, { useState, useEffect } from "react";

import { useNavigate, useLocation, Link } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import "react-datepicker/dist/react-datepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import tableIcons from "src/MaterialTableIcons";
import capacityplannerbg from "src/assets/images/bg/Capacity-Planner.jpg";
import usericon from "src/assets/images/icons/user.png";
import MaterialTable from "material-table";
import { render } from "@testing-library/react";
import { useCallback } from "react";

const CapacityPlanner = () => {
  const [plannerData, setPlannerData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [GridColLen, setGridColLen] = useState(0);
  const {
    REACT_APP_API_URL,

    REACT_APP_GetPlannerDashboard,
  } = process.env;

  useEffect(() => {
    setcolumns([]);
    getCapacityPlannerData();
  }, []);
  // const location = useLocation();
  // const processingDashboardParam = location.state;
  // let navigate = useNavigate();
  // let navData = [];
  // if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
  //   navData = processingDashboardParam.assignedWorkItemDetails;
  // } else {
  //   navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  // }
  const listEmailDetailsColumns = [
    {
      title: "Team Members",
      field: "teamMembers",
    },
    {
      title: "Process ~ Subprocess",
      field: "processSubProcess",
    },
    {
      title: "Total Scheduled Hours",
      field: "totalScheduledHours",
    },
    {
      title: "Available Hours",
      field: "availableHours",
    },
    {
      title: "Intake Capacity",
      field: "intakeCapacity",
    },
    {
      title: "Actually assigned",
      field: "actuallyAssigned",
    },
    {
      title: "Capacity variance",
      field: "capacityVariance",
    },
    {
      title: "View",
      field: "",
      render: () => {
        <Link to="/ActivityScreen" state={{}}></Link>;
      },
    },
  ];
  const updateColumns = useCallback(
    (keys) => {
      debugger;

      let col = [];
      console.log(keys);
      keys.forEach((item) => {
        //console.log(item)
        if (item === "employeeID") {
          col.push({
            title: "Scheduler View",
            field: item,

            render: (row) => (
              <>
                <Link
                  to="/CapacityScheduler"
                  state={{
                    selectedSubprocess,
                    nav,
                    userRoles,
                    assignedWorkItemDetails: navData,
                    empidfilter: row[item],
                  }}
                >
                  Click to view
                </Link>
              </>
            ),
            editable: false,
          });
        } else if (
          item === "subprocessId" ||
          item === "userId" ||
          item === "tableData"
        ) {
          // hide this columns
        } else {
          col.push({
            title: item,
            field: item,
          });
        }
      });
      //col.pop();
      setcolumns(col);
    },
    [GridColLen]
  );
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const getCapacityPlannerData = async () => {
    var selectedEntity = JSON.parse(
      sessionStorage.getItem("selectedEntitiesDetail")
    );

    let body = {
      loggedInPersonID: sessionStorage.getItem("PersonID"),

      weekStart: new Date().getDate(),

      clientId: selectedEntity.clientID.toString(),
    };
    let loginUrl = getAPIURL(REACT_APP_GetPlannerDashboard);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then(async (result) => {
        if (result.status == 200) {
          console.log(result);
          if (result.data.length > 0) {
            debugger;
            setGridColLen(result.data.length);
            setPlannerData(result.data);
            updateColumns(Object.keys(result.data[0]));
          } else {
            addToast("No records", {
              appearance: "info",
              autoDismiss: true,
            });
          }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          // BodydetailsinLog(error.toString() + " FetchSubprocessName ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };

  const location = useLocation();
  const processingDashboardParam = location.state;
  let navigate = useNavigate();
  const { addToast } = useToasts();
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  return (
    <>
      <Header onpreferenceShow={null} pageName="Capacity Planner" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="timeBreak">
            <div className="dashBoxs">
              <div className="formWrap">
                <div className="container mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-2">
                        <h2>Capacity Planner</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-8">
                      <div className="tableWrap">
                        <div className="tableWrap-body MatTable">
                          <MaterialTable
                            title="Emails"
                            options={{
                              search: false,
                              showTitle: false,
                              cellStyle: {
                                // width: 100,
                                // minWidth: 100,
                                whiteSpace: "Normal",
                              },
                              headerStyle: {},
                              rowStyle: {
                                //  fontFamily: '"Yantramanav", sans-serif',
                              },
                            }}
                            columns={columns}
                            icons={tableIcons}
                            data={plannerData}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="alert alert-info" role="alert">
                        <h4 className="alert-heading">
                          Under Capacity: 1{"  |  "}Over Capacity: 0
                        </h4>

                        <p className="mb-0">
                          This is the item which is fully utilized or under
                          utilized.
                        </p>
                      </div>
                      {/* <Link
                        to="/CapacityScheduler"
                        style={{ float: "right" }}
                        className="btn btn-secondary justify"
                      >
                        <b className="material-symbols-outlined ">
                          calendar_clock
                        </b>{" "}
                        Go to scheduler
                      </Link> */}
                      <img
                        src={capacityplannerbg}
                        // style={{ height: "400px" }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form"></ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CapacityPlanner;
