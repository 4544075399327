import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import pdfViewer from "../../../../assets/images/placeholder/pdf_viewer.jpg";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import EditWorkActivityDetailsPage from "../manageworkitem/EditWorkActivityDetailsPage";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import Iframe from "react-iframe";
import Tooltip from "react-bootstrap/Tooltip";
import LoadingSpinner from "../../../../LoadingSpinner";
import "react-table-filter/lib/styles.css";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { unstable_composeClasses } from "@mui/material";
import { Rowing } from "@mui/icons-material";
import FormContext from "../../../../contexts/FormContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Link, Navigate } from "react-router-dom";
import { Toast } from "bootstrap";
import { TbListDetails } from "react-icons/tb";
import ReactTooltip from "react-tooltip";
import xtype from "xtypejs";
import AttachmentIcon from "@mui/icons-material/Attachment";

const Process = () => {
  const { addToast } = useToasts();
  const location = useLocation();
  let navigate = useNavigate();
  const { REACT_APP_EDIT_WORKITEMDETAILS } = process.env;
  const { REACT_APP_GETSCREENDATAFORMULITPLEWORKITEMS } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID } = process.env;
  const { REACT_APP_SUBMITANDCONTINUEWORKITEM } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_Image_URL } = process.env;
  const { REACT_APP_DELETEWORKITEMATTACHMENT } = process.env;
  const { REACT_APP_UPLOADPATHNAME } = process.env;
  //Added by Pradip Rai
  const { REACT_APP_Image_URL_HUBBELL } = process.env;
  const { REACT_APP_HUBBELL_CLIENTID } = process.env;
  const { REACT_APP_Image_URL_GEC } = process.env;
  const { REACT_APP_GEC_CLIENTID } = process.env;
  //Ended by Pradip Rai
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_HOST } = process.env;
  const { REACT_APP_SAVEDATAFORMULTIPLEWORKITEMS } = process.env;
  const { REACT_APP_SAVEWORKITEM } = process.env;

  const { REACT_APP_FETCHONBASEDOCUMENTTYPE } = process.env;
  const { REACT_APP_FETCHONBASEFILEUPLOAD } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  let interval = null;
  const [isLoading, set_IsLoading] = useState(false);
  const [documentTypeValue, setdocumentTypeValue] = useState([]);
  const [isDivUploadVisible, setisDivUploadVisible] = useState(false);
  const [regGroup, setregGroup] = useState([]);
  const [workItemStatus, setworkItemStatus] = useState([]);

  const [SubmitAndContinueDisabled, setSubmitAndContinueDisabled] =
    useState(false);

  const [divStatusVisible, setdivStatusVisible] = useState(false);

  const [fieldClassName, setfieldClassName] = useState("col-4");
  const [divClassName, setdivClassName] = useState("splitfull");

  const [JsonValues, setJsonValues] = useState([]);
  const [NeedRefresh, setNeedRefresh] = useState(false);

  const [IsAssignedToRequired, setIsAssignedToRequired] = useState(false);
  const [workAllocationMode, setworkAllocationMode] = useState("false");
  const [isReasonshow, setisReasonshow] = useState(false);
  const [isDivWorkItemVisible, setisDivWorkItemVisible] = useState(false);
  // const [screenFilterValue, setscreenFilterValue] = useState(false);
  const [employeesForSubprocessActivity, setemployeesForSubprocessActivity] =
    useState([]);
  const [statusReasonForSubprocess, setstatusReasonForSubprocess] = useState(
    []
  );
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [isReason, setReason] = useState(false);
  const [isComment, setisComment] = useState(false);
  const [statusAssignedTo, setstatusAssignedTo] = useState("");
  const [statusReason, setstatusReason] = useState([]);
  const [statusComment, setstatusComment] = useState("");
  const [assignTo, setAssignTo] = useState(false);
  const [nextWorkItemId, setnextWorkItemId] = useState("");
  const [inputvaluesBody, setinputvaluesBody] = useState([]);
  const [workitem_Id, setworkitem_Id] = useState(0);
  const [hfActivityName, sethfActivityName] = useState("");
  const [ddlStatusId, setddlStatusId] = useState(0);
  const [hdnOverrideUniqueCheck, sethdnOverrideUniqueCheck] = useState("0");
  const [ddlWorkItemTypeValue, setddlWorkItemTypeValue] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [UploadingFile, setUploadingFile] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploadOrAppend, setIsUploadOrAppend] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [UploadDocumetId, setUploadDocumetId] = useState(0);
  const [timerStart, settimerStart] = useState("");
  const [btnShowBreakPanelDisabled, setbtnShowBreakPanelDisabled] =
    useState(false);
  const [btnShowBreakPanelColor, setbtnShowBreakPanelColor] = useState("Gray");
  const [ddlActivityEnabled, setddlActivityEnabled] = useState(false);
  const [objdataview, setobjdataview] = useState([]);
  const [ddlActivitySelectedValue, setddlActivitySelectedValue] = useState(0);
  const [txtBreakCommentsEnabled, settxtBreakCommentsEnabled] = useState(false);
  const [timerValue, settimerValue] = useState(0);
  const [hdnSystemBreakValue, sethdnSystemBreakValue] = useState("");
  const [imgActivityToolTip, setimgActivityToolTip] = useState("");
  const [tSaveTimerEnabled, settSaveTimerEnabled] = useState(false);
  const [BtnCanceled, setBtnCanceled] = useState(false);
  const [breakStartTime, setbreakStartTime] = useState("");
  //Added by Pradip Rai on 27/12/2022
  const [countDownTimeDisabled, setCountDownTimer] = useState(false);
  const [ActivityStandardTime, setActivityStandardTime] = useState("");
  const [barvalue, setbarvalue] = useState(0);
  const [timer, setTimer] = useState(0);
  const countRef = React.useRef(null);
  //End by Pradip Rai
  const [btnStartWorkTimerDisabled, setbtnStartWorkTimerDisabled] =
    useState(false);
  const [employeeTimeUtilization, setemployeeTimeUtilization] = useState([]);
  const [activityName, setactivityName] = useState(
    sessionStorage.getItem("activityName")
  );
  const [workflowStateID, setworkflowStateID] = useState(
    sessionStorage.getItem("workflowstateId")
  );
  const [AttchmentModal, setAttchmentModal] = useState(false);

  const [IFrameURL, setIFrameURL] = useState("");

  const modeRef = React.useRef(null);
  const actRef = React.useRef(null);
  const selectedTaskRef = React.useRef(null);
  const viewTypeRef = React.useRef(null);
  const currentactivityRef = React.useRef(null);
  const ViewTypeSelectedRef = React.useRef(null);
  const currentStatusRef = React.useRef(null);
  const currentlyWithRef = React.useRef(null);
  const performedByRef = React.useRef(null);
  const showOnlyUnassignedRef = React.useRef(null);

  const listWorkItemHistoryColumns = [
    {
      title: "From State",
      field: "fromState",
    },
    { title: "Status Name", field: "statusName" },
    { title: "To State", field: "toState" },
    { title: "Status By", field: "statusedBy" },
    { title: "Status DateTime", field: "statusDateTime" },
    { title: "Status Comment", field: "statusComment" },
    { title: "Status Reason", field: "statusReasons" },
    { title: "Time Zone", field: "timeZone" },
  ];

  const onModeClick = (e) => {
    modeRef.current.classList.toggle("show-hide");
    actRef.current.classList.toggle("active");
    accRef.current.classList.remove("show-hide1");
    activeRef.current.classList.remove("active");
  };
  const accRef = React.useRef(null);
  const activeRef = React.useRef(null);
  const onToggleClick = (e) => {
    accRef.current.classList.toggle("show-hide1");
    activeRef.current.classList.toggle("active");
    modeRef.current.classList.remove("show-hide");
    actRef.current.classList.remove("active");
  };

  // MODAL ONCLICK TOGGLE FUNCTION
  const [breakModal, setShow] = useState(false);

  const breakClose = () => setShow(false);
  const breakShow = () => setShow(true);

  const [workitemModal, setShow1] = useState(false);

  const workitemClose = () => setShow1(false);
  const workitemShow = () => setShow1(true);
  const showAttachmentDetails = () => {
    setAttchmentModal(true);
  };
  const AttachmentClose = () => {
    setAttchmentModal(false);
  };
  let processingDashboardParam = [
    {
      row: [],
      selectedSubprocess: "",
      nav: [],
      userRoles: [],
      defaultFilter: "",
      assignedWorkItemDetails: [],
    },
  ];
  //if (sessionStorage.getItem("is_Authenticated") == "true") {
  processingDashboardParam = location.state;
  const selectedWorkItemID = processingDashboardParam.workItemID;
  const subprocessID = processingDashboardParam.subprocessID;
  const personID = processingDashboardParam.personID;
  const multipleWorkItemIDs = processingDashboardParam.multipleWorkItemIDs;
  const fromDate = processingDashboardParam.fromDate;
  const toDate = processingDashboardParam.toDate;
  const viewType = processingDashboardParam.viewType;
  const selectedTask = processingDashboardParam.selectedTask;

  viewTypeRef.current = processingDashboardParam.viewType;
  selectedTaskRef.current = processingDashboardParam.selectedTask;
  currentactivityRef.current = processingDashboardParam.activityName;
  ViewTypeSelectedRef.current = processingDashboardParam.ViewTypeSelected;
  currentStatusRef.current = processingDashboardParam.currentWorkItemStatus;
  currentlyWithRef.current = processingDashboardParam.currentlyWith;
  performedByRef.current = processingDashboardParam.performedBy;
  showOnlyUnassignedRef.current = processingDashboardParam.showOnlyUnassigned;

  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  // debugger;
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const handelSave = (e) => {
    set_IsLoading(true);
    e.preventDefault();
    let inputalues = [];
    let isSubmit = true;
    let x = xtype("55.00");

    if (JsonValues.length >= 0) {
      JsonValues.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          // if (
          //   element.screenFields[i].value === "" &&
          //   element.screenFields[i].dataType === "System.Int32"
          // ) {
          //   element.screenFields[i].value = "0";
          // }
          // if (
          //   element.screenFields[i].required &&
          //   element.screenFields[i].value === ""
          // ) {
          //   addToast(element.screenFields[i].labelText + " is required!", {
          //     appearance: "error",
          //     autoDismiss: true,
          //   });
          //   isSubmit = false;
          // } else 
          if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          // if (
          //   element.screenFields[i].value === "" &&
          //   element.screenFields[i].dataType === "System.Int32"
          // ) {
          //   element.screenFields[i].value = "0";
          // }
          // if (
          //   element.screenFields[i].required &&
          //   element.screenFields[i].value === ""
          // ) {
          //   addToast(element.screenFields[i].labelText + " is required!", {
          //     appearance: "error",
          //     autoDismiss: true,
          //   });
          //   isSubmit = false;
          // } else 
          if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    }
    // if (statusChange == "") {
    //   addToast("Status is required!", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   isSubmit = false;
    // }
    if (ddlWorkItemTypeValue == "Select") {
      addToast("Workitem Type is required!", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (ddlWorkItemTypeValue == "Select") {
      addToast("Workitem Type is required!", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (
      isReasonshow &&
      statusReasonForSubprocess.length > 0 &&
      statusReason == ""
    ) {
      addToast("Status reason is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }

    if (
      workAllocationMode === "Manual" &&
      statusAssignedTo === "" &&
      employeesForSubprocessActivity.length > 0
    ) {
      addToast("Please select a relevant value from the Assigned To field.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (isComment && statusComment == "") {
      addToast("Status Comment is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (isSubmit) {
      let workItemIDInputParam =
        nextWorkItemId == ""
          ? +processingDashboardParam.workItemID
          : nextWorkItemId;
      let body = {
        activityID: +processingDashboardParam.activityId,
        workItemID: +workItemIDInputParam,
        personID: +sessionStorage.getItem("PersonID"),
        isddlWorkItemTypeVisible: true,
        workItemType: ddlWorkItemTypeValue,
        hdnMapToField: "",
        hdnMapToTable: "",
        workflowStateID: +processingDashboardParam.nav[0].workflowStateID,
        isdivWorkItemTypeVisible: isDivWorkItemVisible,
        ischeckReadonly: true,
        inputList: inputalues,
      };
      let loginUrl = getAPIURL(REACT_APP_SAVEWORKITEM);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            set_IsLoading(false);
            //backToDashBoard()
          } else if (result.status === 401) {
            // console.log("ERROR: ", error);
            navigate("/");
          } else {
            addToast("Error occured11!", {
              appearance: "error",
              autoDismiss: true,
            });
            set_IsLoading(false);
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //navigate("/");
          //}
        });
    } else {
      set_IsLoading(false);
    }
  };
  const handelSubmitAndContinue = (e) => {
    set_IsLoading(true);
    e.preventDefault();
    let inputalues = [];
    let isSubmit = true;
    setstatusComment("");
    if (JsonValues.length >= 0) {
      JsonValues.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if(element.screenFields[i].dataType === "System.DateTime" &&
              element.screenFields[i].value === "Blank")
              {
                element.screenFields[i].value="01/01/1900";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.String"
          ) {
            element.screenFields[i].value = "";
          }
          // if (
          //   element.screenFields[i].required &&
          //   element.screenFields[i].value === ""
          // ) {
          //   addToast(element.screenFields[i].labelText + " is required!", {
          //     appearance: "error",
          //     autoDismiss: true,
          //   });
          //   isSubmit = false;
          // }
          //  else 
          if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          } else if(element.screenFields[i].value != "") {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if(element.screenFields[i].dataType === "System.DateTime" &&
              element.screenFields[i].value === "Blank")
              {
                element.screenFields[i].value="01/01/1900";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.String"
          ) {
            element.screenFields[i].value = "";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          // if (
          //   element.screenFields[i].required &&
          //   element.screenFields[i].value === ""
          // ) {
          //   addToast(element.screenFields[i].labelText + " is required!", {
          //     appearance: "error",
          //     autoDismiss: true,
          //   });
          //   isSubmit = false;
          // } else 
          if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          } else {
            if(element.screenFields[i].value !== "")
            {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
        }
      });
    }
    // debugger;
    try {
      // if (isSubmit) {
        let body = {
          selectedMultipleWorkitems: multipleWorkItemIDs, // "13179106,13179257,13179260",
          From_Date: fromDate, //"11/1/2018 12:00:00 AM",
          personID: sessionStorage.getItem("PersonID"),
          inputValues: inputalues,
        };

        let loginUrl = getAPIURL(REACT_APP_SAVEDATAFORMULTIPLEWORKITEMS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            // debugger;
            if (result.status === 200) {
              addToast("Record successfully updated", {
                appearance: "success",
                autoDismiss: true,
              });
              backToManageWorkitem();
              set_IsLoading(false);
            } else if (result.status === 500) {
              set_IsLoading(false);
              let errorMessage = result.Message;
              addToast(errorMessage, {
                appearance: "info",
                autoDismiss: true,
              });
            } else if (result.status === 401) {
              //console.log("ERROR: ", error);
              navigate("/");
            } else {
              addToast("Error occured!1", {
                appearance: "error",
                autoDismiss: true,
              });
              set_IsLoading(false);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //navigate("/");
            // }
          });
      }
      //  else {
      //   set_IsLoading(false);
      // }
     catch (ex) {
      set_IsLoading(false);
      addToast("Error occured!2", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handelChange = (id, event, oldElements) => {
    const newElements = { ...oldElements };
    newElements.state.regGroup.forEach((element) => {
      for (let i = 0; i < element.screenFields.length; i++) {
        if (element.screenFields[i].mapToField === id) {
          const value =
            event.target.value !== "Select" ? event.target.value : "";
          element.screenFields[i].value = value;
        }
      }
    });

    setJsonValues(newElements);
  };
  const backToManageWorkitem = () => {
    debugger
    const row = processingDashboardParam.row;
    const selectedSubprocess = processingDashboardParam.selectedSubprocess;
    const nav = processingDashboardParam.nav;
    const userRoles = processingDashboardParam.userRoles;
    const defaultFilter = processingDashboardParam.defaultFilter;
    const assignedWorkItemDetails =
      processingDashboardParam.assignedWorkItemDetails;
      console.log(selectedTaskRef.current);
      console.log(currentactivityRef.current);
      console.log(viewTypeRef.current);
      console.log(ViewTypeSelectedRef.current);

    navigate("/manageworkitem", {
      state: {
        row,
        selectedSubprocess,
        nav,
        userRoles,
        defaultFilter,
        assignedWorkItemDetails: navData,
        selectedWorkItemDetails: navData.find(
          (e) => e.activityName === activityName
        ),
        currentAcitvity: currentactivityRef.current,
        fromDate,
        toDate,
        viewType : viewTypeRef.current,
        selectedTask : selectedTaskRef.current,
        ViewType: ViewTypeSelectedRef.current,
        currentWorkItemStatus: currentStatusRef.current,
        currentlyWith: currentlyWithRef.current,
        performedBy: performedByRef.current,
        showOnlyUnassigned: showOnlyUnassignedRef.current
      },
    });
  };

  useEffect(() => {
    // debugger;
    let body = {
      workItemID: selectedWorkItemID,
      subprocessID: subprocessID,
      personID: personID,
      multipleWorkItemIDs: multipleWorkItemIDs,
      fromDate: fromDate,
    };

    let GetItemDetailsAPIURL = getAPIURL(
      REACT_APP_GETSCREENDATAFORMULITPLEWORKITEMS
    );
    let getitemdetailsreq = new Request(`${GetItemDetailsAPIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(getitemdetailsreq)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          if (
            result.data.exceptionMessage != "" &&
            result.data.exceptionMessage != null
          ) {
            alert(result.data.exceptionMessage);
            backToManageWorkitem();
          }
          else {
            setJsonValues(result.data.screenFieldGroups);
            console.log(multipleWorkItemIDs);
            if(multipleWorkItemIDs.toString().split(",").length !== 1)
            {for(var i=0;i<result.data.screenFieldGroups[0].screenFields.length;i++)
              {
            if(result.data.screenFieldGroups[0].screenFields[i].value !== "")
            {result.data.screenFieldGroups[0].screenFields[i].value = ""}
              }
            }
            setregGroup(result.data.screenFieldGroups.sort((a, b) => a.groupName.localeCompare(b.groupName, undefined, { numeric: true, sensitivity: 'base' })));
            set_IsLoading(false);
          }
        } else if (result.status === 401) {
          if (
            result.message ===
            " WorkItemLocked 'This workitem is locked by another user.'"
          ) {
            addToast(
              "WorkItemLocked 'This workitem is locked by another user!",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          } else {
            navigate("/");
          }
          //console.log("ERROR: ", error);
        } else {
          addToast("WorkItemLocked 'This workitem is locked by another user!", {
            appearance: "error",
            autoDismiss: true,
          });
          //setisComment(false);
          set_IsLoading(false);
          // backToDashBoard();
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
        // navigate("/")
      });
    // .catch((error) => {
    //   if (error.response) {
    //   }
    //   //  alert("Something went wrong!");
    // });

    fetch(getitemdetailsreq)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // set_IsLoading(false);
        } else if (result.status === 401) {
          //console.log("ERROR: ", error);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
    return () => {};
  }, [NeedRefresh]);

  const employeeAssignedForStatusChange = (e) => {
    setstatusAssignedTo(e.target.value);
  };
  const reasonForStatusChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => +option.value);
    setstatusReason(value);
    //setstatusReason(e.target.selectedOptions);
  };
  const commentForStatusChange = (e) => {
    setstatusComment(e.target.value);
  };

  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Bulk Edit"
        timeCaptureActivityName="Work Item Management"
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <FormContext.Provider value={{ onchange: handelChange }}>
        <div className="centerSection">
          <form>
            {/* One Scenario */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="splitScreen">
                  <div className={divClassName}>
                    <div className="fieldBody">
                      <EditWorkActivityDetailsPage
                        state={{ regGroup, fieldClassName }}
                      />
                    </div>
                    <div className="fieldFooter col-4">
                      <div className="row">
                        <div className="col-7 text-start">
                          <button
                            className="btn btn-outline-primary ms-3"
                            disabled={SubmitAndContinueDisabled}
                            title="Click here to submit this workitem and go to the next WorkItem"
                            onClick={handelSubmitAndContinue}
                          >
                            Submit
                          </button>
                          <button
                            className="btn btn-link text-primary "
                            title="Click here to go back to the Dashboard"
                            onClick={backToManageWorkitem}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </FormContext.Provider>
      <Footer />
    </>
  );
};

export default Process;
