import React, { useEffect, useState } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import pdfViewer from "../../../../assets/images/placeholder/pdf_viewer.jpg";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import Iframe from "react-iframe";
import WorkActivityScreenDetailsPage from "./WorkActivityScreenDetailsPage";
import { ToastProvider, useToasts } from "react-toast-notifications";
import LoadingSpinner from "../../../../LoadingSpinner";
import "react-table-filter/lib/styles.css";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { unstable_composeClasses } from "@mui/material";

function Process() {
  const { addToast } = useToasts();
  const location = useLocation();
  let navigate = useNavigate();
  const { REACT_APP_EDIT_WORKITEMDETAILS } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_NAME } = process.env;
  const [isLoading, set_IsLoading] = useState(false);
  const [regGroup, setregGroup] = useState([]);
  const [workItemStatus, setworkItemStatus] = useState([]);
  const [activityname, setactivityname] = useState("");
  const [SubmitDisabled, setSubmitDisabled] = useState(false);
  const [SubmitAndContinueDisabled, setSubmitAndContinueDisabled] =
    useState(false);
  const [SaveDisabled, setSaveDisabled] = useState(false);
  const [SubmitAndAddBlankItemDisabled, setSubmitAndAddBlankItemDisabled] =
    useState(false);
  const [SkipDisabled, setSkipDisabled] = useState(false);
  const [divStatusVisible, setdivStatusVisible] = useState(false);
  const [CancelVisible, setCancelVisible] = useState(false);
  const [allowAdhocAddition, setallowAdhocAddition] = useState(false);
  const [btnBackToDashboardVisible, setbtnBackToDashboardVisible] =
    useState(false);
  const [btnSubmitVisible, setbtnSubmitVisible] = useState(false);
  const [btnSubmitAndHomeVisible, setbtnSubmitAndHomeVisible] = useState(false);
  const [btnCancelVisible, setbtnCancelVisible] = useState(false);
  const [btnSubmitAndAddBlankItemVisible, setbtnSubmitAndAddBlankItemVisible] =
    useState(false);
  const [UploadFilesReadonly, setUploadFilesReadonly] = useState(false);
  const [btnSkipVisible, setbtnSkipVisible] = useState(false);
  const [currentTime, setcurrentTime] = useState("");

  const processingDashboardParam = location.state;
  console.log("param");

  console.log(processingDashboardParam);

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  let disabledStyle = {
    display: "none",
  };
  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    setcurrentTime(strTime);
  };
  useEffect(() => {
    set_IsLoading(true);
    formatAMPM(new Date());
    console.log(formatAMPM(new Date()));
    let body = {
      mode: "",
      userId: sessionStorage.getItem("GUID"),
      applicationName: REACT_APP_NAME,
      subProcessID: processingDashboardParam.selectedSubprocess,
      workItemID: processingDashboardParam.workItemID,
      isSuperUser: true,
      workflowStateID: processingDashboardParam.nav[0].workflowStateID,
      activityID: processingDashboardParam.activityID,
      screenFilter: "",
      screenFilterStatus: false,
      assigned: "",
      differentBatch: "",
      copyWorkItemType: "",
      isAdhoc: true,
      adhocBlankWorkItemAddition: "",
      employeeVersionId: processingDashboardParam.employeeVersionID,
      employeeId: sessionStorage.getItem("PersonID"),
    };
    console.log(body);
    let screenAPIURL = getAPIURL(REACT_APP_EDIT_WORKITEMDETAILS);
    let req = new Request(`${screenAPIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          
          setregGroup(result.data.repGroup.sort((a, b) => a.groupName.localeCompare(b.groupName, undefined, { numeric: true, sensitivity: 'base' })));
          
          setworkItemStatus(result.data.statusDetails);
          setactivityname(result.data.hfActivityName);
          setSubmitDisabled(result.data.isbtnSubmitDisabled);
          setSubmitAndContinueDisabled(
            result.data.isbtnSubmitAndContinueDisabled
          );
          setSaveDisabled(result.data.isbtnSaveDataDisabled);
          setSubmitAndAddBlankItemDisabled(
            result.data.isbtnSubmitAndAddBlankWorkItemDisabled
          );
          setSkipDisabled(result.data.isbtnSkipDisabled);
          setdivStatusVisible(result.data.isdivStatusVisible);
          setCancelVisible(result.data.isbtnCancelVisible);
          setallowAdhocAddition(result.data.allowAdhocAddition);
          setbtnBackToDashboardVisible(result.data.btnBackToDashboardVisible);
          setbtnSubmitVisible(result.data.btnSubmitVisible);
          setbtnSubmitAndHomeVisible(result.data.btnSubmitAndHomeVisible);
          setbtnCancelVisible(result.data.btnCancelVisible);
          setbtnSubmitAndAddBlankItemVisible(
            result.data.btnSubmitAndAddBlankWorkItemVisible
          );
          setUploadFilesReadonly(result.data.isUcUploadFilesReadonly);
          setbtnSkipVisible(result.data.btnSkipVisible);
          set_IsLoading(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  }, []);

  return (
    <>
      <Header />
      <DashboardContext.Provider value={processingDashboardParam.nav}>
        <Nav />
      </DashboardContext.Provider>

      <div className="centerSection">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="splitScreen">
              <div className="splitLeft">
                <div className="fieldBody">
                  <h3 className="mb-4 pt-2 pb-3 px-2">
                    {activityname} :13187794
                  </h3>
                  <h4 style={{ "padding-bottom": "1.5rem" }}>
                    Working on this workitem from: {currentTime.toUpperCase()}
                  </h4>
                  <div></div>
                  <WorkActivityScreenDetailsPage state={regGroup} />
                  <div className="fieldSet mb-4">
                    <h4 className="mb-2"></h4>
                    <div className="row">
                      <div className="col-12">
                        {divStatusVisible ? (
                          <div className="pb-3">
                            <label className="form-label">Status</label>
                            <span id="" className="mandatory">
                              *
                            </span>

                            <select className="form-control form-control-sm">
                              <option>Select</option>
                              {workItemStatus.map((status) => (
                                <option value={status.workItemStatusID}>
                                  {status.statusName}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <div className="pb-3">
                          <label className="form-label">Comment</label>
                          <textarea className="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fieldFooter">
                  <div className="row">
                    <div className="col-6 text-start">
                      {btnCancelVisible ? (
                        <button className="btn btn-outline-primary ms-3">
                          Cancel
                        </button>
                      ) : null}
                      <button
                        className="btn btn-link text-primary"
                        disabled={SaveDisabled}
                        title="Click here to save the updates on this workitem"
                      >
                        Save Data
                      </button>

                      <button
                        className="btn btn-outline-primary ms-3"
                        disabled={SubmitAndContinueDisabled}
                        title="Click here to submit this workitem and go to the next WorkItem"
                      >
                        Submit & Continue
                      </button>

                      {btnSubmitVisible ? (
                        <button
                          className="btn btn-primary ms-3"
                          disabled={SubmitDisabled}
                          title="Click here to submit this workitem and go back to dashboard"
                        >
                          Submit
                        </button>
                      ) : null}
                    </div>
                    <div className="col-6 text-end">
                      {btnBackToDashboardVisible ? (
                        <button
                          className="btn btn-outline-ntv "
                          title="Click here to go back to the Dashboard"
                        >
                          Back to dashboard
                        </button>
                      ) : null}
                      {btnSkipVisible ? (
                        <button
                          className="btn btn-link ms-3"
                          disabled={SkipDisabled}
                        >
                          Skip
                        </button>
                      ) : null}
                      {btnSubmitAndAddBlankItemVisible ? (
                        <button
                          className="btn btn-outline-primary ms-3"
                          disabled={SubmitAndAddBlankItemDisabled}
                          title="Click here to submit this workitem and Add new blank WorkItem"
                        >
                          Submit & Add Blank WorkItem
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="splitRight">
                <Iframe
                  url="http://10.11.102.41:9001/ImageViewer/ImageViewer.aspx"
                  width="850px"
                  height="550px"
                  id="myId"
                  display="initial"
                  position="relative"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Process;
