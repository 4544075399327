
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import * as FileSaver from "file-saver";
import LoadingSpinner from "../../../../LoadingSpinner";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from 'axios';
// import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

function UploadDropdownValues() {
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
  //ABHISHEK
  const { REACT_APP_FETCHWORKITEMFIELDDROPDOWNDATA } = process.env;
  const { REACT_APP_FETCHWORKITEMFIELDSDROPDOWNVALUE } = process.env;
  const { REACT_APP_ADDDROPDOWNVALUE } = process.env;
  const { REACT_APP_DELETEWORKITEMVALUE } = process.env;
  const { REACT_APP_UPDATEWORKITEMVALUE } = process.env;
  const { REACT_APP_UPLOADWORKITEMFIELDSDROPDOWNVALUES } = process.env;
  const { REACT_APP_UPLOADANDPROCESS } = process.env;
  
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const { addToast } = useToasts();
  
 
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [uploadedFile, setuploadedFile] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [selectedFile, setselectedFile] = useState();
  const [UploadingFile, setUploadingFile] = useState("");
  const [ImgURL, setImgURL] = useState(undefined);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  const { REACT_APP_UPLOADCLIENTLOGOFILEDETAILS } = process.env;
  const { REACT_APP_GETWORKITEMATTACHMENTS } = process.env;
  
  //Abhishek Gupta
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [workitemFieldID, setWorkitemFieldID] = useState([]);
  const [workitemField, setWorkitemField] = useState("");
  const WorkitemIdRef = useRef(null);
  const WorkitemFieldIDRef = useRef(null);
  const stringWorkItemsRef = useRef(null);
  const testRef = useRef(null);
  const [isLoading, set_IsLoading] = useState(false);
  const [workitemFields, setWorkitemFields] = useState([]);
  const [tableColumns,setTableColumns] = useState([]);
  const [col,setColumns] = useState([]);
  const [file, setFile] = useState();
  const [uploadedFilePath, setUploadedFilePath] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [SaveDisabled, setSaveDisabled] = useState(true);
  const [UploadDisabled, setUploadDisabled] = useState(true);
  const [rowValue, setCheckvalue] = useState('');

  // const demofunc = useRef(null);

  const handleUpload = () => {
    debugger;
    const formData = new FormData();
    formData.append('file',file);

    let loginUrl = getAPIURL(REACT_APP_UPLOADWORKITEMFIELDSDROPDOWNVALUES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
      //  "Content-Type": "multipart/form-data",
        UserName: sessionStorage.getItem("UserName"),
        Authorization:
        "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData,
    });
    fetch(req)
    .then((res) => res.json())
    .then((res) => {
     debugger;
      if (res.data !== "") {
        uploadExcelDataDetails(res.data);
        // document.getElementById('fileInput').value = '';
        // demofunc.current.value = '';
        //document.querySelector('input[type="file"]').value='';
        setuploadedFile("");
      }
    })
    .catch((error) => {
      console.log("ERROR: ", error);
      //navigate("/");
    });
};

  const uploadExcelDataDetails = (path) => {
     debugger;
    let body = {
      WorkItemFieldID: WorkitemFieldIDRef.current.value,
      ExcelFilePath: path
    };
    let loginUrl = getAPIURL(REACT_APP_UPLOADANDPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    debugger;
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        
         if (result.status === 200) {
          if (result.data != null && result.data.length > 0) {
           // document.getElementById('fileInput').value = '';
           //document.querySelector('input[type="file"]').value='';
           
           
            addToast("Dropdown values uploaded successfully", {
              appearance: "success",
              autoDismiss: true,
            });
           
              set_IsLoading(false);
              setisLoadingFigma(false);
            setTableColumns(toString(result.data));
            FieldsDropdownValue();
           
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
        else if (result.status === 500) {
          addToast(result.message, {
            appearance: "error",
            autoDismiss: true,
          });
          // sessionStorage.clear();
          // sessionStorage.clear();
        }
        if (result != null && result != undefined) {
          if (result.lblRowsUploaded != null) {
            let message = result.lblRowsUploaded.split("</b>")[1];
            addToast(message, {
              appearance: "success",
              autoDismiss: true,
            });
          }
          if (result.litErrors != null) {
            let errorMessage = result.litErrors;
            addToast(errorMessage, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });
 
  const readUploadFile = (e) => {
    debugger;
    setuploadedFile(e.target.value);
    const extension = e.target.files[0].name.split(".").pop();
    const type = e.target.files[0].type;
    
    setFile(e.target.files[0]);
    if (
      extension === "xls" ||
      extension === "xlsx"
    ) {
      if (
        type.includes("text") ||
        type.includes("msword") ||
        type.includes("application/octet-stream") ||
        type.includes("excel")|| 
        type.includes("image") ||
        type.includes("powerpoint") ||
        type.includes("pdf") ||
        type.includes("openxmlformats-officedocument")
      ) {
       
        if (e.target.files[0].size <= 5000000) {
          setUploadingFile(e.target.files[0]);
          setuploadedFile(e.target.value);

          //uploadFileOnServer(e.target.files[0]);
        } else {
          addToast("The file size exceeded the maximum limit of 5 MB.", {
            appearance: "error",
            autoDismiss: true,
            });
        }
      } else {
        addToast("Invalid Format or the file is open.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      setuploadedFile("");
      alert("Upload Failed! File Type is not supported!");
    }

  };

  
  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    setWorkitemFieldID([]);
    setTableColumns([]);
    setSaveDisabled(true);
    setUploadDisabled(true);
    setColumns([]);

    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setWorkitemFields([]);
      setWorkitemFieldID([]);
      setTableColumns([]);
      setSaveDisabled(true);
      setUploadDisabled(true);
      setColumns([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchLocationClientsByGuid = (e) => { 
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    setWorkitemFieldID([]);
      setTableColumns([]);
      setSaveDisabled(true);
      setUploadDisabled(true);
      setColumns([]);
    
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
      setWorkitemFields([]);
      setWorkitemFieldID([]);
      setTableColumns([]);
      setSaveDisabled(true);
      setUploadDisabled(true);
      setColumns([]);
    }
    if (e.target.value !== "Select") {
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
        let body = {
          memberShipGuid: sessionStorage.getItem("GUID"),
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setDepartments(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //navigate("/");
            //}
          });
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    setWorkitemFieldID([]);
    setTableColumns([]);
    setSaveDisabled(true);
    setUploadDisabled(true);
    setColumns([]);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    setWorkitemFieldID([]);
    setTableColumns([]);
    setSaveDisabled(true);
    setUploadDisabled(true);
    setColumns([]);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    //sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);         
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    //debugger;
    setWorkitemFieldID([]);
    setTableColumns([]);
    setSaveDisabled(true);
    setUploadDisabled(true);
    setColumns([]);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
 
  const subprocesOnchange = (SubProcessID) => {
    debugger;
    if( SubProcessID != undefined){
     if(SubProcessID.target.value != "Select" ){
    setWorkitemFieldID([]);
    setTableColumns([]);
    setSaveDisabled(true);
    setUploadDisabled(true);
    setColumns([]);
    FieldDropdownData(SubProcessID.target.value);
    }
    }
  }

  const FieldDropdownData = (subProcessID) => {
      //  debugger;
    let body = {
    //  guid: sessionStorage.getItem("GUID"),
    SubProcessID: subProcessID,
    //SubProcessID: testRef.current.value,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCHWORKITEMFIELDDROPDOWNDATA);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
           debugger;
          setWorkitemFieldID(result.data)
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
 
  const  FieldsDropdownValue = () => {
    setColumns([]);
    debugger;
    if(WorkitemFieldIDRef.current.value != "Select"){
    let arr=[];
    arr.push({title : WorkitemFieldIDRef.current.selectedOptions[0].label, field : "value"})
    setColumns(arr)
    }
    if(WorkitemFieldIDRef.current.value == "Select"){
    setTableColumns([]);
    setSaveDisabled(true);
    setUploadDisabled(true);
    }
   // settest1("");
    debugger;
    let body = {
        WorkItemFieldID: WorkitemFieldIDRef.current.value,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCHWORKITEMFIELDSDROPDOWNVALUE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        set_IsLoading(false);
       
        if (result.status == 200) {
          debugger;
          set_IsLoading(false);
           setisLoadingFigma(false);
          setTableColumns(result.data.valuetable);
          setSaveDisabled(false);
          setUploadDisabled(false);
          
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        set_IsLoading(false);
        console.log("ERROR: ", error);
      });
  };

  const deleteWorkItem = (row) => {
    debugger;
   
    if (row.isDeleted) {
      addToast(
        "Workitem Field Value - " + row.value + " is already deleted.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      return false;
    }
  
    if (
      window.confirm(
        "Are you sure you want to delete the Workitem Field Value - " +
          row.value +
          " ?"
      )
    ) {
      let body = {
        WorkItemFieldID: WorkitemFieldIDRef.current.value,
        stringWorkItems: row.workItemFieldDropdownValueID.toString()
        
      };
      let loginUrl = getAPIURL(REACT_APP_DELETEWORKITEMVALUE);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // debugger;
            addToast("Workitem FieldID deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            FieldsDropdownValue();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const  AddDropDownValue = () => {
  debugger;
 // if (WorkitemIdRef.current.value.Length >= result.data.maxlentable[0].maxlen){
    let body = {
      WorkItemFieldID: WorkitemFieldIDRef.current.value,
      value: WorkitemIdRef.current.value,
    };
    let loginUrl = getAPIURL(REACT_APP_ADDDROPDOWNVALUE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        debugger;
        set_IsLoading(false);
       
          if (result.status == 200) {
            FieldsDropdownValue();
              addToast("WorkItem Field Dropdown Value added successfully",
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
              WorkitemIdRef.current.value = "";
            }
            else if(WorkitemIdRef.current.value === ""){
              addToast("Please enter a Workitem Field Dropdown Value to add", {
                appearance: "error",
                autoDismiss: true,
              }); 
            }
         else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please Login Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          }
          else if(result.status === 406){
            addToast(result.message, {
              appearance: "error",
              autoDismiss: true,
            }); 
          }
          else if(result.status === 205){
            addToast(result.message, {
              appearance: "error",
              autoDismiss: true,
            }); 
          }
         
      })
      .catch((error) => {
        set_IsLoading(false);
        console.log("ERROR: ", error);
      });
  };

  // const UpdateWorkitemValue = () =>{
  //   debugger;
  //   let body = {
  //     WorkItemFieldID: WorkitemFieldIDRef.current.value,
  //     value:WorkitemIdRef.current.value,
  //   };
  //   let loginUrl = getAPIURL(REACT_APP_UPDATEWORKITEMVALUE);
  //   let req = new Request(`${loginUrl}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status == 200) {
  //       //  if(WorkitemIdRef.current.value.length >= result.data.maxlentable[0].maxlen){
  //         if(result.data == WorkitemIdRef.current.value)  {     
  //         addToast("Workitem Field Dropdown value with similar name already exists.Please enter a different value.", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //       }else{
  //       FieldsDropdownValue();
  //       set_IsLoading(false);   
  //       }
  //       } else if (result.status === 401) {
  //         //unauthorized
  //         addToast("Session Expired/UnAuthorized. Please Login Again", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //         sessionStorage.clear();
  //         sessionStorage.clear();
  //         navigate("/");
  //         set_IsLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       set_IsLoading(false);
  //       console.log("ERROR: ", error);
  //     });
  // };
  
  var rows = [], i = 1, len = 200;
while (i <= len)
{
  rows.push(i);
  i++;
}

      const columns = [
        
        {
          title:  "WorkitemFieldIDRef.current.selectedOptions[0].label",
          field: "value",
        },
        
  
      ];

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
    FetchDepartmentbyGUID();
    FieldDropdownData(sessionStorage.getItem("ProcessId"));
   // AddDropDownValue(); 
    FieldsDropdownValue();
  //  UpdateWorkitemValue();
   // deleteWorkItem();
    subprocesOnchange();
    set_IsLoading(true);
    setColumns([]);
    setTableColumns([]);
    setSaveDisabled(true);
  }, []);

  const downloadExcel = () => {
    debugger;
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

      const fileName = "ListOfDropdownvalues";
      const valuesColumn = tableColumns.map(row => ({value: row.value}));

      const ws = XLSX.utils.json_to_sheet(valuesColumn);
      ws["A1"].v = "WorkItemDropdownValues";
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Header onpreferenceShow={null} pageName="Workitem Field Dropdown Values" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      
      {isLoading ? (
        <LoadingSpinner />
      ) : (
      <>
      {/* <div className="centerSection"> */}
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"> Company Department *</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Location Client *</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
  <div className="mb-3">
    <label className="form-label">Process SubProcess *</label>
    <select
      className="form-control form-control-sm"
      onLoadedDataCapture={(e) => subprocesOnchange(e)}
      onLoad={(e) => subprocesOnchange(e)}
      onChange={(e) => subprocesOnchange(e)}
      ref={testRef}
    >
      <option>Select</option>
      {processSubprocess.map((subprocess) => (
        <option
          value={subprocess.subProcessIDString}
          selected={subprocess.subProcessIDString === processSubProcessID}
        >
          {subprocess.processSubProcess}
        </option>
      ))}
    </select>
  </div>
</div>

              </div>
            </div>
          </div>
          <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Workitem Fields *</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={ FieldsDropdownValue}
                      ref={WorkitemFieldIDRef}
                    >
                      <option>Select</option>
                      debugger;
                      {workitemFieldID.map((workitemField) => ( 
                        <option
                        value={workitemField.workItemFieldID}
                        selected={workitemFields.workitemFieldsIDString === workitemFieldID}
                        >
                        {workitemField.labelText}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-1">
                      <div className="mb-3"></div>
                      </div>
                <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Workitem Field Dropdown Value Name </label>
                        <input
                          className="form-control form-control-sm"
                          
                          type="text"
                        //  maxLength = "100"
                          //value={workitemId}
                          ref={WorkitemIdRef}
                          disabled={SaveDisabled}
                        />
                      </div>
                  </div> 
                  <div className="col-1">
                  <div className="mb-3">
                  <button
                                type="button"
                                className="btn btn-sm btn-outline-primary w-100 text-uppercase"
                                 onClick={(e) => {
                                  debugger;
                                 // demo123(e);
                                  AddDropDownValue(e);
                                //  UpdateWorkitemValue();
                                }}
                                ref={stringWorkItemsRef}
                              >
                                {" "}
                                Add
                              </button>
                              </div>
                              </div>
                    </div>
                      </div>
                        </div>
                          </div>
                
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="fieldSet">
                <div className="row align-items-end">
                  <div>
                    <h6>File to Upload</h6>
                   (Note: The Excel should contain a sheet named - Sheet1 with column name WorkItemDropdownValues)
                   
                </div>
                  <div className="col-3">
                    <input 
                      className="form-control form-control-sm"
                      type="file"
                      onChange={(e) =>{
                       readUploadFile(e)
                    }}
                      value={uploadedFile}
                      disabled={UploadDisabled}
                    />
                  </div>
                  <div style={{ paddingTop: "5px" }}>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary "
                      onClick={handleUpload}
                    >
                      Upload
                     
                    </button>
                    {errorMessage && <div>{errorMessage}</div>}
                    {uploadedFilePath && <div>File uploaded successfully at: {uploadedFilePath}</div>}
                  </div>
                </div>
                <div>
                  {IsUpload ? (
                    <img
                      src={`data:image/jpeg;base64,${ImgURL}`}
                      height="100px"
                      width="100px"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
      {/* </div>   */}
                       
       <div>Workitem Field Dropdown Values</div>
       <table
              className="table table-borderless MatTable">
            <ThemeProvider theme={theme}>
                <MaterialTable
                  localization={{
                    pagination: {
                      // labelDisplayedRows: '50-51 of 100',
                      // labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    
                    toolbar: {
                     
                    },
                    header: {
                      actions: ""
                    }
                  }}
                  
                  actions={[
                    
                    {
                      icon: () => <DeleteIcon />,
                      tooltip: "Delete",
                      onClick: (event, rowData) =>
                      deleteWorkItem(rowData),
                      position: "row",
                    },
                  ]}
                  options={{
                    showTitle: false,
                    headerStyle: { position: "sticky", top: 0 },
                    search: false,
                    overflowY: false,
                    showTitle: false,
                    sorting: true,
                    filtering: true,
                    selection: false,
                    tableLayout: "auto",

                    pageSizeOptions: [5, 15, 50, 100],
                    toolbarButtonAlignment: "left",
                 
                    tableLayout: "auto",
                  }}

                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),  
                  }}
                 
                  columns={col}
                  icons={tableIcons}
                  data={tableColumns}
                />
              </ThemeProvider>
            </table>
              <div className="col-3">
                <div className="mb-3">
                  <div
                    style={{ marginTop: "5px",marginLeft: "10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-1"
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                     Export to Excel
                    
                    </button>
                  </div>
                </div>
              </div>
        </div>
            
      <Footer />
    </>
    
  )};
  </> 
  );
}

export default UploadDropdownValues;
