import React, { useState } from 'react';
import { Container, Row, Col, Table, Button,Modal,Form } from 'react-bootstrap';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { useNavigate, useLocation, Link } from "react-router-dom";


import "react-datepicker/dist/react-datepicker.css";
import DashboardContext from "src/contexts/DashboardContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import NavDataContext from "src/contexts/NavDataContext";

import Nav from '../nav/Nav';
import ApplicationUserContext from 'src/contexts/ApplicationUserContext';
import { useEffect } from 'react';

const Help = () => {
  const { REACT_APP_API_URL,REACT_APP_GETDocuments,REACT_APP_DownloadDocumentTest,REACT_APP_DOWNLOADWORKITEMATTACHMENT,
    REACT_APP_AddDocument,
    REACT_APP_UploadNewFileVersion,
    REACT_APP_UpdateDocumentLink
   } = process.env;
  const [documents, setAdocuments] = useState([]);
  const [documentObj, setdocumentObj] = useState([]);
   // Sample document data with versions
   const documents2 = [
    { 
      title: 'Document 1', 
      description: 'This document covers the initial release notes for the software, including key features and bug fixes.',
      versions: [
        { 
          version: 'v1.0', 
          date: '2024-10-01', 
          description: 'Initial release',
          uploadedBy: 'John Doe', 
          uploaderComments: 'First version uploaded after beta testing.',
          approvedBy: 'Jane Smith', 
          approvedOn: '2024-10-02', 
          approverComments: 'Approved for general use.'
        },
        { 
          version: 'v0.9', 
          date: '2024-09-20', 
          description: 'Beta release',
          uploadedBy: 'John Doe', 
          uploaderComments: 'This version was released for internal testing.',
          approvedBy: 'Jane Smith', 
          approvedOn: '2024-09-25', 
          approverComments: 'Approved for beta testing only.'
        },
      ]
    },
    { 
      title: 'Document 2', 
      description: 'This document contains updates and fixes in version 2.1 for performance improvements.',
      versions: [
        { 
          version: 'v2.1', 
          date: '2024-09-15', 
          description: 'Minor bug fixes',
          uploadedBy: 'Alice Brown', 
          uploaderComments: 'Small bug fixes in this version.',
          approvedBy: 'John Doe', 
          approvedOn: '2024-09-17', 
          approverComments: 'Looks good after fixes.'
        },
        { 
          version: 'v2.0', 
          date: '2024-08-25', 
          description: 'Major update',
          uploadedBy: 'Alice Brown', 
          uploaderComments: 'Major release with new features.',
          approvedBy: 'John Doe', 
          approvedOn: '2024-08-27', 
          approverComments: 'Major update approved for deployment.'
        },
      ]
    },
    // Other documents...
  ];

  useEffect(()=>{

    getDocuments();
  },[])
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const getDocuments = () => {
  
    let docURL = getAPIURL(REACT_APP_GETDocuments);
    let req = new Request(`${docURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
         debugger;
           
          setAdocuments(result.data.documents);
        
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
          //set_IsLoading(false);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };
  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showAddDocModal, setShowAddDocModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [documentData, setDocumentData] = useState({
    title: '',
    description: '',
    versionNumber: '',
    versionDescription: '',
    uploadedBy: sessionStorage.getItem("PersonID"),
    uploaderComments: ''
  });


  // Function to handle modal open
  const handleShowModal = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };

  // Inside your React component
const downloadFile = async (docId, docVersion, filepath, filename) => {
  debugger
  try {
    let downloadurl = getAPIURL(REACT_APP_DownloadDocumentTest);
    
    let AttachmentUrl =
    getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT) +
    "?fileName=" +
    filename+
    "&folderPath=" +
    filepath;
    let apiURL = REACT_APP_API_URL;
    if(!apiURL.includes("Workflowapi"))
   { 
    AttachmentUrl=`${downloadurl}?docId=${encodeURIComponent(docId)}&docVersion=${encodeURIComponent(docVersion)}`;
   } 
   const response = await fetch(AttachmentUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('File download failed');
    }

    // Extract the blob from the response
    const blob = await response.blob();
    
    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);
    
    // Construct the file name for download
    const fileName = `${docId}_v${docVersion}.txt`; // Adjust as necessary

    // Create a temporary link to initiate download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and link
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};


const readbleDate=(dbDate)=>{
  const readableDate = new Date(dbDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return readableDate;
}
  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDocument(null);
  };
  const [file, setFile] = useState(null);

  const handleOpenAddDocModal = () => setShowAddDocModal(true);
  const handleCloseAddDocModal = () => setShowAddDocModal(false);
  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentData((prevData) => ({ ...prevData, [name]: value }));
  };

 // Function to submit document details to API
 const handleAddDocumentSubmit = async (e) => {
  e.preventDefault();

  // POST request to API endpoint
  let newdocURL = getAPIURL(REACT_APP_AddDocument);
  try {
    const response = await fetch(`${newdocURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    
      },
      body: JSON.stringify({
        title: documentData.title,
        description: documentData.description,
        versions: [{
          versionNumber: documentData.versionNumber,
          versionDescription: documentData.versionDescription,
          uploadedBy: documentData.uploadedBy,
          uploaderComments: documentData.uploaderComments
        }]
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("Document created successfully:", result);
    setdocumentObj(result.data);
    // Open the upload modal if the document is created successfully
    setShowAddDocModal(false);
    handleShowUploadModal();

  } catch (error) {
    console.error("Failed to create document:", error);
  }
};



const handleUploadSubmit = async (e) => {
  e.preventDefault();
debugger;
  if (!file) {
    alert("Please select a file to upload.");
    return;
  }
if(!documentObj)
{
  alert("Document version creation failed!");
  return;
}
if(documentObj.Result==="Failure")
{
  alert("Please retry adding the correct values!");
  return;
}
  // Sample document ID and version number to be passed in the query
  const docId = documentObj.documentId; // Replace with actual document ID
  const docVersion = documentObj.versionNumber; ; // Replace with actual document version

  // Creating FormData to send the file
  const formData = new FormData();
  formData.append('file', file);

  try {
    // Construct the URL with query parameters
    let uploadURL = getAPIURL(REACT_APP_UploadNewFileVersion);
    const url = `${uploadURL}?docId=${encodeURIComponent(docId)}&docVersion=${encodeURIComponent(docVersion)}`;

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("File uploaded successfully:", result);
   debugger;
    handleUpdateDocLink(documentObj.versionId,result.data.s3bucketfolderPath,result.data.filekeyName);
    // Reset fields after successful upload
    setFile(null);
    handleCloseUploadModal();

  } catch (error) {
    console.error("Failed to upload file:", error);
  }
};

const handleUpdateDocLink = async (docVersionId,S3bucketLInk,fileName) => {
 
  if (!docVersionId) {
    alert("Something went worng while updating document link");
    return;
  }
if(!S3bucketLInk)
{
  alert("Empty document link!");
  return;
}
  try {
    // Construct the URL with query parameters
    let uploadURL = getAPIURL(REACT_APP_UpdateDocumentLink);

    const response = await fetch(uploadURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    
      },
      body: JSON.stringify({
        docVersionId: docVersionId,
        documentLInk: S3bucketLInk,
        fileName:fileName
       
      }),
    });

    if (!response.ok) {
      addToast("Document failed to upload!", {
        appearance: "error",
        autoDismiss: true,
      });
    }else
    {
      addToast("Document added Successfully and sent for approval!", {
        appearance: "success",
        autoDismiss: true,
      });
    }

    const result = await response.json();
    console.log("document link result", result);
   
    // Reset fields after successful upload
    

  } catch (error) {
    console.error("Failed to upload file:", error);
  }
};
  // Function to get the latest version of a document
  const getLatestVersion = (versions) => {
    return versions[0]; // Assuming the versions are in descending order by date
  };

  const location = useLocation();
  const processingDashboardParam = location.state;
  let navigate = useNavigate();
  const { addToast } = useToasts();
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  return (
    <>
    <Header onpreferenceShow={null} pageName="HELP SECTION" />
    
    <ApplicationUserContext.Provider
      value={processingDashboardParam.userRoles}
    >
      <DashboardContext.Provider value={processingDashboardParam.nav}>
        <NavDataContext.Provider value={navData}>
          <Nav selectedMenu="activities" />
        </NavDataContext.Provider>
      </DashboardContext.Provider>
    </ApplicationUserContext.Provider>
    <div className="centerSection">
      <div className="pageBody">
        <div className="timeBreak">
          <div className="dashBoxs">
            <div className="formWrap">
              <div className="container mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-2">
                      <h2>HELP SECTION</h2>
                    </div>
                  </div>
                </div>
                <Container className="mt-4">
      {/* First Section: Paragraph Content */}
      <Row>
        <Col>
          <div className="p-4 bg-light">
            <h2>Digital Library</h2>
            <p>
              Welcome to the digital library. Here, you can find various documents
              that might be useful to guide you through different processes. If
              you have any further questions, feel free to contact our support
              team.
            </p>
          </div>
        </Col>
      </Row>

      {/* Second Section: Documents Table (Latest Version Only) */}
      <Row className="mt-4">
        <Col>
          <div className="p-4 bg-white border rounded">
            <h3>Available Digital Documents</h3>
            {/* <Button onClick={handleOpenAddDocModal} variant="primary" className="mb-3">
        Add New Document
      </Button> */}

            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Latest Version</th>
                  <th>Uploaded Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, index) => {
                  const latestVersion = getLatestVersion(doc.versions);
                  return (
                    <tr key={index}>
                      <td>{doc.title}</td>
                      <td>{latestVersion.versionNumber}</td>
                      <td>{readbleDate(latestVersion.uploadedDate)}</td>
                      <td>
                        <Button 
                          variant="primary" 
                          size="sm"
                          onClick={() => handleShowModal(doc)}
                        >
                          View/Download
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

           {/* Modal for Document Details */}
           <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedDocument?.title} - Versions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Document Description */}
          {selectedDocument && (
            <div className="mb-4">
              <h5>Description</h5>
              <p>{selectedDocument.description}</p>
            </div>
          )}
          
          {/* Document Versions */}
          <div className="p-3">
            {selectedDocument?.versions?.map((version, index) => (
              <div key={index} className="version-item d-flex mb-4">
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <h5>{version.version}</h5>
                  <p><strong>Date:</strong> {readbleDate(version.uploadedDate)}</p>
                  <p><strong>Uploaded By:</strong> {version.uploadedBy}</p>
                  <p><strong>Uploader Comments:</strong> {version.uploaderComments}</p>
                  <p><strong>Approved By:</strong> {version.approvedBy}</p>
                  <p><strong>Approved On:</strong> {readbleDate(version.approvedDate)}</p>
                  <p><strong>Approver Comments:</strong> {version.approverComments}</p>
                  <Button variant="success" size="sm" onClick={()=>downloadFile(selectedDocument.documentId,version.versionNumber,version.downloadLink,version.fileName)}>Download {version.versionNumber}</Button>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add Document Modal */}
      <Modal show={showAddDocModal} onHide={handleCloseAddDocModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddDocumentSubmit}>
            <Form.Group>
              <Form.Label>Document Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={documentData.title}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={documentData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Version Number</Form.Label>
              <Form.Control
                type="text"
                name="versionNumber"
                value={documentData.versionNumber}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Version Description</Form.Label>
              <Form.Control
                as="textarea"
                name="versionDescription"
                value={documentData.versionDescription}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Comments</Form.Label>
              <Form.Control
                type="text"
                name="uploaderComments"
                value={documentData.uploaderComments}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Upload Document Modal */}
      <Modal show={showUploadModal} onHide={handleCloseUploadModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUploadSubmit}>
            <Form.Group>
              <Form.Label>Upload File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

    </Container>

    </div>
              </div>
              <ul className="timeBreak-form"></ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Help;
