import React, {
  useState,
  useEffect} from "react";
import {
  Paper} from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import { useToasts } from "react-toast-notifications";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { useNavigate } from "react-router-dom";
const BucketManagementDashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToasts();
  const processingDashboardParam = location.state;
  const [bucketManagementData, setBucketManagementData] = useState([]);
  const [refreshTime, setRefreshTime] = useState(600);
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GetBucketManagementDashboardDetails } = process.env

  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
  let currentAcitvity = "";
  if (typeof processingDashboardParam.row.activityName !== "undefined") {
      currentAcitvity = processingDashboardParam.row.activityName;
  } else {
      currentAcitvity = processingDashboardParam.currentAcitvity;
  }
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
      navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
      navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const GetBucketManagementDashboardDetails = () => {
    debugger;
    let body = {
    };
    let loginUrl = getAPIURL(REACT_APP_GetBucketManagementDashboardDetails);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          console.log(result.data);
          setBucketManagementData(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const handleRefreshPage = () => {
    GetBucketManagementDashboardDetails();
  }
  const columns = [
    {
      title: "Process Name",
      field: "processName",
    },
    {
      title: "SubProcess Name",
      field: "subProcessName",
    },
    {
      title: "Bucket",
      field: "bucket",
    },
    {
      title: "Imagery",
      field: "imagery",

    },
    {
      title: "Photoshop",
      field: "photoshop",
    },
    {
      title: "PSO",
      field: "pso",
    },
    {
      title: "Rejected",
      field: "rejected",
    },
    {
      title: "Pending",
      field: "pending",
    },
    {
      title: "Reviewer",
      field: "reviewer",
    },
    {
      title: "Completed",
      field: "client",
    },

  ];


  useEffect(() => {
    GetBucketManagementDashboardDetails();
    const intervalId = setInterval(handleRefreshPage, refreshTime * 1000);

    return () => clearInterval(intervalId); 
  }, [refreshTime]); 

  const handleRefreshButtonClick = () => {
    handleRefreshPage();
  }

  const handleRefreshTimeChange = (e) => {
    let value = parseInt(e.target.value);
    if (value < 10) {
      value = 10; 
    }
    setRefreshTime(value);
  }


  return (
    <>
      <Header onpreferenceShow={null} pageName="Bucket Management Dashboard" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
         
          <div className="row align-items-center justify-content-end">
           
              <div className="col-2 mb-1 text-right justify-content-end">
                <label htmlFor="refreshTime">Refresh Time in sec.</label>
              </div>
              <div className="col-1 mb-1">
                <input
                  className="form-control form-control-sm"
                  type="number"
                  id="refreshTime"
                  name="refreshTime"
                  value={refreshTime}
                  onChange={handleRefreshTimeChange}
                />
              </div>
          
            <div className="col-2 d-flex mb-1">
              <button className="material-symbols-outlined submenu-icon mb-1 custom-refresh-button" onClick={handleRefreshButtonClick}>
                Refresh
              </button>
            </div>
          </div>
          <div className="tableWrap">
            <div className="tableWrap-body">
              <table className="table table-borderless MatTable">
            
                <MaterialTable
                  localization={{
                    pagination: {
                    
                    },
                    toolbar: {
                      
                    },
                    header: {
                      actions: "",
                    },
                  }}
                  actions={[
                  ]}
                  options={{
                    headerStyle: {
                      position: "sticky",
                      top: "-5px",
                    },
                    maxBodyHeight: "420px",
                    pageSizeOptions: [10, 20, 50, 100],
                    exportButton: false,
                    sorting: true,
                    filtering: true,
                    search: false,
                    selection: false,

                    overflowY: false,
                    showTitle: false,
                    toolbarButtonAlignment: "left",
                    headerStyle: {
                    },
                    cellStyle: {
                      width: 200,
                      minWidth: 200,
                      whiteSpace: "Normal",
                      warap: true,

                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                      whiteSpace: "wrap",
                    },
                    headerStyle: {
                    },
                  }}
              
                  columns={columns}
                  icons={tableIcons}
                  data={bucketManagementData}
                  components={{
                    Container: (props) => (
                      <Paper {...props} elevation={0} />
                    ),
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar
                          {...props}
                          style={{
                            fontSize: "5px",
                          }}
                        />
                      </div>
                    ),
                  }}
                />
             
              </table>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default BucketManagementDashboard;