import React, { useEffect, useState, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormContext from "../../../../contexts/FormContext";
import { da } from "date-fns/locale";

export default function UserClassificationListGridDetailsPage(state, setState) {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GetOperatorsFromDataType } = process.env;
  const [operators , setoperators] = useState({});
  //const [dropdownValue, setdropdownValue ] = useState("Equals");
  // console.log({state})
  const [selectedValues, setSelectedValues] = useState(()=>{
    const initialValue = {};
    state.state.WorkItemFieldsbySubProcessIDForClassification?.forEach(data => {
      initialValue[data.workitemfieldid] = "Equals";
    });
      return initialValue;
    });
  
  let options=[];


  // const getAPIURL = (api) => {
  //   let apiURL = REACT_APP_API_URL;
  //   return `${apiURL}${api}`;
  // };
  
  let oldElements = state;

  
  // useEffect(()=> {
  //   // state.state.WorkItemFieldsbySubProcessIDForClassification.map((data) => 
  //   //   {
  //   //     console.log("data from use effect",data);
  //   // //  let values=  await getDropDownOptions(data.dataType)
  //   //   setoperators({...operators, [data.dataType]: []})
  //   //   console.log("operators from useEffect", operators)
  //   //   }
  //   // )

  //       getDropDownOptions();
  //   }, [state]);
  
  
   const handleChange = useContext(FormContext);
  //  console.log(oldElements)

  //  const  getDropDownOptions = () => {
  //   //debugger;
  //   state.state.WorkItemFieldsbySubProcessIDForClassification.map((data) => {

  //     let body = {
  //       DataType: data.dataType
  //     };
  //     let loginUrl = getAPIURL(REACT_APP_GetOperatorsFromDataType);
  //     let req = new Request(`${loginUrl}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         UserName: sessionStorage.getItem("UserName"),
  //         Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //       },
  //       body: JSON.stringify(body),
  //     });
  //     fetch(req)
  //       .then((res) => res.json())
  //       .then((result) => {
  //         //debugger;
  //         if (result.status == 200) {
  //           // console.log("data from api", dataType,result.data)
  //           setoperators({...operators, [data.dataType]: result.data}) 
  //           // result.data;
  //           // setoperators(result.data)
  //           console.log("operators inside api call", operators)
  //         } else if (result.status === 401) {
  //           sessionStorage.clear();
  //           sessionStorage.clear();
  //           return [];
  //           //navigate("/");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("ERROR: ", error);
  //       });
  //   })
  
  //  }
  // const handledropdownChange = (event,oldElements,setState, workFeildId) => {
  //   //debugger;
  //   // console.log("inside handle dropdown change")
  //   setdropdownValue(event.target.value)
   

  //   var newElements = { ...oldElements};
  //   newElements.WorkItemFieldsbySubProcessIDForClassification.forEach(
  //     (element) => {
  //       //debugger;
  //       // console.log("for each of element")
  //       // console.log({element})
  //       // console.log(event.target.value)
  //       if (element.workitemfieldid == workFeildId) {
  //         // element.fieldValues = event.target.value;
  //         // console.log("if of element")
  //         // console.log("elemnt . opertor from handle dropdownchnage", element.operator)
  //         element.operator = event.target.value;
  //       }
  //     }
  //   );
  //   setState(newElements.WorkItemFieldsbySubProcessIDForClassification);
  // };
  const handledropdownChange = (event, workitemfieldid) => {
    debugger;
    setSelectedValues(prevSelectedValues => ({
      ...prevSelectedValues, [workitemfieldid]: event.target.value,
    }))
    //setdropdownValue(event.target.value)
  }

  const valueMapping = {
    "Equals" : "equals",
      "Begins with" : "beginswith",
      "End with": "End With",
      "Not equal to" : "notequalto",
      "Contains" : "contains",
      "Does not contain": "doesnotcontain",
      ">":">",
      ">=":">=",
      "<" : "<",
      "<=": "<="
  }
  const getDropDownOptions= (dataType) => {
    const allOptions =[
      "Equals",
      "Begins with",
      "End with",
      "Not equal to",
      "Contains",
      "Does not contain",
      ">",
      ">=",
      "<",
      "<="
    ];
    if (dataType === "System.String"){
      return allOptions.filter(option => ![">",
      ">=",
      "<",
      "<="].includes(option));

    } else if (["System.Double","System.Int32","System.DateTime"].includes(dataType)) {
      return allOptions.filter(option => !["Begins with",
      "End with","Contains",
      "Does not contain"].includes(option));
    } else {
      return allOptions;
    }
  };



 
  return (
    <>
      <form className="dashBoxs mb-3">
        <div className="formWrap">
          <div className="row align-items-end">
            {state.state.WorkItemFieldsbySubProcessIDForClassification?.map(
              (data, index) => {
                 options =  getDropDownOptions(data.dataType)
                //let groupName = data.groupName;
                return (
                  <>
                    <div className="col-3">
                      <div className="mb-3">
                        <label
                          key={index}
                          className="form-label"
                          id={data.workitemfieldid}
                          mapToField={data.maptofield}
                        >
                          {data.labeltext}
                        </label>
                        <select className="form-control form-control-sm" 
                        onChange={(event) => handledropdownChange(event, data.workitemfieldid )
                        }
                        value={selectedValues[data.workitemfieldid] ||"Equals"}>
                          {options?.map((option, idx) => (
                            <option key={idx} value={option}>{option}</option>
                          ))}
                          
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label"></label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id={data.maptofield}
                          value={data.fieldValues}
                          onChange={(event) =>
                            handleChange.onchange(
                              // data.mapToField,
                              event,
                              valueMapping[selectedValues[data.workitemfieldid]] || "equals",
                              //selectedValues[data.workitemfieldid] || "Equals",
                              oldElements
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </form>
    </>
  );
}
