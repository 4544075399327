import React, { useState, useContext, useEffect } from "react";
import "../../assets/scss/components/_nav.scss";
import { func } from "prop-types";
import { Link } from "react-router-dom";
import Login from "../../cws/containers/pages/login/Login";
import ApplicationUserContext from "../../contexts/ApplicationUserContext";
import DashboardContext from "../../contexts/DashboardContext";
import NavDataContext from "../../contexts/NavDataContext";
//import PowerBiDashboards from "./cws/containers/pages/Reports/PowerBiDashboards"
import EmbeddedReports from "../../cws/containers/pages/Reports/EmbeddedReports";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import BpmnEditor from "../../cws/containers/pages/WorkflowConfiguration/WorkFlowConfig";
import { MotionPhotosOffSharp } from "@mui/icons-material";


const Nav = ({ selectedMenu }) => {
  //const contextType=ApplicationUserContext;
  const { reportids } = useSelector((state) => state);
  let navigate = useNavigate();
  const userRoles = React.useContext(ApplicationUserContext);
  const nav = React.useContext(DashboardContext);
  let assignedWorkItemDetails = React.useContext(NavDataContext);
  const selectedSubprocess = sessionStorage.getItem("ProcessId");
  let defaultFilter =
    "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()";
  let navDetails = "";
  const navactivityname = "";
  // NAV LINKS TOGGLE FUNCTION
  const navRef = React.useRef(null);
  const onToggleClick = (e) => {
    navRef.current.classList.toggle("active");
    modeRef.current.classList.remove("mini-mode");
  };

  // SWICTH MINI MODE FUNCTION
  const modeRef = React.useRef(null);
  const currentURL = window.location.href;
  const [navMenu, setnavMenu] = useState([]);
  const [rows, setrow] = useState([]);
  const [selectedAssignWorkitems, setselectedAssignWorkitem] = useState({});
  const [PreProcessorDashboard, setPreProcessorDashboard] = useState(false);
  const [Processor, setProcessor] = useState(false);
  const [processorData, setprocessorData] = useState({});
  const [PostProcessing, setPostProcessor] = useState(false);
  const [Reviewer, setReviewer] = useState(false);
  const [BOT, setBOT] = useState(false);
  const [BOTPend, setBOTPend] = useState(false);
  const [ReReview, setReReview] = useState(false);
  const [PostReview, setPostReview] = useState(false);
  const [OPIExpert, setOPIExpert] = useState(false);
  const [Imagery, setImagery] = useState(false);
  const [Photoshop, setPhotoshop] = useState(false);
  const [PSO, setPSO] = useState(false);
  const [TestingPending, setTestingPending] = useState(false);
  const [TestingRejected, setTestingRejected] = useState(false);
  const [NextTesting, setNextTesting] = useState(false);
  const [BotProcessor, setBotProcessor] = useState(false);
  const [Rejected, setRejected] = useState(false);
  const [Pending, setPending] = useState(false);
  const [HourTesting, set2HourTesting] = useState(false);
  const [FirstApprover, setFirstApprover] = useState(false);
  const [SecondApprover, setSecondApprover] = useState(false);
  const [ApprovalCoordinator, setApprovalCoordinator] = useState(false);
  const [clientDashboard, setClientDashboard] = useState(false);
  const [clientExceptionDashboard, setClientExceptionDashboard] =
    useState(false);
  const [clientCompletedDashboard, setClientCompletedDashboard] =
    useState(false);
  const [VendorDashboard, setVendorDashboard] = useState(false);
  const [ClosedDashboard, setClosedDashboard] = useState(false);
  const [CllientExceptionDashboard, setCllientExceptionDashboard] =
    useState(false);
  const [CompletedDashboard, setCompletedDashboard] = useState(false);
  const [ExceptionDashboard, setExceptionDashboard] = useState(false);
  const [EXLExpertDashboard, setEXLExpertDashboard] = useState(false);
  const [FifthApproverDashboard, setFifthApproverDashboard] = useState(false);
  const [FourthApproverDashboard, setFourthApproverDashboard] = useState(false);
  const [PreReviewDashboard, setPreReviewDashboard] = useState(false);
  const [RequestorDashboard, setRequestorDashboard] = useState(false);
  const [ResolvedDashboard, setResolvedDashboard] = useState(false);
  const [TestExceptionDashboard, setTestExceptionDashboard] = useState(false);
  const [ThirdApproverDashboard, setThirdApproverDashboard] = useState(false);
  const [VendorCommunicationDashboard, setVendorCommunicationDashboard] =
    useState(false);
  const [VendorMaintenanceDashboard, setVendorMaintenanceDashboard] =
    useState(false);

  const [openAdmin, setOpenAdmin] = useState(false);
  const [openActivities, setOpenActivities] = useState(false);
  const [businessRules, setbusinessRules] = useState(false);
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const [openManageUsers, setOpenManageUsers] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);

  const [RefundReadiness, setRefundReadiness] = useState(false);
  const [RefundAssessment, setRefundAssessment] = useState(false);
  const [RefundPlanning, setRefundPlanning] = useState(false);
  const [RefundValidation, setRefundValidation] = useState(false);
  const [RefundDisbursement, setRefundDisbursement] = useState(false);

  const [ReviewerOnshore, setReviewerOnshore] = useState(false);

  const [LegalServices, setLegalServices] = useState(false);
  const [CustodianReachout, setCustodianReachout] = useState(false);
  const [QualityCheck, setQualityCheck] = useState(false);
  const [MailRoom, setMailRoom] = useState(false);
  const [Payments, setPayments] = useState(false);
  const [Invoicing, setInvoicing] = useState(false);
  const [Workforcemanagement, setWorkforcemanagement] = useState(false);

  const [selection, serSelection] = useState([]);
  const [admin, setAdmin] = useState(
    sessionStorage.getItem("is_admin") == "true" ? true : false
  );
  const [superadmin, setSuperadmin] = useState(
    sessionStorage.getItem("is_superadmin") == "true" ? true : false
  );
  const [OneandDone, setOneandDone] = useState(
    sessionStorage.getItem("is_OneandDone") == "true" ? true : false
  );
  const [upload, setUpload] = useState(
    sessionStorage.getItem("is_upload") == "true" ? true : false
  );
  const [activities, setActivities] = useState(
    sessionStorage.getItem("is_activities") == "true" ? true : false
  );
  const [client, setClient] = useState(
    sessionStorage.getItem("is_client") == "true" ? true : false
  );
  const [exceptions, setExceptions] = useState(
    sessionStorage.getItem("is_exceptions") == "true" ? true : false
  );
  const [complete, setComplete] = useState(
    sessionStorage.getItem("is_complete") == "true" ? true : false
  );
  const [vendor, setVendor] = useState(
    sessionStorage.getItem("is_vendor") == "true" ? true : false
  );
  const [workFlowstatechange, setWorkFlowstatechange] = useState(
    sessionStorage.getItem("is_workFlowstatechange") == "true" ? true : false
  );
  const [reassignment, setReassignment] = useState(
    sessionStorage.getItem("is_reassignment") == "true" ? true : false
  );
  const [adhocEditing, setAdhocEditing] = useState(
    sessionStorage.getItem("is_adhocEditing") == "true" ? true : false
  );
  const [undoActivity, setUndoActivity] = useState(
    sessionStorage.getItem("is_undoActivity") == "true" ? true : false
  );
  const [clientDailyInfo, setClientDailyInfo] = useState(
    sessionStorage.getItem("is_clientDailyInfo") == "true" ? true : false
  );
  const [workitemBulkUpdation, setWorkitemBulkUpdation] = useState(
    sessionStorage.getItem("is_workitemBulkUpdation") == "true" ? true : false
  );
  const [cwsRestrictedSearch, setCwsRestrictedSearch] = useState(
    sessionStorage.getItem("is_cwsRestrictedSearch") == "true" ? true : false
  );
  const [report, setReport] = useState(
    sessionStorage.getItem("is_report") == "true" ? true : false
  );
  const [manageUsers, setManageUsers] = useState(
    sessionStorage.getItem("is_manageUsers") == "true" ? true : false
  );
  const [workItemFieldadmin, setWorkItemFieldadmin] = useState(
    sessionStorage.getItem("is_workItemFieldadmin") == "true" ? true : false
  );
  const [Configuration, setConfiguration] = useState(
    sessionStorage.getItem("is_Configuration") == "true" ? true : false
  );
  const [UserClassificationMapping, setis_UserClassificationMapping] = useState(
    sessionStorage.getItem("is_UserClassificationMapping") == "true"
      ? true
      : false
  );
  const [UserClassificationList, setUserClassificationList] = useState(
    sessionStorage.getItem("is_UserClassificationList") == "true" ? true : false
  );
  const [WorkitemFieldsRules, setWorkitemFieldsRules] = useState(
    sessionStorage.getItem("is_WorkitemFieldsRules") == "true" ? true : false
  );
  const [instructionauthor, setInstructionauthor] = useState(
    sessionStorage.getItem("is_instructionauthor") == "true" ? true : false
  );
  const [instructionapprover, setInstructionapprover] = useState(
    sessionStorage.getItem("is_instructionapprover") == "true" ? true : false
  );
  const [CWSSearch, setCWSSearch] = useState(
    sessionStorage.getItem("is_search") == "true" ? true : false
  );
  const [manageWorkItems, setmanageWorkItems] = useState(
    sessionStorage.getItem("is_manageWorkItems") == "true" ? true : false
  );
  const [UploadandAssignment, setUploadandAssignment] = useState(
    sessionStorage.getItem("is_UploadandAssignment") == "true" ? true : false
  );
  const [QueueManagement, setQueueManagement] = useState(
    sessionStorage.getItem("is_QueueManagement") == "true" ? true : false
  );
  const [BucketManagement, setBucketManagement] = useState(
    sessionStorage.getItem("is_BucketManagement") == "true" ? true : false
  );
  const [RealTimeDashboard, setRealTimeDashboard] = useState(
    sessionStorage.getItem("is_RealTimeDashboard") == "true" ? true : false
  );

  const [imageryQA, setImageryQA] = useState(false);
  const [rejectedResolved, setRejectedResolved] = useState(false);

  const [isSubprocessView, setisSubprocessView]= useState(
    sessionStorage.getItem("is_SubprocessView") === "true" ? true : false
  );

  let row = [];
  let selectedAssignWorkitem = {};
  useEffect(() => {
    setnavMenu(nav);
    sessionStorage.setItem("activityName", "");
    sessionStorage.setItem("workflowstateId", "");
    if (nav.length == 0) {
      setClientDashboard(false);
      setClientExceptionDashboard(false);
      setClientCompletedDashboard(false);
      setProcessor(false);
      setPreProcessorDashboard(false);
      setPostProcessor(false);
      setReviewer(false);
      setBOT(false);
      setBOTPend(false);
      setReReview(false);
      setPostReview(false);
      setOPIExpert(false);
      setImagery(false);
      setPhotoshop(false);
      setPSO(false);
      setTestingPending(false);
      setTestingRejected(false);
      setRejected(false);
      setPending(false);
      set2HourTesting(false);
      setNextTesting(false);
      setBotProcessor(false);
      setFirstApprover(false);
      setSecondApprover(false);
      setApprovalCoordinator(false);
      setVendorDashboard(false);
      setClosedDashboard(false);
      setCllientExceptionDashboard(false);
      setCompletedDashboard(false);
      setExceptionDashboard(false);
      setEXLExpertDashboard(false);
      setFifthApproverDashboard(false);
      setFourthApproverDashboard(false);
      setPreReviewDashboard(false);
      setRequestorDashboard(false);
      setResolvedDashboard(false);
      setTestExceptionDashboard(false);
      setThirdApproverDashboard(false);
      setVendorCommunicationDashboard(false);
      setVendorMaintenanceDashboard(false);
      setImageryQA(false);
      setRejectedResolved(false);
      setRefundReadiness(false);
      setRefundAssessment(false);
      setRefundPlanning(false);
      setRefundValidation(false);
      setRefundDisbursement(false);

      setReviewerOnshore(false);

      setLegalServices(false);
      setCustodianReachout(false);
      setQualityCheck(false);
      setMailRoom(false);
      setPayments(false);
      setInvoicing(false);
      setWorkforcemanagement(false);
    } else {
      for (let i = 0; i < navMenu.length; i++) {
        if (navMenu[i].activityName === "Client") {
          setClientDashboard(true);
        }

        if (navMenu[i].activityName === "Client(Exception)") {
          setClientExceptionDashboard(true);
        }

        if (navMenu[i].activityName === "Client(Completed)") {
          setClientCompletedDashboard(true);
        }

        if (navMenu[i].activityName === "Processing") {
          setProcessor(true);
        }

        if (navMenu[i].activityName === "PreProcessing") {
          setPreProcessorDashboard(true);
        }

        if (navMenu[i].activityName === "PostProcessing") {
          setPostProcessor(true);
        }

        if (navMenu[i].activityName === "Reviewer") {
          setReviewer(true);
        }

        if (navMenu[i].activityName === "BOT") {
          setBOT(true);
        }

        if (navMenu[i].activityName === "BOT Pend") {
          setBOTPend(true);
        }

        if (navMenu[i].activityName === "ReReview") {
          setReReview(true);
        }

        if (navMenu[i].activityName === "PostReview") {
          setPostReview(true);
        }

        if (navMenu[i].activityName === "OPIExpert") {
          setOPIExpert(true);
        }

        if (navMenu[i].activityName === "First Approver") {
          setFirstApprover(true);
        }

        if (navMenu[i].activityName === "Imagery") {
          setImagery(true);
        }

        if (navMenu[i].activityName.toLowerCase() === "photoshop") {
          setPhotoshop(true);
        }

        if (navMenu[i].activityName === "PSO") {
          setPSO(true);
        }

        if (navMenu[i].activityName.toLowerCase() === "testing pending") {
          setTestingPending(true);
        }

        if (navMenu[i].activityName === "Testing Rejected") {
          setTestingRejected(true);
        }

        if (navMenu[i].activityName === "Pending") {
          setPending(true);
        }

        if (navMenu[i].activityName === "Rejected") {
          setRejected(true);
        }

        if (navMenu[i].activityName === "2 Hour Testing") {
          set2HourTesting(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "nexttesting") {
          setNextTesting(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "bot processor") {
          setBotProcessor(true);
        }

        if (navMenu[i].activityName === "Second Approver") {
          setSecondApprover(true);
        }

        if (navMenu[i].activityName === "Approval Coordinator") {
          setApprovalCoordinator(true);
        }

        if (navMenu[i].activityName === "Vendor") {
          setVendorDashboard(true);
        }

        if (navMenu[i].activityName === "Closed") {
          setClosedDashboard(true);
        }

        if (navMenu[i].activityName === "Cllient Exception") {
          setCllientExceptionDashboard(true);
        }

        if (navMenu[i].activityName === "Completed") {
          setCompletedDashboard(true);
        }

        if (navMenu[i].activityName === "Exception") {
          setExceptionDashboard(true);
        }

        if (navMenu[i].activityName === "EXLExpert") {
          setEXLExpertDashboard(true);
        }

        if (navMenu[i].activityName === "Fifth Approver") {
          setFifthApproverDashboard(true);
        }

        if (navMenu[i].activityName === "Fourth Approver") {
          setFourthApproverDashboard(true);
        }

        if (navMenu[i].activityName === "PreReview") {
          setPreReviewDashboard(true);
        }

        if (navMenu[i].activityName === "Requestor") {
          setRequestorDashboard(true);
        }

        if (navMenu[i].activityName === "Resolved") {
          setResolvedDashboard(true);
        }

        if (navMenu[i].activityName === "Test Exception") {
          setTestExceptionDashboard(true);
        }

        if (navMenu[i].activityName === "Third Approver") {
          setThirdApproverDashboard(true);
        }

        if (navMenu[i].activityName === "Vendor Communication") {
          setVendorCommunicationDashboard(true);
        }

        if (navMenu[i].activityName === "Vendor Maintenance") {
          setVendorMaintenanceDashboard(true);
        }
        if (navMenu[i].activityName === "Imagery QA") {
          setImageryQA(true);
        }
        if (navMenu[i].activityName === "Rejected Resolved") {
          setRejectedResolved(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "refund readiness") {
          setRefundReadiness(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "refund assessment") {
          setRefundAssessment(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "refund planning") {
          setRefundPlanning(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "refund validation") {
          setRefundValidation(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "refund disbursement") {
          setRefundDisbursement(true);
        }

        if (navMenu[i].activityName.toLowerCase() === "reviewer onshore") {
          setReviewerOnshore(true);
        }

        if (navMenu[i].activityName.toLowerCase() === "legal services") {
          setLegalServices(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "custodian reachout") {
          setCustodianReachout(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "quality check") {
          setQualityCheck(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "mailroom") {
          setMailRoom(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "payments") {
          setPayments(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "invoicing") {
          setInvoicing(true);
        }
        if (navMenu[i].activityName.toLowerCase() === "workforce management") {
          setWorkforcemanagement(true);
        }
      }
    }

    //onModeClick()
  });
  useEffect(() => {
    modeRef.current.classList.toggle("mini-mode");
  }, []);
  const NavData = (navMenu) => {
    
    sessionStorage.setItem(
      "assignedWorkItemDetails",
      JSON.stringify(assignedWorkItemDetails)
    );
    sessionStorage.setItem("navMenu", navMenu);
    onModeClick();
    // if (
    //   navMenu.includes("Processing") ||
    //   navMenu.includes("Client") ||
    //   navMenu.includes("Completed") ||
    //   navMenu.includes("Exception") ||
    //   navMenu.includes("Reviewer")
    // ) {
    //   //navigateToWorkActivityDashboard();
    // }
  };
  // const navigateToWorkActivityDashboard = () => {
  //   navigate(
  //     "/WorkActivityDashboard",
  //     {state :{
  //       row,
  //       selectedSubprocess,
  //       nav,
  //       userRoles,
  //       defaultFilter,
  //       assignedWorkItemDetails,
  //     }}
  //   );
  // };
  const toggleAdmin = () => {
    setOpenAdmin(!openAdmin);
    modeRef.current.classList.remove("mini-mode");
  };
  const toggleActivities = () => {
    setOpenActivities(!openActivities);
    modeRef.current.classList.remove("mini-mode");
  };
  const toggleBusinessRules = () => {
    setbusinessRules(!businessRules);
    modeRef.current.classList.remove("mini-mode");
  };

  const toggleConfiguration = () => {
    setOpenConfiguration(!openConfiguration);
    modeRef.current.classList.remove("mini-mode");
  };
  const toggleManageUsers = () => {
    setOpenManageUsers(!openManageUsers);
    modeRef.current.classList.remove("mini-mode");
  };
  const toggleReport = () => {
    setOpenReport(!openReport);
    modeRef.current.classList.remove("mini-mode");
  };
  const toggleUpdateProfile = () => {
    setOpenUpdateProfile(!openUpdateProfile);
    modeRef.current.classList.remove("mini-mode");
  };

  const onModeClick = (e) => {
    modeRef.current.classList.toggle("mini-mode");
    if (openActivities) setOpenActivities(!openActivities);
    if (businessRules) setbusinessRules(!businessRules);
    if (openAdmin) setOpenAdmin(!openAdmin);
    if (openConfiguration) setOpenConfiguration(!openConfiguration);
    if (openManageUsers) setOpenManageUsers(!openManageUsers);
    if (openReport) setOpenReport(!openReport);
    if (openUpdateProfile) setOpenUpdateProfile(!openUpdateProfile);
  };
  const backToHome = () => {
    navigate("/Home");
  };
  return (
    <>
      <div className="navbar-left" ref={modeRef}>
        <nav>
          {/* <Link to="/Home"> */}

          {/* </Link> */}
          <ul>
            <a
              title="Home"
              id="liHome"
              className={
                selectedMenu == "home" ? " titleLink active" : "titleLink"
              }
              onClick={backToHome}
            >
              <b class="material-symbols-outlined">dashboard</b>
              <span>Home</span>
            </a>
            {activities || superadmin ? (
              <>
                <a
                  title="Activities"
                  id="togActivities"
                  className={
                    selectedMenu == "activities"
                      ? " nav-toggle active"
                      : "nav-toggle"
                  }
                  onClick={() => toggleActivities(!openActivities)}
                  onKeyPress={() => toggleActivities(!openActivities)}
                >
                  <b class="material-symbols-outlined">rebase</b>{" "}
                  <span>Activities</span>
                </a>
                <li>
                  <div>
                    {openActivities &&
                      UploadandAssignment &&
                      (upload || superadmin) && (
                        <>
                          <li
                            id="liUploadAssignment"
                            className="subMenu-item"
                            onClick={() => NavData("Upload and Assignment")}
                          >
                            <Link
                              to="/UploadAssignment"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                drive_folder_upload
                              </span>
                              <span>Upload and Assignment</span>
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      PreProcessorDashboard && (
                        <>
                          <li
                            id="liPreProcessorDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("PreProcessing")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Pre-Processor Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities && OneandDone && (
                      <li
                        id="liOneandDoneDashboard"
                        className="subMenu-item"
                        onClick={() => NavData("Processing")}
                      >
                        <Link
                          to="/OneandDoneDashboard"
                          state={{
                            row: row,
                            selectedSubprocess,
                            nav,
                            userRoles,
                            defaultFilter,
                            assignedWorkItemDetails: selectedAssignWorkitem,
                          }}
                        >
                          <span class="material-symbols-outlined submenu-icon">
                            dashboard_customize
                          </span>
                          One and Done Dashboard
                        </Link>
                      </li>
                    )}
                    {openActivities && (activities || superadmin) && Processor && (
                      <li
                        id="liProcessorDashboard"
                        className="subMenu-item"
                        onClick={() => NavData("Processing")}
                      >
                        <Link
                          to="/WorkActivityDashboard"
                          state={{
                            row: row,
                            selectedSubprocess,
                            nav,
                            userRoles,
                            defaultFilter,
                            assignedWorkItemDetails: selectedAssignWorkitem
                          }}
                        >
                          <span class="material-symbols-outlined submenu-icon">
                            dashboard_customize
                          </span>
                          Processor Dashboard
                        </Link>
                      </li>
                    )}
                    {openActivities &&
                      (activities || superadmin) &&
                      PostProcessing && (
                        <>
                          <li
                            id="liPostProcessingDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("PostProcessing")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Post Processing Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities && (activities || superadmin) && Reviewer && (
                      <>
                        <li
                          id="liReviewerDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("Reviewer")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Reviewer Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && BOT && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liBOTDashboard"
                          onClick={() => NavData("BOT")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            BOT Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities &&
                      (activities || superadmin) &&
                      BOTPend && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liBOTPendDashboard"
                            onClick={() => NavData("BOT Pend")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              BOT Pend Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      PostReview && (
                        <>
                          <li
                            id="liPostReviewDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("PostReview")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Post Review Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities && (activities || superadmin) && ReReview && (
                      <>
                        <li
                          id="liReReviewDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("ReReview")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Re-Review Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && OPIExpert && (
                      <>
                        <li
                          id="liOpiExpertDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("OPIExpert")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            OPI Expert Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && Imagery && (
                      <>
                        <li
                          id="liImageryDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("Imagery")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Imagery Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && imageryQA && (
                      <>
                        <li
                          id="liImageryQaDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("Imagery QA")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Imagery QA Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && Photoshop && (
                      <>
                        <li
                          id="liPhotoshopDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("photoshop")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Photoshop Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && PSO && (
                      <>
                        <li
                          id="liPsoDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("PSO")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            PSO Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities &&
                      (activities || superadmin) &&
                      TestingPending && (
                        <>
                          <li
                            id="liTestingPendingDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Testing Pending")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Testing Pending Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      NextTesting && (
                        <>
                          <li
                            id="liNextTestingDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("NextTesting")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              NextTesting Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      BotProcessor && (
                        <>
                          <li
                            id="liBotProcessorDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Bot Processor")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Bot Processor Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      TestingRejected && (
                        <>
                          <li
                            id="liTestingRejectedDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Testing Rejected")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Testing Rejected Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities && (activities || superadmin) && Pending && (
                      <>
                        <li
                          id="liPendingDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("Pending")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Pending Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities && (activities || superadmin) && Rejected && (
                      <>
                        <li
                          id="liRejectedDashboard"
                          className="subMenu-item"
                          onClick={() => NavData("Rejected")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Rejected Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities &&
                      (activities || superadmin) &&
                      rejectedResolved && (
                        <>
                          <li
                            id="liRejectedResolvedDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Rejected Resolved")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Rejected Resolved Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      HourTesting && (
                        <>
                          <li
                            id="li2HourTestingDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("2 Hour Testing")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              2 Hour Testing Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      FirstApprover && (
                        <>
                          <li
                            id="liFirstApproverDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("First Approver")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              FirstApprover Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      SecondApprover && (
                        <>
                          <li
                            id="liSecondApproverDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Second Approver")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              SecondApprover Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      ApprovalCoordinator && (
                        <>
                          <li
                            id="liApprovalCoordinatorDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Approval Coordinator")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Approval Coordinator Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    
                    {openActivities &&
                      (activities || superadmin) &&
                      RefundReadiness && (
                        <>
                          <li
                          id="liRefundReadinessDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Refund Readiness")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Refund Readiness Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      RefundAssessment && (
                        <>
                          <li
                          id="liRefundAssessmentDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Refund Assessment")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Refund Assessment Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    
                    {openActivities &&
                      (activities || superadmin) &&
                      RefundPlanning && (
                        <>
                          <li
                          id="liRefundPlanningDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Refund Planning")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Refund Planning Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      RefundValidation && (
                        <>
                          <li
                          id="liRefundValidationDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Refund Validation")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Refund Validation Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      RefundDisbursement && (
                        <>
                          <li
                          id="liRefundDisbursementDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Refund Disbursement")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Refund Disbursement Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      ReviewerOnshore && (
                        <>
                          <li
                          id="liReviewerOnshoreDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Reviewer Onshore")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Reviewer Onshore Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      LegalServices && (
                        <>
                          <li
                          id="liLegalServicesDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Legal Services")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Legal Services Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    
                    {openActivities &&
                      (activities || superadmin) &&
                      CustodianReachout && (
                        <>
                          <li
                          id="liCustodianReachoutDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Custodian Reachout")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Custodian Reachout Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      QualityCheck && (
                        <>
                          <li
                          id="liQualityCheckDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Quality Check")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Quality Check Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      MailRoom && (
                        <>
                          <li
                          id="liMailRoomDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Mailroom")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              MailRoom Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      Payments && (
                        <>
                          <li
                          id="liPaymentsDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Payments")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Payments Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      Invoicing && (
                        <>
                          <li
                          id="liInvoicingDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Invoicing")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Invoicing Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      Workforcemanagement && (
                        <>
                          <li
                          id="liWorkforcemanagementDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Workforce Management")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Workforce Management Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities && (activities || superadmin) && (
                      <>
                        {openActivities && (activities || superadmin) && (
                          <>
                            <li
                              className="subMenu-item"
                              onClick={() => NavData("Capacity Scheduler")}
                            >
                              <Link
                                to="/CapacityScheduler"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  calendar_clock
                                </span>
                                <span>Capacity Scheduler</span>
                              </Link>
                            </li>
                          </>
                        )}
                        {openActivities && (activities || superadmin) && (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") && (
                          <>
                            <li
                              onClick={() =>
                                NavData("Employee Activity Summary")
                              }
                              id="liEmployeeActivitySummary"
                            >
                              <Link
                                to="/EmployeeActivitySummary"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  summarize
                                </span>
                                Employee Activity Summary
                              </Link>
                            </li>
                            <li
                              onClick={() =>
                                NavData("Help")
                              }
                              id="liEmployeeActivitySummary"
                            >
                              <Link
                                to="/Help"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  help
                                </span>
                                Help
                              </Link>
                            </li>
                          </>
                        )}
                        {openActivities && (activities || superadmin) && (
                          <>
                            <li
                              onClick={() =>NavData("Export Sub-Process Configuration")
                            }
                            >
                              <Link
                                to="/ExportConfig"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  summarize
                                </span>
                                Export Configuration
                              </Link>
                            </li>
                          </>
                        )}


                        {openActivities && (activities || superadmin) && isSubprocessView && (
                          <>
                            <li
                              onClick={() =>
                                NavData("WorkItem Bulk Updation")
                              }
                              id="liWorkItemBulkUpdation"
                            >
                              <Link
                                to="/WorkItemBulkUpdation"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  monitoring
                                </span>
                                WorkItem Bulk Updation
                              </Link>
                            </li>
                          </>
                        )}
                        

                        {openActivities && (activities || superadmin) && (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") && (
                          <>
                            <li
                              onClick={() =>
                                NavData("Employee Time Utilization Details")
                              }
                              id="liEmployeeTimeUtilizationDetails"
                            >
                              <Link
                                to="/EmployeeActivityDetails"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  monitoring
                                </span>
                                Employee Time Utilization Details
                              </Link>
                            </li>
                          </>
                        )}
                        {openActivities && (superadmin) &&  (
                          <>
                            <li
                              onClick={() =>
                                NavData("Sub-Process Summary")
                              }
                              id="liSubProcessSummary"
                            >
                              <Link
                                to="/SubProcessSummary"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  monitoring
                                </span>
                                Sub-Process Summary
                              </Link>
                            </li>
                          </>
                        )}

                        {openActivities && (activities || superadmin) && (
                          <>
                            <li
                              onClick={() =>
                                NavData("Client Daily Info")
                              }
                              id="liClientDailyInfo"
                            >
                              <Link
                                to="/ClientDailyInfo"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  summarize
                                </span>
                                Client Daily Info
                              </Link>
                            </li>
                          </>
                        )}
                        {openActivities &&
                          CWSSearch &&
                          (activities || superadmin) && (
                            <>
                              <li
                                id="liSearchWorkitems"
                                className="subMenu-item"
                                onClick={() => NavData("Search Workitems")}
                              >
                                <Link
                                  to="/SearchWorkItem"
                                  state={{
                                    row,
                                    selectedSubprocess,
                                    nav,
                                    userRoles,
                                    assignedWorkItemDetails,
                                  }}
                                >
                                  <span class="material-symbols-outlined submenu-icon">
                                    manage_search
                                  </span>
                                  Search Workitems
                                </Link>
                              </li>
                            </>
                          )}
                        {/* <li>
                        <a>Status History Service Exception Details</a>
                      </li>
                      <li>
                        <Link
                          to="/OutofOffice"
                          state={{ row, selectedSubprocess, nav, userRoles }}
                        >
                          Out of Office Settings
                        </Link>
                      </li> */}
                      </>
                    )}
                    {openActivities &&
                      (client || superadmin) &&
                      clientDashboard && (
                        <>
                          <li
                            id="liClientDashboard"
                            className="subMenu-item"
                            onClick={() => NavData("Client")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Client Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (exceptions || superadmin) &&
                      (clientDashboard || clientExceptionDashboard) && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liClientDashboardExceptions"
                            onClick={() => NavData("Client(Exception)")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Client Dashboard (Exceptions)
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (complete || superadmin) &&
                      (clientDashboard || clientCompletedDashboard) && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liClientDashboardCompleted"
                            onClick={() => NavData("Client(Completed)")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Client Dashboard (Completed)
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities && vendor && VendorDashboard && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liVendorDashboard"
                          onClick={() => NavData("Vendor")}
                        >
                          <Link
                            to="/WorkActivityDashboard"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              dashboard_customize
                            </span>
                            Vendor Dashboard
                          </Link>
                        </li>
                      </>
                    )}
                    {openActivities &&
                      (activities || superadmin) &&
                      ClosedDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liClosedDashboard"
                            onClick={() => NavData("Closed")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Closed Dashboard
                            </Link>
                          </li>
                        </>
                      )}

                    {openActivities &&
                      (activities || superadmin) &&
                      EXLExpertDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liExlExpertDashboard"
                            onClick={() => NavData("EXLExpert")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              EXL Expert Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      FifthApproverDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liFifthApproverDashboard"
                            onClick={() => NavData("Fifth Approver")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Fifth Approver Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      FourthApproverDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liFourthApproverDashboard"
                            onClick={() => NavData("Fourth Approver")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Fourth Approver Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      PreReviewDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liPreReviewDashboard"
                            onClick={() => NavData("PreReview")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              PreReview Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      RequestorDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liRequestorDashboard"
                            onClick={() => NavData("Requestor")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Requestor Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      ResolvedDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liResolvedDashboard"
                            onClick={() => NavData("Resolved")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Resolved Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      TestExceptionDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liTestExceptionDashboard"
                            onClick={() => NavData("Test Exception")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              TestException Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      ThirdApproverDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liThirdApproverDashboard"
                            onClick={() => NavData("Third Approver")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Third Approver Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      VendorCommunicationDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liVendorCommunicationDashboard"
                            onClick={() => NavData("Vendor Communication")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Vendor Communication Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {openActivities &&
                      (activities || superadmin) &&
                      VendorMaintenanceDashboard && (
                        <>
                          <li
                            className="subMenu-item"
                            id="liVendorMaintenanceDashboard"
                            onClick={() => NavData("Vendor Maintenance")}
                          >
                            <Link
                              to="/WorkActivityDashboard"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                dashboard_customize
                              </span>
                              Vendor Maintenance Dashboard
                            </Link>
                          </li>
                        </>
                      )}
                    {/* {openActivities &&
                    (activities ||
                      superadmin ||
                      workFlowstatechange ||
                      reassignment ||
                      adhocEditing ||
                      undoActivity) && (
                      <li>
                        <li>
                          <Link
                            to="/ManageWorkitem"
                            state={{ row, selectedSubprocess, nav, userRoles }}
                          >
                            Manage Workitems
                          </Link>
                        </li>
                      </li>
                    )} */}
                    {/*  {openActivities && (superadmin || clientDailyInfo) && (
                      <>
                        <li>
                          <a>Client Daily Info</a>
                        </li>
                      </>
                    )}
                    {openActivities && superadmin && (
                      <li>
                        <li>
                          <Link
                            to="/ExportConfiguration"
                            state={{ row, selectedSubprocess, nav, userRoles }}
                          >
                            Export Sub-Process Configuration
                          </Link>
                        </li>
                      </li>
                    )}
                    {openActivities && (superadmin || workitemBulkUpdation) && (
                      <li>
                        <li>
                          <Link
                            to="/WorkitemUpdation"
                            state={{ row, selectedSubprocess, nav, userRoles }}
                          >
                            Workitem Bulk Updation
                          </Link>
                        </li>
                      </li>
                    )}
                    {openActivities && (superadmin || cwsRestrictedSearch) && (
                      <li>
                        <li>
                          <a>Search My Workitems</a>
                        </li>
                      </li>
                    )}*/}
                    {openActivities &&
                      (activities || superadmin) && (
                        <li
                          className="subMenu-item"
                          id="liUnlockWorkitems"
                          onClick={() => NavData("Unlock workitems")}
                        >
                          <Link
                            to="/UnlockWorkitems"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              lock_open_right
                            </span>
                            Unlock Workitems
                          </Link>
                        </li>
                      )}
                    {/* {openActivities && manageWorkItems && (activities || superadmin) && ( */}

                    {openActivities &&
                      manageWorkItems &&
                      (activities || superadmin) && (
                        <li
                          className="subMenu-item"
                          id="liManageWorkItem"
                          onClick={() => NavData("Manage workitems")}
                        >
                          <Link
                            to="/manageworkitem"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              settings_ethernet
                            </span>
                            Manage WorkItem
                          </Link>
                        </li>
                      )}
                    {openActivities && (activities || superadmin) && (
                      <li
                        className="subMenu-item"
                        id="liOutOfOffice"
                        onClick={() => NavData("Out of Office")}
                      >
                        <Link
                          to="/OutofOffice"
                          state={{
                            row: row,
                            selectedSubprocess,
                            nav,
                            userRoles,
                            defaultFilter,
                            assignedWorkItemDetails: selectedAssignWorkitem
                          }}
                        >
                          <span class="material-symbols-outlined submenu-icon">
                            phone_disabled
                          </span>
                          Out of Office
                        </Link>
                      </li>
                    )}
                  </div>
                </li>
              </>
            ) : null}
            {/* disabled for now as per Amit Sinha */}

            {admin || superadmin || workItemFieldadmin ? (
              <>
                <a
                  title="Admin"
                  id="togAdmin"
                  className={
                    selectedMenu == "admin"
                      ? " nav-toggle active"
                      : "nav-toggle"
                  }
                  onKeyPress={() => toggleAdmin(!openAdmin)}
                  onClick={() => toggleAdmin(!openAdmin)}
                >
                  <b class="material-symbols-outlined">admin_panel_settings</b>{" "}
                  <span>Admin</span>
                </a>
                <li>
                  <div>
                    {/* {openAdmin && admin && ( */}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liUploadClientLogo"
                          onClick={() => NavData("Upload Client Logo")}
                        >
                          <Link
                            to="/UploadClientLogo"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              macro_auto
                            </span>
                            Upload Client Logo
                          </Link>
                        </li>
                      </>
                    )}

                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liErrorCategory"
                          onClick={() => NavData("ErrorCategory")}
                        >
                          <Link
                            to="/ErrorCategory"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              production_quantity_limits
                            </span>
                            ErrorCategory
                          </Link>
                        </li>
                      </>
                    )}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          onClick={() => NavData("Manage News")}
                        >
                          <Link
                            to="/ManageNews"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              macro_auto
                            </span>
                            Manage News
                          </Link>
                        </li>
                      </>
                    )}
                    {/* {openAdmin && (
                      <>
                        <li onClick={() => NavData("FormBuilderDemo")}>
                          <Link
                            to="/FormBuilderDemo"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              production_quantity_limits
                            </span>
                            FormBuilderDemo
                          </Link>
                        </li>
                      </>
                    )} */}
                    {/* {openAdmin && (
                      <>
                        <li onClick={() => NavData("WorkflowWizard")}>
                          <Link
                            to="/WorkflowWizard"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              production_quantity_limits
                            </span>
                            Configuration Wizard
                          </Link>
                        </li>
                      </>
                    )} */}
                    {/* {openAdmin && (
                      <li
                        className="subMenu-item"
                        onClick={() => NavData("BpmnEditor")}
                      >
                        <Link
                          to="/BpmnEditor"
                          state={{
                            row,
                            selectedSubprocess,
                            nav,
                            userRoles,
                            defaultFilter,
                            assignedWorkItemDetails,
                          }}
                        >
                          <span class="material-symbols-outlined submenu-icon">
                            gallery_thumbnail
                          </span>
                          Generate Workflow
                        </Link>
                      </li>
                    )} */}

                    {/* added by Santosh */}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liOndCommonFieldMapping"
                          onClick={() => NavData("ONDCommonFieldMapping")}
                        >
                          <Link
                            to="/ONDCommonFieldMapping"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              Tune
                            </span>
                            OND Common Field Mapping
                          </Link>
                        </li>
                      </>
                    )}

                    {/* added by Harshita*/}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          onClick={() => NavData("Report Config")}
                        >
                          <Link
                            to="/ReportConfig"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              add_circle
                            </span>
                            Report Config
                          </Link>
                        </li>
                      </>
                    )}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liFieldValueGroupConfig"
                          onClick={() => NavData("SubProcessFieldValueGroupConfig")}
                        >
                          <Link
                            to="/SubProcessFieldValueGroupConfig"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem,
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              macro_auto
                            </span>
                            Sub-Process Field Value Group Configuration
                          </Link>
                        </li>
                      </>
                    )}

                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          onClick={() => NavData("SubProcessInstructionListConfig")}
                        >
                          <Link
                            to="/SubProcessInstructionListConfig"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              Tune
                            </span>
                            Sub Process Instruction List Config
                          </Link>
                        </li>
                      </>
                    )}
                    {/* added by Harshita*/}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          onClick={() => NavData("SubProcess Random Review Config")}
                        >
                          <Link
                            to="/SubProcessRandomReviewConfig"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              add_circle
                            </span>
                            SubProcess Random Review Config
                          </Link>
                        </li>
                      </>
                    )}
                    {/* added by Harshita*/}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          onClick={() => NavData("Sub-Process Taxonomy Mapping Config")}
                        >
                          <Link
                            to="/SubProcessTaxonomyMappingConfig"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                              add_circle
                            </span>
                            Sub-Process Taxonomy Mapping Config
                          </Link>
                        </li>
                      </>
                    )}
                    {openAdmin && (
                      <>
                        <li
                          className="subMenu-item"
                          onClick={() => NavData("Vendor Portal")}
                        >
                          <Link
                            to="/VendorPortalConfig"
                            state={{
                              row: row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails: selectedAssignWorkitem
                            }}
                          >
                            <span class="material-symbols-outlined submenu-icon">
                            add_shopping_cart
                            </span>
                            Vendor Portal Config
                          </Link>
                        </li>
                      </>
                    )}


                  </div>
                </li>
              </>
            ) : null}

            {/* {admin || superadmin || workItemFieldadmin ? (
              <li>
                <a
                  className="nav-toggle"
                  onKeyPress={() => toggleAdmin(!openAdmin)}
                  onClick={() => toggleAdmin(!openAdmin)}
                >
                  <b className="icon-user"></b> <span>Admin</span>
                </a>
                <div>
                  {openAdmin && admin && (
                    <li>
                      <li>
                        <a>Activities</a>
                      </li>
                      <li>
                        <a>Workflow State</a>
                      </li>
                      <li>
                        <a>Error Categories</a>
                      </li>
                      <li>
                        <a>Workitem Statuses</a>
                      </li>
                      <li>
                        <a>News</a>
                      </li>
                      <li>
                        <a>Validation Rules</a>
                      </li>
                      <li>
                        <a>View Error Logs</a>
                      </li>
                      <li>
                        <a>View Report Logs</a>
                      </li>
                      <li>
                        <a>Import Sub-Process Configuration</a>
                      </li>
                      <li>
                        <a>View Service Logs</a>
                      </li>
                      <li>
                        <a>View Error Logs</a>
                      </li>
                      <li>
                        <a>Support Dashboard</a>
                      </li>
                    </li>
                  )}
                </div>
              </li>
            ) : null} */}
            {Configuration || superadmin ? (
              <>
                <a
                  title="Business Rules"
                  id="togBusinessRules"
                  className={
                    selectedMenu == "bussinessrules"
                      ? " nav-toggle active"
                      : "nav-toggle"
                  }
                  onKeyPress={() => toggleBusinessRules(!businessRules)}
                  onClick={() => toggleBusinessRules(!businessRules)}
                >
                  <b class="material-symbols-outlined">gavel</b>{" "}
                  <span>Business Rules</span>
                </a>
                <li>
                  <div>
                    <>
                      {businessRules &&
                        UserClassificationMapping &&
                        (Configuration || superadmin) && (
                          <li
                            className="subMenu-item"
                            id="liUserClassificationMapping"
                            onClick={() => NavData("Classification Mapping")}
                          >
                            <Link
                              to="/SubprocessUserClassificationMapping"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                merge
                              </span>
                              User Classification Mapping
                            </Link>
                          </li>
                        )}

                      {businessRules &&
                        UserClassificationList &&
                        (Configuration || superadmin) && (
                          <li
                            className="subMenu-item"
                            id="liUserClassificationList"
                            onClick={() => NavData("Classification List")}
                          >
                            <Link
                              to="/SubProcessUserClassificationList"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                map
                              </span>
                              User Classification List
                            </Link>
                          </li>
                        )}

                      {businessRules &&
                        WorkitemFieldsRules &&
                        (Configuration || superadmin) && (
                          <li
                            className="subMenu-item"
                            id="liWorkitemFieldsRules"
                            onClick={() => NavData("Workitem Fields Rules")}
                          >
                            <Link
                              to="/WorkitemFieldsRules"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                display_settings
                              </span>
                              Workitem Fields Rules
                            </Link>
                          </li>
                        )}
                      {businessRules &&
                        (Configuration || superadmin) && (
                          <li
                            className="subMenu-item"
                            onClick={() => NavData("SubProcessMoveWorkitem")}
                          >
                            <Link
                              to="/SubProcessMoveWorkitem"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                tune
                              </span>
                              Sub-Process Move WorkItem Config
                            </Link>
                          </li>
                        )}
                      {businessRules &&
                        (Configuration || superadmin) && (
                          <li
                            className="subMenu-item"
                            onClick={() => NavData("Sub Process Bulk Update File Config")}
                          >
                            <Link
                              to="/SubProcessBulkUpdateFileConfig"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                macro_auto
                              </span>
                              Sub Process Bulk Update File Config
                            </Link>
                          </li>
                        )}
                      {businessRules &&
                        (Configuration || superadmin) && (
                          <li
                            className="subMenu-item"
                            onClick={() => NavData("Sub Process Auto Assignment")}
                          >
                            <Link
                              to="/SubProcessAutoAssignment"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                macro_auto
                              </span>
                              Sub Process Auto Assignment
                            </Link>
                          </li>
                        )}
                    </>
                  </div>
                </li>
              </>
            ) : null}

            {/* added for Configuration Wizard and need to be comment out for Customworkflow */}

            {/* Configuration Wizard Start */}
            {/* {Configuration || superadmin ? (
              <div>
                <a
                  title="Configuration Wizard"
                  className={
                    selectedMenu == "configurationWizard"
                      ? " nav-toggle active"
                      : "nav-toggle"
                  }
                  //onKeyPress={() => toggleConfiguration(!openConfiguration)}
                  onClick={() => NavData("WorkflowWizard")}
                >
                  <Link
                    to="/WorkflowWizard"
                    state={{
                      row,
                      selectedSubprocess,
                      nav,
                      userRoles,
                      defaultFilter,
                      assignedWorkItemDetails,
                    }}
                  >
                    <b class="material-symbols-outlined">settings</b>
                    <span>Configuration Wizard</span>
                  </Link>
                </a>
              </div>
            ) : null} */}

            {/* Configuration Wizard End */}

            {/* disabled for now as per discussion with AMIT Sinha
            {Configuration || superadmin ? (
              <li>
                <a className="nav-toggle">
                  <b className="icon-settings"></b> <span>Configuration</span>
                </a>
              </li>
            ) : null} */}
            {/* {
              <>
                <a
                  title="Click here to move to Customer Account Management"
                  target="_blank"
                  href="http://10.11.102.41/UI/CaseEnquiry.aspx"
                >
                  <b class="material-symbols-outlined">manage_accounts</b>
                  <span>Customer Account </span>
                </a>
              </>
            } */}
            {/* disabled for now as per Amit Sinha
            {manageUsers || superadmin ? (
              <li>
                <a className="nav-toggle">
                  <b className="icon-users"></b> <span>Manage Users</span>
                </a>
                <ul>
                  <li>
                    <a>Error Logs</a>
                  </li>
                </ul>
              </li>
            ) : null} */}

            {report || superadmin ? (
              <>
                <a
                  title="Report"
                  id="togReport"
                  className={
                    selectedMenu == "reports"
                      ? " nav-toggle active"
                      : "nav-toggle"
                  }
                  onKeyPress={() => toggleReport(!openReport)}
                  onClick={() => toggleReport(!openReport)}
                >
                  <b class="material-symbols-outlined">finance</b>{" "}
                  <span>Report</span>
                </a>
                <li>
                  <div>
                    {openReport && (report || superadmin) && (
                      <>
                        <li
                          className="subMenu-item"
                          id="liReports"
                          onClick={() => NavData("Report")}
                        >
                          {/* <a
                            target="_blank"
                            href="http://10.11.102.41/UI/CaseEnquiry.aspx"
                          > */}
                          <Link
                            to="/Reports"
                            state={{
                              row,
                              selectedSubprocess,
                              nav,
                              userRoles,
                              defaultFilter,
                              assignedWorkItemDetails,
                            }}
                          >
                            <span class="material-symbols-outlined">
                              analytics
                            </span>
                            Reports
                          </Link>
                          {/* </a> */}
                        </li>
                        {RealTimeDashboard && (
                          <>
                            <li
                              className="subMenu-item"
                              id="liRealTimeDashboard"
                              onClick={() => NavData("RealTimeDashboard")}
                            >
                              {/* <a

                            target="_blank"

                            href="http://10.11.102.41/UI/CaseEnquiry.aspx"

                          > */}

                              <Link
                                to="/RealTimeDashboard"
                                state={{
                                  row,

                                  selectedSubprocess,

                                  nav,

                                  userRoles,

                                  defaultFilter,

                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                  earthquake
                                </span>
                                RealTime Dashboard
                              </Link>

                              {/* </a> */}
                            </li>
                          </>
                        )}
                        {QueueManagement && (
                          <>
                            <li
                              className="subMenu-item"
                              onClick={() => NavData("QueueManagementDashboard")}
                            >
                              {/* <a

                            target="_blank"

                            href="http://10.11.102.41/UI/CaseEnquiry.aspx"

                          > */}

                              <Link
                                to="/QueueManagementDashboard"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                queue
                                </span>
                                Queue Management Dashboard
                              </Link>

                              {/* </a> */}
                            </li>
                          </>
                        )}
                         {BucketManagement && (
                          <>
                            <li
                              className="subMenu-item"
                              onClick={() => NavData("BucketManagementDashboard")}
                            >
                              {/* <a

                            target="_blank"

                            href="http://10.11.102.41/UI/CaseEnquiry.aspx"

                          > */}

                              <Link
                                to="/BucketManagementDashboard"
                                state={{
                                  row,
                                  selectedSubprocess,
                                  nav,
                                  userRoles,
                                  defaultFilter,
                                  assignedWorkItemDetails,
                                }}
                              >
                                <span class="material-symbols-outlined submenu-icon">
                                dashboard
                                </span>
                                Bucket Management Dashboard
                              </Link>

                              {/* </a> */}
                            </li>
                          </>
                        )}
                        {reportids != null && reportids[0] != null ? (
                          <li
                            className="subMenu-item"
                            id="liEmbeddedReports"
                            onClick={() => NavData("EmbeddedReports")}
                          >
                            {/* <a
                             target="_blank"
                             href="http://10.11.102.41/UI/CaseEnquiry.aspx"
                           > */}
                            <Link
                              to="/EmbeddedReports"
                              state={{
                                row,
                                selectedSubprocess,
                                nav,
                                userRoles,
                                defaultFilter,
                                assignedWorkItemDetails,
                              }}
                            >
                              <span class="material-symbols-outlined submenu-icon">
                                gallery_thumbnail
                              </span>
                              Embedded Reports.
                            </Link>
                            {/* </a> */}
                          </li>
                        ) : null}

                        {/* {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString ==
                              companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))} */}
                      </>
                    )}
                  </div>
                </li>
              </>
            ) : null}
            {/* {
              <>
                {/* <li>
                  <a
                    target="_blank"
                    href="http://10.11.102.41/QC_PD_CWS_1DN/index.html"
                  >
                    <b className="icon-user-check"></b>{" "}
                    <span>CWS One View </span>
                  </a>
                </li> 
              </>
           */}

            {/* {
              <>
                <li>
                  <a>
                    <b className="icon-user-check"></b>{" "}
                    <span>Update Profile</span>
                  </a>
                </li>
              </>
            } */}
          </ul>
        </nav>
        <div className="app-name">
          <span className="nav-Seperator"></span>Custom Workflow Solution{" "}
          {"   "}
        </div>

        <a className="modeToggle" onClick={onModeClick}>
          <b className="icon-chevrons-left"></b>
        </a>
      </div>
    </>
  );
};

export default Nav;
