import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "react-bootstrap/Modal";
import { Paper } from "@material-ui/core";
import { padding } from "@mui/system";
import GlobalStyles from "@mui/material/GlobalStyles";


const UploadExcelColumns = (props) => {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GetUploadExcelColumns } = process.env;
  const { REACT_APP_SaveUploadExcelColumns } = process.env;
  const { REACT_APP_GetEmailConfigColumns } = process.env;
  const { REACT_APP_SaveEmailConfigColumns } = process.env;
  const { REACT_APP_GetDefaultEmailLabels } = process.env;
  const { REACT_APP_GetSubProcessUploadExcelColumnsForListPage } = process.env;

  const { addToast } = useToasts();
  let navigate = useNavigate();
  const displayOrder = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 128, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186,
    187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200,
  ];
  let ExportColumns = [
    {
      title: "Label Text",
      field: "labelText",
    },
    {
      title: "Data Type",
      field: "dataType",
    },
    {
      title: "Column No",
      field: "columnNo",
    },
    {
      title: "Column No",
      field: "columnNo",
    },
    {
      title: "AllowBlank",
      field: "allowBlank",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            disabled="true"
            checked={rowData.allowBlank}
            //onClick={(e) => handleallowBlank(e, rowData)}
          />
        </>
      ),
    },
    {
      title: "IsPartOfUniqueKey",
      field: "isPartOfUniqueKey",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            disabled="true"
            checked={rowData.isPartOfUniqueKey}
            //onClick={(e) => handleisPartOfUniqueKey(e, rowData)}
          />
        </>
      ),
    },
  ];
  let Columns = [
    {
      title: "IsSelected",
      field: "isSelected",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            //
            checked={rowData.isSelected}
            onClick={(e) => handleselected(e, rowData)}
          />
        </>
      ),
    },
    {
      title: "UploadExcelColumnID",
      field: "uploadExcelColumnID",
      hidden: true,
    },
    { title: "LabelText", field: "labelText" },
    { title: "MaptoTable", field: "maptoTable", hidden: true },
    { title: "WorkItemFieldID", field: "workItemFieldID", hidden: true },
    {
      title: "ColumnNo",
      field: "columnNo",
      render: (rowData) => (
        <>
          <select
            id="displayOrder"
            className="form-control form-control-sm"
            onChange={(e) => handleDisplayOrder(e, rowData)}
          >
            <option>Select</option>
            {displayOrder.map((Order) => (
              <option value={Order} selected={+Order == rowData.columnNo}>
                {Order}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "AllowBlank",
      field: "allowBlank",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            //
            checked={rowData.allowBlank}
            onClick={(e) => handleallowBlank(e, rowData)}
          />
        </>
      ),
    },
    {
      title: "IsPartOfUniqueKey",
      field: "isPartOfUniqueKey",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            //
            checked={rowData.isPartOfUniqueKey}
            onClick={(e) => handleisPartOfUniqueKey(e, rowData)}
          />
        </>
      ),
    },
  ];
  let EmailColumns = [
    {
      title: "IsSelected",
      field: "isSelected",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            checked={rowData.isSelected}
            onClick={(e) => handleselectedForEmail(e, rowData)}
          />
        </>
      ),
    },
    {
      title: "UploadEmailColumnID",
      field: "uploadEmailColumnID",
      hidden: true,
    },
    { title: "LabelText", field: "labelText" },
    {
      title: "DefaultEmailLabelId",
      field: "defaultEmailLabelId",
      render: (rowData) => (
        <>
          <select
            id="displayOrder"
            className="form-control form-control-sm"
            disabled="true"
            //onChange={(e) => handleDefaultEmailLabel(e, rowData)}
          >
            <option>Select</option>
            {DefaultEmailLabel.map((Order) => (
              <option
                value={Order.defaultEmailLabelID}
                selected={
                  Order.defaultEmailLabelID == rowData.defaultEmailLabelId
                }
              >
                {Order.labelText}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "WorkItemFieldID",
      field: "workItemFieldID",
      hidden: true,
    },
    {
      title: "SequenceNo",
      field: "sequenceNo",
      render: (rowData) => (
        <>
          <select
            id="displayOrder"
            className="form-control form-control-sm"
            onChange={(e) => handleDisplayOrderForEmail(e, rowData)}
          >
            <option>Select</option>
            {displayOrder.map((Order) => (
              <option value={Order} selected={+Order == rowData.sequenceNo}>
                {Order}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "AllowBlank",
      field: "allowBlank",
      render: (rowData) => (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            //
            checked={rowData.allowBlank}
            onClick={(e) => handleallowBlankForEmail(e, rowData)}
          />
        </>
      ),
    },
  ];
  const [UploadExcelColumns, setUploadExcelColumns] = useState([]);
  const [EmailConfigColumns, setEmailConfigColumns] = useState([]);
  const [DefaultEmailLabel, setDefaultEmailLabel] = useState([]);
  const [viewExcelColModal, setviewExcelColModal] = useState(false);
  const [ExcelColmns, setExcelColmns] = useState([]);
  const [isRefresh, setisRefresh] = useState(false);
  const [value, setValue] = React.useState("1");
  const handleDisplayOrder = (e, rowData) => {
    let newDashboardWorkitemFieldConfigs = UploadExcelColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, columnNo: +e.target.value };
      }
      return item;
    });
    setUploadExcelColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleDisplayOrderForEmail = (e, rowData) => {
    let newDashboardWorkitemFieldConfigs = EmailConfigColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, sequenceNo: +e.target.value };
      }
      return item;
    });
    setEmailConfigColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleDefaultEmailLabel = (e, rowData) => {
    let newDashboardWorkitemFieldConfigs = EmailConfigColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, defaultEmailLabelId: +e.target.value };
      }
      return item;
    });
    setEmailConfigColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleisPartOfUniqueKey = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = UploadExcelColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, isPartOfUniqueKey: e.target.checked };
      }
      return item;
    });
    setUploadExcelColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleallowBlank = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = UploadExcelColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, allowBlank: e.target.checked };
      }
      return item;
    });
    setUploadExcelColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleallowBlankForEmail = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = EmailConfigColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, allowBlank: e.target.checked };
      }
      return item;
    });
    setEmailConfigColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleselected = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = UploadExcelColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, isSelected: e.target.checked };
      }
      return item;
    });
    setUploadExcelColumns(newDashboardWorkitemFieldConfigs);
  };
  const handleselectedForEmail = (e, rowData) => {
    debugger;
    let newDashboardWorkitemFieldConfigs = EmailConfigColumns.map((item) => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, isSelected: e.target.checked };
      }
      return item;
    });
    setEmailConfigColumns(newDashboardWorkitemFieldConfigs);
  };
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const GetUploadExcelColumns = () => {
    debugger;
    let body = {
      subprocessId: props.reference.SubprocessID,
    };
    let GetUploadExcelUrl = getAPIURL(REACT_APP_GetUploadExcelColumns);
    let req = new Request(`${GetUploadExcelUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setUploadExcelColumns(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetEmailColumns = () => {
    debugger;
    let body = {
      subprocessId: props.reference.SubprocessID,
    };
    let GetUploadExcelUrl = getAPIURL(REACT_APP_GetEmailConfigColumns);
    let req = new Request(`${GetUploadExcelUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setEmailConfigColumns(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetDefaultEmailLabel = () => {
    let GetDefaultEmailLabelUrl = getAPIURL(REACT_APP_GetDefaultEmailLabels);
    let req = new Request(`${GetDefaultEmailLabelUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setDefaultEmailLabel(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const handleExport = () => {
    GetSubProcessUploadExcelColumnsForListPage();
    setviewExcelColModal(true);
  };
  const GetSubProcessUploadExcelColumnsForListPage = () => {
    let body = {
      subprocessId: props.reference.SubprocessID,
    };
    let ExportExcelUrl = getAPIURL(
      REACT_APP_GetSubProcessUploadExcelColumnsForListPage
    );
    let req = new Request(`${ExportExcelUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          setExcelColmns(result.data);
          addToast("Successfully Uploaded!!", {
            appearance: "success",
            autoDismiss: true,
          });
          setisRefresh(true);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Export Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const viewExcelColClose = () => {
    setviewExcelColModal(false);
  };
  const handleSave = () => {
    debugger;
    if (value == "1") {
      let body = {
        subprocessId: props.reference.SubprocessID,
        UploadExcelColumns: UploadExcelColumns,
        UserID: sessionStorage.getItem("PersonID"),
      };
      let SaveExcelUrl = getAPIURL(REACT_APP_SaveUploadExcelColumns);
      let req = new Request(`${SaveExcelUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            debugger;
            addToast("Successfully Uploaded!!", {
              appearance: "success",
              autoDismiss: true,
            });
            setisRefresh(true);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Uploaded Failed", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    } else {
      let body = {
        SubprocessId: props.reference.SubprocessID,
        EmailConfigColumns: EmailConfigColumns,
        UserID: sessionStorage.getItem("PersonID"),
      };
      let SaveExcelUrl = getAPIURL(REACT_APP_SaveEmailConfigColumns);
      let req = new Request(`${SaveExcelUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            debugger;
            addToast("Successfully Uploaded!!", {
              appearance: "success",
              autoDismiss: true,
            });
            setisRefresh(true);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Uploaded Failed", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };
  useEffect(() => {
    GetDefaultEmailLabel();
    GetUploadExcelColumns();
    GetEmailColumns();
  }, [isRefresh]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="">
        <div className="pageBody ">
          <div className="">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Upload Excel Columns" value="1"></Tab>
                    <Tab
                      label="Email Columns"
                      value="2"
                      style={{ paddingLeft: "20px" }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" style={{ padding: "0px" }}>
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          options={{
                            headerStyle: {
                              position: "sticky",
                              // top: "-5px",
                            },
                            maxBodyHeight: "420px",
                            pageSize: 100,
                            exportAllData: true,
                            showEmptyDataSourceMessage: true,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [100, 200, 500, 1000],
                            debounceInterval: 700,
                            exportButton: false,
                            // exportCsv: () => {
                            //   getDashboardDataForExport();
                            // },
                            sorting: true,
                            filtering: true,
                            search: false,
                            //selection: true,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: false,
                            toolbarButtonAlignment: "left",
                            headerStyle: {
                              //  height: 30,
                            },
                            rowStyle: {},
                            tableLayout: "auto",
                            headerStyle: {
                              // fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              // fontSize: "14px",
                              // textAlign: "left !Important",
                            },
                          }}
                          columns={Columns}
                          icons={tableIcons}
                          data={UploadExcelColumns}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2" style={{ padding: "0px" }}>
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          options={{
                            headerStyle: {
                              position: "sticky",
                              // top: "-5px",
                            },
                            maxBodyHeight: "420px",
                            pageSize: 100,
                            exportAllData: true,
                            showEmptyDataSourceMessage: true,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [100, 200, 500, 1000],
                            debounceInterval: 700,
                            exportButton: false,
                            // exportCsv: () => {
                            //   getDashboardDataForExport();
                            // },
                            sorting: true,
                            filtering: true,
                            search: false,
                            //selection: true,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: false,
                            toolbarButtonAlignment: "left",
                            headerStyle: {
                              //  height: 30,
                            },
                            rowStyle: {},
                            tableLayout: "auto",
                            headerStyle: {
                              // fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              // fontSize: "14px",
                              // textAlign: "left !Important",
                            },
                          }}
                          columns={EmailColumns}
                          icons={tableIcons}
                          data={EmailConfigColumns}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
            <button
              ref={props.reference.UploadExcelConfigRef}
              style={{ display: "none" }}
              onClick={handleSave}
            ></button>
            <button
              ref={props.reference.ExportExcelColRef}
              style={{ display: "none" }}
              onClick={handleExport}
            ></button>
          </div>
        </div>
      </div>
      {/** VIEW WORKITEM MODAL **/}
      <Modal
        show={viewExcelColModal}
        dialogClassName="modal-100w"
        onHide={viewExcelColClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton><h6>Export Columns</h6></Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <div className="dashBoxs">
              {/*  */}
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table
                      className="table table-borderless MatTable"
                      style={{ zIndex: "1", position: "relative" }}
                    >
                      <MaterialTable
                        title="Upload Excel Columns"
                        options={{
                          search: false,
                          overflowY: false,
                          showTitle: false,
                          exportButton:true,
                          pageSize:10,
                          cellStyle: {
                            // width: 100,
                            // minWidth: 100,
                            whiteSpace: "Normal",
                          },
                          headerStyle: {
                            position: "sticky",
                            // top: "-5px",
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                          },
                        }}
                        columns={ExportColumns}
                        icons={tableIcons}
                        data={ExcelColmns}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <GlobalStyles
            styles={{
              ".MuiButtonBase-root.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                cursor: "default !important",
                PointerEvent: "none !important",
              },
              ".MuiButton-root": {
                color: "#1976d2 !important",               
              },
            }}
          /> */}
    </>
  );
};

export default UploadExcelColumns;
