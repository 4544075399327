import React, { useState, useEffect } from "react";

function DisplayTimeZone(data, preferredTimeZone) {
  
  let datainput = data;
  for (let i = 0; i < datainput.length > 0; i++) {
    var finalDate;
    var columnValues;
    if (datainput[i].LastActivityOn != null) {
      finalDate = datainput[i].LastActivityOn;
      finalDate = new Date(finalDate);
      finalDate = finalDate.toISOString();
      columnValues = "LastActivityOn";
    } else if (datainput[i].assignmentDate != null) {
      finalDate = datainput[i].assignmentDate;
      finalDate = new Date(finalDate);
      finalDate = finalDate.toISOString();
      columnValues = "assignmentDate";
    } else if (datainput[i].statusDateTime != null) {
      finalDate = datainput[i].statusDateTime;
      // finalDate= new Date(finalDate);
      // finalDate=finalDate.toISOString();
      columnValues = "statusDateTime";
    } else if (datainput[i].lockedOn != null) {
      finalDate = datainput[i].lockedOn;
      finalDate = new Date(finalDate);
      finalDate = finalDate.toISOString();
      columnValues = "lockedOn";
    }

    let dateobject = new Date(finalDate);
    const now = new Date();
    const isoffset = 5.5 * 60;
    //const isoffset = 0;
    const sysoffset = now.getTimezoneOffset();
    const timedifference = (isoffset + sysoffset) * -1;

    function addMinutes(date, minutes) {
      date.setMinutes(date.getMinutes() + minutes);
      return date;
    }

    const date = new Date(dateobject);
    const result = addMinutes(date, timedifference);
    var option = null;
    if((preferredTimeZone != null || preferredTimeZone != undefined) && columnValues == "statusDateTime")
    {
        option = {
          timeZone: "America/New_York",
        };
    }
    else 
    {
      option = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }
    const Localtimesys = result.toLocaleString("en-US", option);

    if (columnValues == "LastActivityOn") {
      data[i].LastActivityOn = Localtimesys;
    } else if (columnValues == "assignmentDate") {
      data[i].assignmentDate = Localtimesys;
    } else if (columnValues == "statusDateTime") {
      data[i].statusDateTime = Localtimesys;
    } else if (columnValues == "lockedOn") {
      data[i].lockedOn = Localtimesys;
    }
  }
  return datainput;
}

function UploaddateLocalTime(data) {
  let datainput = data;
  for (let i = 0; i < datainput.length > 0; i++) {
    var finalDate;
    var columnValues;
    var finalDateUploadDate;

    if (datainput[i].uploadDate != null) {
      finalDate = datainput[i].uploadDate;
      finalDate = new Date(finalDate);
      finalDate = finalDate.toISOString();
      columnValues = "uploadDate";

      let dateobject = new Date(finalDate);
      const now = new Date();
      const isoffset = 5.5 * 60;
      const sysoffset = now.getTimezoneOffset();
      const timedifference = (isoffset + sysoffset) * -1;

      function addMinutes(date, minutes) {
        date.setMinutes(date.getMinutes() + minutes);
        return date;
      }

      const date = new Date(dateobject);
      const result = addMinutes(date, timedifference);
      const option = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const Localtimesys = result.toLocaleString("en-US", option);

      if (columnValues == "uploadDate") {
        data[i].uploadDate = Localtimesys;
      }
    }

    /*Update start Upload_date_dashBoard*/
    if (datainput[i].UploadDate != null) {
      finalDateUploadDate = datainput[i].UploadDate;
      finalDateUploadDate = new Date(finalDateUploadDate);
      finalDateUploadDate = finalDateUploadDate.toISOString();
      columnValues = "UploadDate";

      let dateobjectUploadDate = new Date(finalDateUploadDate);
      const nowupdte = new Date();
      const isoffsetupdte = 5.5 * 60;
      const sysoffsetupdte = nowupdte.getTimezoneOffset();
      const timedifferenceupdte = (isoffsetupdte + sysoffsetupdte) * -1;

      function addMinutes(dateupdte, minutes) {
        dateupdte.setMinutes(dateupdte.getMinutes() + minutes);
        return dateupdte;
      }

      const dateupdte = new Date(dateobjectUploadDate);
      const resultupdteupdte = addMinutes(dateupdte, timedifferenceupdte);
      const optionupdte = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const Localtimesysupdte = resultupdteupdte.toLocaleString(
        "en-US",
        optionupdte
      );

      if (columnValues == "UploadDate") {
        data[i].UploadDate = Localtimesysupdte;
      }
    }
    /*end Upload_date_dashBoard*/

    /*Start Ship date */
    if (datainput[i].Ship_Date != null && datainput[i].Ship_Date != "") {
      finalDate = datainput[i].Ship_Date;
      finalDate = new Date(finalDate);
      finalDate = finalDate.toISOString();
      columnValues = "Ship_Date";

      let dateobject = new Date(finalDate);
      const now = new Date();
      const isoffset = 5.5 * 60;
      const sysoffset = now.getTimezoneOffset();
      const timedifference = (isoffset + sysoffset) * -1;

      function addMinutes(date, minutes) {
        date.setMinutes(date.getMinutes() + minutes);
        return date;
      }

      const date = new Date(dateobject);
      const result = addMinutes(date, timedifference);
      const option = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const Localtimesys = result.toLocaleString("en-US", option);

      if (columnValues == "Ship_Date") {
        data[i].Ship_Date = Localtimesys;
      }
    }
    /*end Ship_Date*/
  }
  return datainput;
}

//export  DisplayTimeZone;
export { DisplayTimeZone, UploaddateLocalTime };
