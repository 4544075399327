import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../../components/header/Header";
import Footer from "../../../../../components/footer/Footer";
import Nav from "../../../../../components/nav/Nav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import ApplicationUserContext from "../../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import tableIcons from "../../../../../MaterialTableIcons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FcViewDetails } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import { FcDeleteColumn } from "react-icons/fc";
import PendingActionsIcon from "@mui/icons-material/";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinner from "../../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../../LoadingSpinnerFigma";
import Iframe from "react-iframe";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";

import user01 from "../../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../../assets/images/icons/icon_empty.svg";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

//debugger;
const Activities = () => {
  const StatusNameRef = useRef("");
  const WorkItemStatusIDRef = useRef(0);  
  const displayOrderRef = useRef("");
  const RemarksRef = useRef("");
  const isDeletedRef = useRef("");
  const ButtonTextRef = useRef("");

   const NameEditref = React.useRef(null);
   const activityGroupRef = useRef("");
   const isDeletedref = useRef("");
   const isEditableref = useRef(null);
   const activityTyperef = useRef("");
   const TypeRef = useRef("");
   const remarksref = useRef("");
   const GroupRef = useRef("");
  
  const { addToast } = useToasts();
  
  const { REACT_APP_API_URL } = process.env;
  
  const { REACT_APP_SAVETIMEONTICK } = process.env;
 // const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_GETACTIVITIES } = process.env;
  const { REACT_APP_UPDATEACTIVITIES } = process.env;
  const { REACT_APP_DELETEACTIVITY } = process.env;
  const { REACT_APP_ADDACTIVITIES } = process.env;

  //const { REACT_APP_GETSTATUSBYID } = process.env;
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  //const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
 
  const [activitiesData, setactivitiesData] = useState([]);
  const[selectedColumns,setSelectedColumns] = useState([]);

  const [displayOrderArry,setdisplayOrderArry] =useState([]);
  
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [buttonText, setbuttonText] = useState("Save");
   const [openPopUp, setopenPopUp]=useState(false);
   const [openAddPopUp, setopenAddPopUp]=useState(false);
   const [workStatus,setworkStatus] = useState("");
   const [displayOrder,setdisplayOrder]=useState("");
   const [remarks,setremarks]=useState("");
   const [isDeleted,setisDeleted]=useState("");
   const [workStatusID,setworkStatusID]=useState(0);
   const [activityName, setactivityName]=useState("");
   const [activityType, setactivityType]=useState("");
   const [activityGroup, setactivityGroup]=useState("");
   const [isEditable, setisEditable] = useState("");
   const [activityId, setactivityId]= useState(null);
   const [error, seterror]=useState("");
   const activityNameref = useRef("");
   const [Datacount, setDatacount] = useState(0);
   

   const onChange = () =>{
  setisDeleted(!isDeleted);
   }

   const onChangeActivityName=(event) => {
    debugger;
    event.preventDefault();
     setactivityName(event.target.value);
     //NameEditref.current = event.target.value;
  
   }
   const onChangeActivityType = (event) =>{
    setactivityType(event.target.value);
   }
   const OnChangeActivityGroup = (event) =>{
    setactivityGroup(event.target.value);
   }
   const onChangeremarks = (event) =>{
    event.preventDefault();
    setremarks(event.target.value);
   }
   const onChangeisDeleted = (event) =>{
    event.preventDefault();
    setisDeleted(event.target.checked);
   }
   const onChangeisEditable = (event) =>{
    event.preventDefault();
    setisEditable(event.target.checked);
   }
   /* const onChangeworkStatus = (event) =>{
    setworkStatusID(event.target.value.workItemStatusID);
   } */


   //const[pop,setpop]=useState({});
const Save = () => {
  
  debugger;
  let isSubmit = true;
  if (
    StatusNameRef.current.value.length == 0 &&
    StatusNameRef.current.value == ""
  ) {
    addToast("WorkItem Status Name is Required.", {
      appearance: "error",
      autoDismiss: true,
    });
    isSubmit = false;
  }
  if (
    
    displayOrderRef.current.value == 'Select' ||
    displayOrderRef.current.value == ''
  ) {
    addToast("Display Order is Required.", {
      appearance: "error",
      autoDismiss: true,
    });
    isSubmit = false;
  }
  
 
  if (isSubmit)
  {
    setopenPopUp(false);
  let loginUrl;
  let body;
  /* if (buttonText == "Save") */
  /* { */
   // setopenPopUp(true);
    loginUrl = getAPIURL(REACT_APP_ADDACTIVITIES);
    body = {
      //StatusName: StatusNameRef.current.value,
      StatusName:StatusNameRef.current.value,
      DisplayOrder: displayOrderRef.current.value,
      Remarks: RemarksRef.current.value,
      IsDeleted: isDeletedRef.current.checked,
      WorkItemStatusID: 0,
      UpdatedBy:sessionStorage.getItem("PersonID")
    };
  /* }
  else{
    loginUrl = getAPIURL(REACT_APP_UPDATEWORKITEMSTATUS);
     body = {
      StatusName: StatusNameRef.current.value,
      DisplayOrder: displayOrderRef.current.value,
      Remarks: RemarksRef.current.value,
      IsDeleted: isDeletedRef.current.checked,
      WorkItemStatusID: WorkItemStatusIDRef.current,
      UpdatedBy:sessionStorage.getItem("PersonID")
    };
  } */
  let req = new Request(`${loginUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserName: sessionStorage.getItem("UserName"),
      Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    },
    body: JSON.stringify(body),
  });
  fetch(req)
    .then((res) => res.json())
    .then((result) => {
      if (result.status === 200) {
        debugger;
        addToast("Work Item Status saved successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        //resetForm();
        //GetWorkItemStatus();
        //setbuttonText("Save");
    } else if (result.status === 401) {
      addToast("Session Expired/UnAuthorized. Please LogIn Again", {
        appearance: "error",
        autoDismiss: true,
      });
      sessionStorage.clear();
      sessionStorage.clear();
      navigate("/");
      }
    })
    .catch((error) => {
      console.log("ERROR: ", error);
    });
  }
  

};
   const popupClose = () => {
    setopenPopUp(false);
  };
  const AddpopupClose = () => {
    setopenAddPopUp(false);
  };
  const resetForm = () => {
        StatusNameRef.current.value="";
        displayOrderRef.current.value="Select";
        RemarksRef.current.value="";
        isDeletedRef.current.checked=false;
    
  };
  const ActivityType =[{id:1, title:'Break-Non Work Related'},
  {id:2, title:'Break-Work Related'},
  {id:3, title: 'Work'}]

  const ActivityGroup =[{id:1, title:'Not Applicable'},
  {id:2, title:'Processing'},
  {id:3, title:'Review'}]
  
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
//   const updateColumns = (keys) => {
//     console.log(sessionStorage.getItem("EmployeeVersionID"));
//     let col = [];
//     keys.forEach((item) => {
//         if(item!="updatedBy")
//             col.push({ title: item, field: item });
//     });
//     //col.pop();
//     //setcolumns(col);
//   };
//debugger;
const pop = () =>{
  seterror("");
  setopenPopUp(true);
 // editWorkItemStatus(item);
} 
const OpeneditActivity = (item) =>{
  seterror("");
  if(item.isEditable)
  {
    setopenAddPopUp(true);
    editActivity(item);
  }
  else
  {
      addToast("This is non-editable.", {
        appearance: "error",
        autoDismiss: true,
      });
 
  }
}

const editActivity = (item) =>{
  debugger
  NameEditref.current = item.activityName;
  TypeRef.current = item.activityType;
  GroupRef.current = item.activityGroup;
  let RemarksName = item.remarks;
  RemarksRef.current = RemarksName;
  isDeletedref.current = item.isDeleted;
  //setworkStatus(item.statusName);
  setactivityName(item.activityName);
  //Nameref.current = item.activityName;
  
  //let Name = Nameref.current.value;
  //setactivityType(item.activityType);
  //TypeRef.current = item.activityType;
  //setactivityGroup(item.activityGroup);
  //activityTyperef.current = item.activityType;
  //GroupRef.current = item.activityGroup;
  setisEditable(item.isEditable);
  setactivityId(item.activityID);
  //activityGroupRef.current = item.activityGroup;
  //isDeletedref.current = item.isDeleted;
  //StatusNameRef.current.value=item.statusName;
  //setdisplayOrder(item.displayOrder);
  setremarks(item.remarks);
  //RemarksRef.current = item.remarks;
  setisDeleted(item.isDeleted);
  
} 
const resetUpdate = () => {
//  setworkStatus("");
//   setdisplayOrder("");
//   setremarks("");
//   setisDeleted(false);
debugger
//activityNameref.current.value = "";
setactivityName("");
TypeRef.current.value = "";
GroupRef.current.value = "";
setremarks("");
setisDeleted("");
setisEditable("");
//actuvityType


};
const resetForAdd = () => {
  //  setworkStatus("");
  //   setdisplayOrder("");
  //   setremarks("");
  //   setisDeleted(false);
  debugger
  activityNameref.current.value = "";
  //setactivityName("");
  activityTyperef.current.value = "";
  activityGroupRef.current.value = "";
  remarksref.current.value = "";
  isDeletedRef.current.checked = "";
  isEditableref.current.checked = "";
  //actuvityType
  
  
  };
  const SaveActivity =() =>{
    //AddActivity();
    debugger;
    seterror("");
    let Err = {};
    let isSubmit = true;
    if (
      activityNameref.current.value.length == 0 &&
      activityNameref.current.value == ""
    ) {
      Err.ActivityNameErrorMessage = "Activity Name is required.";
      isSubmit = false;
    }
    if (
      activityTyperef.current.value == 'Select' ||
      activityTyperef.current.value == ''
    ) {
      Err.ActivityTypeErrorMessage = "Activity Type is required.";
      isSubmit = false;
    }
    if (
      activityGroupRef.current.value == 'Select' 
      ||
      activityGroupRef.current.value == ''
    ) {
      Err.ActivityGroupErrorMessage = "Activity Group is required.";
      isSubmit = false;
    }
    
   seterror({...Err});
    if (isSubmit)
    {

    let loginUrl;
    let body;
    /* if (buttonText == "Save") */
    /* { */
      setopenPopUp(true);
      loginUrl = getAPIURL(REACT_APP_ADDACTIVITIES);
      debugger
      body = {
        //StatusName: StatusNameRef.current.value,
        // StatusName:StatusNameRef.current.value,
        // DisplayOrder: displayOrderRef.current.value,
        // Remarks: RemarksRef.current.value,
        // IsDeleted: isDeletedRef.current.checked,
        // WorkItemStatusID: 0,
        UpdatedBy:sessionStorage.getItem("PersonID"),
        activityName : activityNameref.current.value,
        activityType : activityTyperef.current.value,
        activityGroup : activityGroupRef.current.value,
        remarks : remarksref.current.value,
        isDeleted : isDeletedRef.current.checked,
        isEditable : isEditableref.current.checked
      };
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          debugger;
          //setopenPopUp(false);
          GetActivities();
          addToast("Activity created successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          resetForm();
          // GetWorkItemStatus();
          setbuttonText("Save");
      } 
      else  if (result.message === 'Record already exists.') {
        //setaddNewStatusReason(false);
        setopenPopUp(false);
        addToast("Record already exists.", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      else if (result.status === 401) {
        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
          appearance: "error",
          autoDismiss: true,
        });
        sessionStorage.clear();
        sessionStorage.clear();
        navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
      
    setopenPopUp(false);
    }
  }

  const AddActivity = () => {
    debugger;
    seterror("");
    let Err = {};
    let isSubmit = true;
    if (
      activityNameref.current.value.length == 0 &&
      activityNameref.current.value == ""
    ) {
      Err.ActivityNameErrorMessage = "Activity Name is required.";
      isSubmit = false;
    }
    if (
      activityTyperef.current.value == 'Select' ||
      activityTyperef.current.value == ''
    ) {
      Err.ActivityTypeErrorMessage = "Activity Type is required.";
      isSubmit = false;
    }
    if (
      activityGroupRef.current.value == 'Select' 
      ||
      activityGroupRef.current.value == ''
    ) {
      Err.ActivityGroupErrorMessage = "Activity Group is required.";
      isSubmit = false;
    }
    
   seterror({...Err});
    if (isSubmit)
    {

    let loginUrl;
    let body;
    /* if (buttonText == "Save") */
    /* { */
      setopenPopUp(true);
      loginUrl = getAPIURL(REACT_APP_ADDACTIVITIES);
      debugger
      body = {
        //StatusName: StatusNameRef.current.value,
        // StatusName:StatusNameRef.current.value,
        // DisplayOrder: displayOrderRef.current.value,
        // Remarks: RemarksRef.current.value,
        // IsDeleted: isDeletedRef.current.checked,
        // WorkItemStatusID: 0,
        UpdatedBy:sessionStorage.getItem("PersonID"),
        activityName : activityNameref.current.value,
        activityType : activityTyperef.current.value,
        activityGroup : activityGroupRef.current.value,
        remarks : remarksref.current.value,
        isDeleted : isDeletedRef.current.checked,
        isEditable : isEditableref.current.checked
      };
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          debugger;
          //setopenPopUp(false);
          GetActivities();
          addToast("Activity created successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          resetForm();
          // GetWorkItemStatus();
          setbuttonText("Save");
      } 
      else  if (result.message === 'Record already exists.') {
        //setaddNewStatusReason(false);
        setopenPopUp(false);
        addToast("Record already exists.", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      else if (result.status === 401) {
        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
          appearance: "error",
          autoDismiss: true,
        });
        sessionStorage.clear();
        sessionStorage.clear();
        navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
    }
  };

  const UpdateActivity = () => {
    debugger;
    seterror("");
    let err={};
    let isEdit = true;
    if (
      activityName.length == 0 &&
      activityName == ""
    ) {
      err.ActivityNameErrorMessage = "Activity Name is required.";
      isEdit = false;
    }
    if (
      TypeRef.current.value == 'Select' ||
      TypeRef.current.value == ''
    ) {
      err.ActivityTypeErrorMessage = "Activity Type is required.";
      isEdit = false;
    }
    if (
      GroupRef.current.value == 'Select' 
      ||
      GroupRef.current.value == ''
    ) {
      err.ActivityGroupErrorMessage = "Activity Group is required.";
      isEdit = false;
    }
    
   seterror({...err});
    if(isEditable)
    {
    if(isEdit){
    let loginUrl;
    let body;
    
      loginUrl = getAPIURL(REACT_APP_UPDATEACTIVITIES);
       body = {
        activityID: activityId,
        activityName : activityName,
        activityType: TypeRef.current.value,
        activityGroup: GroupRef.current.value,
        remarks: remarks,
        isDeleted : isDeleted,
        isEditable: isEditable,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };
    /* } */
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          debugger;
          GetActivities();
          setopenAddPopUp(false);
          addToast("Activity Updated successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          
         // resetForm();
          
         // GetWorkItemStatus();

          //setbuttonText("Save");
      }else  if (result.message === 'Record already exists.') {
        //setaddNewStatusReason(false);
        setopenPopUp(false);
        addToast("Record already exists.", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      
      else if (result.status === 401) {
        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
          appearance: "error",
          autoDismiss: true,
        });
        sessionStorage.clear();
        sessionStorage.clear();
        navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
    }
    //setopenAddPopUp(false);
    }
    
  };

  const columns=[
    {
        title: "ActivityName",
        field: "activityName",
      },
      {
        title: "ActivityType",
        field: "activityType",
      },
      {
        title: "Remarks",
        field: "remarks",
      },
      {
        title: "ActivityGroup",
        field: "activityGroup",
      },      
      {
        title: "IsDeleted",
        field: "isDeleted",
        render: (rowData) => (            
            <>
              <input type="checkbox" id="chkisDeleted" disabled checked={rowData["isDeleted"] } />
            </>
          ),
      },
      {
        title: "IsEditable",
        field: "isEditable",
        render: (rowData) => (            
            <>
              <input type="checkbox" id="chkisEditable" disabled checked={rowData["isEditable"] } />
            </>
          ),
      }
  ];

  
  const editWorkItemStatus = (item) => {
      debugger;
      //setopenPopUp(true);
      StatusNameRef.current=item.statusName;
      WorkItemStatusIDRef.current=item.workItemStatusID;
      displayOrderRef.current=item.displayOrder;
      RemarksRef.current=item.remarks;
      isDeletedRef.current=item.isDeleted;
      //ButtonTextRef.current.value="Update";
     // setbuttonText("Update");
      //StatusNameRef.current.focus();
      //setopenPopUp(true);
  };
  
  const DeleteActivity = (row) => {  
    debugger  
    if(row.isDeleted){
        addToast("WorkItem - "+row.activityName+" is already deleted.", {
            appearance: "success",
            autoDismiss: true,
        });
        return false;
    }
    

    if(window.confirm('Are you sure you want to delete the Activity - '+row.activityName+' ?'))
    {      
        let body = {
            activityID: row.activityID,
            UpdatedBy:sessionStorage.PersonID,
          };
          let loginUrl = getAPIURL(REACT_APP_DELETEACTIVITY);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status === 200) {
                 debugger;
                 GetActivities();
                  addToast("Work Item deleted successfully", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  //GetWorkItemStatus();
              } else if (result.status === 401) {
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
              //navigate("/");
            });
    
    }
  };

 
  useEffect(() => {
   // debugger
    if (runSaveTImer == true && interval == null) {
      interval = setInterval(() => {
        SaveTimerOnTick();
        //clearInterval(interval);
      }, 60000);
    }
    return () => {
      //  console.log("ending dashboard 1");
      clearInterval(interval);
    };
  }, [runSaveTImer]);
  const SaveTimerOnTick = () => {
    console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("ActivityId") == null
          ? 0
          : sessionStorage.getItem("ActivityId"), //for home page
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
        sessionStorage.getItem("empTimeUtilization") == "undefined" ||
        sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment")
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000,
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMEONTICK);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            let FetchBreakTimerOnLoadData = JSON.parse(
              sessionStorage.getItem("FetchBreakTimerOnLoadData")
            );
            FetchBreakTimerOnLoadData.timerStart = result.timerStart;
            FetchBreakTimerOnLoadData.timerValue = result.timerValue;
            FetchBreakTimerOnLoadData.tSaveTimerEnabled =
              result.tSaveTimerEnabled;
            FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
              result.btnStartWorkTimerDisabled;
            // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
            //   result.txtBreakCommentsEnabled;
            // FetchBreakTimerOnLoadData.ddlActivityEnabled =
            //   result.ddlActivityEnabled;
            FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
              result.btnShowBreakPanelDisabled;
            // sessionStorage.setItem(
            //   "objdataview",
            //   JSON.stringify(result.data[0].objdataview)
            // );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(FetchBreakTimerOnLoadData)
            );
            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.employeeTimeUtilization)
            );
          }
        }
        // else if(result.status === 401)
        // {
        //   navigate("/")
        // }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  
  useEffect(() => {
   // debugger;
    // for (let index = 1; index <= 30; index++) {
    //     setdisplayOrderArry(oldArray => [...oldArray,index] );
    // }   
    GetActivities();
    //editWorkItemStatus(item);
    setsearchDivVisible(true);
    setisLoadingFigma(false);
    //ButtonTextRef.current.value="Save";
    //setbuttonText("Save");
  }, []);

  const GetActivities = () => {
    let GetActivitiesURL= getAPIURL(REACT_APP_GETACTIVITIES);
    let req1 = new Request(`${GetActivitiesURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      // body: JSON.stringify(body1),
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        debugger
        if (result.status == 200) {
            setisLoadingFigma(false);
           // updateColumns(Object.keys(result.data[0]));
            setactivitiesData(result.data);
            setDatacount(result.data.length);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
};

  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName={"Activities"}/>
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important",
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important",
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important",
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important",
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important",
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important",
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important",
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important",
          },
        }}
      />

      
        <div className="pageBody pd-top-0"> 
           {/* <div className="dashBoxs mb-3">   
              <div className="formWrap"> */}
                {/* <div className="fieldSet"> */}
                  {/* <h4 className="mb-4">Add/Edit Error Category</h4>
 */}
                  {/* <div className="row align-items-end"> */}
                    {/* <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">WorkItem Status Name*</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          
                          ref={StatusNameRef}
                          
                        />
                      </div>
                    </div> */}
                   {/*  <div className="col-2">
                      <div className="mb-3">
                        <label className="form-label">Display Order</label>
                        <select
                          className="form-control form-control-sm"
                          ref={displayOrderRef}
                        >
                          <option>Select</option>
                          {displayOrderArry.map((state) => (
                            <option
                              value={
                                state
                              }
                            >
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="col-3" style={{ "height": "81px" }}>
                        
                      <div className="mb-2">
                      <label className="form-label">Remarks</label>
                      <textarea name="Text1" cols="40" rows="2" maxLength="4000" ref={RemarksRef}></textarea>
                      </div>
                    </div> */}

                   {/*  <div className="col-1"/> */}

                    {/* <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="chkIsDeletedForm" ref={isDeletedRef}  />
                      </div>
                    </div> */}
                    {/*   </div>
                </div> */}
               
                {/* <hr className="mb-4" /> */}
                {/* <div className="fieldSet">
                  <div className="row"> */}
                    {/* <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div> */}
                    {/* <div className="col-6"> */}
                      {/* <div className="mb-2 text-end">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                            pop();
                          }}
                        >
                          + Add New Activity
                        </button>
                      </div> */}
                    

          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header">
                <div className="row align-items-center">
                  <div className="col-8"><h6 className="pageName">MANAGE ACTIVITIES</h6></div>
                  <div class="col-4 textend">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                    <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                            pop();
                          }}
                          >
                          + Add New Activity
                    </button>
                      {/* <div className="d-inline">
                        <div className="input-group input-group-sm">
                                                   
                        </div>
                      </div> */}
                    </div>
                    </div>
                  
                  <div className="col-12 text-right mt-2"></div>
                </div>
              </div>
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "blue",
                      marginLeft: "1rem"
                    }}
                  >
                    {Datacount} out of {Datacount} records displayed
                  </span>
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                             exportCSVName: "Export as Excel",
                             exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <EditIcon />,
                            tooltip: "Edit Activity",
                            onClick: (event, rowData) => OpeneditActivity(rowData),
                            position: "row",
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete Already Completed",
                            onClick: (event, rowData) => DeleteActivity(rowData),
                            position: "row",
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          pageSize: numberOfRecord,
                          paging: false,
                          //pageSizeOptions: [10, 20, 50,{label:'All' , value: numberOfRecord}],
                          exportButton: true,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                       
                        columns={columns}
                        icons={tableIcons}
                        data={activitiesData}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
         </div> 
      
      <Footer />

      {/* Add Modal */}
      
      <Modal
        show={openPopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="pageBody pd-top-0">
        <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                 {/*  <h4 className="mb-4">Add/Edit WorkItem Status</h4> */}

                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="mandatory">Activity Name
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.ActivityNameErrorMessage}</span>
                        </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          ref={activityNameref}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="mandatory">Activity Type
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.ActivityTypeErrorMessage}</span>
                        </div>
                        <select
                          className="form-control form-control-sm"
                          ref={activityTyperef}
                          //value={activityType}
                          //onChange={onChangeDisplay}
                        >
                          <option>Select</option>
                          {ActivityType.map((e) => (
                            <option
                              value={
                                e.title
                              }
                              selected={ActivityType.title== activityTyperef}
                            >
                              {e.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="mandatory">Activity Group
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.ActivityGroupErrorMessage}</span>
                        </div>
                        <select
                          className="form-control form-control-sm"
                          //ref={displayOrderRef}
                          ref={activityGroupRef}
                          //onChange={onChangeDisplay}
                        >
                          <option>Select</option>
                          {ActivityGroup.map((e) => (
                            <option
                              value={
                                e.title
                              }
                              selected={ActivityGroup.title== activityGroup}
                            >
                              {e.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4" /* style={{ "height": "81px" }} */>
                        
                      <div className="mb-1">
                      <label className="form-label">Remarks
                     </label>
                      <textarea className = "form-control form-control-sm" name="Text1" cols="40" rows="2" maxLength="4000" ref={remarksref} /* ref={RemarksRef} */></textarea>
                      </div>
                    </div>

                    {/* <div className="col-1"/> */}

                    <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="chkIsDeletedForm" role="switch" 
                      ref={isDeletedRef}/>
                      </div>
                    </div>
                    <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Editable</label>
                      <input type="checkbox" id="chkIsDeletedForm" role="switch" ref={isEditableref}  
                      />
                      </div>
                    </div>
                      </div>
                </div>
                {/* <hr className="mb-4" /> */}
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForAdd}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                            SaveActivity();
                          }}
                        >
                         Save
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                            AddActivity();
                          }}
                        >
                         Save & Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>           
          </div>
          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                      <div className="d-inline">
                        <div className="input-group input-group-sm">
                          {/* <small
                            className="noteText"
                            style={{ padding: "5px", "padding-right": "35px" }}
                          >
                            Note: All the Workitem statuses were displayed below
                          </small>     */}                      
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mt-2"></div>
                </div>
              </div>
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                    
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            /*  exportCSVName: "Export as Excel",
                             exportPDFName: "Export as pdf", */
                          },
                          
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          /* {
                            icon: () => <EditIcon />,
                            tooltip: "Edit Work Item Status",
                            onClick: (event, rowData) => editWorkItemStatus(rowData),
                            position: "row",
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) => DeleteWorkItemStatus(rowData),
                            position: "row",
                          } */
                        ]}
                        title="Existing Entries"
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: true,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        
                        
                        columns={columns}
                        icons={tableIcons}
                        data={activitiesData}
                        
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
        
            


        </Modal.Body>
      </Modal>
      

      {/* Add Modal End */}

      {/* Edit Modal */}

      <Modal
        show={openAddPopUp}
        dialogClassName="modal-100w"
        onHide={AddpopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Activity </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="pageBody pd-top-0">
          <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                 {/*  <h4 className="mb-4">Add/Edit WorkItem Status</h4> */}

                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                      <label className="mandatory">Activity Name
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.ActivityNameErrorMessage}</span>
                        </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          // value = {NameEditref.current}
                          // ref = {NameEditref}
                          value = {activityName}
                          //ref = {activityName}
                          onChange={onChangeActivityName}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                      <label className="mandatory">Activity Type
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.ActivityTypeErrorMessage}</span>
                        </div>
                        <select
                          className="form-control form-control-sm"
                          ref={TypeRef}
                        >
                          <option>Select</option>
                          {ActivityType.map((e) => (
                            <option
                              value={
                                e.title
                              }
                              //selected={ActivityType.title== activityType}
                              selected={TypeRef}
                            >
                              {e.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                      <label className="mandatory">Activity Group
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.ActivityGroupErrorMessage}</span>
                        </div>
                        <select
                          className="form-control form-control-sm"
                          ref={GroupRef}
                        >
                          <option>Select</option>
                          {ActivityGroup.map((e) => (
                            <option
                              value={
                                e.title
                              }
                             // selected={ActivityGroup.title== activityGroup}
                             selected = {GroupRef}
                            >
                              {e.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4" /* style={{ "height": "81px" }} */>
                        
                      <div className="mb-1">
                      <label className="form-label">Remarks
                     </label>
                      <textarea className = "form-control form-control-sm" cols="40" rows="2" maxLength="4000" 
                      //ref={RemarksRef}
                      value={remarks} 
                      onChange={onChangeremarks}/* ref={RemarksRef} */
                      >

                      </textarea>
                      </div>
                    </div>

                    {/* <div className="col-1"/> */}

                    <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="chkIsDeletedForm" role="switch" 
                      checked={isDeleted}  onChange={onChangeisDeleted}
                      //ref={isDeletedref}
                      />
                      </div>
                    </div>
                    <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Editable</label>
                      <input type="checkbox" id="chkIsEditableForm" role="switch" checked={isEditable} 
                      onChange={onChangeisEditable} />
                      </div>
                    </div>
                      </div>
                </div>
                {/* <hr className="mb-4" /> */}
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetUpdate}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                          UpdateActivity();
                          }}
                        >
                         Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>           
          </div>
          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                      <div className="d-inline">
                        <div className="input-group input-group-sm">
                          {/* <small
                            className="noteText"
                            style={{ padding: "5px", "padding-right": "35px" }}
                          >
                            Note: All the Workitem statuses were displayed below
                          </small>     */}                      
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mt-2"></div>
                </div>
              </div>
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                    
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            /*  exportCSVName: "Export as Excel",
                             exportPDFName: "Export as pdf", */
                          },
                          
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          /* {
                            icon: () => <EditIcon />,
                            tooltip: "Edit Work Item Status",
                            onClick: (event, rowData) => editWorkItemStatus(rowData),
                            position: "row",
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) => DeleteWorkItemStatus(rowData),
                            position: "row",
                          } */
                        ]}
                        title="Exsisting Entries "
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: true,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        
                        
                        columns={columns}
                        icons={tableIcons}
                        data={activitiesData}
                       
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>  
        </Modal.Body>
      </Modal>                 
      
    </>
  );
};

export default React.memo(Activities);
