import React, { useState } from 'react';
import { Form } from 'react-formio';
import 'formiojs/dist/formio.full.css';

function FormSubmissionPage (form ) {
    debugger
const [submissionData, setSubmissionData] = useState(null);

const handleSubmit = (submission) => {
// Handle the form submission and store the submission data in state.
setSubmissionData(submission.data);
};

return (
<div>
<h2>Form Submission</h2>
<Form
form={form}
submission={{ data: submissionData }}
onSubmit={handleSubmit}
/>
<pre>
{/* Display the submission data in the console */}
{JSON.stringify(submissionData, null, 2)}
</pre>
</div>
);
};

export default FormSubmissionPage;
