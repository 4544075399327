import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { Paper } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from "react-bootstrap/Modal";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

function ReportConfig() {
    const { addToast } = useToasts();

    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCHREPORTCONFIGLIST } = process.env;
    const { REACT_APP_DELETEREPORTCONFIGBYREPORTID } = process.env;
    const { REACT_APP_FETCHREPORTDETAILSBYIDFORUPDATING } = process.env;
    const { REACT_APP_CREATEREPORTCONFIG } = process.env;
    const { REACT_APP_UPDATEREPORTCONFIG } = process.env;
    const { REACT_APP_UPLOADFILEFROMREPORTDATASOURCE } = process.env;
    const { REACT_APP_GETDETAILSFROMUPLOADEDFILE } = process.env;

    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const userRoles = processingDashboardParam.userRoles;
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }

    const [searchDivVisible, setsearchDivVisible] = useState(true);

    const [reportsList, setReportsList] = useState([]);
    const [viewModal, setviewModal] = useState(false);
    const [buttonText, setbuttonText] = useState("");
    const [checkboxesForColumns, setCheckboxesForColumns] = useState({});
    const [checkboxesForColumnsDivVisible, setCheckboxesForColumnsDivVisible] = useState(false);

    const [reportId, setReportId] = useState();
    const [reportName, setReportName] = useState("");
    //const [reportCategory, setReportCategory] = useState("");
    const [reportCategories, setReportCategories] = useState([]);

    const [reportSourceType, setReportSourceType] = useState("");
    const [reportFileName, setReportFileName] = useState("");
    const [uploadingFile, setUploadingFile] = useState("");
    const [existingreportFileName, setExistingreportFileName] = useState("");
    const [reportDefinition, setReportDefinition] = useState("");

    const [reportDataSourceType, setReportDataSourceType] = useState("");
    const [reportDataSource, setReportDataSource] = useState("");
    const [mainReportId, setMainReportId] = useState("");
    const [mainReportName, setMainReportName] = useState("");

    const [reportDataSourceParameterName, setReportDataSourceParameterName] = useState("");
    const [reportDataSourceParameterDataType, setReportDataSourceParameterDataType] = useState("");
    const [reportDataSourceParameterDefaultValue, setReportDataSourceParameterDefaultValue] = useState("");
    const [reportDataSourceParametersList, setReportDataSourceParametersList] = useState([]);
    const [searchReportDataSourceParameterDivVisible, setsearchReportDataSourceParameterDivVisible] = useState(false);

    const [reportParameterName, setReportParameterName] = useState("");
    const [reportParameterDataType, setReportParameterDataType] = useState("");
    const [reportParameterDefaultValue, setReportParameterDefaultValue] = useState("");
    const [reportParametersList, setReportParametersList] = useState([]);
    const [searchReportParameterDivVisible, setsearchReportParameterDivVisible] = useState(false);

    const [subProcessId, setSubProcessId] = useState("0");
    const [reportDataSetName, setReportDataSetName] = useState("0");
    const [remarks, setRemarks] = useState("");
    const [isDeletedCheck, setIsDeletedCheck] = useState(false);

    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };

    const columns = [
        {
            title: "Report Name",
            field: "reportName",
        },
        {
            title: "Report Category",
            field: "reportTab",
        },
        {
            title: "Report Source Type",
            field: "reportSourceType",
        },
        {
            title: "Is Sub Report",
            field: "isSubReport",
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id=""
                        checked={rowData.isSubReport}
                    />
                </>
            ),
        },
        {
            title: "Sub Report Name",
            field: "subReportName",
        },
        {
            title: "Is Deleted",
            field: "isDeleted",
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id=""
                        checked={rowData.isDeleted}
                    />
                </>
            ),
        },
    ]

    const reportFormColumns = [
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Data Type",
            field: "dataType",
        },
        {
            title: "Default Value",
            field: "defaultValue",
        }
    ]

    const openModal = () => {
        setviewModal(true);
    };
    const closeModal = () => {
        setviewModal(false);

        setReportId();
        setReportName("");
        //setReportCategory("");
        setReportCategories([]);

        setReportSourceType("");
        setReportFileName("");
        setUploadingFile("");
        setExistingreportFileName("");
        setReportDefinition("");

        setReportDataSourceType("");
        setReportDataSource("");
        setMainReportId("");
        setMainReportName("");

        setReportDataSourceParameterName("");
        setReportDataSourceParameterDataType("");
        setReportDataSourceParameterDefaultValue("");
        setReportDataSourceParametersList([]);
        setsearchReportDataSourceParameterDivVisible(false);

        setReportParameterName("");
        setReportParameterDataType("");
        setReportParameterDefaultValue("");
        setReportParametersList([]);
        setsearchReportParameterDivVisible(false);

        setSubProcessId("0");
        setReportDataSetName("0");
        setRemarks("");
        setIsDeletedCheck(false);
    };

    const reportNameTextBox = (e) => {
        setReportName(e.target.value);
        //console.log(e.target.value);
        //console.log(reportName);
    }

    const reportCategoryList = [
        { value: "Global Reports", name: "Global" },
        { value: "Management Reports", name: "Management" },
        { value: "Client Reports", name: "Client" },
        { value: "Sub-Process Reports", name: "Sub-Process" }
    ]
    /*const reportCategoryDropdown = (e) => {
        setReportCategory(e.target.value);
        //console.log(e.target.value);
        //console.log(reportCategory);
    }*/
    const handleReportCategoryCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setReportCategories((prevSelected) => [...prevSelected, value]);
        } else {
            setReportCategories((prevSelected) =>
                prevSelected.filter((option) => option !== value)
            );
        }
    };

    const reportSourceTypeList = [
        { value: "File", name: "File" },
        { value: "DataBase", name: "DataBase" },
        { value: "Dynamic", name: "Dynamic" },
        { value: "Excel", name: "Excel" },
    ]
    const reportSourceTypeDropdown = (e) => {
        setReportSourceType(e.target.value);
        if (e.target.value !== "File" && e.target.value !== "DataBase") {
            setReportFileName("");
        }
    }
    /*report file name, report definition, upload button - existing report file name*/

    const reportDataSourceTypeList = [
        { value: "Query", name: "Query" },
        { value: "SP", name: "SP" },
    ]
    const reportDataSourceTypeDropdown = (e) => {
        setReportDataSourceType(e.target.value);
    }

    const reportDataSourceTextBox = (e) => {
        setReportDataSource(e.target.value);
    }

    const mainReportIdDropdown = (e, reportId) => {
        //console.log(e.target.value, reportId);
        setMainReportId(reportId);
        setMainReportName(e.target.value);
    }

    const reportDataSourceParameterNameTextBox = (e) => {
        setReportDataSourceParameterName(e.target.value);
    }
    const reportDataSourceParameterDataTypeList = [
        { value: "Int32", name: "Int32" },
        { value: "Double", name: "Double" },
        { value: "String", name: "String" },
        { value: "DateTime", name: "DateTime" },
        { value: "Boolean", name: "Boolean" },
        { value: "Int64", name: "Int64" },
        { value: "Xml", name: "Xml" },
    ]
    const reportDataSourceParameterDataTypeDropdown = (e) => {
        setReportDataSourceParameterDataType(e.target.value);
    }
    const reportDataSourceParameterDefaultValueTextBox = (e) => {
        setReportDataSourceParameterDefaultValue(e.target.value);
    }
    const handleAddReportDataSourceParameter = () => {
        console.log(reportDataSourceParametersList);
        if (reportDataSourceParameterName.trim() && reportDataSourceParameterDataType.trim() &&
            !reportDataSourceParametersList.some(param => param.name === reportDataSourceParameterName)) {
            const newParameter = {
                name: reportDataSourceParameterName,
                dataType: reportDataSourceParameterDataType,
                defaultValue: reportDataSourceParameterDefaultValue
            };
            setReportDataSourceParametersList(prevListState => [...prevListState, newParameter]);
            setsearchReportDataSourceParameterDivVisible(true);

            setReportDataSourceParameterName("");
            setReportDataSourceParameterDataType("");
            setReportDataSourceParameterDefaultValue("");
        } else {
            alert("Report Data Source Parameter name must be unique and NAME & DATA-TYPE fields must not be blank.");
            addToast(
                "Report Data Source Parameter name must be unique and name & data-type fields must not be blank.",
                {
                    appearance: "error",
                    autoDismiss: true,
                }
            );
        }
    };
    const DeleteReportDataSourceParameterByName = (row) => {
        console.log(row.name);
        if (
            window.confirm(
                "Are you sure you want to delete this Parameter?"
            )
        ) {
            setReportDataSourceParametersList(prevListState =>
                prevListState.filter(param => param.name !== row.name)
            );
        }
    }


    const reportParameterNameTextBox = (e) => {
        setReportParameterName(e.target.value);
    }
    const reportParameterDataTypeList = [
        { value: "System.Int32", name: "System.Int32" },
        { value: "System.Double", name: "System.Double" },
        { value: "System.String", name: "System.String" },
        { value: "System.DateTime", name: "System.DateTime" },
        { value: "System.Boolean", name: "System.Boolean" },
    ]
    const reportParameterDataTypeDropdown = (e) => {
        setReportParameterDataType(e.target.value);
    }
    const reportParameterDefaultValueTextBox = (e) => {
        setReportParameterDefaultValue(e.target.value);
    }
    const handleAddReportParameter = () => {
        if (reportParameterName.trim() && reportParameterDataType.trim() &&
            !reportParametersList.some(param => param.name === reportParameterName)) {
            const newParameter = {
                name: reportParameterName,
                dataType: reportParameterDataType,
                defaultValue: reportParameterDefaultValue
            };

            setReportParametersList(prevListState => [...prevListState, newParameter]);
            setsearchReportParameterDivVisible(true);

            setReportParameterName("");
            setReportParameterDataType("");
            setReportParameterDefaultValue("");
        } else {
            alert("Report Parameter name must be unique and NAME & DATA-TYPE fields must not be blank.");
            addToast(
                "Report Parameter name must be unique and name & data-type fields must not be blank.",
                {
                    appearance: "error",
                    autoDismiss: true,
                }
            );
        }
    };
    const DeleteReportParameterByName = (row) => {
        console.log(row.name);
        if (
            window.confirm(
                "Are you sure you want to delete this Parameter?"
            )
        ) {
            setReportParametersList(prevListState =>
                prevListState.filter(param => param.name !== row.name)
            );
        }
    }

    const subProcessIdTextBox = (e) => {
        if (Number.isNaN(Number(e.target.value))) {
            addToast(
                "Enter numeric values only!",
                {
                    appearance: "error",
                    autoDismiss: true,
                }
            );
        }
        setSubProcessId(e.target.value);
    }

    const reportDataSetNameTextBox = (e) => {
        setReportDataSetName(e.target.value);
    }

    const remarksTextBox = (e) => {
        setRemarks(e.target.value);
    }

    const isDeletedCheckBox = (e) => {
        var check = isDeletedCheck;
        setIsDeletedCheck(!check);
    }

    useEffect(() => {
        FetchReportsConfigListOnPageLoad();

        const initialCheckboxesState = columns.reduce((acc, column) => {
            acc[column.field] = true;
            return acc;
        }, {});
        setCheckboxesForColumns(initialCheckboxesState);
    }, []);

    const FetchReportsConfigListOnPageLoad = async () => {
        let body = {

        };

        let loginUrl = getAPIURL(REACT_APP_FETCHREPORTCONFIGLIST);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setReportsList(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };

    const DeleteReportConfigById = async (row) => {
        // debugger;
        if (row.isDeleted) {
            addToast(
                "Report - " + row.reportName + " is already deleted.",
                {
                    appearance: "success",
                    autoDismiss: true,
                }
            );
            return false;
        }

        if (
            window.confirm(
                "Are you sure you want to delete the Report - " +
                row.reportName +
                " ?"
            )
        ) {
            let body = {
                reportId: row.reportId,
                // UpdatedBy: sessionStorage.PersonID,
            };
            let loginUrl = getAPIURL(REACT_APP_DELETEREPORTCONFIGBYREPORTID);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status === 200) {
                        // debugger;
                        addToast("Report deleted successfully", {
                            appearance: "success",
                            autoDismiss: true,
                        });
                        FetchReportsConfigListOnPageLoad();
                    } else if (result.status === 401) {
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                    //navigate("/");
                });
        }
    };

    const GetReportDetailsbyId = async (row) => {
        //debugger;
        //console.log(row.reportId);
        let body = {
            reportId: row.reportId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCHREPORTDETAILSBYIDFORUPDATING);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    //debugger;
                    setReportId(row.reportId);
                    setReportName(result.data[0].reportName);
                    setReportCategories(result.data[0].reportTab);
                    setReportSourceType(result.data[0].reportSourceType);
                    setExistingreportFileName(result.data[0].reportFileName);
                    setReportDefinition(result.data[0].reportDefinition);
                    setReportDataSourceType(result.data[0].reportDataSourceType);
                    setReportDataSource(result.data[0].reportDataSource);
                    setMainReportId(result.data[0].parentReportID);
                    setSubProcessId(result.data[0].subProcessID);
                    setReportDataSetName(result.data[0].reportDataSetName);
                    setRemarks(result.data[0].remarks);
                    setIsDeletedCheck(result.data[0].isDeleted);

                    setReportDataSourceParametersList(result.data[0].reportDataSourceParameters);
                    setsearchReportDataSourceParameterDivVisible(true);
                    setReportParametersList(result.data[0].reportParameters);
                    setsearchReportParameterDivVisible(true);

                    setMainReportName(reportsList.find((list) => list.reportId.toString() === result.data[0].parentReportID.toString())?.reportName);

                    console.log(result.data[0]);
                    addToast("Report details filled successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });

                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });
    };

    const GetReportDetailsByIdforDownload = async (row) => {
        //debugger;
        //console.log(row.reportId);
        let body = {
            reportId: row.reportId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCHREPORTDETAILSBYIDFORUPDATING);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    //console.log(result.data);
                    if (result.data[0].reportDefinition === "") {
                        //debugger;
                        addToast("Report definition doesn't exist!", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                    }
                    else {
                        //debugger;
                        const blob = new Blob([result.data[0].reportDefinition], { type: 'text/xml' });

                        FileSaver.saveAs(blob, `${result.data[0].reportName}.rdlc`);

                        addToast("Report details downloaded successfully!", {
                            appearance: "success",
                            autoDismiss: true,
                        });
                    }

                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });
    };

    const createReportConfig = async () => {
        let body = {
            reportName: reportName,
            parentReportID: mainReportId,
            reportTab: reportCategories,
            reportSourceType: reportSourceType,
            reportFileName: existingreportFileName,
            reportDefinition: reportDefinition,
            reportDataSourceType: reportDataSourceType,
            reportDataSource: reportDataSource,
            reportDataSourceParameters: reportDataSourceParametersList,
            reportParameters: reportParametersList,
            isDeleted: isDeletedCheck,
            remarks: remarks,
            subProcessID: subProcessId,
            reportDataSetName: reportDataSetName
        };

        let loginUrl = getAPIURL(REACT_APP_CREATEREPORTCONFIG);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    addToast("Report Configuration created successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };

    const UpdateReportConfig = async () => {
        let body = {
            reportID: reportId,
            parentReportID: mainReportId,
            reportName: reportName,
            reportTab: reportCategories,
            reportSourceType: reportSourceType,
            reportFileName: existingreportFileName,
            reportDefinition: reportDefinition,
            reportDataSourceType: reportDataSourceType,
            reportDataSource: reportDataSource,
            reportDataSourceParameters: reportDataSourceParametersList,
            reportParameters: reportParametersList,
            isDeleted: isDeletedCheck,
            remarks: remarks,
            subProcessID: subProcessId,
            reportDataSetName: reportDataSetName
        };

        let loginUrl = getAPIURL(REACT_APP_UPDATEREPORTCONFIG);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    addToast("Report Configuration updated successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };

    const handleSaveandUpdate = () => {
        if (reportName != "" && reportCategories.length > 0 && reportSourceType != "" && reportDataSource != "" && reportDataSourceType != "") {
            if (Number.isNaN(Number(subProcessId))) {
                alert("Sub Process Id should be a numeric value");
            }
            else {
                if (buttonText == "Save") {
                    createReportConfig();
                    FetchReportsConfigListOnPageLoad();
                    closeModal();
                }
                else if (buttonText == "Update") {
                    UpdateReportConfig();
                    FetchReportsConfigListOnPageLoad();
                    closeModal();
                }
            }
        }
        else {
            alert("Report Name, Report Categories, Report Source Type, Report Data Source and Report Data Source Type cannot be left blank");
            addToast("Report Name, Report Categories, Report Source Type, Report Data Source and Report Data Source Type cannot be left blank", {
                appearance: "error",
                autoDismiss: true,
            });
        }
    }

    const handleColumnExportChoice = (e) => {
        var check = checkboxesForColumnsDivVisible;
        setCheckboxesForColumnsDivVisible(!check);
    }

    const onCheckboxChangeForColumnsExport = (field) => {
        setCheckboxesForColumns(prevCheckboxes => ({
            ...prevCheckboxes,
            [field]: !prevCheckboxes[field]
        }));
    }

    const downloadExcel = () => {
        const exportType = "xls";
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        /*const filteredColumn = reportsList.map(item => ({
            "Report Name": item.reportName,
            "Report Category": item.reportTab,
            "Report Source Type": item.reportSourceType,
            "Is Sub Report": item.isSubReport,
            "Sub Report Name": item.subReportName,
            "Is Deleted": item.isDeleted,
        }));*/

        const headers = columns.map(col => col.title);
        const filteredColumn = reportsList.map(item => {
            return columns
                .filter(col => checkboxesForColumns[col.field])
                .reduce((obj, col) => {
                    obj[col.title] = item[col.field];
                    return obj;
                }, {});
        });

        const fileName = "ListofReportConfigDetails";
        const ws = XLSX.utils.json_to_sheet(filteredColumn);
        const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const downloadPdf = () => {
        const doc = new jsPDF();
        const fileName = "ListofReportConfigDetails";
        const fileExtension = ".pdf";

        /*doc.autoTable({
            head: [["Report Name", "Report Category", "Report Source Type", "Is Sub Report", "Sub Report Name", "Is Deleted"]],
            body: reportsList.map(item => [item.reportName, item.reportTab, item.reportSourceType, item.isSubReport, item.subReportName, item.isDeleted])
        });*/

        const filteredHeaders = columns.filter(col => checkboxesForColumns[col.field]).map(col => col.title);
        const selectedFields = columns.filter(col => checkboxesForColumns[col.field]).map(col => col.field);

        const head = [filteredHeaders];
        const body = reportsList.map(item => {
            return selectedFields.map(field => item[field]);
        });

        doc.autoTable({ head, body });
        doc.save(fileName + fileExtension);
    }

    const readUploadFile = (e) => {
        //console.log(e.target.files[0]);
        const extension = e.target.files[0].name.split(".").pop();
        //const type = e.target.files[0].type;
        if (extension === "rdlc") {
            if (e.target.files[0].size <= 10240000) {
                setUploadingFile(e.target.files[0]);
                setReportFileName(e.target.value);
            } else {
                addToast("The file size exceeded the maximum limit of 10 MB.", {
                    appearance: "error",
                    autoDismiss: true,
                });
            }
        } else {
            alert("Upload Failed! File Type is not supported! Choose .rdlc file!");
        }
    };

    const handleUploadRDLCfile = () => {
        const formData = new FormData();
        formData.append('file', uploadingFile);

        let loginUrl = getAPIURL(REACT_APP_UPLOADFILEFROMREPORTDATASOURCE);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                //  "Content-Type": "multipart/form-data",
                UserName: sessionStorage.getItem("UserName"),
                Authorization:
                    "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: formData,
        });
        fetch(req)
            .then((res) => res.json())
            .then((res) => {
                //debugger;
                if (res.data !== "") {
                    //debugger;
                    console.log(res.data);
                    getExtractedDataOfUploadedFile(res.data);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });
    }

    const getExtractedDataOfUploadedFile = (path) => {
        //debugger;
        let body = {
            uploadedPathofReport: path
        };

        let loginUrl = getAPIURL(REACT_APP_GETDETAILSFROMUPLOADEDFILE);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization:
                    "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                //debugger;
                if (result.status === 200) {
                    //debugger;
                    console.log(result.data);

                    if (result.data[0].reportDefinition == "") {
                        setReportDefinition("");
                    }
                    else {
                        setReportDefinition(result.data[0].reportDefinition);
                    }

                    if (result.data[0].reportDataSetName == null) {
                        setReportDataSetName("");
                    }
                    else {
                        setReportDataSetName(result.data[0].reportDataSetName);
                    }

                    if (result.data[0].reportParameters == null) {
                        setReportParametersList([]);
                        setsearchReportParameterDivVisible(false);
                    }
                    else {
                        setReportParametersList(result.data[0].reportParameters);
                        setsearchReportParameterDivVisible(true);
                    }

                    let pathArray = reportFileName.split('\\');
                    let fileName = pathArray.pop();
                    setExistingreportFileName(fileName);

                    setUploadingFile("");
                    setReportFileName("");

                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                //navigate("/");
            });
    }

    const NavElements = useMemo(() => {
        return (
            <>
                <Header onpreferenceShow={null} pageName="Report Config" />
                <ApplicationUserContext.Provider
                    value={processingDashboardParam.userRoles}
                >
                    <DashboardContext.Provider value={processingDashboardParam.nav}>
                        <NavDataContext.Provider value={navData}>
                            <Nav selectedMenu="admin" />
                        </NavDataContext.Provider>
                    </DashboardContext.Provider>
                </ApplicationUserContext.Provider>
            </>
        );
    }, [navData]);

    return (
        <>
            {NavElements}

            <div className="centerSection">
                <div className="pageBody">

                    {searchDivVisible ? (
                        <div className="dashBoxs">
                            <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <h6>Report Configuration List</h6>
                                        { }
                                    </div>
                                </div>
                            </div>
                            {(
                                <div className="tableWrap">
                                    <div className="tableWrap-body">
                                        <table className="table table-borderless MatTable">
                                            {/* <ThemeProvider theme={theme}> */}
                                            <MaterialTable
                                                localization={{
                                                    pagination: {
                                                        //labelDisplayedRows: '{from}-{to} of {count}'
                                                    },
                                                    toolbar: {
                                                        // nRowsSelected: '{0} row(s) selected'
                                                        // exportCSVName: "Export as Excel",
                                                        // exportPDFName: "Export as pdf",
                                                    },
                                                    header: {
                                                        actions: "",
                                                    },
                                                }}
                                                actions={[
                                                    {
                                                        icon: () => <EditIcon />,
                                                        tooltip: "Edit Report Details",
                                                        onClick: (event, rowData) => {
                                                            openModal();
                                                            GetReportDetailsbyId(rowData);
                                                            setbuttonText("Update");
                                                        },
                                                        position: "row",
                                                    },
                                                    {
                                                        icon: () => <DeleteIcon />,
                                                        tooltip: "Delete Report Details",
                                                        onClick: (event, rowData) =>
                                                            DeleteReportConfigById(rowData),
                                                        position: "row",
                                                    },
                                                    {
                                                        icon: () => <FileDownloadIcon />,
                                                        tooltip: "Get Report Details",
                                                        onClick: (event, rowData) =>
                                                            GetReportDetailsByIdforDownload(rowData),
                                                        position: "row",
                                                    }
                                                ]}
                                                options={{
                                                    headerStyle: {
                                                        position: "sticky",
                                                        top: "-5px",
                                                    },
                                                    maxBodyHeight: "420px",
                                                    //pagination:true,
                                                    //paginationType:"normal",
                                                    //paginationPosition: "bottom",
                                                    pageSize: 10,
                                                    pageSizeOptions: [5, 10, 20, 50, 100],
                                                    exportButton: false,
                                                    sorting: true,
                                                    filtering: true,
                                                    search: false,
                                                    selection: false,
                                                    //paging: false,

                                                    overflowY: false,
                                                    showTitle: false,
                                                    toolbarButtonAlignment: "left",
                                                    headerStyle: {
                                                        // height: 30,
                                                    },
                                                    rowStyle: {
                                                        fontFamily: '"Yantramanav", sans-serif',
                                                        //                            fontSize: "13px",
                                                        // whiteSpace: "nowrap",
                                                    },
                                                    headerStyle: {
                                                        // fontFamily: '"Yantramanav", sans-serif',
                                                        // fontWeight: "inherit",
                                                        // fontSize: "14px",
                                                        // textAlign: "left !Important",
                                                    },
                                                }}

                                                columns={columns}
                                                icons={tableIcons}
                                                data={reportsList}
                                                components={{
                                                    Container: (props) => (
                                                        <Paper {...props} elevation={0} />
                                                    ),
                                                    Toolbar: (props) => (
                                                        <div
                                                            style={{
                                                                backgroundColor: "rgb(206 206 206)",
                                                                fontSize: "0.75em",
                                                            }}
                                                        >
                                                            <MTableToolbar
                                                                {...props}
                                                                style={{
                                                                    fontSize: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                    ),
                                                }}
                                            />
                                            {/* </ThemeProvider> */}
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null}

                    <div className="col-6 text-start mt-4">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                                openModal();
                                setbuttonText("Save");
                            }}
                        >
                            <b className="icon-plus"></b>Add New Report Configuration
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                                downloadExcel();
                            }}
                        >
                            <b className=""></b>Export to Excel
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                                downloadPdf();
                            }}
                        >
                            <b className=""></b>Export to PDF
                        </button>
                    </div>

                    <div className="col-3 text-start mt-4 row align-items-end">
                        <div className="mb-3">
                            <button className="btn btn-sm btn-primary form-label" style={{ color: 'white' }} onClick={handleColumnExportChoice}>Columns To Export</button>
                            <div className="fieldSet pt-1">
                                <div className="col-8">
                                    {checkboxesForColumnsDivVisible ? (
                                        columns.map(column => (
                                            <label key={column.field}>
                                                <input
                                                    type="checkbox"
                                                    checked={checkboxesForColumns[column.field]}
                                                    onChange={() => onCheckboxChangeForColumnsExport(column.field)}
                                                />
                                                <span className="form-label" style={{ padding: '4px' }}>{column.title}</span>
                                            </label>
                                        ))
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />

            <Modal
                show={viewModal}
                dialogClassName="modal-100w"
                onHide={closeModal}
                fullscreen={"lg-down"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Report Config</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <>
                        <div className="timeBreak">
                            <ul className="timeBreak-form">
                                <div className="tableWrap">
                                    <div className="tableWrap-body">
                                        <table className="table brandTable">
                                            <div>
                                                <div className="fieldSet pt-1">
                                                    <div className="form-control form-control-sm">

                                                        <div className="row align-items-end">
                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Report Name *</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportName}
                                                                        onChange={reportNameTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/*<div className="col-3">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Report Category *</label>
                                                                        <select
                                                                            className="form-control form-control-sm"
                                                                            //disabled={checkedValue === "0"}
                                                                            value={reportCategory}
                                                                            onChange={reportCategoryDropdown}
                                                                            defaultValue="-- Select Report Category --"
                                                                        >
                                                                            <option value="">-- Select Report Category --</option>
                                                                            {reportCategoryList.map((category, index) => (
                                                                                <option
                                                                                    key={index}
                                                                                    value={category.value}
                                                                                >
                                                                                    {category.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                            </div>*/}

                                                            <div className="col-5">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Report Category *</label>
                                                                        <div className="fieldSet pt-1">
                                                                            {reportCategoryList.map((category) => (
                                                                                <label key={category.value} style={{ padding: '4px' }}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={category.value}
                                                                                        checked={reportCategories.includes(category.value)}
                                                                                        onChange={handleReportCategoryCheckboxChange}
                                                                                    />
                                                                                    <span className="form-label" style={{ padding: '4px' }}>{category.name}</span>
                                                                                </label>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-end">
                                                            <div className="col-3">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Report Source Type *</label>
                                                                        <select
                                                                            className="form-control form-control-sm"
                                                                            //disabled={checkedValue === "0"}
                                                                            value={reportSourceType}
                                                                            onChange={reportSourceTypeDropdown}
                                                                            defaultValue="-- Select Report Source Type --"
                                                                        >
                                                                            <option value="">-- Select Report Source Type --</option>
                                                                            {reportSourceTypeList.map((sourceType, index) => (
                                                                                <option
                                                                                    key={index}
                                                                                    value={sourceType.value}
                                                                                >
                                                                                    {sourceType.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {reportSourceType === "File" || reportSourceType === "DataBase" ? (
                                                                <div className="col-3">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Report File Name</label>
                                                                        <input
                                                                            className="form-control form-control-sm"
                                                                            type="file"
                                                                            onChange={readUploadFile}
                                                                            value={reportFileName}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    {existingreportFileName !== "" ? (
                                                                        <label value={existingreportFileName} className="form-label" >{"Existing File: " + existingreportFileName}</label>
                                                                    ) : null}

                                                                    {reportSourceType === "File" || reportSourceType === "DataBase" ? (
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-sm btn-primary ms-3"
                                                                            onClick={() => {
                                                                                handleUploadRDLCfile();
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </button>
                                                                    ) : null}

                                                                </div>
                                                            </div>

                                                            {existingreportFileName !== "" ? (
                                                                <div className="col-3">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Report Definition</label>
                                                                        <input
                                                                            className="form-control form-control-sm"
                                                                            type="text"
                                                                            value={reportDefinition}
                                                                        //onChange={}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="row align-items-end">
                                                            <div className="col-3">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Report Data Source Type *</label>
                                                                        <select
                                                                            className="form-control form-control-sm"
                                                                            //disabled={checkedValue === "0"}
                                                                            value={reportDataSourceType}
                                                                            onChange={reportDataSourceTypeDropdown}
                                                                            defaultValue="-- Select Report Data Source Type --"
                                                                        >
                                                                            <option value="">-- Select Report Data Source Type --</option>
                                                                            {reportDataSourceTypeList.map((dataSourceType, index) => (
                                                                                <option
                                                                                    key={index}
                                                                                    value={dataSourceType.value}
                                                                                >
                                                                                    {dataSourceType.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Report Data Source *</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportDataSource}
                                                                        onChange={reportDataSourceTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Main Report Name</label>
                                                                        <select
                                                                            className="form-control form-control-sm"
                                                                            //disabled={checkedValue === "0"}
                                                                            value={mainReportName}
                                                                            onChange={
                                                                                (e) => mainReportIdDropdown(e, reportsList.find((list) => list.reportName === e.target.value)?.reportId)
                                                                            }
                                                                            defaultValue="-- Select Main Report --"
                                                                        >
                                                                            <option value="">-- Select Main Report --</option>
                                                                            {reportsList.map((list) => {
                                                                                if (!list.isDeleted) {
                                                                                    return (
                                                                                        <option
                                                                                            key={list.reportId}
                                                                                            value={list.reportName}
                                                                                        >
                                                                                            {list.reportName}
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-end">
                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Report Data Source Parameter Name</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportDataSourceParameterName}
                                                                        onChange={reportDataSourceParameterNameTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Data Type</label>
                                                                        <select
                                                                            className="form-control form-control-sm"
                                                                            //disabled={checkedValue === "0"}
                                                                            value={reportDataSourceParameterDataType}
                                                                            onChange={reportDataSourceParameterDataTypeDropdown}
                                                                            defaultValue="-- Select Data Type --"
                                                                        >
                                                                            <option value="">-- Select Data Type --</option>
                                                                            {reportDataSourceParameterDataTypeList.map((dataType, index) => (
                                                                                <option
                                                                                    key={index}
                                                                                    value={dataType.value}
                                                                                >
                                                                                    {dataType.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Default Value</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportDataSourceParameterDefaultValue}
                                                                        onChange={reportDataSourceParameterDefaultValueTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary ms-3"
                                                                        onClick={() => {
                                                                            handleAddReportDataSourceParameter();
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {searchReportDataSourceParameterDivVisible ? (
                                                            <div className="row align-items-end">
                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <table className="table table-borderless MatTable" style={{ zIndex: "1", position: "relative" }}>
                                                                            <MaterialTable
                                                                                title=""
                                                                                options={{
                                                                                    showTitle: false,
                                                                                    exportButton: false,
                                                                                    sorting: true,
                                                                                    filtering: false,
                                                                                    search: false,
                                                                                    paging: false,
                                                                                    cellStyle: {
                                                                                        // width: 100,
                                                                                        // minWidth: 100,
                                                                                    },
                                                                                    headerStyle: {},
                                                                                    rowStyle: {
                                                                                        // fontFamily: '"Yantramanav", sans-serif',
                                                                                    }
                                                                                }}
                                                                                localization={{
                                                                                    pagination: {
                                                                                        //labelDisplayedRows: '{from}-{to} of {count}'
                                                                                    },
                                                                                    toolbar: {
                                                                                        // nRowsSelected: '{0} row(s) selected'
                                                                                        // exportCSVName: "Export as Excel",
                                                                                        // exportPDFName: "Export as pdf",
                                                                                    },
                                                                                    header: {
                                                                                        actions: "",
                                                                                    },
                                                                                }}
                                                                                actions={[
                                                                                    {
                                                                                        icon: () => <DeleteIcon />,
                                                                                        tooltip: "Delete Data Source Parameter Details",
                                                                                        onClick: (event, rowData) =>
                                                                                            DeleteReportDataSourceParameterByName(rowData),
                                                                                        position: "row",
                                                                                    }
                                                                                ]}
                                                                                components={{
                                                                                    Container: props => <Paper {...props} elevation={0} />,
                                                                                    Toolbar: props => (
                                                                                        <div
                                                                                            style={
                                                                                                {
                                                                                                    // backgroundColor: "rgb(206 206 206)",
                                                                                                    // fontSize: "0.75em",
                                                                                                }
                                                                                            }
                                                                                        >
                                                                                            <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                                columns={reportFormColumns}
                                                                                icons={tableIcons}
                                                                                data={reportDataSourceParametersList}
                                                                            />

                                                                        </table>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        ) : null}

                                                        <div className="row align-items-end">
                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Report Parameter Name</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportParameterName}
                                                                        onChange={reportParameterNameTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="d-inline">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Data Type</label>
                                                                        <select
                                                                            className="form-control form-control-sm"
                                                                            //disabled={checkedValue === "0"}
                                                                            value={reportParameterDataType}
                                                                            onChange={reportParameterDataTypeDropdown}
                                                                            defaultValue="-- Select Data Type --"
                                                                        >
                                                                            <option value="">-- Select Data Type --</option>
                                                                            {reportParameterDataTypeList.map((dataType, index) => (
                                                                                <option
                                                                                    key={index}
                                                                                    value={dataType.value}
                                                                                >
                                                                                    {dataType.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Default Value</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportParameterDefaultValue}
                                                                        onChange={reportParameterDefaultValueTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-sm btn-primary ms-3"
                                                                        onClick={() => {
                                                                            handleAddReportParameter();
                                                                            //resetButton();
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {searchReportParameterDivVisible ? (
                                                            <div className="row align-items-end">
                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <table className="table table-borderless MatTable" style={{ zIndex: "1", position: "relative" }}>
                                                                            <MaterialTable
                                                                                title=""
                                                                                options={{
                                                                                    showTitle: false,
                                                                                    exportButton: false,
                                                                                    sorting: true,
                                                                                    filtering: false,
                                                                                    search: false,
                                                                                    paging: false,
                                                                                    cellStyle: {
                                                                                        // width: 100,
                                                                                        // minWidth: 100,
                                                                                    },
                                                                                    headerStyle: {},
                                                                                    rowStyle: {
                                                                                        // fontFamily: '"Yantramanav", sans-serif',
                                                                                    }
                                                                                }}
                                                                                localization={{
                                                                                    pagination: {
                                                                                        //labelDisplayedRows: '{from}-{to} of {count}'
                                                                                    },
                                                                                    toolbar: {
                                                                                        // nRowsSelected: '{0} row(s) selected'
                                                                                        // exportCSVName: "Export as Excel",
                                                                                        // exportPDFName: "Export as pdf",
                                                                                    },
                                                                                    header: {
                                                                                        actions: "",
                                                                                    },
                                                                                }}
                                                                                actions={[
                                                                                    {
                                                                                        icon: () => <DeleteIcon />,
                                                                                        tooltip: "Delete Report Parameter Details",
                                                                                        onClick: (event, rowData) =>
                                                                                            DeleteReportParameterByName(rowData),
                                                                                        position: "row",
                                                                                    }
                                                                                ]}
                                                                                components={{
                                                                                    Container: props => <Paper {...props} elevation={0} />,
                                                                                    Toolbar: props => (
                                                                                        <div
                                                                                            style={
                                                                                                {
                                                                                                    // backgroundColor: "rgb(206 206 206)",
                                                                                                    // fontSize: "0.75em",
                                                                                                }
                                                                                            }
                                                                                        >
                                                                                            <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                                columns={reportFormColumns}
                                                                                icons={tableIcons}
                                                                                data={reportParametersList}
                                                                            />

                                                                        </table>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        ) : null}

                                                        <div className="row align-items-end">
                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Sub Process ID</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={subProcessId}
                                                                        onChange={subProcessIdTextBox}
                                                                        title="Please enter numeric value only."
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Report DataSet Name</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="text"
                                                                        value={reportDataSetName}
                                                                        onChange={reportDataSetNameTextBox}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Remarks</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="textbox"
                                                                        maxLength={4000}
                                                                        value={remarks}
                                                                        onChange={remarksTextBox}
                                                                        title="Remarks should not exceed 4000 characters."
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Is Deleted</label>
                                                                    <input
                                                                        //className="form-control form-control-sm"
                                                                        type="checkbox"
                                                                        checked={isDeletedCheck}
                                                                        onChange={isDeletedCheckBox}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </>
                </Modal.Body >

                <Modal.Footer>
                    <div >
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary ms-3"
                            title="Click here to save the Report Configuration"
                            position="row"
                            onClick={() => handleSaveandUpdate()}
                        >
                            {buttonText}
                        </button>
                    </div>
                </Modal.Footer>

            </Modal >
        </>
    );

};

export default ReportConfig;
