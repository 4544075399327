import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import { FormBuilder, Form, Components } from "react-formio";
import LoadingSpinner from "../../../../LoadingSpinner";
import "formiojs/dist/formio.full.css";
import FormSubmissionPage from "../../../../common/FormSubmit";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import { useNavigate, useLocation } from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";
import { FormEditor } from "@bpmn-io/form-js";
import "bpmn-font/dist/css/bpmn.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";
import ConfigContext from "../../../../contexts/ConfigContext";

import { ToastProvider, useToasts } from "react-toast-notifications";
import { json2xml } from "xml-js";
import { DomainAddRounded } from "@mui/icons-material";

function FormBuilderConfig(props) {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_CREATEWORKITEMFIELDS } = process.env;
  const { REACT_APP_GETWORKITEMFIELDS } = process.env;
  const { REACT_APP_DELETEWORKITEMFIELD } = process.env;
  const { REACT_APP_UPDATEFORMXML } = process.env;
  const { REACT_APP_GetSubProcessWorkitemFields } = process.env;
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  const formEditorContainerRef = useRef(null);
  const formEditorRef = useRef(null);
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  let modifiedForm = null;
  const [formComponents, setFormComponents] = useState();
  const [deletedField, setdeletedField] = useState({});
  const [IsCreated, setIsCreated] = useState(false);
  const [IsConfigxml, setIsConfigxml] = useState(false);
  const [Isdelete, setIsdelete] = useState(false);
  const [IsRefresh, setIsRefresh] = useState(false);
  const [isLoading, set_IsLoading] = useState(false);
  const [fieldsDetails, setFieldsDetails] = useState([]);

  useEffect(() => {
    setIsCreated(false);
    getSubProcessWorkitemFields();
  }, [IsCreated]);

  useEffect(() => {
    setIsConfigxml(false);
    setIsdelete(false);
    let componentArray = [];
    let configXML = {};
    let newFormDefinition = {};
    debugger;
    if (formComponents !== undefined) {
      configXML =
        formComponents !== undefined ? JSON.parse(formComponents) : null;
      if (configXML.CreateWorkitemFields.components !== null) {
        if (configXML.CreateWorkitemFields.components.Component.length > 1) {
          configXML.CreateWorkitemFields.components.Component.forEach(
            element => {
              debugger;
              fieldsDetails.forEach(field => {
                if (element.label === field.labelText) {
                  element.id = field.workItemFieldID.toString();
                }
              });
              element.readonly = element.readonly === "false" ? false : true;
              element.searchable =
                element.searchable === "false" ? false : true;
              if (element.validate !== undefined) {
                element.validate.required =
                  element.validate.required === "true" ? true : false;
                element.validate.maxLength = +element.validate.maxLength;
              }
              element.disabled =
                element.disabled.required === "true" ? true : false;
              element.decimalDigits = +element.decimalDigits;
              if (element.values !== undefined) {
                if (element.values.Value.length > 0) {
                  element.values = element.values.Value;
                }
              }
              componentArray.push(element);

              if (element.key.includes('select') && element.values.length == undefined
              ) {
                for (let i = 0; i < componentArray.length; i++) {
                  if (componentArray[i].type == 'select') {
                    debugger;
                    if (componentArray[i].values.length == undefined) {
                      componentArray[i].values = [
                        {
                          label: componentArray[i].values.Value.label,
                          value: componentArray[i].values.Value.value,
                        },
                      ];
                    }
                  }
                }
              }

            }
          );
        } else {
          fieldsDetails.forEach(field => {
            if (configXML.CreateWorkitemFields.components.Component.label === field.labelText) {
              configXML.CreateWorkitemFields.components.Component.id = field.workItemFieldID.toString();
            }
          });
          configXML.CreateWorkitemFields.components.Component.readonly =
            configXML.CreateWorkitemFields.components.Component.readonly ===
              "false"
              ? false
              : true;
          configXML.CreateWorkitemFields.components.Component.searchable =
            configXML.CreateWorkitemFields.components.Component.searchable ===
              "false"
              ? false
              : true;
          if (
            configXML.CreateWorkitemFields.components.Component.validate !==
            undefined
          ) {
            configXML.CreateWorkitemFields.components.Component.validate.required =
              configXML.CreateWorkitemFields.components.Component.validate
                .required === "true"
                ? true
                : false;
            configXML.CreateWorkitemFields.components.Component.validate.maxLength = +configXML
              .CreateWorkitemFields.components.Component.validate.maxLength;
          }

          configXML.CreateWorkitemFields.components.Component.disabled =
            configXML.CreateWorkitemFields.components.Component.disabled
              .required === "true"
              ? true
              : false;
          configXML.CreateWorkitemFields.components.Component.decimalDigits = +configXML
            .CreateWorkitemFields.components.Component.decimalDigits;
          if (
            configXML.CreateWorkitemFields.components.Component.values !==
            undefined
          ) {
            if (
              configXML.CreateWorkitemFields.components.Component.values.Value
                .length > 0
            ) {
              configXML.CreateWorkitemFields.components.Component.values =
                configXML.CreateWorkitemFields.components.Component.values.Value;
            }
          }
          componentArray.push(
            configXML.CreateWorkitemFields.components.Component
          );

          if (configXML.CreateWorkitemFields.components.Component.key.includes('select') &&
            configXML.CreateWorkitemFields.components.Component.values.length == undefined
          ) {
            componentArray[0].values = [
              {
                label: componentArray[0].values.Value.label,
                value: componentArray[0].values.Value.value,
              },
            ];
          }
        }
      }

      newFormDefinition = {
        components: componentArray,
        id: configXML.CreateWorkitemFields.id,
        schema: configXML.CreateWorkitemFields.schemaVersion,
        type: configXML.CreateWorkitemFields.type
      };
    } else {
      newFormDefinition = {
        components: [],
        type: "default"
      };
    }
    const customElementProps = {
      bpmnElements: {
        style: {
          width: 20, // Adjust width as desired (in pixels)
          height: 20 // Adjust height as desired (in pixels)
        },
        // More specific element properties if needed:
        task: {
          style: {
            fill: "lightblue" // Example: Change task fill color
          }
        }
      }
    };
    formEditorRef.current = new FormEditor({
      container: formEditorContainerRef.current,
      customElementProps: customElementProps
    });
    formEditorRef.current.importSchema(newFormDefinition);

    const eventBus = formEditorRef.current.get("eventBus");

    eventBus.on("commandStack.formField.add.preExecute", event => {
      AddCustomProperty(event);
    });
    let schema = null;
    let formField = null;
    let index = 0;
    // eventBus.on("commandStack.formField.remove.preExecute", async (event) => {
    //   schema = await formEditorRef.current.saveSchema();
    //   console.log("pre-delete");
    //   console.log(schema);
    // });
    // eventBus.on("commandStack.formField.remove.postExecute", async (event) => {
    //   const schema1 = await formEditorRef.current.saveSchema();
    //   console.log("post-predelete");
    //   console.log(schema1);
    //   // if (Isdelete) {
    //   //   schema.components.splice(index, 0, formField);
    //   //   formEditorRef.current.importSchema(schema);
    //   //   addToast("The field Cant be deleted as Its alreday in Use.", {
    //   //     appearance: "error",
    //   //     autoDismiss: true,
    //   //   });
    //   // } else {
    //   //   addToast("The Workitem Field Deleted Successfully.", {
    //   //     appearance: "success",
    //   //     autoDismiss: true,
    //   //   });
    //   // }
    // });
    eventBus.on("formField.remove", async event => {
      schema = await formEditorRef.current.saveSchema();
      formField = event.formField;
      deleteFormField(formField.label, schema, formField, index);
    });
    return () => {
      formEditorRef.current.destroy();
    };
  }, [IsConfigxml]);

  const AddCustomProperty = async event => {
    //const schema = await formEditorRef.current.saveSchema();
    var properties = {
      IsMultiSelect: "false",
      IsHyperLink: "false",
      "Sorting Order(NONE,ASC,DESC)": "NONE",
      "MultiLine TextBox Type(S,M,L)": "S",
      "Mask($99,$9999,99,9999)": "",
      "Strip Special Characters": "false"
    };
    // for(let i=0;i<schema.components.length;i++)
    // {
    event.context.formField.properties = properties;
    // }
    //formEditorRef.current.importSchema(schema);
  };
  const deleteFormField = async (field, schema, formField, index) => {
    debugger;
    setIsdelete(true);
    let body = {
      fieldName: field,
      subprocessId: props.reference.SubprocessID
    };
    let loginUrl = getAPIURL(REACT_APP_DELETEWORKITEMFIELD);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          debugger;
          setIsdelete(true);
          addToast("The Workitem Field Deleted Successfully.", {
            appearance: "success",
            autoDismiss: true
          });
          var updatedSchema = formEditorRef.current.saveSchema();
          UpdateFormXml(updatedSchema);
        } else if (result.status == 403) {
          setIsdelete(false);
          schema.components.splice(index, 0, formField);
          formEditorRef.current.importSchema(schema);
          addToast("The field Cant be deleted as Its alreday in Use.", {
            appearance: "error",
            autoDismiss: true
          });
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  const UpdateFormXml = updatedSchema => {
    let loginUrl = getAPIURL(REACT_APP_UPDATEFORMXML);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        SubprocessId: props.reference.SubprocessID,
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(updatedSchema)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          //setIsConfigxml(true);
          setIsCreated(true);
          //setFormComponents(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const getWorkitemFields = () => {
    //set_IsLoading(true);
    debugger;
    let body = {
      subprocessId: props.reference.SubprocessID
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          set_IsLoading(false);
          setIsConfigxml(true);
          setFormComponents(result.data);
        } else if (result.status === 401) {
          set_IsLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_IsLoading(false);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const getSubProcessWorkitemFields = () => {
    //set_IsLoading(true);
    debugger;
    let body = {
      subprocessId: props.reference.SubprocessID
    };
    let loginUrl = getAPIURL(REACT_APP_GetSubProcessWorkitemFields);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          console.log(result.data);
          setFieldsDetails(result.data);
          getWorkitemFields();
        } else if (result.status === 401) {
          set_IsLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_IsLoading(false);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const getFormDefinition = async () => {
    //setIsFormValid(true);
    debugger;
    let count = 0;
    const schema = await formEditorRef.current.saveSchema();

    schema.components.forEach(component => {
      delete component.layout;
    });
    console.log(schema);

    schema.components.map((component, index) => {
      let property = {
        IsMultiSelect: component.properties.IsMultiSelect,
        IsHyperLink: component.properties.IsHyperLink,
        SortingOrder:
          component.properties["Sorting Order(NONE,ASC,DESC)"] == undefined
            ? component.properties.SortingOrder
            : component.properties["Sorting Order(NONE,ASC,DESC)"],
        MultiLineTextBoxType:
          component.properties["MultiLine TextBox Type(S,M,L)"] == undefined
            ? component.properties.MultiLineTextBoxType
            : component.properties["MultiLine TextBox Type(S,M,L)"],
        Mask:
          component.properties["Mask($99,$9999,99,9999)"] == undefined
            ? component.properties.Mask == null
              ? ""
              : component.properties.Mask
            : component.properties["Mask($99,$9999,99,9999)"],
        StripSpecialCharacters:
          component.properties["Strip Special Characters"] == undefined
            ? component.properties.StripSpecialCharacters
            : component.properties["Strip Special Characters"]
      };
      component.properties = property;
      if (
        component.validate == undefined &&
        component.type == "select" &&
        component.properties.IsMultiSelect.toLocaleLowerCase() == "true"
      ) {
        let validate = {
          max: 100,
          maxLength: 100
        };
        component.validate = validate;
      }
      if (
        component.properties.IsHyperLink.toLocaleLowerCase() == "true" &&
        component.defaultValue == undefined
      ) {
        count++;
        addToast("Enter Default Value for the href field", {
          appearance: "error",
          autoDismiss: true
        });
      }
      const elementNumber = index + 1;

      if (component.type == "datetime") {
        const isValidLabel = /^[A-Za-z][A-Za-z0-9_]*$/;
        if (!isValidLabel.test(component.dateLabel)) {
          count++;
          addToast("Date Label Text should always start with a character and should contain only alphabets, numbers, and underscore (_).", {
            appearance: "error",
            autoDismiss: true
          });
        }
        else {
          component.label = component.dateLabel;
        }
      }
      if (component.label === undefined) {
        //setIsFormValid(false);
        count++;
        addToast("Select Label for the " + elementNumber + " Field", {
          appearance: "error",
          autoDismiss: true
        });
      }
      if (component.label) {
        const isValidLabel = /^[A-Za-z][A-Za-z0-9_]*$/;
        if (!isValidLabel.test(component.label)) {
          count++;
          addToast("Label Text should always start with a character and should contain only alphabets, numbers, and underscore (_).", {
            appearance: "error",
            autoDismiss: true
          });
        }
      }
      if (
        component.validate === undefined &&
        (component.key.includes("text") || component.key.includes("number"))
      ) {
        //setIsFormValid(false);
        // count++;
        // addToast("Select Max Length for the " + elementNumber + " Field", {
        //   appearance: "error",
        //   autoDismiss: true
        // });
        if (component.key.includes("text")) {
          let validate = {
            maxLength: 100
          };
          component.validate = validate;
        }
        else if (component.key.includes("number")) {
          let validate = {
            max: 20
          };
          component.validate = validate;
        }

      } else if (component.key.includes("text")) {
        //setIsFormValid(false);
        if (component.validate.maxLength === undefined) {
          // count++;
          // addToast("Select Max Length for the " + elementNumber + " Field", {
          //   appearance: "error",
          //   autoDismiss: true
          // });
          component.validate.maxLength = 100;
        }
      } else if (component.key.includes("number")) {
        if (component.validate.max === undefined) {
          // count++;
          // addToast("Select Max Length for the " + elementNumber + " Field", {
          //   appearance: "error",
          //   autoDismiss: true
          // });
          component.validate.max = 20;
        }
      }
      if (component.validate == undefined) {
        let validate = {
          max: 0,
          maxLength: 25,
          required: false
        };
        component.validate = validate;
      }
      // else if (component.type == "datetime" && component.validate.required == true) {
      //   let validate = {
      //     max: 0,
      //     maxLength: 25,
      //     required: true
      //   };
      //   component.validate = validate;
      // }
    });
    if (count === 0) {
      for (
        let i = 0;
        i < schema.components.filter(e => e.type == "number").length;
        i++
      ) {
        schema.components.filter(e => e.type == "number")[i].defaultValue =
          "" +
          schema.components.filter(e => e.type == "number")[i].defaultValue;
      }
      let body = schema;
      let loginUrl = getAPIURL(REACT_APP_CREATEWORKITEMFIELDS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          SubprocessId: props.reference.SubprocessID,
          PersonId: sessionStorage.getItem("PersonID"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status == 200) {
            addToast("Workitem Fields Created Successfully!", {
              appearance: "success",
              autoDismiss: true
            });
            setIsCreated(true);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else if (result.status === 400) {
            addToast("Kindly check for the invalid field value in the form!!", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };
  const NavElements = useMemo(() => {
    return (
      <>
        <Header />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  // const handleClick = async () => {
  //   debugger
  //   await getFormDefinition();
  // };
  return (
    <>
      {/* <ConfigContext.Provider value={{ onclick: handleClick }}> */}
      {isLoading ? (
        <div className="pt-4">
          {" "}
          <LoadingSpinner />
        </div>
      ) : (
        <div
          className="dashBoxs"
          style={{
            overflowX: "hidden",
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <div className="row">
            <div ref={formEditorContainerRef} style={{ height: "460px" }}></div>
          </div>
          <button
            ref={props.reference.FormBuilderConfigRef}
            style={{ display: "none" }}
            onClick={getFormDefinition}
          ></button>
        </div>
      )}
      {/* </ConfigContext.Provider> */}
    </>
  );
}

export default FormBuilderConfig;
