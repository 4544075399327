import React, { useState, useEffect } from "react";
import { ListGroup, Badge } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";

import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";

import { ToastProvider, useToasts } from "react-toast-notifications";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import markdone from "src/assets/images/icons/mark.png";
import addicon from "src/assets/images/icons/add-capacity.png";
import deleteicon from "src/assets/images/icons/delete.png";

const localizer = momentLocalizer(moment);

const CapacityScheduler = () => {
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedEmployeeId, setselectedEmployeeId] = useState(null);
  const [EmployeeDetails, setEmployeeDetails] = useState([]);
  const [scheduleJson, setScheduleJson] = useState([]);
  const [scheduleChanged, setScheduleChanged] = useState(false);
  const [IsEditable, setisEditable] = useState(false);
  const [IsPlannerVisible, setIsPlannerVisible] = useState(false);
  const [isFetching, setisFetching] = useState(false);
  const [isSaving, setisSaving] = useState(false);
  const [selectedmemberProcess, setSelectedmemberProcess] = useState([]);
  const [currentWeekStart, setCurrentWeekStart] = useState(() => {
    const currentDate = new Date();
    const currentDayIndex = currentDate.getDay();
    return new Date(
      currentDate.getTime() - currentDayIndex * 24 * 60 * 60 * 1000
    );
  });
  const [editMode, setEditMode] = useState([]);

  // Dummy data for team members and subProcesss
  const teamMembers = ["John", "Alice", "Bob"];
  const scheduleformat = {
    schedule: [
      {
        date: "04/28/2024",
        formattedDate: "04282024",
        capacity: [],
      },
      {
        date: "04/29/2024",
        formattedDate: "04292024",
        capacity: [
          {
            subProcess: "Process 1",
            hours: 7,
          },
          {
            subProcess: "Process 2",
            hours: 5,
          },
        ],
      },
      {
        date: "04/30/2024",
        formattedDate: "04302024",
        capacity: [
          {
            subProcess: "Process 1",
            hours: 7,
          },
          {
            subProcess: "Process 2",
            hours: 5,
          },
        ],
      },
      {
        date: "05/01/2024",
        formattedDate: "05012024",
        capacity: [
          {
            subProcess: "Process 1",
            hours: 7,
          },
          {
            subProcess: "Process 2",
            hours: 5,
          },
        ],
      },
      {
        date: "05/02/2024",
        formattedDate: "05022024",
        capacity: [
          {
            subProcess: "Process 1",
            hours: 7,
          },
          {
            subProcess: "Process 2",
            hours: 5,
          },
        ],
      },
      {
        date: "05/03/2024",
        formattedDate: "05032024",
        capacity: [
          {
            subProcess: "Process 1",
            hours: 7,
          },
          {
            subProcess: "Process 2",
            hours: 5,
          },
        ],
      },
      {
        date: "05/04/2024",
        formattedDate: "05042024",
        capacity: [],
      },
    ],
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const {
    REACT_APP_API_URL,
    REACT_APP_FETCH_PROCESSSUBPROCESS,
    REACT_APP_GetEmployeeBySupervisor,
    REACT_APP_SaveCapacitySchedule,
    REACT_APP_GetCapacitySchedule,
  } = process.env;

  // Functions to format and parse dates
  const formatDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${month}${day}${year}`;
  };
  const CallenderToolBar = () => {
    return <div className="rbc-toolbar"></div>;
  };
  const GetEmployeeListBySupervisor = () => {
    setEmployeeDetails([]);
    setSelectedTeamMember(null);
    //set_IsLoading(true);
    let body = {
      loggedInPersonID: sessionStorage.getItem("PersonID"),
    };
    let activityUrl = getAPIURL(REACT_APP_GetEmployeeBySupervisor);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        // debugger;
        if (result.status == 200) {
          setEmployeeDetails(result.data._list_Employee_Details);

          // set_IsLoading(false);
        }
        //  set_IsLoading(false);
      })
      .catch((error) => {
        // set_IsLoading(false);

        console.log("ERROR: ", error);
      });
  };
  const parseDate = (formattedDate) => {
    const month = parseInt(formattedDate.substring(0, 2)) - 1;
    const day = parseInt(formattedDate.substring(2, 4));
    const year = parseInt(formattedDate.substring(4));
    return new Date(year, month, day);
  };
  const checkIsPlannerVisible = () => {
    var rolesList = JSON.parse(sessionStorage.getItem("rolesList"));
    var plannerRole = rolesList.filter((item) => {
      return item.roleName === "Capacity planning";
    });
    if (plannerRole?.length > 0) {
      setIsPlannerVisible(true);
    }
  };

  useEffect(() => {
    checkIsPlannerVisible();
    GetEmployeeListBySupervisor();
    initialSchedule([]);
    if (empidfilter) {
      console.log("empidfilter", empidfilter);
    }
  }, []);

  useEffect(() => {
    if (selectedmemberProcess.length > 0) {
      getCapacitySchedule();
    }
  }, [selectedmemberProcess]);
  const initialSchedule = async (savedentries) => {
    const editSchedule = {};
    const initialData = [];
    // const currentDate = new Date(); // Get current date
    for (let i = 0; i < 7; i++) {
      const date = new Date(
        currentWeekStart.getTime() + i * 24 * 60 * 60 * 1000
      );
      const formattedDate = formatDate(date); // Function to format date as "MMddYYYY"
      await initialData.push({
        date: moment(date).format("MM/DD/YYYY"),
        formattedDate: formattedDate,
        capacity: [],
      });
      editSchedule[formattedDate] = [];
    }
    //console.log("initialData at refresh", initialData);
    if (savedentries.length > 0) {
      populateSavedSchedule(initialData, savedentries);
    } else {
      setScheduleJson(initialData);
    }
    setEditMode(editSchedule);
    setScheduleChanged(false);
  };
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const handleTeamMemberSelect = (event) => {
    if (event.target.value === "Select Team Member") {
      return;
    }
    setSelectedTeamMember(event.target.selectedOptions[0].text);
    setselectedEmployeeId(event.target.value.split("/")[0]);
    var userId = event.target.value.split("/")[1];
    FetchSubprocessName(userId);
    if (userId.toString() === sessionStorage.getItem("GUID")) {
      setisEditable(true);
    } else {
      setisEditable(false);
    }
    // Initialize schedule for the selected team member

    const editModeIntital = {};
    for (let i = 0; i < 7; i++) {
      const date = new Date(
        currentWeekStart.getTime() + i * 24 * 60 * 60 * 1000
      );
      const formattedDate = formatDate(date); // Function to format date as "MMddYYYY"
      editModeIntital[formattedDate] = [];
      initialSchedule([]);
      //  getCapacitySchedule();
    }
    const startDate = new Date(currentWeekStart);
    setEditMode(editModeIntital);
    InitializeWeek(startDate);
    setScheduleChanged(false);
  };

  useEffect(() => {
    var empobj = EmployeeDetails.filter((item) => {
      return item.employeeID === empidfilter;
    });

    if (!empidfilter || empobj.length === 0) {
      return;
    }
    setSelectedTeamMember(empobj[0].employeeName);
    setselectedEmployeeId(empobj[0].employeeID);
    var userId = empobj[0].userId;
    FetchSubprocessName(userId);
    if (userId.toString() === sessionStorage.getItem("GUID")) {
      setisEditable(true);
    } else {
      setisEditable(false);
    }
    // Initialize schedule for the selected team member

    const editModeIntital = {};
    for (let i = 0; i < 7; i++) {
      const date = new Date(
        currentWeekStart.getTime() + i * 24 * 60 * 60 * 1000
      );
      const formattedDate = formatDate(date); // Function to format date as "MMddYYYY"
      editModeIntital[formattedDate] = [];
      initialSchedule([]);
      //  getCapacitySchedule();
    }
    const startDate = new Date(currentWeekStart);
    setEditMode(editModeIntital);
    InitializeWeek(startDate);
    setScheduleChanged(false);
  }, [EmployeeDetails]);
  // Handle client and hours update
  const handleClientAndHoursUpdate = (
    date,
    capacityIndex,
    subProcess,
    hours
  ) => {
    const scheduleJsoncp = [...scheduleJson];
    scheduleJsoncp.map((e) => {
      if (e.formattedDate === date) {
        e.capacity[capacityIndex].subProcess = subProcess;
        e.capacity[capacityIndex].hours = hours;
      }
    });
    // console.log(scheduleJsoncp);
    setScheduleJson(scheduleJsoncp);
  };

  // Handle add capacity
  const handleAddCapacity = (date) => {
    var len = 0;
    const scheduleJsoncp = [...scheduleJson];
    scheduleJsoncp.forEach((scheduleJsonArray) => {
      if (scheduleJsonArray.formattedDate === date) {
        len = scheduleJsonArray.capacity.length;

        scheduleJsonArray.capacity.push({ subProcess: "", hours: 0 });
      }
    });

    setScheduleJson(scheduleJsoncp);
    // console.log(scheduleformat);
    const updatedEditMode = { ...editMode };
    updatedEditMode[date] = [];
    for (let i = 0; i <= len; i++) {
      if (i === len) {
        updatedEditMode[date].push({ mode: true });
      } else {
        updatedEditMode[date].push({ mode: false });
      }
    }
    setEditMode(updatedEditMode);
  };

  const handleDeleteCapacity = (date) => {
    const scheduleJsoncp = [...scheduleJson];
    scheduleJsoncp.map((e) => {
      if (e.formattedDate === date) {
        e.capacity = [];
      }
    });
    // console.log(scheduleJsoncp);
    setScheduleJson(scheduleJsoncp);

    const updatedEditMode = { ...editMode };
    updatedEditMode[date] = [];
    setEditMode(updatedEditMode);
    setScheduleChanged(true);
  };
  const handleDoneEditing = (date, capacityIndex) => {
    const scheduleJsoncp = [...scheduleJson];
    scheduleJsoncp.map((e) => {
      if (e.formattedDate === date) {
        if (
          e.capacity[capacityIndex].subProcess === "" ||
          e.capacity[capacityIndex].hours === 0
        ) {
          const newArray = e.capacity.filter(
            (item, index) => index !== capacityIndex
          );
          e.capacity = newArray;

          addToast("Please select valid inputs", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    });
    // console.log(scheduleJsoncp);
    setScheduleJson(scheduleJsoncp);
    const updatedEditMode = { ...editMode };
    updatedEditMode[date][capacityIndex].mode = false;
    setEditMode(updatedEditMode);
    setScheduleChanged(true);
  };
  const populateSavedSchedule = (initialData, savedschedules) => {
    // console.log("initialData at populate", initialData);
    // const scheduleJsoncp = [...scheduleJson];
    initialData.forEach((scheduleJsonArray) => {
      var savedvalues = savedschedules.filter(
        (item) =>
          formatDate(new Date(item.date)) === scheduleJsonArray.formattedDate
      );
      if (savedvalues.length > 0) {
        savedvalues.map((e) => {
          scheduleJsonArray.capacity.push({
            subProcess: getFormattedSubprocess(e.subProcess),
            hours: e.hours,
          });
        });
      }
    });

    setScheduleJson(initialData);
    addToast("Records found!", {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const isPastDate = (date) => {
    const currentDate = new Date();
    return (
      date <
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
    );
  };
  const saveCapacitySchedule = async () => {
    var selectedEntity = JSON.parse(
      sessionStorage.getItem("selectedEntitiesDetail")
    );
    setisSaving(true);
    let UserToken = "";
    let indexp = 0;
    selectedEmployeeId.split("").forEach((character) => {
      UserToken = UserToken + "=e" + selectedEmployeeId.charCodeAt(indexp);
      indexp = indexp + 1;
    });
    var Buffer = require("buffer/").Buffer;

    let buff = new Buffer(UserToken);
    let encryptedempId = buff.toString("base64");

    var loggedinpersonid = sessionStorage.getItem("PersonID");
    loggedinpersonid.split("").forEach((character) => {
      UserToken = UserToken + "l@" + loggedinpersonid.charCodeAt(indexp);
      indexp = indexp + 1;
    });
    var Buffer = require("buffer/").Buffer;

    let buff1 = new Buffer(UserToken);
    let encryptedenteredBy = buff.toString("base64");
    let body = {
      loggedInPersonID: encryptedenteredBy,
      personIdString: encryptedempId,
      weekStart: currentWeekStart,
      schedules: scheduleJson,
      clientId: selectedEntity.clientID.toString(),
    };
    let loginUrl = getAPIURL(REACT_APP_SaveCapacitySchedule);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then(async (result) => {
        setisSaving(false);
        if (result.status == 200) {
          addToast("Records saved!", {
            appearance: "success",
            autoDismiss: true,
          });
          if (result.data.length > 0) {
            await initialSchedule(result.data);
          }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 400 || result.status === 500) {
          addToast("Something went wrong", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        setisSaving(false);
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          // BodydetailsinLog(error.toString() + " FetchSubprocessName ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };

  const getCapacitySchedule = async () => {
    if (isFetching) {
      return;
    }
    var selectedEntity = JSON.parse(
      sessionStorage.getItem("selectedEntitiesDetail")
    );
    setisFetching(true);
    let body = {
      loggedInPersonID: sessionStorage.getItem("PersonID"),
      employeeId: selectedEmployeeId,
      weekStart: currentWeekStart,
      schedules: [],
      clientId: selectedEntity.clientID.toString(),
    };
    let loginUrl = getAPIURL(REACT_APP_GetCapacitySchedule);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then(async (result) => {
        setisFetching(false);

        if (result.status == 200) {
          //   console.log(result);
          if (result.data.length > 0) {
            await initialSchedule(result.data);
          } else {
            addToast("No records", {
              appearance: "info",
              autoDismiss: true,
            });
          }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setisFetching(false);

        //if (result.status === 401) {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          // BodydetailsinLog(error.toString() + " FetchSubprocessName ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchSubprocessName = (userId) => {
    var selectedEntity = JSON.parse(
      sessionStorage.getItem("selectedEntitiesDetail")
    );

    let body = {
      guid: userId.toString(),
      subProcessId: "",
      clientId: selectedEntity.clientID,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          //  console.log(result);
          setSelectedmemberProcess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          // BodydetailsinLog(error.toString() + " FetchSubprocessName ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const getTotalHours = () => {
    let totalHours = 0;
    scheduleJson.forEach((dateObject) => {
      totalHours += dateObject.capacity.reduce(
        (dayTotal, capacity) => dayTotal + capacity.hours,
        0
      );
    });
    if (totalHours > 40) {
      return (
        <Badge bg="danger" title="Exceeding allowed working hours.">
          {totalHours}
        </Badge>
      );
    }
    return totalHours;
  };

  const getCollectiveTotalHours = (date) => {
    //console.log("scheduleformat", scheduleformat);
    if (scheduleJson === undefined) {
      return 0; // Return 0 if array is empty or undefined
    }

    const dateObject = scheduleJson.find((e) => e.formattedDate === date);

    if (dateObject) {
      const totalHours = dateObject.capacity.reduce(
        (acc, curr) => acc + curr.hours,
        0
      );
      return totalHours;
    } else {
      return 0;
    }
  };

  // Render events on calendar
  const events = [];
  for (let i = 0; i < 7; i++) {
    const date = moment(currentWeekStart).add(i, "days").format("MMDDYYYY");
    const totalHours = getCollectiveTotalHours(date);
    events.push({
      title: `${totalHours} Hours`,
      start: new Date(currentWeekStart.getTime() + i * 24 * 60 * 60 * 1000),
      end: new Date(currentWeekStart.getTime() + i * 24 * 60 * 60 * 1000),
      formattedDate: date,
    });
  }

  const goToPreviousWeek = () => {
    const prevStart = new Date(
      currentWeekStart.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    setCurrentWeekStart(
      (prev) => new Date(prev.getTime() - 7 * 24 * 60 * 60 * 1000)
    );
    InitializeWeek(prevStart);
  };
  const getFormattedSubprocess = (subprocessid) => {
    var item = selectedmemberProcess.filter(
      (e) => e.subProcessIDString.toString() === subprocessid.toString()
    );
    if (item.length > 0) {
      return item[0].subProcessIDString + "/" + item[0].processSubProcess;
    } else {
      return "Unknown";
    }
  };
  const InitializeWeek = (nextStart) => {
    const initialSchedule = {};
    const editSchedule = {};
    for (let i = 0; i < 7; i++) {
      const date = new Date(nextStart.getTime() + i * 24 * 60 * 60 * 1000);
      const formattedDate = formatDate(date); // Function to format date as "MMddYYYY"
      initialSchedule[formattedDate] = []; // Initialize empty array for each date
      editSchedule[formattedDate] = [];
    }

    setEditMode(editSchedule);
  };
  const goToNextWeek = async () => {
    const nextStart = new Date(
      currentWeekStart.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    await setCurrentWeekStart(
      (prev) => new Date(prev.getTime() + 7 * 24 * 60 * 60 * 1000)
    );
    InitializeWeek(nextStart);
  };

  const location = useLocation();
  const processingDashboardParam = location.state;
  let navigate = useNavigate();
  const { addToast } = useToasts();
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;

  const empidfilter = processingDashboardParam.empidfilter;
  return (
    <>
      <Header onpreferenceShow={null} pageName="Capacity Scheduler" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="container mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-2">
                      <h2>
                        Capacity Scheduler{" "}
                        {IsPlannerVisible && (
                          <Link
                            to="/CapacityPlanner"
                            state={{
                              selectedSubprocess,
                              nav,
                              userRoles,
                              assignedWorkItemDetails: navData,
                            }}
                            style={{ float: "right" }}
                            className="btn btn-secondary"
                          >
                            <b className="material-symbols-outlined ">
                              event_available
                            </b>{" "}
                            Go to planner
                          </Link>
                        )}
                      </h2>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <select
                            id="ddlEmployeeList"
                            className="form-control form-control-sm"
                            onChange={handleTeamMemberSelect}
                          >
                            <option>Select Team Member</option>
                            {EmployeeDetails.map((Emp) => (
                              <option
                                value={Emp.employeeID + "/" + Emp.userId}
                                selected={Emp.employeeID == empidfilter}
                              >
                                {Emp.employeeName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-2">
                          {selectedTeamMember && (
                            <button
                              className="btn btn-outline-ntv mx-2"
                              onClick={getCapacitySchedule}
                            >
                              {isFetching ? "Fetching.." : "Fetch"}
                            </button>
                            // <div className="d-sm-inline-block">
                            //   <div className="dropdown userDropdown">
                            //     <a
                            //       className="navbar-dropdown-account-wrapper"
                            //       title="Fetch schedule"
                            //     >
                            //       <div
                            //         className="avatar avatar-circle"

                            //       >
                            //         {" "}
                            //         <div className="avatar-img">
                            //           {" "}
                            //           <img src={FetchDataIcon} alt="?" />
                            //         </div>
                            //       </div>
                            //     </a>
                            //   </div>
                            // </div>
                          )}
                        </div>
                        <div className="col-4 ">
                          {scheduleChanged && (
                            <button
                              className="btn btn-primary"
                              onClick={saveCapacitySchedule}
                            >
                              {isSaving ? "Saving.." : "Save Changes"}
                            </button>
                            // <div className="d-sm-inline-block">
                            //   <div className="dropdown userDropdown">
                            //     <a
                            //       className="navbar-dropdown-account-wrapper"
                            //       title="Save schedule changes"
                            //     >
                            //       <div
                            //         className="avatar avatar-circle"

                            //       >
                            //         {" "}
                            //         <div className="avatar-img">
                            //           {" "}
                            //           <img src={pushDataIcon} alt="?" />
                            //         </div>
                            //       </div>
                            //     </a>
                            //   </div>
                            // </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {selectedTeamMember && (
                      <>
                        <h3>{selectedTeamMember}'s Schedule</h3>
                        {/* <div className="d-flex justify-content-between mb-3">
                          <button
                            className="btn btn-primary"
                            onClick={goToPreviousWeek}
                          >
                            Previous Week
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={goToNextWeek}
                          >
                            Next Week
                          </button>
                        </div> */}
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Day</th>
                              <th>Capacity</th>
                              <th>Hours</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {events.map((event, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(event.start).format("MM/DD/YYYY")}
                                </td>
                                <td>{days[index]}</td>

                                <td>
                                  <ListGroup as="ol" numbered>
                                    {scheduleJson
                                      .find(
                                        (e) =>
                                          e.formattedDate ===
                                          event.formattedDate
                                      )
                                      ?.capacity?.map(
                                        (capacity, capacityIndex) => (
                                          <>
                                            {editMode[event.formattedDate][
                                              capacityIndex
                                            ]?.mode ? (
                                              <div
                                                key={capacityIndex}
                                                className="col-md-12 d-flex"
                                              >
                                                <select
                                                  className="form-control mb-2"
                                                  disabled={isPastDate(
                                                    event.start
                                                  )}
                                                  onChange={(e) =>
                                                    handleClientAndHoursUpdate(
                                                      event.formattedDate,
                                                      capacityIndex,
                                                      e.target.value,
                                                      capacity.hours
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select process
                                                  </option>
                                                  {selectedmemberProcess.map(
                                                    (subprocess) => (
                                                      <option
                                                        value={
                                                          subprocess.subProcessIDString +
                                                          "/" +
                                                          subprocess.processSubProcess
                                                        }
                                                      >
                                                        {
                                                          subprocess.processSubProcess
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                <select
                                                  className="form-control mb-2"
                                                  disabled={isPastDate(
                                                    event.start
                                                  )}
                                                  value={capacity.hours}
                                                  onChange={(e) =>
                                                    handleClientAndHoursUpdate(
                                                      event.formattedDate,
                                                      capacityIndex,
                                                      capacity.subProcess,
                                                      parseFloat(e.target.value)
                                                    )
                                                  }
                                                >
                                                  {Array.from(
                                                    { length: 16 },
                                                    (_, i) => i / 2
                                                  ).map((value) => (
                                                    <option
                                                      key={value}
                                                      value={value}
                                                    >
                                                      {value}
                                                    </option>
                                                  ))}
                                                </select>

                                                <img
                                                  className="px-2 pe-auto w-25 h-25"
                                                  title="Save capacity"
                                                  src={markdone}
                                                  onClick={() =>
                                                    handleDoneEditing(
                                                      event.formattedDate,
                                                      capacityIndex
                                                    )
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                <ListGroup.Item
                                                  as="li"
                                                  className="d-flex justify-content-between align-items-start"
                                                  style={{
                                                    fontSize: "0.72rem",
                                                  }}
                                                >
                                                  <div
                                                    key={capacityIndex}
                                                    className="ms-2 lh-1 me-auto"
                                                  >
                                                    {
                                                      capacity.subProcess.split(
                                                        "/"
                                                      )[1]
                                                    }
                                                  </div>
                                                  <Badge bg="primary" pill>
                                                    {capacity.hours}
                                                  </Badge>
                                                </ListGroup.Item>
                                              </>
                                            )}
                                          </>
                                        )
                                      )}
                                    {!isPastDate(event.start) ? (
                                      IsEditable && (
                                        <div className="">
                                          {scheduleJson.find(
                                            (e) =>
                                              e.formattedDate ===
                                              event.formattedDate
                                          )?.capacity.length > 0 && (
                                            <img
                                              className="px-2 pe-auto"
                                              title="Remove capacity"
                                              src={deleteicon}
                                              onClick={() =>
                                                handleDeleteCapacity(
                                                  event.formattedDate
                                                )
                                              }
                                            />
                                          )}

                                          <img
                                            className="px-2 pe-auto"
                                            title="Add new capacity"
                                            role="Button"
                                            src={addicon}
                                            onClick={() =>
                                              handleAddCapacity(
                                                event.formattedDate
                                              )
                                            }
                                            disabled={isPastDate(event.start)}
                                          />
                                        </div>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </ListGroup>
                                </td>
                                <td>
                                  {getCollectiveTotalHours(
                                    event.formattedDate
                                  ) > 8 ? (
                                    <Badge
                                      bg="danger"
                                      title="Exceeding allowed working hours."
                                    >
                                      {getCollectiveTotalHours(
                                        event.formattedDate
                                      )}
                                    </Badge>
                                  ) : (
                                    <>
                                      {" "}
                                      {getCollectiveTotalHours(
                                        event.formattedDate
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* <div>
                          <h3>Total Available Hours:</h3>
                          <p>{getTotalHours()}</p>
                        </div> */}
                      </>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="alert alert-info" role="alert">
                      <h4 className="alert-heading">
                        Total Available Hours: {getTotalHours()}
                      </h4>

                      <p className="mb-0">
                        This is the total available hours for the selected team
                        member.
                      </p>
                    </div>
                    <h3>Monthly Calendar View</h3>
                    <Calendar
                      localizer={localizer}
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      components={{
                        toolbar: CallenderToolBar,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CapacityScheduler;
