import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/exl_logo_rgb_orange_pos.png";
import editicon from "../../assets/images/icons/edit_black_18dp.svg";
import usericon from "../../assets/images/icons/user.png";
import bellicon from "../../assets/images/icons/bell.png";
import bellicon_not from "../../assets/images/icons/bellicon_not.png";
import newspaper from "../../assets/images/icons/newspaper.jpg";
import news from "../../assets/images/icons/news.jpg";
import newsReading from "../../assets/images/icons/newsReading.png";
import supporticon from "../../assets/images/icons/support.png";
import Onlineicon from "../../assets/images/icons/Connected-2.png";
import Offlineicon from "../../assets/images/icons/Disconnected-2.png";
import breakicon from "../../assets/images/icons/break-time.png";
import Login from "../../cws/containers/pages/login/Login";
import "../../assets/scss/modules/_header.scss";
import "../../assets/scss/modules/_timeBreak.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import DashboardContext from "../../contexts/DashboardContext";
import UseOutsideClick from "./UseOutsideClick";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../CarouselComponent.css';
import { ToastProvider, useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import SupportForm from "src/cws/containers/Comman/SupportForm";
import {
  SaveTimerOnTick,
  updateEmpUtilzSession,
} from "src/cws/containers/Comman/TimeCapture";
import { useSelector, useDispatch } from "react-redux";
import IdleTimeout from "src/components/header/IdleTimeout";
//import {GetUpdatedTimeCapturing} from "./updateTimeCapturingSlice";
import { GetUpdatedTimeCapturing } from "src/redux/updateTimeCapturingSlice";

const Header = ({
  onpreferenceShow,
  pageName,
  subPageName,
  timeCaptureActivityName,
  timeCaptureWorkItemId = null,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { REACT_APP_LOGOUT } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_BREAKTIMESUBMIT } = process.env;
  const { REACT_APP_SAVETIMESUBMIT } = process.env;
  const { REACT_APP_ALLOWCLIENTTIMECAPTURING } = process.env;
  const { REACT_APP_CLIENTURL } = process.env;
  const { REACT_APP_AllowCustomerSupport } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
    const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
    const { REACT_APP_FETCHBREAKTIMERFORCLIENTURL } = process.env;
    const [BreakTimerForClientUrl, setBreakTimerForClientUrl] = useState(false);
  const { REACT_APP_GETNEWSFORCAROUSEL } = process.env;  
  // const { REACT_APP_GETSTATUS } = process.env;
  // const { REACT_APP_SUBSCRIBEUNSUBSCRIBENEWS } = process.env;
  const [user, showText] = useState(false);
  const [breakTypes, setbreakTypes] = useState([]);
  // BREAK ONCLICK TOGGLE FUNCTION
  const [breakModal, setShow] = useState(false);
  const [breakModalPop, setbreakModalPop] = useState(false);
  const [fetchtimerload, setfetchtimerload] = useState(false);
  const [IsBrowserOnline, setIsBrowserOnline] = useState("true");
  const [breakComment, setbreakComment] = useState("");
    const { BrowserOnlineStatus } = useSelector((state) => state);  
    //const  dispatch = useDispatch();
  const [newsForCarousel, setNewsForCarousel] = useState([]);
  const [IsShaking, setIsShaking] = useState(false);
  const [isNews, setIsNews] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef(null);
  const modalRef = useRef(null);
  const ApplicationNewsTypeRef = useRef('');
  const currentURL = window.location.href;
  
  let isClientEnvironment = currentURL.includes(REACT_APP_CLIENTURL);
  let defaultbreakval =
    sessionStorage.getItem("FetchBreakTimerOnLoadData") == null
      ? "0"
      : JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
        .ddlActivitySelectedValue;
  const [breakVal, setbreakVal] = useState(defaultbreakval);
  const images = [newspaper, news, newsReading];
  const breakClose = () => setShow(false);
  const breakShow = () => {
    let breaktyps = JSON.parse(sessionStorage.getItem("objdataview"));
    breaktyps != "null" ?? setbreakTypes(breaktyps);
    setShow(true);
  };
  const breakTimeClose = () => setbreakModalPop(false);
  const breakTimeShow = () => {
    //
    // let breaktyps = JSON.parse(sessionStorage.getItem("objdataview"));
    // breaktyps != "null" ?? setbreakTypes(breaktyps);
    setbreakModalPop(true);
  };
  let ActivityName = "";
  if (sessionStorage.getItem("SelctedSubprocessName") !== null) {
    ActivityName = sessionStorage
      .getItem("SelctedSubprocessName")
      .split("~")[1];
  }
  let navigate = useNavigate();
  // USER TOGGLE FUNCTION
  const accountRef = React.useRef(null);
  const onAccountClick = (user) => {
    return showText(!user);
  };
  const BreakTImerSubmit = (e) => {
    // debugger;
    let breakActValueConfigured = JSON.parse(
      sessionStorage.getItem("FetchBreakTimerOnLoadData")
    ).ddlActivitySelectedValue;
    // alert(breakVal);
    if (breakVal === "Select") {
      alert("Please select Break type & retry again!");
    } else {
      if (breakVal === "0" || breakVal === "") {
        setbreakVal(breakActValueConfigured);
        sessionStorage.setItem("breakactivity", breakActValueConfigured);
      } else {
        sessionStorage.setItem("breakactivity", breakVal);
      }
      setbreakModalPop(true);
      setShow(false);
      FetchBreakTimerSubmit();
    }
  };
  const EndBreakTImerSubmit = (e) => {
    setbreakComment("");
    sessionStorage.setItem("breakcomment", "");

    sessionStorage.removeItem("breakcomment");
    sessionStorage.setItem("breakactivity", "");
    sessionStorage.removeItem("breakactivity");
    setbreakVal(0);
    setbreakModalPop(false);
    FetchBreakTimerSave();
    //  setShow(true);
  };
  const FetchBreakTimerSubmit = () => {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID: +sessionStorage.getItem("breakactivity"),
      workItemID:
        sessionStorage.getItem("workItemIDInputParam") == null
          ? 0
          : sessionStorage.getItem("workItemIDInputParam"),
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
          sessionStorage.getItem("empTimeUtilization") == "undefined" ||
          sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
          sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
          sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime: "",
      txtBreakComments: breakComment,
      timerValue: 2,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000,
    };

    let BreakTimerOnLoadUrl = getAPIURL(REACT_APP_BREAKTIMESUBMIT);
    let req = new Request(`${BreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          // settimerStart(result.timerStart);
          // setbtnShowBreakPanelDisabled(result.btnShowBreakPanelDisabled);
          // setbtnShowBreakPanelColor(result.btnShowBreakPanelColor);
          // setddlActivityEnabled(result.ddlActivityEnabled);
          // setobjdataview(result.data[0].objdataview);
          // sessionStorage.setItem(
          //   "objdataview",
          //   JSON.stringify(result.data[0].objdataview)
          // );

          let FetchBreakTimerOnLoadData = JSON.parse(
            sessionStorage.getItem("FetchBreakTimerOnLoadData")
          );
          FetchBreakTimerOnLoadData.tSaveTimerEnabled =
            result.tSaveTimerEnabled;
          FetchBreakTimerOnLoadData.hdnSystemBreakValue =
            result.hdnSystemBreakValue;
          FetchBreakTimerOnLoadData.timerValue = result.timerValue;
          sessionStorage.setItem(
            "FetchBreakTimerOnLoadData",
            FetchBreakTimerOnLoadData
          );
          sessionStorage.setItem(
            "FetchBreakTimerOnLoadData",
            JSON.stringify(FetchBreakTimerOnLoadData)
          );
          //  debugger;
          updateEmpUtilzSession(result.employeeTimeUtilization);

          sessionStorage.setItem(
            "breakStartTime",
            JSON.stringify(result.breakStartTime)
          );
          // setddlActivitySelectedValue(result.ddlActivitySelectedValue);
          // settxtBreakCommentsEnabled(result.txtBreakCommentsEnabled);
          // settimerValue(result.timerValue);
          // sethdnSystemBreakValue(result.hdnSystemBreakValue);
          // setimgActivityToolTip(result.imgActivityToolTip);
          // settSaveTimerEnabled(result.tSaveTimerEnabled);
          // setbreakStartTime(result.breakStartTime);
          // setbtnStartWorkTimerDisabled(result.btnStartWorkTimerDisabled);
          // setemployeeTimeUtilization(result.employeeTimeUtilization);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const FetchBreakTimerSave = () => {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        sessionStorage.getItem("PageId") == null
          ? 0
          : +sessionStorage.getItem("PageId"),
      workItemID:
        sessionStorage.getItem("workItemIDInputParam") == null
          ? 0
          : +sessionStorage.getItem("workItemIDInputParam"),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
          sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
          sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
          sessionStorage.getItem("empTimeUtilization") == "undefined" ||
          sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      breakStartTime: "",
      txtBreakComments: "",
      timerValue: 1,
      personID: +sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 600000,
    };

    let BreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMESUBMIT);
    let req = new Request(`${BreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 401) {
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          // settimerStart(result.timerStart);
          // setbtnShowBreakPanelDisabled(result.btnShowBreakPanelDisabled);
          // setbtnShowBreakPanelColor(result.btnShowBreakPanelColor);
          // setddlActivityEnabled(result.ddlActivityEnabled);
          // setobjdataview(result.data[0].objdataview);
          // sessionStorage.setItem(
          //   "objdataview",
          //   JSON.stringify(result.data[0].objdataview)
          // );
          let FetchBreakTimerOnLoadData = JSON.parse(
            sessionStorage.getItem("FetchBreakTimerOnLoadData")
          );
          FetchBreakTimerOnLoadData.tSaveTimerEnabled =
            result.tSaveTimerEnabled;
          FetchBreakTimerOnLoadData.hdnSystemBreakValue =
            result.hdnSystemBreakValue;
          FetchBreakTimerOnLoadData.timerValue = result.timerValue;
          sessionStorage.setItem(
            "FetchBreakTimerOnLoadData",
            JSON.stringify(FetchBreakTimerOnLoadData)
          );
          //  debugger;
          if (FetchBreakTimerOnLoadData.ddlActivityEnabled === true) {
            setbreakVal(FetchBreakTimerOnLoadData.ddlActivitySelectedValue);
          }

          updateEmpUtilzSession(result.employeeTimeUtilization);

          sessionStorage.setItem(
            "breakStartTime",
            JSON.stringify(result.breakStartTime)
          );
          // setddlActivitySelectedValue(result.ddlActivitySelectedValue);
          // settxtBreakCommentsEnabled(result.txtBreakCommentsEnabled);
          // settimerValue(result.timerValue);
          // sethdnSystemBreakValue(result.hdnSystemBreakValue);
          // setimgActivityToolTip(result.imgActivityToolTip);
          // settSaveTimerEnabled(result.tSaveTimerEnabled);
          // setbreakStartTime(result.breakStartTime);
          // setbtnStartWorkTimerDisabled(result.btnStartWorkTimerDisabled);
          // setemployeeTimeUtilization(result.employeeTimeUtilization);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  // Track events outside scope
  const clickOutside = (e) => {
    if (accountRef.current.contains(e.target)) {
      // inside click
      console.log("clicked inside");
      if (typeof e.target.text !== "undefined") {
        if (e.target.text.includes("Sign out")) {
          logout(e);
        }
        if (e.target.text.includes("Change")) {
          ResetPassword(e);
        }
      } else {
        accountRef.current.classList.toggle("active");
        return;
      }
    }

    // outside click
   // console.log("clicked outside scope");
    accountRef.current.classList.remove("active");
    showText(false);
  };
    // UseOutsideClick(accountRef, () => {
    //   accountRef.current.classList.toggle("active");
    // });
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const ResetPassword = (e) => {
    navigate("/Reset");
  };
  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };
  const logout = (e, autoLogout) => {
    if (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") {
      SaveTimerOnTick();
    }
    let body = {
      username: sessionStorage.getItem("UserName"),
      personId: sessionStorage.getItem("HRISEmployeeID"),
      personType: sessionStorage.getItem("PersonType"),
      etuList:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
          sessionStorage.getItem("empTimeUtilization") == "undefined" ||
          sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
    };

    let logOutUrl = getAPIURL(REACT_APP_LOGOUT);
    let req = new Request(`${logOutUrl}`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
        }
        sessionStorage.clear();
        

          let queryString = '';
          if(autoLogout) {
              queryString = '?autoLogout';
        }
        window.location.href = "/" + queryString;
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        sessionStorage.clear();
        sessionStorage.clear();
      });
  };
  const BreakTypeValue = (e) => {
    // alert(e.target.value);
    setbreakVal(e.target.value);
    sessionStorage.setItem("breakactivity", e.target.value);
  };
  const BreakCommentValue = (e) => {
    setbreakComment(e.target.value);
    sessionStorage.setItem("breakcomment", e.target.value);
  };
  let userName = sessionStorage.getItem("UserFullName");
  let UserEmail = sessionStorage.getItem("UserEmail");
  let result = [];
  sessionStorage["UserFullName"]
    .split(" ")
    .map((word) => (word.charAt(0) != "" ? result.push(word.charAt(0)) : ""));

  let UserInit = result[0] + result[result.length - 1];
  const FetchBreakTimerOnLoad = () => {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let activityId = sessionStorage.getItem("PageId");
    sessionStorage.setItem("PageId", activityId);
    if (
      sessionStorage.getItem("selectedEntitiesDetail") != "null" &&
      sessionStorage.getItem("selectedEntitiesDetail") != "undefined" &&
      sessionStorage.getItem("selectedEntitiesDetail") != null &&
      activityId != "null" &&
      activityId != null &&
      activityId != 0 &&
      activityId != "0"
    ) {
      let body = {
        timerStart: strTime,
        subProcessID:
          sessionStorage.getItem("ProcessId") == null
            ? ""
            : sessionStorage.getItem("ProcessId"),
        activityID: sessionStorage.getItem("PageId"), //for home page
        workItemID: sessionStorage.getItem("workItemIDInputParam"),
        employeeTimeUtilization:
          sessionStorage.getItem("empTimeUtilization") == "null" ||
            sessionStorage.getItem("empTimeUtilization") == "undefined"
            ? []
            : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
        selectedEntitiesDetail:
          sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
            sessionStorage.getItem("selectedEntitiesDetail") == "undefined"
            ? {}
            : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
        breakStartTime: "",
        txtBreakComments: "",
        timerValue: 1,
        personID: sessionStorage.getItem("PersonID"),
        tSaveTimerInterval: 60000,
        ignoreCapture:
          sessionStorage.getItem("IgnoreTimeCapture") == null
            ? false
            : sessionStorage.getItem("IgnoreTimeCapture").toLowerCase() ==
              "true"
              ? true
              : false,
      };
      let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_FETCHBREAKTIMERONLOAD);
      let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
            Authorization:
                "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            // settimerStart(result.timerStart);
            // setbtnShowBreakPanelDisabled(result.btnShowBreakPanelDisabled);
            // setbtnShowBreakPanelColor(result.btnShowBreakPanelColor);
            // setddlActivityEnabled(result.ddlActivityEnabled);
            // setobjdataview(result.objdataview);
            if (result.data[0].tSaveTimerEnabled) {
              //  setrunSaveTImer(true);
            }
            sessionStorage.setItem(
              "objdataview",
              JSON.stringify(result.data[0].objdataview)
            );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(result.data[0])
            );
            //  debugger;
            updateEmpUtilzSession(result.data[0].employeeTimeUtilization);
            // let lastsynctime = new Date(
            //   sessionStorage.getItem("timerlastsync")
            // );
            // console.log(lastsynctime);

            // if (lastsynctime != null) {
            //   let filteredvalues =
            //     result.data[0].employeeTimeUtilization.filter((item) => {
            //       return new Date(item.timeFrom) >= lastsynctime;
            //     });
            //   console.log(filteredvalues);
            //   sessionStorage.setItem(
            //     "empTimeUtilization",
            //     JSON.stringify(filteredvalues)
            //   );
            // } else {
            //   sessionStorage.setItem(
            //     "empTimeUtilization",
            //     JSON.stringify(result.data[0].employeeTimeUtilization)
            //   );
            // }

            // setddlActivitySelectedValue(result.ddlActivitySelectedValue);
            // settxtBreakCommentsEnabled(result.txtBreakCommentsEnabled);
            // settimerValue(result.timerValue);
            // sethdnSystemBreakValue(result.hdnSystemBreakValue);
            // setimgActivityToolTip(result.imgActivityToolTip);
            // settSaveTimerEnabled(result.tSaveTimerEnabled);
            // setbreakStartTime(result.breakStartTime);
            // setbtnStartWorkTimerDisabled(result.btnStartWorkTimerDisabled);
            //setemployeeTimeUtilization(result.employeeTimeUtilization);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };
  // const GetStatus = () => {
  //   let body={
  //     employeeID: sessionStorage.getItem("PersonID")
  //   };
  //   let GetStatusURL = getAPIURL(REACT_APP_GETSTATUS);
  //   let req1 = new Request(`${GetStatusURL}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req1)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status == 200) {
  //         setisLoadingFigma(false);
  //         setButtonText("Unsubscribe News");
  //       }
  //       else{
  //         setisLoadingFigma(false);
  //         setButtonText("Subscribe To News");
  //       }

  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //     });
  // };
  // const SusbcribeUnsubscribeNews = () => {
  //   let body={
  //     employeeID: sessionStorage.getItem("PersonID")
  //   };
  //   let SusbcribeUnsubscribeNewsURL = getAPIURL(REACT_APP_SUBSCRIBEUNSUBSCRIBENEWS);
  //   let req1 = new Request(`${SusbcribeUnsubscribeNewsURL}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req1)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status == 200) {
  //         GetStatus();
  //       }
  //       else{
  //       }

  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //     });
  // };
  const handleNoNews= () =>{
    addToast("No news available", {
      appearance: "error",
      autoDismiss: true,
    });
  }
  const GetNewsForCarousel = () => {
    let body={
      ApplicationNewsType: ApplicationNewsTypeRef.current,
      subProcessID: sessionStorage.getItem("ProcessId")
    };
    let GetNewsForCarouselURL = getAPIURL(REACT_APP_GETNEWSFORCAROUSEL);
    let req1 = new Request(`${GetNewsForCarouselURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },     
      body: JSON.stringify(body),
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {      
          if (result.data.length>0){
            setIsNews(true)
            setNewsForCarousel(result.data) 
            setIsShaking(true);
          }         
                    
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  useEffect(() => {      
    
    if (timeCaptureActivityName == null || timeCaptureActivityName == "") {
      sessionStorage.setItem("IgnoreTimeCapture", true);
      timeCaptureActivityName = "Welcome";
    } else {
      sessionStorage.setItem("IgnoreTimeCapture", false);
    }
    if (
      timeCaptureActivityName != "WorkActivityDashboard" &&
      timeCaptureActivityName != "ActivityScreen"
    ) {
      getActivityByName();
      sessionStorage.setItem("workItemIDInputParam", 0);
    } else {
      if (timeCaptureWorkItemId != null && timeCaptureWorkItemId != "") {
        sessionStorage.setItem("workItemIDInputParam", timeCaptureWorkItemId);
      } else {
        sessionStorage.setItem("workItemIDInputParam", 0);
      }
      setfetchtimerload(true);
    }
    
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        (!modalRef.current || !modalRef.current.contains(event.target))
      ) {
        setIsClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, modalRef]);

  useEffect(() => {
    // debugger;
    let AllowTimeCapturing = REACT_APP_ALLOWCLIENTTIMECAPTURING;
    let ClientURL = REACT_APP_CLIENTURL;
    sessionStorage.setItem("ALLOWCLIENTTIMECAPTURING", "0");
    if (AllowTimeCapturing == "false") {
      if (!currentURL.includes(ClientURL)) {
        sessionStorage.setItem("ALLOWCLIENTTIMECAPTURING", "1");
        }
      else if (currentURL.includes(ClientURL) && sessionStorage.getItem("BreakTimeForClientURL") === 'true')
      {

          sessionStorage.setItem("ALLOWCLIENTTIMECAPTURING", "1");

      }
    } else {
        //dispatch(GetUpdatedTimeCapturing());
        sessionStorage.setItem("ALLOWCLIENTTIMECAPTURING", "1");
        //window.dispatchEvent(new Event("sessionStorageChange"));

    }
    if (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") {
      FetchBreakTimerOnLoad();
    }
  }, [fetchtimerload, sessionStorage.getItem("workItemIDInputParam"), sessionStorage.getItem("BreakTimeForClientURL")]);
  useEffect(()=>{
    if (window.location.href.toLowerCase().includes('smartworks')){
      ApplicationNewsTypeRef.current = 'PD'
    }
    else{
    ApplicationNewsTypeRef.current = 'CWS'
    }
    GetNewsForCarousel();
  },[isNews]);
  const getActivityByName = () => {
    let body = {
      activityName: timeCaptureActivityName,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHACTIVITYDETAILSBYNAME);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          sessionStorage.setItem("PageId", result.activityID);
          setfetchtimerload(true);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);

    // clean up function before running new effect
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [user]);
  
  return (
    <>
      <header>
        <head>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
          />
        </head>
        {isClientEnvironment && <IdleTimeout onLogout={logout}></IdleTimeout>}
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-11">
              <div className="row">
                <div className="col-11">
                  <h3 className="cmyLogo">
                    <Link to="/Home">
                      <img src={logo} alt="EXL" />
                    </Link>
                    {/* <span>Custom Workflow Solutions</span> */}
                    <h3 className="processName">
                      <>{ActivityName}</>{" "}
                      {onpreferenceShow == null ? (
                        <></>
                      ) : (
                        <div
                          className={
                            ActivityName == ""
                              ? "selectProcessDiv"
                              : "editProcessDiv"
                          }
                          title="Select Process-Subprocess"
                        >
                          <img
                            id="iconEditProcessSubProcess"
                            className="editProcessIcon"
                            onClick={onpreferenceShow}
                            src={editicon}
                            alt="Edit"
                          />
                        </div>
                      )}
                      {pageName === "" || pageName === undefined ? (
                        <></>
                      ) : (
                        <>
                          <div class="material-symbols-outlined arrow">
                            arrow_right
                          </div>

                          <div className="pageName">
                            {pageName === "ActivityDashboard" ||
                              pageName === "ActivityScreen" ? (
                              <>
                                {sessionStorage.getItem("workflowactivityName")}{" "}
                                Dashboard
                              </>
                            ) : (
                              pageName
                            )}
                          </div>
                        </>
                      )}
                      {subPageName === "" || subPageName === undefined ? (
                        <></>
                      ) : (
                        <>
                          <div class="material-symbols-outlined arrow">
                            arrow_right
                          </div>

                          <div className="subPageName">
                            {pageName === "ActivityScreen" ? (
                              <>#{subPageName}</>
                            ) : (
                              subPageName
                            )}
                          </div>
                        </>
                      )}
                    </h3>
                    {ActivityName == "" ? (
                      <div className="noProcessName">
                        You haven't selected any subprocess, kindly use the{" "}
                        <div className="selectProcessDiv"> highLight icon </div>{" "}
                        and choose the subprocess to see the data.
                      </div>
                    ) : (
                      <></>
                    )}{" "}
                  </h3>
                </div>
                {/* <div className="col-2"></div>*/}
              </div>
            </div>
            <div className="col-1 text-end">
              <div className="navbar-nav-wrap-content-end">
                <div className="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-4 px-3">
                  {BrowserOnlineStatus === true ? (
                    <div className="d-sm-inline-block">
                      <div className="dropdown userDropdown">
                        <a
                          className="navbar-dropdown-account-wrapper"
                          title="Online"
                        >
                          <div className="avatar avatar-circle">
                            {" "}
                            <div className="avatar-img">
                              {" "}
                             <img src={Onlineicon} alt="?" id="iconOnline"/>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="d-sm-inline-block">
                      <div className="dropdown userDropdown">
                        <a
                          className="navbar-dropdown-account-wrapper"
                          title="Offline"
                        >
                          <div className="avatar avatar-circle">
                            {" "}
                            <div className="avatar-img">
                              {" "}
                              <img src={Offlineicon} alt="?" id="iconOffline" />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                  {sessionStorage.getItem("clientLogo") != null &&
                    sessionStorage.getItem("clientLogo") != "null" ? (
                    currentURL.includes("smartworks.exlservice.com") ? (
                      ""
                    ) : (
                      <div className="d-sm-inline-block">
                        <div className="dropdown userDropdown">
                          <a
                            title={sessionStorage.getItem(
                              "selectedLocationName"
                            )}
                            className="navbar-dropdown-account-wrapper"
                          >
                            <div className="avatar avatar-circle">
                              {" "}
                              <div className="avatar-img">
                                <img
                                id="iconClientLogo"  
                                src={`data:image/jpeg;base64,${sessionStorage.getItem(
                                    "clientLogo"
                                  )}`}
                                  height="45px"
                                  width="45px"
                                  className="client-logo"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  <div className="d-sm-inline-block">
                    {sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") ==
                      "0" ? (
                      ""
                    ) : (
                      <div className="dropdown userDropdown">
                        <a
                          className="navbar-dropdown-account-wrapper"
                          title={
                            sessionStorage.getItem(
                              "FetchBreakTimerOnLoadData"
                            ) !== null
                              ? JSON.parse(
                                sessionStorage.getItem(
                                  "FetchBreakTimerOnLoadData"
                                )
                              ).imgActivityToolTip
                              : "Time is not yet capturing."
                          }
                          onClick={breakShow}
                        >
                          <div className="avatar avatar-circle">
                            {" "}
                            <div className="avatar-img">
                              {" "}
                              <img src={breakicon} alt="?" id="iconBreak" />
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                  {REACT_APP_AllowCustomerSupport === "true" ? (
                    <div className="d-sm-inline-block">
                      <div className="dropdown userDropdown">
                        <a
                          className="navbar-dropdown-account-wrapper"
                          title="Need help?"
                        >
                          <div className="avatar avatar-circle">
                            {" "}
                            <div
                              className="avatar-img"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              {" "}
                              <img src={supporticon} alt="?" id="iconSupport" />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

<div className="d-sm-inline-block">
      <div className="dropdown userDropdown" ref={dropdownRef}>
        <a
          className="navbar-dropdown-account-wrapper"
          onClick={() => isNews ? setIsClick(!isClick): handleNoNews()}
          title="News"
        >
          <div className="avatar avatar-circle">
            <div className="avatar-img">
            <img className={`bell ${IsShaking ? 'shake': ''}`} src={isNews ? bellicon_not : bellicon} alt="User"/>
            </div>
          </div>
        </a>
        <div className="dropdown-menu-news borderless dropdown-account" hidden={!isClick}>
          <Carousel showArrows={true} infiniteLoop={true} showThumbs={false}className="carousel-item">
            {newsForCarousel.map((item, index) => (
              <div key={index} className="carousel-item">
                <div className="text-part fixed-height">
                <p className="updated-on">Updated on: {item.updatedOn}</p>
                  <h5 className="news-title">{item.newsTitle}</h5>                  
                  <p className="news-body " dangerouslySetInnerHTML={{ __html: item.newsBody}}></p>
                  <div className="button-container">
                    <Button className="btn btn-primary" onClick={() => openModal(item)}>READ MORE</Button>
                  </div>
                </div>
                <div className="image-part fixed-height">
                <img src={images[index % images.length]} alt={item.title} />
                </div>
              </div>
            ))}
          </Carousel>

          {selectedItem && (
            <Modal show={modalIsOpen} onHide={closeModal}  ref={modalRef}>
              <Modal.Header closeButton>
                <Modal.Title>News</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>{selectedItem.newsTitle}</h5> 
                <p className="updated-on">Updated on: {selectedItem.updatedOn}</p>
                <p dangerouslySetInnerHTML={{ __html: selectedItem.newsBody }}></p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn btn-primary" onClick={closeModal}>Close</Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </div>

                  <div className="d-sm-inline-block">
                    <div className="dropdown userDropdown" ref={accountRef}>
                      <a
                        className="navbar-dropdown-account-wrapper"
                        onClick={() => onAccountClick(user)}
                        id="liUser"
                      >
                        <div className="avatar avatar-circle">
                          {" "}
                          <div className="avatar-img">
                            {" "}
                            <img src={usericon} alt={UserInit} id="iconUser"/>
                          </div>
                        </div>
                      </a>

                      <div className="dropdown-menu borderless dropdown-account">
                        <div className="dropdown-item-text">
                          <div className="d-flex align-items-center">
                            <div className="avatar avatar-circle">
                              <h3 className="avatar-img">{UserInit}</h3>
                            </div>
                            <div className="avatar-info">
                              <h5 className="mb-0">{userName}</h5>
                              <p className="card-text text-body">{UserEmail}</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="dropdown-divider"></div>
                        <a className="dropdown-item">Profile &amp; account</a>
                        <a className="dropdown-item">Settings</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item">About</a>
                        <a className="dropdown-item">Help</a> */}
                        {sessionStorage.getItem("HRISEmployeeID") !== "" ? (
                          ""
                        ) : (
                          <>
                            {" "}
                            <div className="dropdown-divider"></div>
                            <a
                              className="dropdown-item"
                              id="liChangePassword"
                              onClick={ResetPassword}
                            >
                              Change Password
                            </a>
                          </>
                        )}
                        <div className="dropdown-divider"></div>
                          <a className="dropdown-item" onClick={logout} id="liSignOut">
                          Sign out
                        </a>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">
            CWS Support{"      "}
            <span class="material-symbols-outlined ">help</span>
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <SupportForm></SupportForm>
        </div>
      </div>
      {/** BREAK TIMER MODAL **/}

      <Modal
        show={breakModal}
        dialogClassName="modal-25w"
        onHide={breakClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <span
            //onClick={showAttachmentDetails}
            style={{ float: "right" }}
            data-tip
            data-for="attachmentTip"
          >
            <Modal.Title>Break Timer</Modal.Title>
            <ReactTooltip id="attachmentTip" place="top" effect="solid">
              {sessionStorage.getItem("FetchBreakTimerOnLoadData") !== null
                ? JSON.parse(
                  sessionStorage.getItem("FetchBreakTimerOnLoadData")
                ).imgActivityToolTip
                : ""}
            </ReactTooltip>
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <li>
                <label className="form-label">Break Type</label>
                {sessionStorage.getItem("FetchBreakTimerOnLoadData") !==
                  null ? (
                  JSON.parse(
                    sessionStorage.getItem("FetchBreakTimerOnLoadData")
                  ).ddlActivityEnabled === true ? (
                    <select className="form-control" onChange={BreakTypeValue}>
                      <option>Select</option>
                      {JSON.parse(sessionStorage.getItem("objdataview")) != null
                        ? JSON.parse(sessionStorage.getItem("objdataview")).map(
                          (type) => (
                            <option
                              value={type.activityID}
                              selected={
                                type.activityID ===
                                JSON.parse(
                                  sessionStorage.getItem(
                                    "FetchBreakTimerOnLoadData"
                                  )
                                ).ddlActivitySelectedValue
                              }
                            >
                              {type.activityName}
                            </option>
                          )
                        )
                        : null}
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      onChange={BreakTypeValue}
                      disabled="true"
                    >
                      <option>Select</option>
                      {JSON.parse(sessionStorage.getItem("objdataview")) != null
                        ? JSON.parse(sessionStorage.getItem("objdataview")).map(
                          (type) => (
                            <option
                              value={type.activityID}
                              selected={
                                type.activityID ==
                                JSON.parse(
                                  sessionStorage.getItem(
                                    "FetchBreakTimerOnLoadData"
                                  )
                                ).ddlActivitySelectedValue
                              }
                            >
                              {type.activityName}
                            </option>
                          )
                        )
                        : null}
                    </select>
                  )
                ) : (
                  <select
                    className="form-control"
                    onChange={BreakTypeValue}
                    disabled="true"
                  >
                    <option>Select</option>
                    {JSON.parse(sessionStorage.getItem("objdataview")) != null
                      ? JSON.parse(sessionStorage.getItem("objdataview")).map(
                        (type) => (
                          <option
                            value={type.activityID}
                            selected={
                              type.activityID ==
                              JSON.parse(
                                sessionStorage.getItem(
                                  "FetchBreakTimerOnLoadData"
                                )
                              ).ddlActivitySelectedValue
                            }
                          >
                            {type.activityName}
                          </option>
                        )
                      )
                      : null}
                  </select>
                )}
              </li>
              <li>
                <label className="form-label">Comment</label>
                {sessionStorage.getItem("FetchBreakTimerOnLoadData") !==
                  null ? (
                  JSON.parse(
                    sessionStorage.getItem("FetchBreakTimerOnLoadData")
                  ).txtBreakCommentsEnabled == true ? (
                    <textarea
                      className="form-control"
                      placeholder="Type here...."
                      onChange={BreakCommentValue}
                    ></textarea>
                  ) : (
                    <textarea
                      className="form-control"
                      placeholder="Type here...."
                      disabled="true"
                    ></textarea>
                  )
                ) : (
                  <textarea
                    className="form-control"
                    placeholder="Type here...."
                    disabled="true"
                  ></textarea>
                )}
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-ntv mx-2" onClick={breakClose}>
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={
              sessionStorage.getItem("FetchBreakTimerOnLoadData") !== null
                ? JSON.parse(
                  sessionStorage.getItem("FetchBreakTimerOnLoadData")
                ).btnShowBreakPanelDisabled
                : true
            }
            style={{
              color:
                (sessionStorage.getItem("FetchBreakTimerOnLoadData") !== null &&
                  !JSON.parse(
                    sessionStorage.getItem("FetchBreakTimerOnLoadData")
                  ).btnShowBreakPanelDisabled) ??
                JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
                  .btnShowBreakPanelColor,
            }}
            onClick={BreakTImerSubmit}
          >
            Start Break Timer
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={breakModalPop}
        dialogClassName="modal-25w"
        onHide={breakTimeClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <span
            //onClick={showAttachmentDetails}
            style={{ float: "right" }}
            data-tip
            data-for="attachmentTip"
          >
            <Modal.Title>Break Timer</Modal.Title>
            <ReactTooltip id="attachmentTip" place="top" effect="solid">
              {sessionStorage.getItem("FetchBreakTimerOnLoadData") !== null
                ? JSON.parse(
                  sessionStorage.getItem("FetchBreakTimerOnLoadData")
                ).imgActivityToolTip
                : ""}
            </ReactTooltip>
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <li>
                <h5 className="">
                  Production Dashboard application is locked as you are away on
                  break. Please click the button below when you are back from
                  break to continue your work.
                </h5>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={
              sessionStorage.getItem("FetchBreakTimerOnLoadData") !== null
                ? JSON.parse(
                  sessionStorage.getItem("FetchBreakTimerOnLoadData")
                ).btnShowBreakPanelDisabled
                : true
            }
            style={{
              color:
                (sessionStorage.getItem("FetchBreakTimerOnLoadData") !== null &&
                  !JSON.parse(
                    sessionStorage.getItem("FetchBreakTimerOnLoadData")
                  ).btnShowBreakPanelDisabled) ??
                JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
                  .btnShowBreakPanelColor,
            }}
            onClick={EndBreakTImerSubmit}
          >
            Back To Work
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
