import Slider from "react-slick";
import React, { useEffect, useState ,useMemo} from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { object } from "prop-types";
import { red } from "@material-ui/core/colors";
//import { ToastProvider, useToasts } from "react-toast-notifications";
import bcrypt from "bcryptjs";
import { Buffer } from "buffer";
import Home from "../src/cws/containers/pages/home/Home.js";
import Header from "../src/components/header/Header.js";
import Footer from "../src/components/footer/Footer.js";

export default function ErrorHandler({
  error,
  componentStack,
  resetErrorBoundary,
}) {
  //const { addToast } = useToasts();
  const { REACT_APP_UILOGEXTENSION } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const currentPage = window.location.href;
  let navigate = useNavigate();
  const BacktoHome = () => {
    navigate("/Home");
    window.location.reload();
  };
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  useEffect(() => {
    debugger
    let logUrl = getAPIURL(REACT_APP_UILOGEXTENSION);
    let body = {
      personID: +sessionStorage.getItem("PersonID"),
      lanID: sessionStorage.getItem("UserName"),
      message: error.toString(),
      pageName: currentPage.split('/')[currentPage.split('/').length-1],
      logType: true,
    };
    let req = new Request(`${logUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
        } else {
          // addToast("Something went wrong", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  }, []);
  return (
    <>
      <Header />
      <div className="authCenter">
        <div className="authWrap">
          <div className="authLeft">
            <div className="authDetail">
              <h1>Something went Wrong!!</h1>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={BacktoHome}
              >
                Go To Home
              </button>
              <p>Click here to report this issue.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
