
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
 
const ManageWorkItemDetails = (values) => {  
  return (
    <div>
      {!values.state.isDynamic?
        <>
        <div className="">
          {values.state.workItemDetailModel.map((groups) => {
            return (
              <div className="col-12">
                <form className="form-inputs">
                  <div className="fieldSet">                  
                    <div className="row">
                      <div className="row">
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Workitem ID"
                            > Workitem ID
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.workItemID}
                            readOnly={true}
                            ></input>
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Workitem ID"
                            > SLA Start Date
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.slaStartDate}
                            readOnly={true}
                            ></input>
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="SLA Due Date"
                            > SLA Due Date
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.slaDueDate}
                            readOnly={true}
                            ></input>
                        </div> 
                        </div>
                        <div className="row">
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="SLA Completion Date"
                            > SLA Completion Date
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.slaCompletionDate}
                            readOnly={true}
                            ></input>
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Current State"
                            > Current State
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.workFlowStateName}
                            readOnly={true}
                            ></input>
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Last Worked By"
                            > Last Worked By
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.lastWorkedBy}
                            readOnly={true}
                            ></input>
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Last Worked By"
                            > Last Worked On
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.lastWorkedOn}
                            readOnly={true}
                            ></input>
                        </div>
                      </div>
                    </div>                   
                  </div>                  
                </form>
              </div>
              
            );
          })}
        </div>
        </>:
        <>
         <div className="row">
          {values.state.workItemFieldsModel.map((groups) => {
            return (
              <div className="col-4">
                <form className="form-inputs">
                  <div className="fieldSet">
                    <div className="row">
                      <div>
                        <div className="pb-3 pt-1">
                            <label
                            className="form-label"
                            title={groups.labelText}
                            > {groups.labelText}
                            </label>
                            <input
                            className="form-control  form-control-sm"
                            type="text"
                            value={groups.value}
                            readOnly={true}
                            ></input>
                        </div> 
                      
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            );
          })}
        </div>
        </>}
     
    </div>
  );
};
export default ManageWorkItemDetails;
 