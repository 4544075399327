import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";

import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import tableIcons from "../../../../MaterialTableIcons";
//import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
//import LoadingSpinner from "../../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
//import Iframe from "react-iframe";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";

//debugger;
const WorkFlowState = () => {
  //const location = useLocation();
  //const processingDashboardParam = location.state;
  const StatusNameRef = useRef("");
  const StatusNameRefpop = useRef("");
  const WorkItemStatusIDRef = useRef(0);
  const WorkFlowStateIDRef = useRef(0);
  const WorkFlowStateIDRefpop = useRef(0);
  const WorkItemStatusIDRefpop = useRef(0);
  const displayOrderRef = useRef("");
  const displayOrderRefpop = useRef("");
  const WorkNameRef = useRef("");
  const ActivityRef = useRef("");
  const isEditableRef = useRef("");
  const WorkNameRefpop = useRef("");
  const ActivityRefpop = useRef(0);
  const isEditableRefpop = useRef("");
  const RemarksRef = useRef("");
  const RemarksRefpop = useRef("");
  const isDeletedRef = useRef("");
  const isDeletedRefpop = useRef("");
  const ButtonTextRef = useRef("");

  const { addToast } = useToasts();

  const { REACT_APP_API_URL } = process.env;

  const { REACT_APP_SAVETIMEONTICK } = process.env;
  // const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_GETWORKFLOWADMIN } = process.env;
  const { REACT_APP_UPDATEWORKFLOWSTATE } = process.env;
  const { REACT_APP_DELETEWORKFLOWSTATE } = process.env;
  const { REACT_APP_ADDWORKFLOWSTATE } = process.env;
  const { REACT_APP_GETACTIVITY } = process.env;

  //const { REACT_APP_GETSTATUSBYID } = process.env;
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  //const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [searchWorkitemData, setsearchWorkitemData] = useState([]);
  const [Datacount, setDatacount] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const [displayOrderArry, setdisplayOrderArry] = useState([]);

  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [buttonText, setbuttonText] = useState("Save");
  const [openPopUp, setopenPopUp] = useState(false);
  const [openAddPopUp, setopenAddPopUp] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [data, setdata] = useState({});
  const [Activity, setActivity] = useState([]);
  const [Editable, setEditable] = useState(false);

  const Save = () => {
    debugger;
    seterrorMessage("");
    let err = {};
    let isSubmit = true;
    if (
      WorkNameRef.current.value.length == 0 &&
      WorkNameRef.current.value == ""
    ) {
      /* addToast("WorkItem Status Name is Required.", {
      appearance: "error",
      autoDismiss: true,
    }); */
      err.name = "Workflow State Name is Required.";
      isSubmit = false;
    }

    seterrorMessage({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;
      /* if (buttonText == "Save") */
      /* { */
      // setopenPopUp(true);
      loginUrl = getAPIURL(REACT_APP_ADDWORKFLOWSTATE);
      body = {
        WorkFlowStateName: WorkNameRef.current.value,
        ActivityID: ActivityRef.current.value,
        Remarks: RemarksRef.current.value,
        IsDeleted: isDeletedRef.current.checked,
        IsEditable: isEditableRef.current.checked,
        WorkFlowStateID: 0,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenPopUp(false);
            debugger;
            addToast("Workflow State saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            //resetForm();
            GetWorkFlowState();
            seterrorMessage("");
            //setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.name = "Record Already Exists";
            seterrorMessage({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
          // else {
          //   err.record = "Record already exists.";
          //   seterrorMessage({ ...err });
          // }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };
  const popupClose = () => {
    setopenPopUp(false);
    seterrorMessage("");
  };
  const AddpopupClose = () => {
    setopenAddPopUp(false);
    seterrorMessage("");
  };
  const resetForm = () => {
    WorkNameRef.current.value = "";
    ActivityRef.current.value = "";
    RemarksRef.current.value = "";
    isDeletedRef.current.checked = false;
    isEditableRef.current.checked = false;
    seterrorMessage("");
  };

  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const updateColumns = keys => {
    console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach(item => {
      if (item != "updatedBy") col.push({ title: item, field: item });
    });
    //col.pop();
    //setcolumns(col);
  };
  //debugger;
  const pop = () => {
    GetActivity();
    setopenPopUp(true);
    seterrorMessage("");
    // editWorkItemStatus(item);
  };

  const Addpop = item => {
    if (item.isEditable) {
      debugger;
      //GetActivity();
      setopenAddPopUp(true);
      seterrorMessage("");
      setdata(item);
    } else {
      addToast("Non Editable Field", {
        appearance: "warning",
        autoDismiss: true
      });
    }
  };
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      WorkNameRefpop.current.value = data.workflowStateName;
      ActivityRefpop.current.value = data.mappedActivityID;
      RemarksRefpop.current.value = data.remarks;
      isDeletedRefpop.current.checked = data.isDeleted;
      isEditableRefpop.current.checked = data.isEditable;
      WorkFlowStateIDRefpop.current = data.workflowStateID;
    }
  }, [openAddPopUp]);

  const resetUpdate = () => {
    WorkNameRefpop.current.value = "";
    ActivityRefpop.current.value = "";
    RemarksRefpop.current.value = "";
    isDeletedRefpop.current.checked = false;
    isEditableRefpop.current.checked = false;
    seterrorMessage("");
  };
  const AddworkFlowState = () => {
    debugger;
    seterrorMessage("");
    let isSubmit = true;
    let err = {};
    if (
      WorkNameRef.current.value.length == 0 &&
      WorkNameRef.current.value == ""
    ) {
      /* addToast("WorkItem Status Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.name = "Workflow state Name is Required.";
      isSubmit = false;
    }

    seterrorMessage({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;
      /* if (buttonText == "Save") */
      /* { */

      loginUrl = getAPIURL(REACT_APP_ADDWORKFLOWSTATE);
      body = {
        WorkFlowStateName: WorkNameRef.current.value,
        ActivityID: ActivityRef.current.value,
        Remarks: RemarksRef.current.value,
        IsDeleted: isDeletedRef.current.checked,
        IsEditable: isEditableRef.current.checked,
        WorkFlowStateID: 0,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenPopUp(true);
            debugger;
            addToast("Workflow State saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            resetForm();
            seterrorMessage("");
            GetWorkFlowState();
            //setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.name = "Record Already Exists";
            seterrorMessage({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
          // else {
          //   err.record = "Record already exists.";
          //   seterrorMessage({ ...err });
          // }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };

  const Updateworkflowstate = () => {
    debugger;
    //GetActivity();
    let isSubmit = true;
    let err = {};
    if (
      WorkNameRefpop.current.value.length == 0 &&
      WorkNameRefpop.current.value == ""
    ) {
      /* addToast("WorkItem Status Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.name = "Workflow State Name is Required.";
      isSubmit = false;
    }

    seterrorMessage({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;

      loginUrl = getAPIURL(REACT_APP_UPDATEWORKFLOWSTATE);
      body = {
        WorkFlowStateName: WorkNameRefpop.current.value,
        ActivityID: ActivityRefpop.current.value,
        Remarks: RemarksRefpop.current.value,
        IsDeleted: isDeletedRefpop.current.checked,
        IsEditable: isEditableRefpop.current.checked,
        WorkFlowStateID: WorkFlowStateIDRefpop.current,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenAddPopUp(false);
            debugger;
            addToast("Workflow State Updated successfully", {
              appearance: "success",
              autoDismiss: true
            });
            // resetForm();

            GetWorkFlowState();

            //setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.name = "Record Already Exists";
            seterrorMessage({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
          //else {
          //   err.record = "Record already exists.";
          //   seterrorMessage({ ...err });
          // }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
    //setopenAddPopUp(false);
  };

  //In line Update start//
  /* const updateWorkItem = newRow => {
    debugger;

    let loginUrl;
    let body;

    loginUrl = getAPIURL(REACT_APP_UPDATEWORKFLOWSTATE);
    body = {
      StatusName: newRow.statusName,
      DisplayOrder: newRow.displayOrder,
      Remarks: newRow.remarks,
      IsDeleted: newRow.isDeleted,
      WorkItemStatusID: newRow.workItemStatusID,
      UpdatedBy: sessionStorage.getItem("PersonID")
    };
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          debugger;
          addToast("Work Item Status saved successfully", {
            appearance: "success",
            autoDismiss: true
          });
          resetForm();
          seterrorMessage("");
          GetWorkFlowState();
          //setbuttonText("Save");
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  }; */

  //In line Update end//

  const columns = [
    {
      title: "Workflow State Name",
      field: "workflowStateName"
    },
    {
      title: "Activity Name",
      field: "activityName"
    },
    {
      title: "Remarks",
      field: "remarks"
    },
    {
      title: "IsDeleted",
      field: "isDeleted",

      render: isDeleted => (
        <>
          <input
            type="checkbox"
            id="chkIsDeleted"
            disabled
            checked={isDeleted["isDeleted"]}
          />
        </>
      )
    },
    {
      title: "IsEditable",
      field: "isEditable",

      render: isEditable => (
        <>
          <input
            type="checkbox"
            id="chkIsEditable"
            disabled
            checked={isEditable["isEditable"]}
          />
        </>
      )
    }
  ];

  /*  const editWorkItemStatus = item => {
    debugger;
    //setopenPopUp(true);
    StatusNameRef.current = item.statusName;
    WorkItemStatusIDRef.current = item.workItemStatusID;
    displayOrderRef.current = item.displayOrder;
    RemarksRef.current = item.remarks;
    isDeletedRef.current = item.isDeleted;
  }; */

  const DeleteWorkFlowState = row => {
    if (row.isEditable) {
      if (row.isDeleted) {
        addToast(
          "WorkItem - " + row.workflowStateName + " is already deleted.",
          {
            appearance: "success",
            autoDismiss: true
          }
        );
        return false;
      }

      if (
        window.confirm(
          "Are you sure you want to delete the Workflow State - " +
            row.workflowStateName +
            " ?"
        )
      ) {
        debugger;
        let body = {
          WorkFlowStateID: row.workflowStateID,
          UpdatedBy: sessionStorage.PersonID
        };
        let loginUrl = getAPIURL(REACT_APP_DELETEWORKFLOWSTATE);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken")
          },
          body: JSON.stringify(body)
        });
        fetch(req)
          .then(res => res.json())
          .then(result => {
            if (result.status === 200) {
              debugger;
              addToast("Workflow State deleted successfully", {
                appearance: "success",
                autoDismiss: true
              });
              GetWorkFlowState();
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch(error => {
            console.log("ERROR: ", error);
            //navigate("/");
          });
      }
    } else {
      addToast("Non Editable Field", {
        appearance: "warning",
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    // debugger
    if (runSaveTImer == true && interval == null) {
      interval = setInterval(() => {
        SaveTimerOnTick();
        //clearInterval(interval);
      }, 60000);
    }
    return () => {
      //  console.log("ending dashboard 1");
      clearInterval(interval);
    };
  }, [runSaveTImer]);
  const SaveTimerOnTick = () => {
    console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("ActivityId") == null
          ? 0
          : sessionStorage.getItem("ActivityId"), //for home page
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
        sessionStorage.getItem("empTimeUtilization") == "undefined" ||
        sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment")
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMEONTICK);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result != null) {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            let FetchBreakTimerOnLoadData = JSON.parse(
              sessionStorage.getItem("FetchBreakTimerOnLoadData")
            );
            FetchBreakTimerOnLoadData.timerStart = result.timerStart;
            FetchBreakTimerOnLoadData.timerValue = result.timerValue;
            FetchBreakTimerOnLoadData.tSaveTimerEnabled =
              result.tSaveTimerEnabled;
            FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
              result.btnStartWorkTimerDisabled;
            // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
            //   result.txtBreakCommentsEnabled;
            // FetchBreakTimerOnLoadData.ddlActivityEnabled =
            //   result.ddlActivityEnabled;
            FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
              result.btnShowBreakPanelDisabled;
            // sessionStorage.setItem(
            //   "objdataview",
            //   JSON.stringify(result.data[0].objdataview)
            // );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(FetchBreakTimerOnLoadData)
            );
            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.employeeTimeUtilization)
            );
          }
        }
        // else if(result.status === 401)
        // {
        //   navigate("/")
        // }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  useEffect(() => {
    // debugger;
    for (let index = 1; index <= 30; index++) {
      setdisplayOrderArry(oldArray => [...oldArray, index]);
    }
    GetWorkFlowState();

    //editWorkItemStatus(item);
    setsearchDivVisible(true);
    setisLoadingFigma(false);
    //ButtonTextRef.current.value="Save";
    //setbuttonText("Save");
  }, []);

  const GetWorkFlowState = () => {
    debugger;
    let WorkItemStatusURL = getAPIURL(REACT_APP_GETWORKFLOWADMIN);
    let req1 = new Request(`${WorkItemStatusURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      }
      // body: JSON.stringify(body),
    });
    fetch(req1)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setisLoadingFigma(false);
          updateColumns(Object.keys(result.data[0]));
          setsearchWorkitemData(result.data);
          setDatacount(result.data.length);
          GetActivity();
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  const GetActivity = () => {
    debugger;
    let WorkItemStatusURL = getAPIURL(REACT_APP_GETACTIVITY);
    let req1 = new Request(`${WorkItemStatusURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      }
      // body: JSON.stringify(body),
    });
    fetch(req1)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setisLoadingFigma(false);
          //updateColumns(Object.keys(result.data[0]));
          debugger;
          setActivity(result.data);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName="Workflow State" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important"
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important"
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important"
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important"
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important"
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important"
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important"
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important"
          }
        }}
      />

      <div className="col-12">
        <div className="pageBody pd-top-0">
          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header pb-3 pt-2">
                <div class="row  align-items-center">
                  <div class="col">
                    <h6 /* className="pageName" */>MANAGE WORKFLOW STATE</h6>
                  </div>
                  {/* <div class="col"></div> */}
                  <div class="col text-end">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-3"
                      //style = "float: right;"
                      //color = "#f194ff"
                      onClick={() => {
                        pop();
                      }}
                    >
                      + Add New Workflow State
                    </button>
                  </div>
                </div>
              </div>

              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "blue",
                      marginLeft: "1rem"
                    }}
                  >
                    {Datacount} out of {Datacount} records displayed
                  </span>
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        title="List of Workflow State"
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            exportCSVName: "Export as Excel",
                            exportPDFName: "Export as pdf"
                          },
                          header: {
                            actions: ""
                          }
                        }}
                        /*  editable={{
                          isDeletable: row =>
                            row.workflowStateName === "Approval Coordinator"
                        }} */
                        actions={[
                          {
                            icon: () => <EditIcon />,
                            /* disabled: rawData =>
                              rawData.isEditable.checked ? false : true, */
                            tooltip: "Update Already Completed",

                            onClick: (event, rowData) =>
                              //editWorkItemStatus(rowData),

                              Addpop(rowData),

                            position: "row"
                          },

                          {
                            hidden: searchWorkitemData.isEditable === "false",
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete Already Completed",
                            onClick: (event, rowData) =>
                              DeleteWorkFlowState(rowData),
                            position: "row"
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px"
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          // pageSize: 20,
                          // pageSizeOptions: [20, 50, 100, 200],
                          paging: false,
                          exportButton: true,
                          sorting: true,
                          filtering: false,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px"
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important"
                          }
                        }}
                        columns={columns}
                        icons={tableIcons}
                        data={searchWorkitemData}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px"
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <Footer />

      {/* Add Modal */}

      <Modal
        show={openPopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Workflow State</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageBody pd-top-0">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="fieldSet">
                  {/*  <h4 className="mb-4">Add/Edit WorkItem Status</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {errorMessage.record}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {errorMessage.name}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Workflow State Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          type="text"
                          //value={errorCategoryName}
                          ref={WorkNameRef}
                          title={
                            "Enter Workflow State Name corresponding to the Activity"
                          }
                          //onChange={getWorkitemId}
                        />
                        {/* <span >{errorMessage.name}</span> */}
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="mb-3">
                        <label className="mandatory">
                          Activity Name
                          {/* <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span> */}
                        </label>
                        {/* <div>
                          <span
                            style={{
                              color: "red"
                            }}
                          >
                            {{errorMessage.name}}
                          </span>
                        </div> */}
                        <select
                          className="form-control form-control-sm"
                          name="RelevantApplicationPage"
                          /* onChange={onChangepage}
                         value={page} */
                          title={"Select Activity Name."}
                          ref={ActivityRef}
                        >
                          {/* <option>Select</option> */}
                          {Activity.map(state => (
                            <option value={state.activityID}>
                              {state.activityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div className="mb-1">
                        <label className="form-label">Remarks</label>
                        <textarea
                          name="Text1"
                          cols="40"
                          rows="2"
                          maxLength="4000"
                          title={"Remarks should not exceed 4000 characters"}
                          ref={RemarksRef}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-1" />

                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-1">
                        <label className="form-label">Is Deleted</label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          ref={isDeletedRef}
                          title={
                            "Check to flag this entry as deleted.Deleted fields are only removed from the scope but not actually deleted"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-1">
                        <label className="form-label">Is Editable</label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          ref={isEditableRef}
                          title={
                            "Check to enable editing of the entry in future"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <hr className="mb-4" /> */}
                  {/* <hr className="mb-4" />
                <div className="fieldSet"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2"></div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={
                            "Click here to save workflow state and return to manage workflow state list page"
                          }
                          onClick={() => {
                            Save();
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={
                            "Click here to save more than one workflow state"
                          }
                          onClick={() => {
                            AddworkFlowState();
                          }}
                        >
                          Save and Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {searchDivVisible ? (
              <div className="dashBoxs">
                {isLoadingFigma ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {},

                            header: {
                              actions: ""
                            }
                          }}
                          actions={[]}
                          title="Existing Entries "
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px"
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100, 200],
                            paging: false,
                            exportButton: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: true,
                            toolbarButtonAlignment: "right",
                            headerStyle: {
                              height: 30
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px"
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important"
                            }
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={searchWorkitemData}
                          components={{
                            Container: props => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: props => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em"
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px"
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* Add Modal End */}

      {/* Edit Modal */}

      <Modal
        show={openAddPopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={AddpopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit WorkFlow State</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageBody pd-top-0">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="fieldSet">
                  {/*  <h4 className="mb-4">Add/Edit WorkItem Status</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {errorMessage.record}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {errorMessage.name}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Workflow State Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          type="text"
                          /* value={workStatus}  
                          onChange={onChangeStatus} */
                          ref={WorkNameRefpop}
                          //onChange={getWorkitemId}
                          title={
                            "Enter Workflow State Name corresponding to the Activity"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="mandatory">
                          Activity Name
                          {/*  <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span> */}
                        </label>

                        <select
                          className="form-control form-control-sm"
                          name="RelevantApplicationPage"
                          /* onChange={onChangepage}
                         value={page} */
                          ref={ActivityRefpop}
                          title={"Select Activity Name."}
                        >
                          {Activity.map(state => (
                            <option value={state.activityID}>
                              {state.activityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div className="mb-1">
                        <label className="form-label">Remarks</label>
                        <textarea
                          name="Text1"
                          cols="40"
                          rows="2"
                          maxLength="4000"
                          title={"Remarks should not exceed 4000 characters"}
                          /* value={remarks} onChange={onChangeremarks} */ ref={
                            RemarksRefpop
                          }
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-1" />

                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-1">
                        <label className="form-label">Is Deleted</label>
                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          title={
                            "Check to flag this entry as deleted.Deleted fields are only removed from the scope but not actually deleted"
                          }
                          role="switch" /* defaultChecked={isDeleted}  onChange={onChangeisDeleted} */
                          /* value={isDeleted} */ ref={isDeletedRefpop}
                        />
                      </div>
                    </div>
                    <div className="col-3" style={{ height: "81px" }}>
                      <div className="mb-1">
                        <label className="form-label">Is Editable</label>

                        <input
                          type="checkbox"
                          id="chkIsDeletedForm"
                          role="switch"
                          title={
                            "Check to enable editing of the entry in future"
                          }
                          /* defaultChecked={isDeleted}  onChange={onChangeisDeleted} */
                          /* value={isDeleted} */ ref={isEditableRefpop}
                        />
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <hr className="mb-4" /> */}
                  {/* <hr className="mb-4" /> */}
                  {/* <div className="fieldSet"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2"></div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetUpdate}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          title={"Click here to update workflow state"}
                          //ref={ButtonTextRef}
                          onClick={() => {
                            Updateworkflowstate();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {searchDivVisible ? (
              <div className="dashBoxs">
                {isLoadingFigma ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {},

                            header: {
                              actions: ""
                            }
                          }}
                          actions={[]}
                          title="Existing Entries"
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px"
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100, 200],
                            paging: false,
                            exportButton: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: true,
                            toolbarButtonAlignment: "right",
                            headerStyle: {
                              height: 30
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px"
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important"
                            }
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={searchWorkitemData}
                          components={{
                            Container: props => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: props => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em"
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px"
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal */}
    </>
  );
};

export default React.memo(WorkFlowState);
